import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {LOG, XSUtils} from '@xs/base';

export abstract class XSAuthenticationGuard {

    protected returnUrl: string | undefined;

    protected constructor(
        protected router: Router,
        protected defaultRoute: string,
        protected baseRoute: string,
        protected loginRoute: string) {
    }

    protected abstract isAuthenticated(): Promise<boolean> | boolean;

    protected async baseCanActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const currentRoute: string = XSUtils.removeFirstCharIfSlash(state.url);
        const authenticated = await this.isAuthenticated();
        if (authenticated) {
            if (currentRoute.startsWith(this.baseRoute)) {
                this.router.navigate([this.defaultRoute]).then();
                return false;
            } else if (!XSUtils.isEmpty(this.returnUrl)) {
                LOG().debug('Redirecting toward return URL : ' + this.returnUrl);
                this.router.navigate([this.returnUrl]).then();
                this.returnUrl = undefined;
                return false;
            }
        } else {
            if (currentRoute !== this.loginRoute) {
                if (!currentRoute.startsWith(this.baseRoute)) this.returnUrl = currentRoute;
                this.router.navigate([this.loginRoute]).then();
                return false;
            }
        }

        return true;
    }
}
