<div [ngClass]="{ 'xs-disabled': disabled === true, 'xs-border-discrete xs-border-radius-default-imp xs-p-10': showBorder }" class="{{ styleClass }} xs-flex-column">
    <label *ngIf="label | xsIsNotEmpty" [ngClass]="{ 'lce-resource-partial-label-ml': showIcon }" class="xs-data-field-label lce-resource-partial-label {{ labelStyleClass }}">{{ label! | translate }}</label>
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full">
        <div class="xs-flex-row xs-width-full">
            <xs-icon *ngIf="showIcon" [ngClass]="{'xs-clickable': viewRecord}" [value]="ICON_STAMP" styleClass="lce-resource-partial-avatar-icon xs-color-secondary-imp xs-font-size-20-imp"></xs-icon>
            <div class="xs-width-10 xs-height-10 xs-mt-3 xs-border-radius-round xs-mr-6 {{statusBackgroundColorClass}}"></div>

            <div class="xs-flex-column">
                    <span (click)="onClick()" [ngClass]="{'xs-clickable': viewRecord}"
                          class="xs-display-block xs-font-weight-500 {{ intermediate ? 'xs-font-size-intermediate-imp' : '' }} {{ sizeStyleClass }}">
                        {{ 'lce.shared.facility.paymentTransfer.transferFrom' | translate: {date: (data.transactionDate | xsDate : format)} }}</span>

                <xs-time-ago [givenDate]="data.transactionDate" styleClass="xs-font-size-small-imp xs-color-secondary"></xs-time-ago>
            </div>
        </div>

    </div>
</div>
