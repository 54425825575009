<div class="xs-position-relative">
    <xs-table
            (onPagination)="paginationEvent.emit($event)"
            (onResetPreferences)="resetPreferencesEvent.emit()"
            (onResetSort)="resetSortEvent.emit()"
            (onRowDoubleClick)="openRecordDialog($event)"
            (onSort)="sortEvent.emit($event)"
            [caption]="caption"
            [columns]="columns"
            [data]="data"
            [emptyMessage]="emptyMessage"
            [loadingState]="loadingState"
            [nRecords]="nResults"
            [options]="options"
            [subCaption]="subCaption"
            captionStyleClass="xs-font-size-medium xs-font-weight-500"
            columnsStyleClass="xs-color-secondary xs-font-size-percent-90"
            subCaptionStyleClass="xs-font-size-percent-90"
            tableStyleClass="xs-table-border xs-table-border-discrete">

        <ng-template let-oneData xsTemplate="code">
            <xs-data-field-identifier
                    (clickEvent)="openRecordDialog(oneData)"
                    [copyable]="false"
                    [ellipsis]="true"
                    [required]="true"
                    [testColored]="true"
                    [value]="oneData.code"
                    type="code"
                    valueStyleClass="xs-font-size-intermediate">
            </xs-data-field-identifier>
        </ng-template>
        <ng-template let-oneData xsTemplate="createdOn">
            <xs-data-field-calendar
                    [calendarOptions]="calendarOptions"
                    [timeAgoLive]="true"
                    [timeAgoShortMode]="true"
                    [timeAgo]="true"
                    [value]="oneData.createdOn"
                    valueStyleClass="xs-font-size-intermediate xs-font-weight-500 xs-white-space-nowrap">
            </xs-data-field-calendar>
        </ng-template>
        <ng-template let-oneData xsTemplate="stamps">
            <lce-facility-town-hall-stamp-number
                    [badgeMode]="true"
                    [numberOfStampsRemaining]="oneData!.numberOfStampsLeft"
                    [numberOfStamps]="oneData!.numberOfStamps">
            </lce-facility-town-hall-stamp-number>
        </ng-template>
        <ng-template let-oneData xsTemplate="status">
            <lce-facility-town-hall-stamp-token-status
                    [data]="oneData.status"
                    [shortMode]="true"
                    [showBackground]="true">
            </lce-facility-town-hall-stamp-token-status>
        </ng-template>
        <ng-template let-oneData xsTemplate="mobilePhoneNumber">
            <xs-data-field-phone-number [required]="false" [value]="oneData.mobilePhoneNumber"></xs-data-field-phone-number>
        </ng-template>
        <ng-template let-oneData xsTemplate="actions">
            <lce-facility-town-hall-stamp-token-actions [data]="oneData" [resourceService]="facilityStampTokenService">
            </lce-facility-town-hall-stamp-token-actions>
        </ng-template>
    </xs-table>
</div>
