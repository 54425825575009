import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCEHttpClientService} from '@lce/core';
import {XSTranslationService} from '@xs/common';
import {XSAuthenticationBaseService, XSAuthenticationEndpoint, XSAuthenticationRoute, XSDialogService, XSLocalStorageService} from '@xs/core';
import {LCE_MBO_ENDPOINT} from '../constants/lce-mbo-endpoint.constant';
import {LCE_MBO_AUTHENTICATION_ROUTE} from '../constants/lce-mbo.constant';
import {LCEMBOContextService} from './lce-mbo-context.service';

@Injectable({providedIn: 'root'})
export class LCEMBOAuthenticationService extends XSAuthenticationBaseService {

    constructor(
        protected router: Router,
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCEMBOContextService,
        protected translationService: XSTranslationService,
        protected localStorageService: XSLocalStorageService,
        protected dialogService: XSDialogService) {
        super(router, httpClientService, contextService, translationService, localStorageService, dialogService, LCE_MBO_ICON);
    }

    public getEndpoint(): XSAuthenticationEndpoint {
        return LCE_MBO_ENDPOINT.authentication;
    }

    public getRoute(): XSAuthenticationRoute {
        return LCE_MBO_AUTHENTICATION_ROUTE;
    }
}
