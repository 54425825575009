import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEUserCustomerAdditionalStats, LCEUserCustomerSearch, LCEUserCustomerService, LCEUserCustomerState} from '@lce/core';
import {XSIntegerRange, XSPKDTOStats} from '@xs/base';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

type LoadingError = { loading: boolean, error: any };

@Component({selector: 'lce-user-customer-statistics', templateUrl: './lce-user-customer-statistics.component.html'})
export class LCEUserCustomerStatisticsComponent implements OnInit {

    readonly TR_BASE: string = 'lce.shared.customer.';

    readonly STATE = LCEUserCustomerState;

    @Input() styleClass?: string;

    @Output() additionalStatsClickEvent = new EventEmitter<LCEUserCustomerState>();

    stats: XSPKDTOStats;
    additionalStats: LCEUserCustomerAdditionalStats = {
        total: 0,
        registered: 0,
        unregistered: 0,
        orderedAtLeastOnce: 0,
        neverOrdered: 0
    };

    errorA: any;

    additionalStatsLoadingError: any;

    loading: boolean = false;

    showBackgroundIcon: boolean = true;

    private subscription: Subscription = new Subscription();

    constructor(private customerService: LCEUserCustomerService) {
    }

    ngOnInit(): void {
        this.update();
    }

    public update(): void {
        this.retrieveStatsA();
        this.retrieveAdditionalStats();
    }

    public onAdditionalStatsClick(state: LCEUserCustomerState): void {
        this.additionalStatsClickEvent.emit(state);
    }

    private retrieveAdditionalStats(): void {
        this.additionalStatsLoadingError = {
            registered: {loading: true, error: undefined},
            unregistered: {loading: true, error: undefined},
            orderedAtLeastOnce: {loading: true, error: undefined},
            neverOrdered: {loading: true, error: undefined}
        };

        this.retrieveAdditionalStat(this.additionalStatsLoadingError!.registered!, {registered: true}, (stat: number) => {
            this.additionalStats.registered = stat;
        });
        this.retrieveAdditionalStat(this.additionalStatsLoadingError!.unregistered!, {registered: false}, (stat: number) => {
            this.additionalStats.unregistered = stat;
        });
        this.retrieveAdditionalStat(this.additionalStatsLoadingError!.orderedAtLeastOnce!, {numberOfOrdersRange: new XSIntegerRange(1, undefined)}, (stat: number) => {
            this.additionalStats.orderedAtLeastOnce = stat;
        });
        this.retrieveAdditionalStat(this.additionalStatsLoadingError!.neverOrdered!, {numberOfOrdersRange: new XSIntegerRange(0, 0)}, (stat: number) => {
            this.additionalStats.neverOrdered = stat;
        });
    }

    private retrieveStatsA(): void {
        this.errorA = undefined;
        this.loading = true;
        this.subscription.add(
            this.customerService
                .retrieveStats()
                .pipe(finalize(() => (this.loading = false)))
                .subscribe({
                    next: (stats) => (this.stats = stats),
                    error: (error) => (this.errorA = error)
                })
        );
    }


    private retrieveAdditionalStat(arg: LoadingError, search: LCEUserCustomerSearch, fnCallback?: (stat: number) => void): void {
        this.subscription.add(
            this.customerService
                .count(search)
                .pipe(finalize(() => (arg.loading = false)))
                .subscribe({
                    next: (count) => fnCallback!(count.total),
                    error: (error) => (arg.error = error)
                })
        );
    }
}
