<div class="xs-width-full {{ styleClass }}">
    <div *ngIf="isCertificateOrder()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.status | lceCertificateOrderStatus" label="lce.shared.label.statusPrefix"
                                    valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="'lce.core.certificateType.' + data.certificateType" label="lce.core.label.certificateType"
                                    valueStyleClass="xs-font-size-intermediatte">
                </xs-data-field-text>
            </div>
            <div class="col-12 md:col-6">
                <xs-data-field-text
                        label="lce.core.label.deliveryDestinationType"
                        value="{{ 'lce.core.deliveryDestinationType.' + data.destination | translate }}"
                        valueStyleClass="xs-font-size-intermediatte">
                </xs-data-field-text>
            </div>
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.batchProcessCode" label="lce.core.label.batchNumberShort" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isSuggestion()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text label="xs.core.label.status" value="{{ 'lce.shared.suggestion.status.' + data.status }}"
                                    valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text
                        label="lce.shared.suggestion.label.suggestedBy"
                        value="{{ data.anonymous === true ? ('lce.shared.label.anonymous' | translate) : data.customerCode }}"
                        valueStyleClass="xs-font-size-intermediatte">
                </xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isBirthDeclaration()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.birthNumber" label="lce.shared.birthDeclaration.delivery.birthNumber"
                                    valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text
                        label="lce.shared.birthDeclaration.delivery.newBornGender"
                        value="{{ 'xs.common.gender.' + data.newBornGender | translate }}"
                        valueStyleClass="xs-font-size-intermediatte">
                </xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.newBornName | xsPersonName" label="lce.shared.birthDeclaration.delivery.newBornName" valueStyleClass="xs-font-size-intermediatte">
                </xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isDistrict()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.fullName" label="xs.common.label.name" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.code" label="xs.common.label.code" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isMunicipality()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.fullName" label="xs.common.label.name" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.code" label="xs.common.label.code" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isFacility()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.fullName" label="xs.common.label.name" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.code" label="xs.common.label.code" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isServicePoint()">
        <div class="grid xs-fields xs-fields-spacer-15">
            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.fullName" label="xs.common.label.name" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>

            <div class="col-12 md:col-6">
                <xs-data-field-text [value]="data.code" label="xs.common.label.code" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
            </div>
        </div>
    </div>

    <div *ngIf="isNews()">
        <div class="xs-flex-row">
            <div class="xs-mr-20">
                <img [src]="data.coverImage" alt="" class="xs-height-100 xs-width-100 xs-border-radius-default"/>
            </div>
            <div class="grid xs-fields">
                <div class="col-12">
                    <xs-data-field-text [value]="data[buildArticleTitle()]" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text label="xs.core.label.status" value="{{ 'lce.shared.news.status.' + data.status | translate }}" valueStyleClass="xs-font-size-intermediatte">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            label="lce.shared.label.reviewStatus"
                            value="{{ 'lce.shared.news.reviewStatus.' + data.reviewStatus | translate }}"
                            valueStyleClass="xs-font-size-intermediatte">
                    </xs-data-field-text>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isSmartcity()">
        <div class="xs-flex-row">
            <div class="xs-mr-20">
                <img [src]="data.coverImage" alt="" class="xs-height-100 xs-width-100 xs-border-radius-default"/>
            </div>
            <div class="grid xs-fields">
                <div class="col-12">
                    <xs-data-field-text [value]="data[buildArticleTitle()]" valueStyleClass="xs-font-size-intermediatte"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text label="xs.core.label.status" value="{{ 'lce.shared.smartcity.status.' + data.status | translate }}"
                                        valueStyleClass="xs-font-size-intermediatte">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            label="lce.shared.label.reviewStatus"
                            value="{{ 'lce.shared.smartcity.reviewStatus.' + data.reviewStatus | translate }}"
                            valueStyleClass="xs-font-size-intermediatte">
                    </xs-data-field-text>
                </div>
            </div>
        </div>
    </div>
</div>
