import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCEBirthDeclaration, LCEBirthDeclarationPartial, LCEBirthDeclarationSearch, LCEBirthDeclarationService, LCECoreDomainUtils} from '@lce/core';
import {XSSearchResult, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {Observable, Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEBirthDeclarationDialogService} from './lce-birth-declaration-dialog.service';

@Component({
    selector: 'lce-birth-declarations',
    templateUrl: './lce-birth-declarations.component.html',
    host: {class: 'xs-flex-column xs-width-full xs-height-full xs-flex-1'},
    providers: [LCEBirthDeclarationDialogService]
})
export class LCEBirthDeclarationsComponent implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_CORE: string = 'xs.core.';
    readonly TR_CORE_LABEL: string = this.TR_CORE + 'label.';
    readonly TR_ERROR_MESSAGE: string = this.TR_CORE + 'errorMessage.';

    TR_BASE = 'lce.shared.birthDeclaration.';
    TR_BASE_MAIN = this.TR_BASE + 'main.';

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    @ViewChild('actionMenu') actionMenu: TieredMenu;

    dataManagerOptions: XSDataManagerOptions;

    errorMessage: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        public birthDeclarationService: LCEBirthDeclarationService,
        private birthDeclarationDialogService: LCEBirthDeclarationDialogService) {
    }

    ngOnInit(): void {
        this.buildDataManagerOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public update(): void {
        this.dataManager.updateSearch();
    }

    public onCreate(): void {
        this.birthDeclarationDialogService.openCreateUpdateDialog({showRecordAfterSave: true, onSave: () => this.dataManager.search()});
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public onEdit(): void {
        this.birthDeclarationDialogService.openCreateUpdateDialog({
            birthDeclaration: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: birthDeclarationRecord => {
                this.dataManager.updateSelectedRecordData(birthDeclarationRecord);
                this.dataManager.selectedRowResult = LCECoreDomainUtils.toBirthDeclarationPartial(birthDeclarationRecord);
            }
        });
    }

    public canEdit(): boolean {
        return this.readonly !== false;
    }

    private retrieveBirthDeclaration(selectedRowResult: LCEBirthDeclarationPartial): Observable<LCEBirthDeclaration> {
        return this.birthDeclarationService.retrieve(selectedRowResult.id!);
    }

    private searchBirthDeclaration(query?: string): Observable<XSSearchResult<LCEBirthDeclarationPartial>> {
        const birthDeclarationSearch: LCEBirthDeclarationSearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: 0,
            paginationSize: 20,
            sort: ['createdOn|desc']
        };
        return this.birthDeclarationService.search(birthDeclarationSearch);
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query) => this.searchBirthDeclaration(query),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveBirthDeclaration(selectedRowData),

            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultsFound',
                listingItemStyleClass: 'xs-min-width-350'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
