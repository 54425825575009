import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadPartial, LCEFacilityTownHallStampLoadSearch, LCEFacilityTownHallStampLoadService} from '@lce/core';
import {XSAssert, XSIDCodeFullName, XSPagination, XSSearchResult, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDataManagerOptions, XSDialogable} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEFacilityTownHallStampLoadDialogService} from './lce-facility-town-hall-stamp-load-dialog.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-loads',
    templateUrl: './lce-facility-town-hall-stamp-loads.component.html',
    host: {class: 'xs-width-full'},
    providers: [LCEFacilityTownHallStampLoadDialogService]
})
export class LCEFacilityTownHallStampLoadsComponent extends XSDialogable implements OnInit {
    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.facility.townHallStampLoad.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    @Input() readonly?: boolean;

    @Input() showBackgroundIcon?: boolean;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    @Input() styleClass?: string;

    @Input() facility: XSIDCodeFullName;

    private subscription: Subscription = new Subscription();

    constructor(
        public facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facility = this.dialogConfig.data.facility;
            console.log('facility', this.facility);
        }

        XSAssert.notEmpty(this.facility, 'facility');
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private refresh(): void {
        this.dataManager.updateSearch();
    }

    private retrieveFacilityTownHallStampsLoad(selectedRowResult: LCEFacilityTownHallStampLoadPartial): Observable<LCEFacilityTownHallStampLoad> {
        return this.facilityTownHallStampLoadService.retrieve(selectedRowResult.id);
    }

    private searchFacilityTownHallStampsLoads(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCEFacilityTownHallStampLoadPartial>> {
        const facilityTownHallStampsLoad: LCEFacilityTownHallStampLoadSearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.facilityTownHallStampLoadService.search(facilityTownHallStampsLoad);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchFacilityTownHallStampsLoads(query, pagination),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveFacilityTownHallStampsLoad(selectedRowData),

            search: {
                defaultPaginationSize: 5,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultFound',
                listingItemStyleClass: 'xs-min-width-250'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }

}
