import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSTranslationService} from '@xs/common';
import {XSPaymentTransferPartial, XSPaymentTransferStatus} from '@xs/payment/base';
import {XS_PAYMENT_TRANSFER_STATUS_COLOR_MAP, XSPaymentTransferDialogService} from '@xs/payment/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-town-hall-payment-transfer-partial',
    templateUrl: './lce-facility-town-hall-payment-transfer-partial.component.html',
    host: {class: 'xs-width-full'},
    providers: [XSPaymentTransferDialogService]
})
export class LCEFacilityTownHallPaymentTransferPartialComponent implements OnInit, OnDestroy {

    readonly ICON_STAMP = LCE_SHARED_ICON.stamp;

    readonly TR_BASE: string = 'lce.shared.facility.';

    @Input() styleClass?: string;
    @Input() labelStyleClass?: string;
    @Input() sizeStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() intermediate?: boolean;

    @Input() label?: string;
    @Input() showDefaultLabel?: boolean;

    @Input() showCode?: boolean;
    @Input() showIcon?: boolean;
    @Input() showBorder?: boolean;

    @Input() viewRecord?: boolean;

    @Input() data: XSPaymentTransferPartial;

    format: string;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService, private paymentTransferDialogService: XSPaymentTransferDialogService) {
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.handleDateFormat()));
    }

    get statusBackgroundColorClass(): string {
        return XS_PAYMENT_TRANSFER_STATUS_COLOR_MAP.get(this.data.status as XSPaymentTransferStatus)!.backgroundColor;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.showCode)) this.showCode = true;
        if (XSUtils.isNull(this.intermediate)) this.intermediate = true;
        if (this.showDefaultLabel === true) {
            this.label = 'lce.core.label.facility.';
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onClick(): void {
        if (!this.viewRecord) return;
        this.paymentTransferDialogService.openRecordDialog({transferID: this.data.id});
    }

    private handleDateFormat(): void {
        const currentLanguage = this.translationService.getCurrentLanguage();
        this.format = currentLanguage === XSLanguage.FRENCH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR : XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN;
    }
}
