import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCEFacilityTownHallService} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSPaymentTransferPartial} from '@xs/payment/base';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({selector: 'lce-facility-town-hall-payment-last-transfer', templateUrl: './lce-facility-town-hall-payment-last-transfer.component.html'})
export class LCEFacilityTownHallPaymentLastTransferComponent implements OnInit, OnDestroy {

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE = 'lce.shared.facility.';

    @Input() styleClass?: string;

    @Input() facilityID: string;

    @Input() showBorder?: boolean;
    @Input() showBackgroundColor?: boolean;
    @Input() showLoaderText?: boolean;

    data: XSPaymentTransferPartial;

    error: any;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private facilityTownHallService: LCEFacilityTownHallService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityID, 'facilityID');
        this.update();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.data);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public update(): void {
        this.retrievePaymentTransfer();
    }

    private retrievePaymentTransfer(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID, {text: this.showLoaderText ? 'lce.shared.facility.paymentTransfer.lastPaymentLoading' : undefined});
        this.subscription.add(
            this.facilityTownHallService
                .retrieveLastTransfer(this.facilityID)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: transfer => {
                        this.data = transfer;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

}
