<div class="lce-suggestions-main {{ styleClass }}">
    <div class="lce-suggestions-main-container">

        <lce-suggestion-last
            #lastSuggestion
            (avatarClickEvent)="onLastSuggestionAvatarClick($event)"
            (codeClickEvent)="onLastSuggestionCodeClick($event)"
            *ngIf="lastSuggestionVisible"
            styleClass="xs-max-width-600 xs-ml-25 xs-mb-50">
        </lce-suggestion-last>

        <p-splitter
            #pSplitter
            [gutterSize]="splitterConfig.gutterSize"
            [minSizes]="splitterConfig.minSizes"
            [panelSizes]="splitterConfig.panelSizes"
            [stateKey]="splitterConfig.stateKey"
            [stateStorage]="splitterConfig.stateStorage"
            class="xs-flex-column xs-height-full xs-flex-1"
            styleClass="xs-flex-1 xs-min-height-750">
            <ng-template pTemplate>
                <div class="xs-flex-column-center xs-width-full xs-plr-25">
                    <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750">
                        <ng-template xsTemplate="sort">
                            <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
                        </ng-template>

                        <!-- === Row Result Template ===-->
                        <ng-template let-oneResult xsTemplate="row">
                            <lce-suggestion-partial [data]="oneResult"></lce-suggestion-partial>
                        </ng-template>

                        <!-- === Below Search Template === -->
                        <ng-template xsTemplate="belowSearch">
                            <div class="xs-flex-column-center xs-width-full xs-pt-25">
                                <div class="xs-flex-column-center xs-width-full xs-xs-hidden xs-sm-hidden xs-md-hidden xs-lg-hidden xs-xl-mb-25">
                                    <p-selectButton
                                            (onChange)="onStatusFilterChange()"
                                            [(ngModel)]="statusFilter"
                                            [multiple]="true"
                                            [options]="statusFilterOptions"
                                            class="xs-width-full xs-max-width-500"
                                            styleClass="xs-select-button xs-select-button-small">
                                    </p-selectButton>
                                </div>
                                <div class="xs-width-full xs-max-width-400">
                                    <xs-chips (valueChange)="onStatusFilter($event)" [chips]="chipFilters" [selectable]="true" [small]="true" [swiperNavigation]="true" [swiper]="true"></xs-chips>
                                </div>

                            </div>
                        </ng-template>

                        <!-- === Record Toolbar Template === -->
                        <ng-template xsTemplate="recordToolbar">
                            <div class="xs-flex-column xs-width-full">
                                <div *ngIf="suggestionActions.hasError()" class="xs-mb-15">
                                    <xs-error
                                            [error]="suggestionActions.error"
                                            [message]="TR_BASE + 'updateStatusError'"
                                            [showErrorDetailButton]="true"
                                            mode="inline">
                                    </xs-error>
                                </div>
                                <lce-suggestion-actions
                                    #suggestionActions
                                    (suggestionChange)="onSuggestionChange($event)"
                                    [(suggestion)]="dataManager.selectedRecordData"
                                    [disabled]="!canDoAction()">
                                </lce-suggestion-actions>
                            </div>
                        </ng-template>

                        <!-- === Record Template ===-->
                        <ng-template let-recordData xsTemplate="record">
                            <lce-suggestion-record
                                    [suggestion]="recordData"
                                    class="xs-flex-column xs-flex-1"
                                    loadingStyleClass="xs-min-height-500 xs-min-width-600"
                                    styleClass="xs-width-full xs-flex-1 xs-max-width-1000 xs-min-width-500 xs-p-25">
                            </lce-suggestion-record>
                        </ng-template>
                    </xs-data-manager>
                </div>
            </ng-template>
            <ng-template pTemplate>
                <lce-suggestion-lateral-panel
                    #suggestionLateralPanel
                    (selectedByMayorClickEvent)="onSelectedByMayorClick()"
                    (statClickEvent)="onStatClick($event)"
                    *ngIf="lateralPanelVisible"
                    [municipalityCode]="municipalityCode"
                    [showBackgroundIcon]="true">
                </lce-suggestion-lateral-panel>
            </ng-template>
        </p-splitter>
    </div>
</div>
