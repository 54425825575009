import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadCreate, LCEFacilityTownHallStampLoadService} from '@lce/core';
import {LOG, XSAssert, XSIDCodeFullName, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldCheckboxOptions, XSInputFieldTextAreaOptions, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-facility-town-hall-stamp-load-create', templateUrl: 'lce-facility-town-hall-stamp-load-create.component.html'})
export class LCEFacilityTownHallStampLoadCreateComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly LOADER_ID = XSUtils.uuid() + 'stampLoadCreate';
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() facility: XSIDCodeFullName;

    @Input() lastLoadDate: string | Date;

    @Input() showBorder?: boolean;

    @Output() closeEvent = new EventEmitter<LCEFacilityTownHallStampLoad>();
    @Output() saveEvent = new EventEmitter<LCEFacilityTownHallStampLoad>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    formGroup: FormGroup = new FormGroup({});
    stampsTokensField: XSInputFieldTextOptions;
    noteField: XSInputFieldTextAreaOptions;
    unloadField: XSInputFieldCheckboxOptions;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.save()
    };

    resetConfirmation: XSConfirmation = {
        key: 'resetConfirmationKey',
        trMessage: 'xs.core.label.confirmationResetForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.reset();
        },
        reject: () => {
        }
    };
    closeConfirmation: XSConfirmation = {
        key: 'closeConfirmationKey',
        trMessage: 'xs.core.label.confirmationLeaveForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.closeDialog();
        },
        reject: () => {
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService) {
        super();
    }

    ngOnInit(): void {

        if (this.isDialog()) {
            console.log('dialogConfig', this.dialogConfig);
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.facility = this.dialogConfig.data.facility;
            this.lastLoadDate = this.dialogConfig.data.lastLoadDate;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        this.validate();
        this.buildFields();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public shouldShowCloseConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public reset() {
        this.formGroup.reset();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public save(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating Stamp load ...');
        this.loaderService.startLoader(this.LOADER_ID);

        let formData = this.formGroup.value;
        let stampLoadCreate: LCEFacilityTownHallStampLoadCreate = {
            facilityCode: this.facility.code,
            numberOfStamps: formData.numberOfStamps,
            unload: formData.unload,
            note: formData.note
        };
        console.log('Stamp Load Create', stampLoadCreate);
        this.subscription.add(
            this.facilityTownHallStampLoadService.create(stampLoadCreate)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe(
                    {
                        next: (stampLoad) => {
                            LOG().debug('Stamp Load created :-) [ id: ' + stampLoad.id + ']');
                            this.formGroup.reset();
                            this.formGroup.clearValidators();
                            this.saveEvent.emit(stampLoad);
                        },
                        error: (error) => this.error = error
                    }
                )
        );
    }

    private isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.stampsTokensField.control?.value) ||
            !XSUtils.isEmpty(this.noteField.control?.value) ||
            !XSUtils.isEmpty(this.unloadField.control?.value));
    }

    private buildFields(): void {
        this.stampsTokensField = {
            fieldName: 'numberOfStamps',
            control: new FormControl(undefined, Validators.required),
            type: 'number',
            inputStyleClass: 'xs-height-100-imp xs-font-size-60-imp xs-text-align-center-imp xs-border-radius-default-imp',
            placeholder: '0'

        };

        this.noteField = {
            fieldName: 'note',
            control: new FormControl(undefined),
            inputStyleClass: 'xs-height-62-imp',
            rows: 5,
            cols: 50,
            placeholder: 'lce.shared.facility.townHallStampLoad.label.moreDetails'

        };
        this.unloadField = {
            fieldName: 'unload',
            control: new FormControl(undefined),
            checkboxLabel: 'lce.shared.facility.townHallStampLoad.label.unload',
            belowText: 'lce.shared.facility.townHallStampLoad.label.unloadDescription',
            belowTextStyleClass: 'xs-mt-1-imp',
            binary: true,
            changeEvent: (event: boolean) => {
                console.log(event);
                if (event) this.noteField.control!.addValidators(Validators.required);
                else this.noteField.control!.removeValidators(Validators.required);
                this.noteField.control!.updateValueAndValidity();
            }

        };

        this.formGroup.addControl(this.stampsTokensField.fieldName, this.stampsTokensField.control!);
        this.formGroup.addControl(this.noteField.fieldName, this.noteField.control!);
        this.formGroup.addControl(this.unloadField.fieldName, this.unloadField.control!);
    }

    private validate(): void {
        XSAssert.notEmpty(this.facility, 'facility');
        XSAssert.notEmpty(this.lastLoadDate, 'lastLoadDate');
    }
}
