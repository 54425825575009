import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCESuggestion, LCESuggestionService, LCESuggestionStatus} from '@lce/core';
import {XSIDCode, XSUtils} from '@xs/base';
import {XSLoaderService, XSLoaderType} from '@xs/common';
import {XSStyleHelper} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';

@Component({
    selector: 'lce-suggestion-last',
    templateUrl: './lce-suggestion-last.component.html',
    host: {class: 'xs-width-full'}
})
export class LCESuggestionLastComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.suggestion.label.';

    readonly LOADER_ID = 'lastSuggestion';
    readonly LOADER_TYPE = XSLoaderType.THREE_BOUNCE;
    readonly LOADER_SIZE?: number = 15;

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data?: LCESuggestion;

    @Input() showBorder?: boolean;
    @Input() showIcon?: boolean;
    @Input() showLabel?: boolean;

    @Output() codeClickEvent = new EventEmitter<XSIDCode>();
    @Output() avatarClickEvent = new EventEmitter<XSIDCode>();

    error: any;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private suggestionService: LCESuggestionService) {
    }

    get statusColorStyleClass(): string {
        return XSUtils.isNull(this.data) ? XSStyleHelper.CLASS.color.secondaryImp : LCESharedUtils.getSuggestionColorStatusStyleClass(this.data!.status);
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showIcon)) this.showIcon = true;
        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (XSUtils.isNull(this.showLabel)) this.showLabel = true;
        if (XSUtils.isEmpty(this.data)) {
            this.update();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateStatus(status: LCESuggestionStatus): void {
        if (XSUtils.isNull(this.data)) {
            return;
        }
        this.data!.status = status;
    }

    public onAvatarClick(): void {
        this.avatarClickEvent.emit({id: this.data!.id, code: this.data!.code});
    }

    public update(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.suggestionService.findNLast()
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: lastSuggestions => {
                        this.data = XSUtils.isEmpty(lastSuggestions) ? undefined : lastSuggestions[0];
                    },
                    error: error => this.error = error
                })
        );
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }
}
