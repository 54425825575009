import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEServicePoint, LCETerminal, LCETerminalCanOptions, LCETerminalService} from '@lce/core';
import {LOG, XS_STR_SPACE, XSDevice, XSUtils} from '@xs/base';
import {XSDeviceOsIconPipe, XSLoaderService, XSSize, XSTranslationService} from '@xs/common';
import {XSAddressFieldOptions, XSAvatar, XSButton, XSConfirmation, XSContactPersonFieldOptions, XSDataManagerComponent, XSDialogable, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-terminal-record',
    templateUrl: './lce-terminal-record.component.html',
    providers: [XSDeviceOsIconPipe],
    host: {class: 'xs-width-full'}
})
export class LCETerminalRecordComponent extends XSDialogable implements OnInit {

    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.terminal.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() terminalID?: string;
    @Input() terminal?: LCETerminal;

    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;

    @Input() canOptions?: LCETerminalCanOptions;

    @Output() editEvent = new EventEmitter<LCETerminal>();
    @Output() closeEvent = new EventEmitter<LCETerminal>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEServicePoint>;

    generateSecurityCodeConfirmation: XSConfirmation = {
        key: 'generateSecurityCodeConfirmationKey',
        trMessage: this.TR_BASE_LABEL + 'generateSecurityCodeConfirmationMessage',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => this.generateSecurityCode()
    };

    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieve()
    };

    error = {
        generateSecurityCode: undefined,
        retrieveError: undefined
    };

    loading = {
        generateSecurityCode: false
    };

    securityCode: string;

    addressFieldOptions: XSAddressFieldOptions;
    primaryContactPersonFieldOptions: XSContactPersonFieldOptions;
    secondaryContactPersonFieldOptions: XSContactPersonFieldOptions;

    private subscription: Subscription = new Subscription();

    private readonly SECURITY_CODE_HIDDEN_VALUE: string = '****';

    constructor(
        public translationService: XSTranslationService,
        public terminalService: LCETerminalService,
        private loaderService: XSLoaderService,
        private deviceOsIconPipe: XSDeviceOsIconPipe) {
        super();
    }

    get deviceData(): XSDevice {
        return {
            id: this.terminal!.device.id,
            idType: this.terminal!.device.idType,
            os: this.terminal!.device.os
        };
    }

    get registeredStateLabel(): string {
        return XSUtils.isEmpty(this.terminal!.registeredOn) ? 'xs.core.label.false' : 'xs.core.label.true';
    }

    get headerAvatar(): XSAvatar {
        if (XSUtils.isEmpty(this.terminal?.device.os)) return {type: 'icon', data: LCE_SHARED_ICON.terminal};
        return {type: 'icon', size: XSSize.SMALL, data: this.deviceOsIconPipe.transform(this.terminal!.device.os)};
    }

    get headerIcon(): string {
        if (XSUtils.isEmpty(this.terminal?.device.os)) return LCE_SHARED_ICON.terminal;
        return this.deviceOsIconPipe.transform(this.terminal!.device.os);
    }

    get headerTitle(): string {
        const str = this.translationService.translateKey('lce.shared.label.terminal');
        let title = XSUtils.isEmpty(this.terminal) ? str : str + XS_STR_SPACE + this.terminal!.device.name;
        return title!;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.terminal) ? '...' : this.terminal!.code;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.terminalID = this.dialogConfig.data.terminalID;
            this.terminal = this.dialogConfig.data.terminal;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.terminal));
        }
        if (XSUtils.isEmpty(this.terminalID) && XSUtils.isEmpty(this.terminal)) {
            throw new Error('terminalID and terminal cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.terminalID) && !XSUtils.isEmpty(this.terminal)) {
            throw new Error('terminalID and terminal cannot both be set at the same time.');
        }
        this.securityCode = this.SECURITY_CODE_HIDDEN_VALUE;
        if (!XSUtils.isEmpty(this.terminalID)) this.retrieve();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSecurityCodeShow(): void {
        this.securityCode = this.terminal!.securityCode;
        setTimeout(() => (this.securityCode = this.SECURITY_CODE_HIDDEN_VALUE), 5000);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public onEdit(): void {
        this.editEvent.emit(this.terminal);
    }

    public canDisplayData(): boolean {
        return !this.hasErrorRetrieve() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.terminal);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasErrorRetrieve(): boolean {
        return !XSUtils.isNull(this.error.retrieveError);
    }

    public hasErrorGenerateNewCode(): boolean {
        return !XSUtils.isNull(this.error.generateSecurityCode);
    }

    private generateSecurityCode(): void {
        this.loading.generateSecurityCode = true;
        this.securityCode = this.SECURITY_CODE_HIDDEN_VALUE;
        this.subscription.add(
            this.terminalService.generateSecurityCode(this.terminal!.id)
                .pipe(finalize(() => this.loading.generateSecurityCode = false))
                .subscribe(response => {
                        this.terminal!.securityCode = response.securityCode;
                        this.terminal!.securityCodeGeneratedOn = response.securityCodeGeneratedOn;
                        this.terminal!.securityCodeGeneratedBy = response.securityCodeGeneratedBy;
                        this.terminal!.updatedOn = response.updatedOn;
                        this.terminal!.updatedBy = response.updatedBy;
                        this.onSecurityCodeShow();
                        LOG().debug('Terminal (' + response.code + ') Security Code generated on ' + response.securityCodeGeneratedOn + '].');
                    },
                    (error: any) => (this.error.generateSecurityCode = error)
                )
        );
    }

    private retrieve(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error.retrieveError = undefined;
        this.subscription.add(
            this.terminalService
                .retrieve(this.terminalID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (retrievedTerminal: LCETerminal) => {
                        this.securityCode = retrievedTerminal.securityCode;
                        this.terminal = retrievedTerminal;
                    },
                    error: (error: any) => (this.error.retrieveError = error)
                })
        );
    }
}
