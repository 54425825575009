<div class="xs-flex-column xs-width-full {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-baseline xs-justify-content-space-between xs-pb-10 xs-mb-30 xs-border-bottom-discrete">
        <span class="xs-display-block xs-font-size-medium xs-font-weight-500"> {{ TR_BASE + 'comments' | translate }} {{ hasComment() ? '(' + total + ')' : '(0)' }} </span>
        <div class="xs-flex-row xs-align-items-baseline xs-justify-content-end">
            <xs-button
                    (clickEvent)="onFillCommentMessage()"
                    label="Fill Comment Message"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="onRefresh()"
                    [disabled]="mainLoader.isRunning()"
                    [icon]="ICON.refresh"
                    class="xs-ml-40"
                    type="icon">
            </xs-button>
        </div>
    </div>

    <div class="xs-flex-row-center xs-width-full">
        <div class="xs-flex-column xs-width-full xs-max-width-1000">
            <div [ngClass]="{ 'xs-disabled': mainLoader.isRunning() }" class="xs-flex-column xs-width-full">
                <xs-input-field-textarea [options]="commentMessageField"></xs-input-field-textarea>
                <div class="xs-flex-row xs-width-full xs-justify-content-end xs-mt-10 xs-pr-10">
                    <xs-button
                            (clickEvent)="onCreateComment()"
                            [disabled]="isMessageEmpty() || createCommentLoading"
                            [icon]="ICON.send"
                            [label]="TR_BASE + 'postComment'"
                            [loading]="createCommentLoading"
                            loaderPosition="left"
                            type="button">
                    </xs-button>
                </div>
            </div>

            <div class="xs-flex-column xs-width-full xs-position-relative xs-mt-30 xs-min-height-200">
                <div class="xs-absolute-center-vh">
                    <xs-loader #mainLoader [loaderId]="MAIN_LOADER_ID" [loaderSize]="30"></xs-loader>
                </div>
                <div [ngClass]="{ 'xs-disabled': (comments | xsIsNotEmpty) && mainLoader.isRunning()  }" class="xs-flex-column xs-width-full">
                    <lce-news-article-comment *ngFor="let comment of comments" [comment]="comment" styleClass="xs-mt-20"></lce-news-article-comment>
                </div>
            </div>

            <div class="xs-flex-row xs-justify-content-center xs-width-full xs-mt-15">
                <p-paginator
                        (onPageChange)="handlePagination($event)"
                        *ngIf="hasComment()"
                        [rowsPerPageOptions]="ROWS_PER_PAGE_OPTIONS"
                        [rows]="search.paginationSize!"
                        [totalRecords]="total">
                </p-paginator>
            </div>
        </div>
    </div>

</div>
