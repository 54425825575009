<div class="grid xs-width-full {{ styleClass }}">

    <div class="col-5 xs-flex-row xs-align-items-center">
        <div [ngClass]="{
        'xs-pl-25': showAuthenticationStateColor,
        'xs-container-left-stripe-success': data.success && showAuthenticationStateColor,
        'xs-container-left-stripe-danger': !data.success && showAuthenticationStateColor }" class="xs-flex-row xs-align-items-center xs-width-full">

            <i class="{{ stateIconClass }} xs-display-block xs-font-size-18-imp xs-color-secondary xs-mr-12"></i>
            <div class="xs-flex-column xs-overflow-hidden">
                <xs-data-calendar
                        [ellipsis]="true"
                        [options]="{ type: 'dateTime', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }"
                        [value]="data.createdOn"
                        styleClass="xs-width-full-imp xs-font-size-intermediate xs-mb-2">
                </xs-data-calendar>

                <span *ngIf="!isUsernamePhoneNumber()" class="xs-display-block xs-color-secondary xs-font-size-small xs-ellipsis">
                    {{ getUsername() }}
                </span>
                <xs-data-phone-number
                        *ngIf="isUsernamePhoneNumber()"
                        [ellipsis]="true"
                        [showIcon]="false"
                        [value]="getUsername()" class="xs-width-full-imp"
                        styleClass="xs-color-secondary"
                        valueStyleClass="xs-font-size-small">
                </xs-data-phone-number>
            </div>
        </div>
    </div>

    <div class="col-4 xs-flex-row xs-align-items-center">
        <span *ngIf="!hasUserAgent()" class="xs-display-block xs-color-secondary xs-font-size-intermediate">
            {{ data.requestor }}
        </span>
        <ng-container *ngIf="hasUserAgent()">
            <xs-ivar (line1Click)="deviceInfoPanel.toggle($event)" [line1]="deviceStr" [line2]="TR_BASE_REQUESTOR_TYPE + data.requestor"
                     line1StyleClass="xs-font-size-intermediate-imp" line2StyleClass="xs-color-secondary-imp">
            </xs-ivar>
            <p-overlayPanel #deviceInfoPanel>
                <xs-device-informations [data]="deviceInfo" styleClass="xs-max-width-400"></xs-device-informations>
            </p-overlayPanel>
        </ng-container>
    </div>

    <div class="col-3 xs-flex-row xs-align-items-center">
        <xs-ivar
                *ngIf="data.geolocation | xsIsEmpty"
                line1="{{ TR_BASE }}noLocation"
                line1StyleClass="xs-font-size-intermediate-imp xs-color-secondary-imp"
                line2="{{ TR_BASE }}missingGeolocation"
                line2StyleClass=" xs-font-size-small-imp xs-color-secondary-imp">
        </xs-ivar>

        <div *ngIf="data.geolocation | xsIsNotEmpty" class="xs-flex-column xs-overflow-hidden">
            <span class="xs-display-block xs-font-size-intermediate xs-ellipsis">
                {{ data.geolocation!.city | xsCapitalizeFirstLetters }}, {{ data.geolocation!.countryISO | uppercase }}
            </span>
            <xs-coordinate
                    [clickable]="true"
                    [ellipsis]="true"
                    [showPinIcon]="false"
                    [value]="data.geolocation!.coordinate!"
                    styleClass="xs-width-full xs-mt-2"
                    valueStyleClass="xs-font-size-9-imp">
            </xs-coordinate>
        </div>
    </div>
</div>