import {Component, Input, OnInit} from '@angular/core';
import {LCECertificateOrderPartial, LCECertificateOrderService} from '@lce/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSLoaderService} from '@xs/common';
import {XSButton, XSCalendarOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrdersFeatureService} from '../../../../certificate-order/lce-certificate-orders-feature.service';

@Component({
    selector: 'lce-user-customer-orders',
    templateUrl: './lce-user-customer-orders.component.html',
    providers: [LCECertificateOrdersFeatureService]
})
export class LCEUserCustomerOrdersComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.customer.customerRecord.';

    readonly ORDERS_LOADER: string = XSUtils.uuid();

    @Input() customerID: string;

    certificateOrders: LCECertificateOrderPartial[];

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        icon: this.ICON.refresh,
        label: 'xs.core.label.pleaseTryAgain',
        onClick: () => this.findCertificateOrders()
    };

    nOrders: string = XS_STR_EMPTY;

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private certificateOrderService: LCECertificateOrderService) {
    }

    ngOnInit(): void {
        this.findCertificateOrders();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private findCertificateOrders(): void {
        this.loaderService.startLoader(this.ORDERS_LOADER);
        this.error = undefined;
        this.nOrders = '...';
        this.subscription.add(
            this.certificateOrderService
                .retrieveCustomerOrders(this.customerID)
                .pipe(finalize(() => this.loaderService.stopLoader(this.ORDERS_LOADER)))
                .subscribe({
                    next: (orders: LCECertificateOrderPartial[]) => {
                        this.certificateOrders = orders;
                        this.nOrders = this.certificateOrders.length.toString();
                    },
                    error: error => {
                        this.error = error;
                        this.nOrders = XS_STR_EMPTY;
                    }
                })
        );
    }
}
