import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCESuggestion, LCESuggestionService, LCESuggestionStatus} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSConfirmation} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedService} from '../../api/services/lce-shared.service';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';

@Component({selector: 'lce-suggestion-actions', templateUrl: './lce-suggestion-actions.component.html', host: {class: 'xs-width-full'}})
export class LCESuggestionActionsComponent implements OnInit, OnDestroy {

    readonly TR_BASE: string = 'lce.shared.suggestion.';

    readonly ICON = LCE_SHARED_ICON;

    STATUS = LCESuggestionStatus;

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() suggestion: LCESuggestion;

    @Output() suggestionChange = new EventEmitter<LCESuggestion>();
    @Output() errorEvent = new EventEmitter<any>();

    loading = {
        markAsRead: false,
        markAsUnread: false,
        markAsConsidered: false,
        markAsSelected: false
    };

    error: any;

    markAsReadConfirmation: XSConfirmation;
    markAsUnreadConfirmation: XSConfirmation;
    considerConfirmation: XSConfirmation;
    selectedConfirmation: XSConfirmation;
    protected readonly LCESuggestionStatus = LCESuggestionStatus;
    private subscription: Subscription = new Subscription();

    constructor(private sharedService: LCESharedService, private suggestionService: LCESuggestionService) {
    }

    ngOnInit(): void {
        this.buildConfirmations();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public canMarkAsRead(): boolean {
        if (!this.canDoAction()) {
            return false;
        }
        return this.suggestion?.status !== LCESuggestionStatus.READ;
    }

    public canMarkAsUnread(): boolean {
        if (!this.canDoAction()) {
            return false;
        }
        return this.suggestion?.status !== LCESuggestionStatus.UNREAD;
    }

    public canMarkAsConsidered(): boolean {
        if (!this.canDoAction()) {
            return false;
        }
        return this.suggestion?.status !== LCESuggestionStatus.CONSIDERED;
    }

    public canMarkAsSelected(): boolean {
        if (!this.canDoAction()) {
            return false;
        }
        return this.suggestion?.status !== LCESuggestionStatus.SELECTED;
    }

    public getStatusColorStyleClass(status: LCESuggestionStatus): string {
        return LCESharedUtils.getSuggestionColorStatusStyleClass(status);
    }

    private canDoAction(): boolean {
        return !this.loading.markAsRead && !this.loading.markAsUnread && !this.loading.markAsConsidered && !this.loading.markAsSelected;
    }

    private markAsRead(): void {
        this.loading.markAsRead = true;
        this.error = undefined;
        this.errorEvent.emit(this.error);
        this.subscription.add(
            this.suggestionService
                .updateStatus(this.suggestion.id, LCESuggestionStatus.READ)
                .pipe(finalize(() => (this.loading.markAsRead = false)))
                .subscribe({
                    next: suggestion => this.handleUpdateStatus(suggestion),
                    error: error => this.handleError(error)
                })
        );
    }

    private markAsUnread(): void {
        this.loading.markAsUnread = true;
        this.error = undefined;
        this.errorEvent.emit(this.error);
        this.subscription.add(
            this.suggestionService
                .updateStatus(this.suggestion.id, LCESuggestionStatus.UNREAD)
                .pipe(finalize(() => (this.loading.markAsUnread = false)))
                .subscribe({
                    next: suggestion => this.handleUpdateStatus(suggestion),
                    error: error => this.handleError(error)
                })
        );
    }

    private markAsConsidered(): void {
        this.loading.markAsConsidered = true;
        this.error = undefined;
        this.errorEvent.emit(this.error);
        this.subscription.add(
            this.suggestionService
                .updateStatus(this.suggestion.id, LCESuggestionStatus.CONSIDERED)
                .pipe(finalize(() => (this.loading.markAsConsidered = false)))
                .subscribe({
                    next: suggestion => this.handleUpdateStatus(suggestion),
                    error: error => this.handleError(error)
                })
        );
    }

    private markAsSelected(): void {
        this.loading.markAsSelected = true;
        this.error = undefined;
        this.errorEvent.emit(this.error);
        this.subscription.add(
            this.suggestionService
                .updateStatus(this.suggestion.id, LCESuggestionStatus.SELECTED)
                .pipe(finalize(() => (this.loading.markAsSelected = false)))
                .subscribe({
                    next: suggestion => this.handleUpdateStatus(suggestion),
                    error: error => this.handleError(error)
                })
        );
    }

    private handleUpdateStatus(suggestion: LCESuggestion): void {
        this.suggestion = suggestion;
        this.suggestionChange.emit(this.suggestion);
        this.sharedService.emitSuggestionStatusChanged();
    }

    private handleError(error: any): void {
        this.error = error;
        this.errorEvent.emit(this.error);
    }

    private buildConfirmations() {
        this.markAsReadConfirmation = {
            key: 'markAsReadConfirmationKey',
            trMessage: this.TR_BASE + 'confirmation.markAsRead',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.markAsRead()
        };
        this.markAsUnreadConfirmation = {
            key: 'markAsUnreadConfirmationKey',
            trMessage: this.TR_BASE + 'confirmation.markAsUnRead',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.markAsUnread()
        };
        this.considerConfirmation = {
            key: 'considerConfirmationKey',
            trMessage: this.TR_BASE + 'confirmation.consideredConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.markAsConsidered()
        };
        this.selectedConfirmation = {
            key: 'selectedConfirmationKey',
            trMessage: this.TR_BASE + 'confirmation.selectedConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.markAsSelected()
        };
    }
}