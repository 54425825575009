import {Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {XSTemplateDirective, XSUtils} from '@xs/base';
import {XSColor, XSColorStr, XSLoaderService, XSLoaderType, XSSize, XSSizeStr} from '@xs/common';
import {XS_CORE_ICON} from '@xs/core';

@Component({selector: 'xs-authentication-pin-code', templateUrl: 'xs-authentication-pin-code.component.html'})
export class XSAuthenticationPINCodeComponent implements OnInit, OnChanges {

    readonly ICON_LOCK: string = XS_CORE_ICON.lock;

    readonly LOADER_ID: string = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() contentStyleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;
    @Input() messageIconStyleClass?: string;
    @Input() messageLine1StyleClass?: string;
    @Input() messageLine2StyleClass?: string;
    @Input() messageTemplateContainerStyleClass?: string;
    @Input() footerTemplateContainerStyleClass?: string;

    @Input() logoText?: string;
    @Input() showLogoText?: boolean;

    @Input() showHeaderSeparator?: boolean;

    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() showSubtitle?: boolean;

    @Input() messageIcon?: string;
    @Input() messageIconSize?: XSSize | XSSizeStr | string;
    @Input() messageIconColor?: XSColor | XSColorStr | string;
    @Input() messageLine1?: string;
    @Input() messageLine2?: string;

    @Input() loading?: boolean;
    @Input() loaderType?: XSLoaderType;
    @Input() loaderSize?: number = 20;

    @Input() error?: any;
    @Input() errorMessage?: string;
    @Input() showErrorDetailButton?: boolean;

    @Input() messageTemplateRef?: TemplateRef<any>;
    @Input() footerTemplateRef?: TemplateRef<any>;

    @Output() pinCodeChange = new EventEmitter<string | undefined>();

    pinCodeControl: FormControl;

    @ContentChildren(XSTemplateDirective) private templates: QueryList<any>;

    private readonly TEMPLATE_NAME_MESSAGE = 'message';
    private readonly TEMPLATE_NAME_FOOTER = 'footer';

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit() {
        if (XSUtils.isNull(this.showHeaderSeparator)) this.showHeaderSeparator = true;

        if (XSUtils.isNull(this.showLogoText)) this.showLogoText = true;
        if (XSUtils.isNull(this.showSubtitle)) this.showSubtitle = true;

        if (XSUtils.isEmpty(this.messageIcon)) this.messageIcon = this.ICON_LOCK;
        if (XSUtils.isEmpty(this.messageIconSize)) this.messageIconSize = '35px';
        if (XSUtils.isEmpty(this.messageIconColor)) this.messageIconColor = XSColor.SECONDARY;

        if (XSUtils.isEmpty(this.loaderType)) this.loaderType = XSLoaderType.RECTANGLE_BOUNCE;

        this.pinCodeControl = new FormControl();
        this.pinCodeControl.valueChanges.subscribe(value => this.pinCodeChange.emit(value?.trim()));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.loading)) {
            if (this.loading === true) this.loaderService.startLoader(this.LOADER_ID);
            else this.loaderService.stopLoader(this.LOADER_ID);
        }
    }

    ngAfterViewInit(): void {
        if (!XSUtils.isNull(this.templates) && this.templates.length > 0) {
            this.messageTemplateRef = this.templates.find(item => this.TEMPLATE_NAME_MESSAGE === item.getName())?.template || this.messageTemplateRef;
            this.footerTemplateRef = this.templates.find(item => this.TEMPLATE_NAME_FOOTER === item.getName())?.template || this.footerTemplateRef;
        }
    }

    public hasErrorMessage(): boolean {
        return !XSUtils.isEmpty(this.errorMessage);
    }
}
