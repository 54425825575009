import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCEEvent, LCEEventService, LCEEventType, LCEResourceType} from '@lce/core';
import {XSGender, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_SS_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_SS_FR, XSLoaderService} from '@xs/common';
import {XSButton, XSCalendarOptions, XSDialogable, XSStyleHelper} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-event-record',
    templateUrl: './lce-event-record.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEEventRecordComponent extends XSDialogable implements OnInit, OnDestroy {
    readonly TR_BASE: string = 'lce.shared.event.';

    readonly ICON = LCE_SHARED_ICON;
    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() showBorder?: boolean;

    @Input() eventID?: string;
    @Input() data?: LCEEvent;

    @Input() eventName: string;
    @Input() eventType: LCEEventType;
    @Input() resourceType: LCEResourceType;

    @Output() closeEvent = new EventEmitter<LCEEvent>();

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveEvent()
    };

    calendarOptions: XSCalendarOptions = {
        type: 'date',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_SS_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_SS_FR
    };

    iconStyleClass: string;

    private subscription: Subscription = new Subscription();

    constructor(private eventService: LCEEventService, private loaderService: XSLoaderService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.eventID = this.dialogConfig.data.eventID;
            this.data = this.dialogConfig.data.event;
            this.eventName = this.dialogConfig.data.eventName;
            this.eventType = this.dialogConfig.data.eventType;
            this.resourceType = this.dialogConfig.data.resourceType;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.data));
        }

        if (XSUtils.isEmpty(this.eventID) && XSUtils.isEmpty(this.data)) {
            throw new Error('eventID and event cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.eventID) && !XSUtils.isEmpty(this.data)) {
            throw new Error('eventID and event cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.eventID)) this.retrieveEvent();
        else this.data = this.data!;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.data);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public buildIconColor(): string {
        if (!XSUtils.isNull(this.data) && this.data?.resourceType === LCEResourceType.BIRTH_DECLARATION) {
            return (this.iconStyleClass = this.data!.data!.newBornGender === XSGender.FEMALE ? XSStyleHelper.CLASS.color.pinkImp : XSStyleHelper.CLASS.color.blueLightImp);
        }
        return '';
    }

    private retrieveEvent(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.eventService
                .retrieve(this.eventID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (eventRetrieved: LCEEvent) => {
                        this.data = eventRetrieved;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }
}
