import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCEMBOAppInitialData} from '@lce-mbo/domain/lce-mbo-app-initial-data';
import {LCEMBOContextService} from '@lce-mbo/services/lce-mbo-context.service';
import {LCEMBOInitialDataService} from '@lce-mbo/services/lce-mbo-initial-data.service';
import {LCE_CORE_ENDPOINTS, LCEHttpClientService, LCEUserMunicipalEmployeeAuthenticationService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLocalStorageService} from '@xs/core';

@Injectable({providedIn: 'root'})
export class LCEMBOUserAuthenticationService extends LCEUserMunicipalEmployeeAuthenticationService {

    constructor(
        protected router: Router,
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCEMBOContextService,
        private localStorageService: XSLocalStorageService,
        private initialDataService: LCEMBOInitialDataService) {
        super(router, httpClientService, contextService, LCE_CORE_ENDPOINTS.initialization.mbo);
        this.listen();
    }

    protected async storageRemove(key: string): Promise<void> {
        this.localStorageService.remove(key);
    }

    protected async storageSetValue(key: string, value: any): Promise<void> {
        if (XSUtils.isObject(value)) {
            this.localStorageService.setObject(key, value);
        } else this.localStorageService.setItem(key, value);
    }

    protected async storageGetValue(key: string): Promise<string | undefined> {
        return this.localStorageService.getItem(key);
    }

    private listen(): void {
        this.onFetchInitialData.subscribe(initialData => {
            this.initialDataService.initialize(initialData as LCEMBOAppInitialData, '/stn').then();
        });
        this.onLogout.subscribe(() => {
            this.initialDataService.logout();
            console.log('[LCEMBOUserAuthenticationService] Logout ...');
        });
    }
}
