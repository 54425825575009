import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LCEMBOAuthenticationService} from '@lce-mbo/core/services/lce-mbo-authentication.service';
import {Subscription} from 'rxjs';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';

@Component({
    selector: 'lce-mbo-authentication',
    templateUrl: './lce-mbo-authentication.component.html',
    styles: [
        `
			:host {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}
		`
    ]
})
export class LCEMBOAuthenticationComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_MBO_ICON;

    title: string;
    subTitle: string;

    private subscription: Subscription = new Subscription();

    constructor(private cd: ChangeDetectorRef, private authenticationService: LCEMBOAuthenticationService) {
    }

    ngOnInit(): void {
        this.subscription.add(
            this.authenticationService.onTitleChanged.subscribe(title => {
                this.title = title;
                this.cd.detectChanges();
            })
        );
        this.subscription.add(
            this.authenticationService.onSubTitleChanged.subscribe(subTitle => {
                this.subTitle = subTitle;
                this.cd.detectChanges();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
