import {Component, OnInit} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';

@Component({selector: 'lce-template-sheldon', templateUrl: './lce-template-sheldon.component.html'})
export class LCETemplateSheldonComponent implements OnInit {

    readonly ICON = LCE_MBO_ICON;

    ngOnInit() {
    }
}
