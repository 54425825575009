import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenService} from '@lce/core';
import {XSPagination, XSResourceAuditCanOptions, XSSort, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {XSCalendarOptions, XSClipboardService, XSMenuItem, XSTableColumn, XSTableOptions} from '@xs/core';
import {LCECertificateOrderDialogService} from '../../../certificate-order/lce-certificate-order-dialog.service';
import {LCEFacilityStampTokenFeatureService} from '../lce-facility-stamp-token-feature.service';
import {LCEFacilityTownHallStampTokenDialogService} from '../lce-facility-town-hall-stamp-token-dialog.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-table',
    templateUrl: './lce-facility-town-hall-stamp-token-table.component.html',
    providers: [LCEFacilityTownHallStampTokenDialogService]
})
export class LceFacilityTownHallStampTokenTableComponent implements OnInit {

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.table.';

    readonly LOADER_ID_DELETE = XSUtils.uuid();

    @Input() styleClass?: string;

    @Input() data: LCEFacilityTownHallStampTokenPartial[] = [];

    @Input() nResults: number = 0;

    @Input() caption: XSText;

    @Input() subCaption?: XSText;

    @Input() loadingState: XSLoaderState;

    @Input() readonly?: boolean;

    @Output() resetPreferencesEvent = new EventEmitter();

    @Output() resetSortEvent = new EventEmitter();

    @Output() paginationEvent = new EventEmitter<XSPagination>();

    @Output() sortEvent = new EventEmitter<XSSort>();

    @Input() canAudit?: XSResourceAuditCanOptions;
    @Input() canDoAction: boolean;

    options: XSTableOptions;
    columns: XSTableColumn[];

    emptyMessage: XSText = {value: this.TR_BASE + 'noResultFound'};

    error: any;

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    private readonly ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];

    constructor(
        private clipboardService: XSClipboardService,
        private translationService: XSTranslationService,
        public facilityStampTokenService: LCEFacilityTownHallStampTokenService,
        private facilityStampTokenDialogService: LCEFacilityTownHallStampTokenDialogService,
        private certificateOrderDialogService: LCECertificateOrderDialogService,
        private featureService: LCEFacilityStampTokenFeatureService) {
    }

    ngOnInit(): void {
        this.buildTableColumns();
        this.buildTableOptions();
    }

    public openRecordDialog(selection: LCEFacilityTownHallStampTokenPartial): void {
        this.facilityStampTokenDialogService.openRecordDialog({stampTokenID: selection.id, canAudit: this.canAudit});
    }

    private buildTableColumns(): void {
        this.columns = [
            {
                field: 'code',
                header: 'xs.core.label.code',
                visible: true,
                displayable: true
            },

            {
                field: 'createdOn',
                header: 'xs.core.audit.createdOn',
                visible: true,
                displayable: true
            },
            {
                field: 'stamps',
                header: 'lce.shared.facility.townHallStampToken.table.nStamps',
                visible: true,
                displayable: true
            },
            {
                field: 'status',
                header: 'lce.shared.facility.townHallStampToken.label.status',
                visible: true,
                displayable: true
            },
            {
                field: 'mobilePhoneNumber',
                header: 'lce.shared.facility.townHallStampToken.table.mobilePhoneNumber',
                visible: true,
                displayable: true
            },
            {
                field: 'actions',
                header: 'lce.shared.facility.townHallStampToken.table.actions',
                visible: true,
                displayable: true
            }
        ];
    }

    private buildTableOptions(): void {
        this.options = {
            id: 'facilityTownHallStampTable',
            localStorageKey: 'facilityTownHallStamp.table',
            dataUniqueField: 'id',
            lazy: true,
            rowHover: true,
            selectionMode: 'single',
            contextMenu: true,
            sortMode: 'multiple',
            paginatorRows: this.featureService.pagination.size,
            paginator: true,
            simplePaginator: true,
            paginatorRowsPerPageOptions: this.ROWS_PER_PAGE_OPTIONS,
            resizableColumns: false,
            reorderableColumns: true,
            dynamicColumns: true,
            toolbar: {
                showExport: true,
                showPreferences: true,
                showResetSort: true,
                showResetColumnWidths: true
            },
            preferences: {
                showResetColumnWidths: true,
                showResetSort: true,
                showResetDefaultPreferences: true
            },
            exportOptions: {
                fileNameWithoutExtension: 'facilityTownHallStampResults',
                pdfOptions: {
                    pageWidthInPx: 1000,
                    firstPageMarginTop: 70,
                    title: 'facility Town Hall Stamp From 2023-01-01 To 2023-01-31',
                    textTopLeft: 'LCE (La Commune Électronique)',
                    textTopRight: 'Facility Town Hall Stamp [{{nResults}}]',
                    textBottomRight: 'Generated By Ryane Alla',
                    cellAlignCenter: false,
                    autoComputePageWidth: false
                },
                xlsxOptions: {
                    worksheetName: 'Facility Town Hall Stamp',
                    title: 'This is a test Title',
                    subject: 'Facility Town Hall Stamp Token',
                    author: 'LCE (La Commune Électronique)',
                    company: 'Iro-XS Inc.',
                    keywords: ['Stamp', 'Token'],
                    comments: 'Generated By Ryane Alla'
                }
            },
            buildContextMenuItems: (selection: LCEFacilityTownHallStampTokenPartial) => this.buildContextMenuItems(selection),
            loaderSize: 30
        };
    }

    private buildContextMenuItems(selection: LCEFacilityTownHallStampTokenPartial): XSMenuItem[] {
        return [
            {label: this.translationService.translateKey('lce.shared.facility.townHallStampToken.table.copyCode'), command: () => this.copy(selection.code)},
            {label: this.translationService.translateKey('lce.shared.facility.townHallStampToken.table.copyID'), command: () => this.copy(selection.id)},
            {label: this.translationService.translateKey('xs.core.label.showDetail'), command: () => this.openRecordDialog(selection)}
        ];
    }

    private copy(str: string): void {
        this.clipboardService.copy(str);
    }
}
