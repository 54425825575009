import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef} from '@angular/core';
import {LCEResourceType} from '@lce/core';
import {XSAssert, XSPKSearchFilter, XSTemplateDirective, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEGlobalSearchTabPanelOptions} from '../lce-global-search';
import {LCEGlobalSearchEvent, LCEGlobalSearchFeatureService} from '../lce-global-search-feature-service';

@Component({
    selector: 'lce-global-search-tab-panel',
    templateUrl: './lce-global-search-tab-panel.component.html',
    host: {class: 'xs-height-full'}
})
export class LCEGlobalSearchTabPanelComponent implements OnInit, AfterViewInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.globalSearch.';

    readonly LOADER_ID = XSUtils.uuid();

    readonly DEFAULT_PAGINATION_SIZE: number = 20;

    @Input() styleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() resourceType: LCEResourceType;

    @Input() options: LCEGlobalSearchTabPanelOptions;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    subscription: Subscription = new Subscription();

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    totalResults: number;

    results: any[] = [];
    leftColumnResults: any[] = [];
    rightColumnResults: any[] = [];

    paginationPage: number = 0;
    paginationSize: number = this.DEFAULT_PAGINATION_SIZE;

    backupSearchFilter: XSPKSearchFilter;

    rowResultTemplateRef: TemplateRef<any>;

    @ContentChildren(XSTemplateDirective) private templates: QueryList<any>;

    constructor(
        private loaderService: XSLoaderService,
        private globalSearchFeatureService: LCEGlobalSearchFeatureService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.resourceType, 'resourceType');
        XSAssert.notEmpty(this.options, 'options');

        if (XSUtils.isNull(this.options.defaultPaginationSize)) this.options.defaultPaginationSize = this.DEFAULT_PAGINATION_SIZE;

        if (XSUtils.isNull(this.options.biColumnResults)) this.options.biColumnResults = true;
        if (this.options.biColumnResults && XSUtils.isNull(this.options.columnResultSize)) this.options.columnResultSize = 10;

        this.paginationSize = this.options.defaultPaginationSize!;

        this.subscription.add(this.globalSearchFeatureService.onSearch.subscribe(searchEvent => this.handleSearch(searchEvent)));
        this.subscription.add(this.globalSearchFeatureService.onTabChange.subscribe(searchEvent => this.handleSearch(searchEvent)));
        this.subscription.add(this.globalSearchFeatureService.onUpdate.subscribe(() => this.search()));
    }

    ngAfterViewInit() {
        if (!XSUtils.isNull(this.templates) && this.templates.length > 0) {
            this.rowResultTemplateRef = this.templates.find(item => 'rowResult' === item.getName())?.template;
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public handlePagination(event: any) {
        this.paginationPage = event.page;
        this.paginationSize = event.rows;
        this.search();
    }

    public hasResults(): boolean {
        return !XSUtils.isEmpty(this.results);
    }

    public isPaginable(): boolean {
        return this.totalResults > this.paginationSize!;
    }

    public handleSearch(searchEvent: LCEGlobalSearchEvent): void {
        if (XSUtils.isEmpty(searchEvent) || !this.searchFilterHasChanged(searchEvent.searchFilter) || searchEvent.currentResourceTypeTabPanel !== this.resourceType) {
            return;
        }
        this.backupSearchFilter = {...searchEvent.searchFilter};
        this.search();
    }

    public onDoubleClick(oneResult: any): void {
        if (this.options.openRecord) this.options.openRecord(oneResult);
    }

    private searchFilterHasChanged(searchFilter: XSPKSearchFilter): boolean {
        if (XSUtils.isEmpty(this.backupSearchFilter)) return true;
        return this.backupSearchFilter.query?.trim() !== searchFilter.query?.trim();
    }

    private search(): void {
        const searchFilter = {...this.backupSearchFilter, paginationPage: this.paginationPage, paginationSize: this.paginationSize};
        this.error = undefined;
        this.startLoader();
        this.subscription.add(
            this.options.search(searchFilter)
                .pipe(
                    finalize(() => {
                        this.stopLoader();
                    })
                )
                .subscribe({
                    next: (searchResult) => {
                        this.results = searchResult.data;
                        if (this.options.biColumnResults) {
                            this.leftColumnResults = this.results.slice(0, this.options.columnResultSize!);
                            this.rightColumnResults = this.results.slice(this.options.columnResultSize!, this.paginationSize!);
                        } else {
                            this.leftColumnResults = undefined!;
                            this.rightColumnResults = undefined!;
                        }
                        this.totalResults = searchResult.total;
                        this.totalResultsChange.emit(this.totalResults);
                    },
                    error: (error) => {
                        this.error = error;
                        this.totalResults = undefined!;
                        this.totalResultsChange.emit(this.totalResults);
                        this.errorEvent.emit(this.error);
                    }
                })
        );
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }
}
