<div [ngClass]="{ 'xs-disabled': disabled === true, 'xs-overflow-hidden': ellipsis === true }"
     class="xs-flex-column xs-width-fit-content {{ styleClass }}">
    <div [ngClass]="{ 'xs-overflow-hidden': ellipsis === true }"
         class="xs-flex-inline xs-width-full xs-align-items-center xs-justify-content-center xs-background-discrete xs-border-radius-25 xs-plr-20 xs-ptb-4">
        <span
                [ngClass]="{ 'xs-ellipsis': ellipsis === true }"
                class="xs-display-block xs-font-size-intermediate xs-white-space-nowrap xs-font-weight-500 {{ statusColorStyleClass }}">
            {{ statusStr | translate }}
        </span>
    </div>

    <lce-user-resource-audit-inline
            *ngIf="auditOn | xsIsNotEmpty"
            [by]="auditBy"
            [disabled]="disabled"
            [on]="auditOn"
            [showViewRecordButton]="true"
            [viewDetail]="true"
            byStyleClass="xs-font-size-small-imp xs-color-secondary"
            onPrefix="xs.core.audit.on"
            onStyleClass="xs-font-size-small-imp xs-color-secondary xs-font-weight-600"
            styleClass="xs-mt-2 xs-align-items-center">
    </lce-user-resource-audit-inline>
</div>