import { Component, Input, OnInit } from '@angular/core';
import { LCENewsArticleComment } from '@lce/core';
import { XSAssert, XS_LOREM_IPSUM } from '@xs/base';

@Component({ selector: 'lce-news-article-comment', templateUrl: 'lce-news-article-comment.component.html' })
export class LCENewsArticleCommentComponent implements OnInit {
    LOREM_IPSUM = XS_LOREM_IPSUM;

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;

    @Input() comment: LCENewsArticleComment;

    ngOnInit(): void {
        XSAssert.notEmpty(this.comment, 'comment');
    }
}
