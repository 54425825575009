<div class="xs-flex-column xs-align-items-center xs-width-full xs-plr-15 {{ styleClass }}">

    <div class="lce-input-dropdown-compose lce-input-dropdown-compose-stripe lce-background-color-discrete xs-mb-30">
        <xs-icon [value]="ICON.calendar" size="medium" styleClass="xs-display-block xs-mt-minus-5 xs-color-secondary">
        </xs-icon>
        <xs-input-dropdown
                (valueChange)="onPeriodFilterChange()"
                [(value)]="predefinedPeriod"
                [items]="periodFilterItems"
                [options]="periodFilterOptions"
                dropdownStyleClass="xs-color-secondary">
            <!-- FIXME: Issue when trying to apply panelStyleClass="xs-min-width-250-imp" because of the scrolling. The z-index of the panel can't go over the scrollbar. -->
        </xs-input-dropdown>
    </div>

    <div class="xs-flex-column xs-align-items-center xs-width-full xs-max-width-200 xs-plr-15 xs-pt-15 xs-pb-50 xs-border-discrete xs-border-radius-7 xs-overflow-y-auto xs-max-height-800">

        <div class="xs-flex-column xs-mt-50">
            <div class="xs-flex-row-center xs-pb-40">
                <xs-statistic-tile
                        (clickEvent)="statClickEvent.emit()"
                        [error]="error"
                        [loading]="loading"
                        [ngClass]="{ 'xs-background-icon xs-background-icon-font-size-150 xs-background-icon-opacity-040 lce-background-icon-suggestion': showBackgroundIcon }"
                        [value]="data?.total"
                        text="{{ TR_BASE }}stats.total">
                </xs-statistic-tile>
            </div>
            <div class="xs-flex-row-center xs-ptb-40">
                <xs-statistic-tile
                        (clickEvent)="statClickEvent.emit(STATUS.UNREAD)"
                        [error]="error"
                        [loading]="loading"
                        [value]="data?.unread"
                        text="{{ TR_BASE }}stats.unRead">
                </xs-statistic-tile>
            </div>
            <div class="xs-flex-row-center xs-ptb-40">
                <xs-statistic-tile
                        (clickEvent)="statClickEvent.emit(STATUS.READ)"
                        [error]="error"
                        [loading]="loading"
                        [value]="data?.read"
                        text="{{ TR_BASE }}stats.read">
                </xs-statistic-tile>
            </div>
            <div class="xs-flex-row-center xs-ptb-40">
                <xs-statistic-tile
                        (clickEvent)="statClickEvent.emit(STATUS.CONSIDERED)"
                        [error]="error"
                        [loading]="loading"
                        [value]="data?.considered"
                        text="{{ TR_BASE }}stats.considered">
                </xs-statistic-tile>
            </div>
        </div>

        <hr class="xs-hr xs-hr-discrete xs-mtb-30-imp"/>

        <xs-square-data
                (clickEvent)="selectedByMayorClickEvent.emit(data!.selected!)"
                [error]="error"
                [showBorder]="true"
                [value]="loading ? '...' : data.selected | xsToString"
                bottomText="{{ TR_BASE }}label.selectedByMayor"
                bottomTextStyleClass="xs-color-secondary xs-mt-6-imp"
                valueStyleClass="xs-font-size-default-imp">
        </xs-square-data>

    </div>

</div>
