import { Injectable } from '@angular/core';
import { XSDialogService, XSErrorService } from '@xs/core';
import { LCEMBOEnvironmentService } from './lce-mbo-environment.service';

@Injectable({ providedIn: 'root' })
export class LCEMBOErrorService extends XSErrorService {
	constructor(protected environmentService: LCEMBOEnvironmentService, protected dialogService: XSDialogService) {
		super(environmentService, dialogService);
	}
}
