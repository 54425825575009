import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCEUserAccountService, LCEUserCustomer, LCEUserCustomerService} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {TieredMenu} from 'primeng/tieredmenu';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEUserActions} from '../../common/actions/lce-user-actions';

type Action = 'lock' | 'unlock';

@Component({selector: 'lce-customer-actions', templateUrl: './lce-customer-actions.component.html'})
export class LCECustomerActionsComponent extends LCEUserActions<LCEUserCustomer> implements OnInit, OnDestroy {
	readonly ICON = LCE_SHARED_ICON;

	readonly TR_BASE: string = 'lce.shared.customer.label.';

	@Input() styleClass?: string;
	@Input() buttonStyleClass?: string;

	@Input() disabled?: boolean;

	@Input() spaceBetween?: string;

	@Input() data: LCEUserCustomer;

	@ViewChild('actionMenu') actionMenu: TieredMenu;

	disable = {
		lock: false,
		unlock: false
	};

	can = {
		lock: false,
		unlock: false
	};

	// ---------------------------------------------------------------------------------------------------------------------
	// ---------------------------------------------------------------------------------------------------------------------
	// ---------------------------------------------------------------------------------------------------------------------

	constructor(
		protected translationService: XSTranslationService,
		protected userAccountService: LCEUserAccountService,
		protected resourceService: LCEUserCustomerService) {
		super(translationService, userAccountService);
	}

	ngOnInit(): void {
		super.onInit();
		XSAssert.notNull(this.data, 'data');
		if (XSUtils.isEmpty(this.spaceBetween)) this.spaceBetween = '40px';

		this.buildAuditMenuItems();

		this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.buildAuditMenuItems()));
	}

	ngOnDestroy() {
		super.onDestroy();
	}

	public canAction(action: Action): boolean {
		return this.can[action];
	}

	public isDisabled(action: Action): boolean {
		return this.disable[action];
	}

	public disableAction(disableState: boolean, action?: Action): void {
		if (XSUtils.isEmpty(action)) {
			this.disable.lock = disableState;
			this.disable.unlock = disableState;
			return;
		}
		this.disable[action!] = disableState;
	}

	public onMenuClick(event: any): void {
		this.buildActionMenuItems();
		this.actionMenu.toggle(event);
	}

	public buildActionMenuItems(): void {
		this.buildAuditMenuItems();
	}
}
