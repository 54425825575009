import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEArticleReviewerService} from '../article/lce-article-reviewer.service';
import {LCECoreContextService} from '../lce-core-context.service';
import {LCEHttpClientService} from '../lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCENewsArticleReviewerService extends LCEArticleReviewerService {

    constructor(protected httpClientService: LCEHttpClientService, protected contextService: LCECoreContextService) {
        super(httpClientService, contextService, LCE_CORE_ENDPOINTS.news.reviewers.index);
    }
}
