import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCEFacilityTownHallService} from '@lce/core';
import {XS_STR_EMPTY, XSAssert, XSPagination, XSSearchResult, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {XSPaymentTransfer, XSPaymentTransferPartial, XSPaymentTransferSearch} from '@xs/payment/base';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'lce-facility-town-hall-payment-transfer',
    templateUrl: './lce-facility-town-hall-payment-transfer.component.html'
})
export class LCEFacilityTownHallPaymentTransferComponent implements OnInit, OnDestroy {

    readonly TR_BASE: string = 'lce.shared.facility.';

    @Input() styleClass?: string;

    @Input() facilityID: string;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    statsLoading: boolean = false;
    error: any;

    private subscription: Subscription = new Subscription();

    constructor(private facilityTownHallService: LCEFacilityTownHallService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityID, 'facilityID');
        this.buildDataManagerOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public update(): void {
        this.dataManager.updateSearch();
    }

    private retrievePaymentTransfer(selectedRowResult: XSPaymentTransferPartial): Observable<XSPaymentTransfer> {
        return this.facilityTownHallService.retrieveTransfer(this.facilityID, selectedRowResult.id);
    }

    private searchPaymentTransfers(query?: string, pagination?: XSPagination): Observable<XSSearchResult<XSPaymentTransferPartial>> {
        const paymentTransferSearch: XSPaymentTransferSearch = {
            query: XSUtils.isEmpty(query) ? XS_STR_EMPTY : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.facilityTownHallService.searchTransfers(this.facilityID, paymentTransferSearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {

            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchPaymentTransfers(query, pagination),
            fnRetrieveRecordData: selectedRowData => this.retrievePaymentTransfer(selectedRowData),

            search: {
                defaultPaginationSize: 3,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE + 'paymentTransfer.resultFound',
                listingItemStyleClass: 'xs-min-width-300'

            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }

}
