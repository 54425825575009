import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LCENewsArticleCard, LCENewsArticlePredefinedFilter, LCENewsArticleSearch, LCENewsArticleService, LCENewsArticleStatus} from '@lce/core';
import {XSPagination, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton} from '@xs/core';
import {Paginator} from 'primeng/paginator/paginator';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCENewsFeatureService} from '../lce-news-feature.service';

@Component({
    selector: 'lce-news-articles',
    templateUrl: './lce-news-articles.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCENewsArticlesComponent implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.news.articles.';

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 3;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_ROWS_PER_PAGE_OPTIONS: number[] = [6, 12, 24];
    readonly PAGINATOR_ROWS: number = this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[1];

    readonly PRIMARY_LOADER_ID = XSUtils.uuid();
    readonly SECONDARY_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;

    @ViewChild('pPaginator') pPaginatorComponent: Paginator;

    searchText: string;

    nArticles: number = 0;
    articles: LCENewsArticleCard[] = [];
    resultSubText: string;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    firstInitialization: boolean = true;

    statusFilters: LCENewsArticleStatus[] = [];
    predefinedFilter: LCENewsArticlePredefinedFilter = LCENewsArticlePredefinedFilter.LATEST;

    pagination: XSPagination = {page: 0, size: this.PAGINATOR_ROWS};
    articleSearch: LCENewsArticleSearch = {paginationPage: this.pagination.page, paginationSize: this.pagination.size};

    private readonly TR_BASE_STATUS: string = 'lce.shared.news.status.';
    statusFilterOptions: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_STATUS + LCENewsArticleStatus.ACCEPTED, value: LCENewsArticleStatus.ACCEPTED},
        {trLabel: this.TR_BASE_STATUS + LCENewsArticleStatus.IN_REVIEW, value: LCENewsArticleStatus.IN_REVIEW},
        {trLabel: this.TR_BASE_STATUS + LCENewsArticleStatus.REWORKING, value: LCENewsArticleStatus.REWORKING},
        {trLabel: this.TR_BASE_STATUS + LCENewsArticleStatus.PUBLISHED, value: LCENewsArticleStatus.PUBLISHED},
        {trLabel: this.TR_BASE_STATUS + LCENewsArticleStatus.SCHEDULED, value: LCENewsArticleStatus.SCHEDULED}
    ];
    private readonly TR_BASE_PREDEFINED_FILTER: string = 'lce.shared.news.predefinedFilter.';
    predefinedFilterOptions: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCENewsArticlePredefinedFilter.LATEST, value: LCENewsArticlePredefinedFilter.LATEST},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCENewsArticlePredefinedFilter.POPULAR, value: LCENewsArticlePredefinedFilter.POPULAR},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCENewsArticlePredefinedFilter.MOST_READ, value: LCENewsArticlePredefinedFilter.MOST_READ},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCENewsArticlePredefinedFilter.MOST_LIKED, value: LCENewsArticlePredefinedFilter.MOST_LIKED},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCENewsArticlePredefinedFilter.MOST_UNLIKED, value: LCENewsArticlePredefinedFilter.MOST_UNLIKED}
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private newsArticleService: LCENewsArticleService,
        private featureService: LCENewsFeatureService) {

        this.featureService.state.title = this.TR_BASE + 'title';
        this.featureService.state.subTitle = this.TR_BASE + 'subTitle';
        this.featureService.state.showNewArticleButton = true;
        this.featureService.state.showRefreshButton = true;
        this.featureService.state.showBackButton = false;
        this.featureService.onRefresh.subscribe(() => this.update());

        this.initializeTranslation();
        this.handleRouting();
    }

    ngOnInit(): void {

        this.search();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public update(): void {
        this.search();
    }

    public onInputSearchChange(): void {
        this.search();
    }

    public onPredefinedFilterChange(): void {
        this.resetPagination();
        this.search();
    }

    public onStatusFilterChange(): void {
        this.resetPagination();
        this.search();
    }

    public onPaginationNumberChange() {
        this.search();
    }

    public handlePagination(event: any) {
        this.pagination.page = event.page;
        this.pagination.size = event.rows;
        this.search();
    }

    public isPaginable(): boolean {
        return this.nArticles > this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[0];
    }

    public isSecondaryLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID);
    }

    private resetPagination(): void {
        this.pagination.page = 0;
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.predefinedFilterOptions);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.predefinedFilterOptions);
                this.predefinedFilterOptions = [...this.predefinedFilterOptions];
            })
        );
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statusFilterOptions);
                this.statusFilterOptions = [...this.statusFilterOptions];
            })
        );
    }

    private search(status?: LCENewsArticleStatus): void {
        this.startLoader();
        this.error = undefined;
        this.buildSearch(status);
        this.subscription.add(this.newsArticleService
            .searchAsCards(this.articleSearch)
            .pipe(finalize(() => this.stopLoader()))
            .subscribe({
                next: searchResult => {
                    this.articles = searchResult.data;
                    this.nArticles = searchResult.total;
                    this.firstInitialization = false;
                    this.buildResultSubText();
                },
                error: error => this.error = error
            })
        );
    }

    private buildSearch(status?: LCENewsArticleStatus): void {
        if (!XSUtils.isEmpty(status)) {
            this.statusFilters = [status!];
            this.articleSearch = {
                statuses: this.statusFilters
            };
        } else {
            this.articleSearch = {
                query: this.searchText?.trim(),
                statuses: this.statusFilters,
                paginationPage: this.pagination.page,
                paginationSize: this.pagination.size
            };
        }
        XSUtils.removeNullAndUndefinedEntries(this.articleSearch);
        XSUtils.removeEmptyObjectEntries(this.articleSearch);
    }

    private startLoader(): void {
        if (this.firstInitialization) {
            this.loaderService.startLoader(this.PRIMARY_LOADER_ID);
        } else {
            this.loaderService.startLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private stopLoader(): void {
        if (this.loaderService.isLoaderRunning(this.PRIMARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.PRIMARY_LOADER_ID);
        }
        if (this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private buildResultSubText(): void {
        // TODO: Build custom message depending of the filters below the total number of results.
        //const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        this.resultSubText = 'Articles les plus aimés depuis le début de l\'année 2023';
    }

    private handleRouting(): void {
        const routerStateData = this.router.getCurrentNavigation()?.extras?.state;
        if (!XSUtils.isEmpty(routerStateData?.status)) {
            this.statusFilters = [this.toStatus(routerStateData!.status)];
        }

        const paramStatus = this.activatedRoute.snapshot.queryParamMap.get('status');
        if (!XSUtils.isEmpty(paramStatus)) {
            this.statusFilters = [this.toStatus(paramStatus)];
        }
    }

    private toStatus(paramStatus: any): LCENewsArticleStatus {
        const pStatus = XSUtils.toEnum<LCENewsArticleStatus>(paramStatus, LCENewsArticleStatus);
        if (XSUtils.isEmpty(pStatus)) {
            throw new Error(`failed to cast parameter [${paramStatus}] to LCENewsArticleStatus.`);
        }
        return pStatus!;
    }
}
