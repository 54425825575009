import {Component, Input, OnInit} from '@angular/core';
import {XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEFacilityStampTokenManagerService} from '../lce-facility-stamp-token-manager.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-settings',
    templateUrl: 'lce-facility-town-hall-stamp-token-settings.component.html'
})
export class LCEFacilityTownHallStampTokenSettingsComponent implements OnInit {

    readonly ICON_SETTINGS = LCE_SHARED_ICON.setting;

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.createSettings.';

    @Input() styleClass?: string;

    createOnEnter: boolean;
    useMobilePhoneNumber: boolean;

    constructor(private stampTokenManagerService: LCEFacilityStampTokenManagerService) {
    }

    ngOnInit(): void {
        const useMobilePhoneNumberState = this.stampTokenManagerService.getUseMobilePhoneNumber();
        this.useMobilePhoneNumber = XSUtils.isNull(useMobilePhoneNumberState) ? true : useMobilePhoneNumberState!;

        const createOnEnterState = this.stampTokenManagerService.getCreateOnEnter();
        this.createOnEnter = XSUtils.isNull(createOnEnterState) ? true : createOnEnterState!;
    }

    public handleCreateOnEnter(event: boolean) {
        this.createOnEnter = event;
        this.stampTokenManagerService.saveCreateOnEnter(this.createOnEnter);
    }

    public handleUseMobilePhoneNumber(event: boolean): void {
        this.useMobilePhoneNumber = event;
        this.stampTokenManagerService.saveUseMobilePhoneNumber(this.useMobilePhoneNumber);
    }
}
