<div class="lce-facility-town-hall-stamp-token-historic-main {{ styleClass }}">
    <div class="lce-facility-town-hall-stamp-token-historic-main-container">
        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-flex-column-center xs-width-full">

            <div class="xs-width-full xs-flex-row xs-justify-content-space-between xs-mb-35">
                <xs-statistic-tile
                        (valueClickEvent)="onStatValueClick(PREDEFINED_PERIOD.TODAY)"
                        [icon]="ICON_STAMP"
                        [loading]="stampsGeneratedTodayLoading"
                        [showBackground]="false"
                        [showSeparator]="true"
                        [text]=" TR_BASE + 'myGeneratedStamps'"
                        [value]="stampsGeneratedToday"
                        chipLabel="xs.common.predefinedPeriod.today"
                        class=" xs-max-width-350"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-full"
                        textStyleClass="xs-font-weight-none">
                </xs-statistic-tile>
                <xs-statistic-tile
                        (valueClickEvent)="onStatValueClick(PREDEFINED_PERIOD.THIS_WEEK)"
                        [icon]="ICON_STAMP"
                        [loading]="stampsGeneratedThisWeekLoading"
                        [showBackground]="false"
                        [showSeparator]="true"
                        [text]=" TR_BASE + 'myGeneratedStamps'"
                        [value]="stampsGeneratedThisWeek"
                        chipLabel="xs.common.predefinedPeriod.thisWeek"
                        class="xs-max-width-350"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-full"
                        textStyleClass="xs-font-weight-none">
                </xs-statistic-tile>
            </div>

            <div class="xs-field xs-field-input xs-field-input-search xs-width-full xs-max-width-750 xs-mb-25">
                <label class="xs-font-size-medium-imp xs-text-primary-color-imp" for="{{ searchText }}">{{ TR_BASE + 'searchStampToken' | translate }}</label>
                <xs-input-search
                        (changeEvent)="search()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'searchTextPlaceholder'"
                        [searching]="searching"
                        inputId="searchText">
                </xs-input-search>
            </div>

            <!--            <div class="xs-field xs-field-input xs-width-full xs-max-width-750">-->
            <!--                <lce-input-field-user-autocomplete-multiple (selectEvent)="search()" *ngIf="canFilterByCreatedBy" [options]="createdByAutocompleteField"-->
            <!--                ></lce-input-field-user-autocomplete-multiple>-->
            <!--            </div>-->
            <p-selectButton
                    (onChange)="onPredefinedPeriodFilterChange()"
                    [(ngModel)]="predefinedPeriodFilter"
                    [options]="predefinedPeriodFilterOptions"
                    class="xs-sm-display-none xs-mb-15 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>
            <p-selectButton
                    (onChange)="onPredefinedPeriodFilterChange()"
                    [(ngModel)]="statusFilter"
                    [multiple]="true"
                    [options]="statusFilterOptions"
                    class="xs-sm-display-none xs-mb-25 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>

            <div class="xs-width-full xs-max-width-550">
                <xs-chips
                        (valueChange)="onNumberOfStampsFilterChange($event)"
                        [chips]="numberOfStampsFilterChips"
                        [clickable]="true"
                        [selectable]="true"
                        [swiperNavigation]="!isMobile"
                        [swiper]="true"
                        chipStyleClass="xs-plr-30-imp">
                </xs-chips>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Table === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <lce-facility-town-hall-stamp-token-table
                    (paginationEvent)="onPagination($event)"
                    (resetPreferencesEvent)="onResetPreferences()"
                    (resetSortEvent)="onResetSort()"
                    (sortEvent)="onSort($event)"
                    [caption]="{value: 'lce.shared.facility.townHallStampToken.table.caption'}"
                    [data]="tableData"
                    [loadingState]="tableLoadingState"
                    [nResults]="tableNResults"
                    [readonly]="readonly">
            </lce-facility-town-hall-stamp-token-table>
        </div>

    </div>
</div>
