import {Injectable} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCESuggestion} from '@lce/core';
import {LCESuggestionRecordComponent} from './record/lce-suggestion-record.component';

export interface LCESuggestionDialogRecordArg {
    suggestionID?: string;
    suggestion?: LCESuggestion;
    onClose?: (suggestion: LCESuggestion) => void;
}

@Injectable()
export class LCESuggestionDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCESuggestionDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.suggestionID) && XSUtils.isEmpty(arg.suggestion)) {
            throw new Error('arg.suggestionID and arg.suggestion cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.suggestionID) && !XSUtils.isEmpty(arg.suggestion)) {
            throw new Error('arg.suggestionID and arg.suggestion cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                suggestionID: arg.suggestionID,
                suggestion: arg.suggestion,
                loadingStyleClass: 'xs-min-height-400 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-800'
        };

        const recordComponent: LCESuggestionRecordComponent = this.dialogService.openComponentDialog(LCESuggestionRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe(suggestion => {
            if (arg?.onClose) arg!.onClose!(suggestion);
        });
    }
}
