import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { XSAssert, XSUtils } from '@xs/base';
import { XSInputFieldChipsOptions } from '@xs/core';
import { map } from 'rxjs/operators';
import { LCECategoryPartial } from '@lce/core';
import { LCEArticleCategoriesOptions } from './lce-article-categories-options';
import { LCEArticleCategoryUtils } from './lce-article-category-utils';

@Component({ selector: 'lce-article-categories', template: `<xs-input-field-chips [options]="inputField"></xs-input-field-chips>` })
export class LCEArticleCategoriesComponent implements OnInit {
    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() options: LCEArticleCategoriesOptions;

    @Input() control?: FormControl;

    @Input() categories?: LCECategoryPartial[];

    @Output() addEvent = new EventEmitter<LCECategoryPartial>();
    @Output() removeEvent = new EventEmitter<LCECategoryPartial>();

    inputField: XSInputFieldChipsOptions;

    constructor() {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.options, 'options');

        if (this.options.mode === 'input') {
            XSAssert.notNull(this.control, 'control');
            if (!XSUtils.isEmpty(this.categories)) throw new Error('categories cannot be set in input mode.');
        } else {
            XSAssert.notNull(this.categories, 'categories');
            if (!XSUtils.isNull(this.control)) throw new Error('control cannot be set in view mode.');
        }

        if (XSUtils.isNull(this.control)) this.control = new FormControl();
        if (!XSUtils.isEmpty(this.categories)) {
            this.control = new FormControl(LCEArticleCategoryUtils.toChips(this.categories, this.options.canRemoveAlreadyAdded));
        }

        this.buildField();
        this.installEvents();
    }

    private installEvents(): void {
        this.addEvent.subscribe((category) => {
            if (this.options.onAdd) this.options.onAdd(category);
        });
        this.removeEvent.subscribe((category) => {
            if (this.options.onRemove) this.options.onRemove(category);
        });
    }

    private buildField(): void {
        this.inputField = {
            fieldName: 'categories',
            control: this.control,
            chipsRemovable: true,
            disabled: this.disabled,
            canAdd: this.options.canAdd,
            toggleMode: this.options.mode === 'view',
            addButtonLabel: 'lce.shared.news.label.addCategories',
            autoCompletePlaceholder: 'lce.shared.news.label.addCategories',
            addChipEvent: (selectedChip) => {
                this.addEvent.emit(selectedChip.value);
            },
            removeChipEvent: (removedChip) => {
                this.removeEvent.emit(removedChip.value);
            },
            autoCompleteOptions: {
                labelField: 'label',
                maxNumberOfResults: 5,
                search: (query: string) => this.options.autocomplete(query, 5).pipe(map((categories) => LCEArticleCategoryUtils.toLabelValues(categories))),
            },
        };
    }
}
