import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCE_TR_BASE_SUGGESTION_STATUS, LCECoreContextService, LCESuggestion, LCESuggestionPartial, LCESuggestionSearch, LCESuggestionService, LCESuggestionStatus, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {XSIDCode, XSSearchResult, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSTranslationService} from '@xs/common';
import {XSChip, XSCoreDomUtils, XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {Splitter} from 'primeng/splitter';
import {Observable, Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCESuggestionLastComponent} from './last/lce-suggestion-last.component';
import {LCESuggestionLateralPanelComponent} from './lateral-panel/lce-suggestion-lateral-panel.component';
import {LCESuggestionsFeatureService} from './lce-suggestions-feature.service';
import {LCEEventProcessorService} from '../api/services/lce-event-processor.service';
import {LCESuggestionDialogService} from './lce-suggestion-dialog.service';

@Component({
    selector: 'lce-suggestions',
    templateUrl: './lce-suggestions.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCESuggestionDialogService]
})
export class LCESuggestionsComponent implements OnInit, OnDestroy, AfterViewInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.suggestion.';

    readonly SPLITTER_DEFAULT_GUTTER_SIZE: number = 1;
    readonly SPLITTER_DEFAULT_MIN_SIZES: number[] = [65, 15];
    readonly SPLITTER_DEFAULT_PANEL_SIZES: number[] = [75, 25];

    STATUS = LCESuggestionStatus;

    @Input() styleClass?: string;

    @ViewChild('pSplitter') pSplitter: Splitter;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;
    @ViewChild('lastSuggestion') lastSuggestion: LCESuggestionLastComponent;
    @ViewChild('suggestionLateralPanel') suggestionLateralPanel: LCESuggestionLateralPanelComponent;

    dataManagerOptions: XSDataManagerOptions;

    mayorIDCode: XSIDCode;

    statusFilter: LCESuggestionStatus[] = [LCESuggestionStatus.UNREAD];
    statusFilterOptions: XSLabelValueItem[] = [
        {trLabel: LCE_TR_BASE_SUGGESTION_STATUS + LCESuggestionStatus.READ, value: LCESuggestionStatus.READ},
        {trLabel: LCE_TR_BASE_SUGGESTION_STATUS + LCESuggestionStatus.UNREAD, value: LCESuggestionStatus.UNREAD},
        {trLabel: LCE_TR_BASE_SUGGESTION_STATUS + LCESuggestionStatus.CONSIDERED, value: LCESuggestionStatus.CONSIDERED},
        {trLabel: LCE_TR_BASE_SUGGESTION_STATUS + LCESuggestionStatus.SELECTED, value: LCESuggestionStatus.SELECTED}
    ];

    anonymousChipFilter: XSChip;
    readByMeChipFilter: XSChip;
    readByTheMayorChipFilter: XSChip;
    selectedByTheMayorChipFilter: XSChip;

    chipFilters: XSChip[];

    lateralPanelVisible: boolean;
    lastSuggestionVisible: boolean;

    splitterConfig = {
        gutterSize: this.SPLITTER_DEFAULT_GUTTER_SIZE,
        minSizes: this.SPLITTER_DEFAULT_MIN_SIZES,
        panelSizes: this.SPLITTER_DEFAULT_PANEL_SIZES,
        stateStorage: 'local',
        stateKey: LCESuggestionsFeatureService.STORAGE_KEY_ROOT + '.splitter'
    };

    suggestionSearch: LCESuggestionSearch = {
        readBy: [],
        selectedBy: [],
        paginationPage: 0,
        paginationSize: 20,
        sort: ['createdOn|desc']
    };

    municipalityCode: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private contextService: LCECoreContextService,
        private eventProcessorService: LCEEventProcessorService,
        private suggestionService: LCESuggestionService,
        private suggestionDialogService: LCESuggestionDialogService,
        private featureService: LCESuggestionsFeatureService) {
        this.initializeTranslation();
    }

    ngOnInit(): void {
        this.buildDataManagerOptions();
        this.initializeChipFilters();
        this.municipalityCode = (this.contextService.getUser() as LCEUserMunicipalEmployeePartial).municipalityCode;
        this.lateralPanelVisible = this.featureService.getVisibleLateralPanel();
        this.lastSuggestionVisible = this.featureService.getVisibleLastSuggestion();

        this.subscription.add(this.featureService.onVisibleLastSuggestion.subscribe(visible => this.lastSuggestionVisible = visible));

        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.update()));

        this.subscription.add(this.eventProcessorService.onNewSuggestion.subscribe(() => this.update(false)));

        //this.subscription.add(this.municipalEmployeeService.)
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.subscription.add(this.featureService.onVisibleLateralPanel.subscribe(visible => this.handleVisibleLateralPanelChange(visible)));
    }

    public onStatusFilter(status: any[]): void {
        this.statusFilter = status;
        this.search();
    }

    public onLastSuggestionAvatarClick(lastSuggestion: XSIDCode): void {
        this.dataManager.searchText = lastSuggestion.code;
        this.statusFilter = [];
        this.dataManager.search(lastSuggestion.id);
    }

    public onLastSuggestionCodeClick(lastSuggestion: XSIDCode) {
        this.suggestionDialogService.openRecordDialog({suggestionID: lastSuggestion.id});
    }

    public onStatClick(status?: LCESuggestionStatus): void {
        if (!XSUtils.isEmpty(status)) {
            this.statusFilter = [status!];
        } else {
            this.statusFilter = [];
        }
        this.dataManager.search();
    }

    public onSelectedByMayorClick() {
        this.statusFilter = [LCESuggestionStatus.SELECTED];
        this.dataManager.search();
    }

    public onStatusFilterChange(): void {
        this.dataManager.search();
    }

    public update(doUpdateDataManager: boolean = true): void {
        this.lastSuggestion.update();
        this.suggestionLateralPanel.update();
        if (doUpdateDataManager) {
            this.dataManager.search(this.dataManager.selectedRowResult?.id);
        }
    }

    public onSuggestionChange(suggestionRecord: LCESuggestion): void {
        this.dataManager.updateSelectedRecordData(suggestionRecord);
        (this.dataManager.selectedRowResult as LCESuggestionPartial).status = suggestionRecord.status;
        this.lastSuggestion.updateStatus(suggestionRecord.status);
    }

    public canDoAction(): boolean {
        return !this.dataManager.isLoaderRunning() && this.dataManager.hasSelectedRowResult();
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.statusFilterOptions);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statusFilterOptions);
                this.statusFilterOptions = [...this.statusFilterOptions];
            })
        );
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private retrieveSuggestion(selectedRow: string | LCESuggestionPartial): Observable<LCESuggestion> {
        const suggestionID = XSUtils.isString(selectedRow) ? selectedRow as string : (selectedRow as LCESuggestionPartial).id;
        return this.suggestionService.retrieve(suggestionID);
    }

    private search(query?: string): Observable<XSSearchResult<LCESuggestionPartial>> {
        this.suggestionSearch.query = XSUtils.isEmpty(query) ? '' : XSUtils.trim(query);
        this.suggestionSearch.statuses = this.statusFilter;
        this.suggestionSearch.anonymous = this.anonymousChipFilter.selected ? true : undefined;
        this.suggestionSearch.readBy = [];
        this.suggestionSearch.selectedBy = [];
        if (this.readByMeChipFilter.selected) {
            this.suggestionSearch.readBy!.push(this.contextService.getUser().id);
        }
        if (this.readByTheMayorChipFilter.selected && !XSUtils.isEmpty(this.mayorIDCode?.id)) {
            this.suggestionSearch.readBy!.push(this.mayorIDCode.id);
        }
        if (this.selectedByTheMayorChipFilter.selected && !XSUtils.isEmpty(this.mayorIDCode?.id)) {
            this.suggestionSearch.selectedBy!.push(this.mayorIDCode.id);
        }
        return this.suggestionService.search(this.suggestionSearch);
    }

    private handleVisibleLateralPanelChange(visible: boolean): void {
        this.lateralPanelVisible = visible;
        this.setSplitterSecondPanelVisible(this.lateralPanelVisible);
    }

    private setSplitterSecondPanelVisible(visible: boolean) {
        if (this.pSplitter) {
            const splitterPanels = XSCoreDomUtils.findElements(this.pSplitter['el'].nativeElement, '.p-splitter-panel')!;
            const splitterPanelSecondPanel = splitterPanels[1];
            const splitterGutter = XSCoreDomUtils.findElement(this.pSplitter['el'].nativeElement, '.p-splitter-gutter')!;

            if (visible) {
                XSCoreDomUtils.removeClass(splitterPanelSecondPanel, 'xs-display-none-imp');
                XSCoreDomUtils.removeClass(splitterGutter, 'xs-display-none-imp');
            } else {
                XSCoreDomUtils.addClass(splitterPanelSecondPanel, 'xs-display-none-imp');
                XSCoreDomUtils.addClass(splitterGutter, 'xs-display-none-imp');
            }
        }
    }

    private initializeChipFilters(): void {
        this.anonymousChipFilter = {
            selectable: true,
            removable: false,
            label: 'lce.shared.label.anonymous',
            selected: false,
            onSelected: () => this.dataManager.search(),
            onUnselect: () => this.dataManager.search()
        };
        this.readByMeChipFilter = {
            selectable: true,
            removable: false,
            label: this.TR_BASE + 'label.readByMe',
            selected: false,
            onSelected: () => this.dataManager.search(),
            onUnselect: () => this.dataManager.search()
        };
        this.readByTheMayorChipFilter = {
            selectable: true,
            removable: false, label: this.TR_BASE + 'label.readByTheMayor',
            selected: false,
            onSelected: () => this.dataManager.search(),
            onUnselect: () => this.dataManager.search()
        };
        this.selectedByTheMayorChipFilter = {
            selectable: true,
            removable: false,
            label: this.TR_BASE + 'label.selectedByTheMayor',
            selected: false,
            onSelected: () => this.dataManager.search(),
            onUnselect: () => this.dataManager.search()
        };
        this.chipFilters = [this.anonymousChipFilter, this.readByMeChipFilter, this.readByTheMayorChipFilter, this.selectedByTheMayorChipFilter];
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query: string | undefined) => this.search(query),
            fnRetrieveRecordData: (selectedRow: string | LCESuggestionPartial) => this.retrieveSuggestion(selectedRow),

            search: {
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE + 'main.resultFound',
                listingItemStyleClass: 'xs-min-width-250'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
