import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
    LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE,
    LCECoreContextService,
    LCESmartCityArticle,
    LCESmartCityArticleCategoryService,
    LCESmartCityArticleCreate,
    LCESmartCityArticleReviewerService,
    LCESmartCityArticleService,
    LCESmartCityArticleType
} from '@lce/core';
import {LOG, XS_LOREM_IPSUM, XS_STR_EMPTY, XS_STR_SPACE, XSEnvironmentName, XSUtils} from '@xs/base';
import {XSFormUtils, XSLabelValue, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldDropdownOptions, XSInputFieldEditorOptions, XSInputFieldTextOptions, XSNavigationService} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEArticleCategoriesOptions} from '../../article/categories/lce-article-categories-options';
import {LCEArticleCategoryUtils} from '../../article/categories/lce-article-category-utils';
import {LCEArticleReviewersOptions} from '../../article/reviewers/lce-article-reviewers-options';
import {LCEArticleTagUtils} from '../../article/tags/lce-article-tag-utils';
import {LCEArticleTagsOptions} from '../../article/tags/lce-article-tags-options';
import {LCESmartCityFeatureService} from '../lce-smartcity-feature.service';

@Component({
    selector: 'lce-smartcity-article-create-update',
    templateUrl: './lce-smartcity-article-create-update.component.html'
})
export class LCESmartCityCreateUpdateComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.smartcity.articleCreateUpdate.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateArticleCentralLoader';

    @Input() styleClass?: string;

    @Input() smartCityArticle: LCESmartCityArticle;
    @Input() smartcityArticleID: string;

    @Output() saveEvent = new EventEmitter<LCESmartCityArticle>();
    @Output() closeEvent = new EventEmitter<LCESmartCityArticle>();

    titleField: XSInputFieldTextOptions;
    subTitleField: XSInputFieldTextOptions;
    contentField: XSInputFieldEditorOptions;
    descriptionField: XSInputFieldEditorOptions;
    typeField: XSInputFieldDropdownOptions;
    categoriesControl: FormControl;
    categoriesOptions: LCEArticleCategoriesOptions;
    tagsControl: FormControl;
    tagsOptions: LCEArticleTagsOptions;
    reviewersControl: FormControl;
    reviewersOptions: LCEArticleReviewersOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveArticle()
    };

    createUpdateError: any;

    private subscription: Subscription = new Subscription();

    private formGroup: FormGroup = new FormGroup({});

    private articleID?: string;

    private readonly TYPES: XSLabelValue[] = [
        {
            label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.AMBER_ALERT,
            value: LCESmartCityArticleType.AMBER_ALERT
        },
        {
            label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.NOTIFICATION,
            value: LCESmartCityArticleType.NOTIFICATION
        },
        {
            label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.PUBLIC_EVENT,
            value: LCESmartCityArticleType.PUBLIC_EVENT
        }
    ];

    constructor(
        private router: Router,
        private loaderService: XSLoaderService,
        private activatedRoute: ActivatedRoute,
        private contextService: LCECoreContextService,
        private smartcityArticleService: LCESmartCityArticleService,
        private smartcityArticleCategoryService: LCESmartCityArticleCategoryService,
        private smartcityArticleReviewerService: LCESmartCityArticleReviewerService,
        private featureService: LCESmartCityFeatureService,
        private navigationService: XSNavigationService
    ) {
        super();
    }

    get mode(): string {
        return this.isCreateMode() ? 'create' : 'update';
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.smartcityArticleID = this.dialogConfig.data.smartcityArticleID;
            this.smartCityArticle = this.dialogConfig.data.newArticle;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.smartCityArticle));
        }

        this.featureService.state.title = this.TR_BASE + this.mode + 'Title';
        this.featureService.state.subTitle = this.isCreateMode() ? this.TR_BASE + 'createSubTitle' : this.smartCityArticle?.code;
        this.featureService.state.showNewArticleButton = false;
        this.featureService.state.showRefreshButton = false;
        this.featureService.state.showBackButton = true;
        this.featureService.onRefresh.subscribe(() => this.retrieveArticle());

        this.activatedRoute.params.pipe(first()).subscribe((params) => {
            this.articleID = params['id'];
            if (XSUtils.isEmpty(this.articleID)) {
                this.initialize();
            } else {
                this.retrieveArticle();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.articleID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.articleID);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) {
            this.create();
        } else {
            this.update();
        }
    }

    public reset() {
        this.formGroup.reset();
    }

    public close() {
        const defaultURL = this.isCreateMode() ? '/smartcity' : '/news/smartcity/' + this.smartcityArticleID;
        this.navigationService.back(defaultURL);
        this.featureService.emitBack();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) {
            return !this.isFormEmpty();
        } else {
            return this.formGroup.dirty;
        }
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.titleField.control?.value) ||
            !XSUtils.isEmpty(this.subTitleField.control?.value) ||
            !XSUtils.isEmpty(this.descriptionField.control?.value) ||
            !XSUtils.isEmpty(this.contentField.control?.value) ||
            !XSUtils.isEmpty(this.categoriesControl.value) ||
            !XSUtils.isEmpty(this.tagsControl?.value) ||
            !XSUtils.isEmpty(this.typeField.control?.value)
        ) {
            return false;
        }
        return true;
    }

    public canShowFillForm(): boolean {
        const environmentName: XSEnvironmentName = this.contextService.getAppConfig().environment;
        return environmentName === XSEnvironmentName.NOBACKEND || environmentName === XSEnvironmentName.LOCAL || environmentName === XSEnvironmentName.DEV;
    }

    public fillForm(): void {
        this.titleField.control?.setValue(XS_LOREM_IPSUM.medium);
        this.subTitleField.control?.setValue(XS_LOREM_IPSUM.long);
        this.descriptionField.control?.setValue('<p>' + XS_LOREM_IPSUM.long + XS_STR_SPACE + XS_LOREM_IPSUM.long + '</p>');
        this.contentField.control?.setValue('<p>' + XS_LOREM_IPSUM.veryLong + '</p>');
        this.typeField.control?.setValue(XSUtils.randomElement([LCESmartCityArticleType.AMBER_ALERT, LCESmartCityArticleType.NOTIFICATION, LCESmartCityArticleType.PUBLIC_EVENT]));
        this.categoriesControl.setValue(
            LCEArticleCategoryUtils.toChips([
                {id: 'dd968154-17e3-4a51-8022-3021b51b01f9', name: 'Show Business', code: 'LCE-SMC-CAT-0345'}
                // TODO : add 2 more.
            ])
        );
        this.tagsControl.setValue(LCEArticleTagUtils.toChips(['can', 'can2024', 'iphone13', 'cie', 'sodecie']));
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
        this.buildOptions();
    }

    private retrieveArticle(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.smartcityArticleService
                .retrieve(this.articleID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: (retrievedArticle) => {
                        this.smartCityArticle = retrievedArticle;
                        this.initialize();
                        if (!this.isDialog()) {
                            this.featureService.state.subTitle = this.smartCityArticle?.code;
                        }
                    },
                    error: (error) => (this.retrieveError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        LOG().debug('Updating Article ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildArticleUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Article not updated. No change detected ! [code: ' + this.smartCityArticle!.code + ', id: ' + this.articleID + ']');
            this.router.navigate(['/smartcity/articles', this.articleID]).then();
            return;
        }

        this.subscription.add(
            this.smartcityArticleService
                .update(this.articleID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (article) => {
                        const updatedArticleID = article.id;
                        LOG().debug('Article successfully updated :-) [code: ' + article.code + ', id: ' + updatedArticleID + ']');
                        this.router.navigate(['smartcity/articles', updatedArticleID]).then();
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        LOG().debug('Creating Article ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const articleCreate = this.buildArticleCreate(formData);

        this.subscription.add(
            this.smartcityArticleService
                .create(articleCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (article) => {
                        const savedArticleID = article.id;
                        LOG().debug('Article successfully saved :-) [code: ' + article.code + ', id: ' + savedArticleID + ']');
                        this.router.navigate(['smartcity/articles', savedArticleID]).then();
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildArticleUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        const currentArticle = this.buildArticleCreate(formData);
        if (!XSUtils.equal(this.smartCityArticle?.title, currentArticle.title)) {
            fieldValueMap.set('title', currentArticle.title);
        }
        if (!XSUtils.equal(this.smartCityArticle?.subTitle, currentArticle.subTitle)) {
            fieldValueMap.set('subTitle', currentArticle.subTitle);
        }
        if (!XSUtils.equal(this.smartCityArticle?.description, currentArticle.description)) {
            fieldValueMap.set('description', currentArticle.description);
        }
        if (!XSUtils.equal(this.smartCityArticle?.content, currentArticle.content)) {
            fieldValueMap.set('content', currentArticle.content);
        }
        if (!XSUtils.equal(this.smartCityArticle?.type, currentArticle.type)) {
            fieldValueMap.set('type', currentArticle.type);
        }
        const areReviewersEqual = XSUtils.equal(
            this.smartCityArticle?.reviewers.map((reviewer) => reviewer.user.id),
            currentArticle.reviewerIDs
        );
        if (!areReviewersEqual) {
            fieldValueMap.set('reviewerIDs', currentArticle.reviewerIDs);
        }

        const areCategoriesEqual = XSUtils.equal(
            this.smartCityArticle?.categories.map((category) => category.code), currentArticle.categoryCodes
        );
        if (!areCategoriesEqual) {
            fieldValueMap.set('categoryCodes', currentArticle.categoryCodes);
        }

        if (!XSUtils.equal(this.smartCityArticle?.tags, currentArticle.tags)) {
            fieldValueMap.set('tags', currentArticle.tags);
        }

        console.log('fieldValueMap', fieldValueMap);
        return fieldValueMap;
    }

    private buildArticleCreate(formData: any): LCESmartCityArticleCreate {
        return {
            title: {en: XSUtils.trim(formData.title), fr: XSUtils.trim(formData.title)},
            subTitle: XSUtils.isEmpty(formData.subTitle) ? undefined : {
                en: XSUtils.trim(formData.subTitle),
                fr: XSUtils.trim(formData.subTitle)
            },
            description: {en: XSUtils.trim(formData.description), fr: XSUtils.trim(formData.description)},
            content: {en: XSUtils.trim(formData.content), fr: XSUtils.trim(formData.content)},

            coverImage: formData.coverImage,

            reviewerIDs: formData.reviewers,

            categoryCodes: LCEArticleCategoryUtils.getCategoryCodes(formData.categories),
            tags: LCEArticleTagUtils.getTags(formData.tags),

            type: formData.type
        };
    }

    private buildOptions(): void {
        this.categoriesOptions = {
            mode: 'input',
            canAdd: true,
            autocomplete: (query: string, limit: number) => {
                return this.smartcityArticleCategoryService.autocomplete(query, limit);
            }
        };
        this.tagsOptions = {
            mode: 'input',
            canAdd: true,
            autocomplete: (query: string, limit: number) => {
                return this.smartcityArticleService.autocompleteTags(query, limit);
            }
        };
        this.reviewersOptions = {
            canAdd: true,
            canRemoveAlreadyAdded: true,
            showSectionTitles: true,
            retrieveDefaultReviewers: () => {
                const categoryCodes: string[] = LCEArticleCategoryUtils.getCategoryCodes(this.categoriesControl.value);
                return this.smartcityArticleReviewerService.retrieveDefaultReviewers(categoryCodes);
            }
        };
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: this.TR_BASE + 'resetConfirmation',
            icon: this.ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: this.TR_BASE + 'closeConfirmation',
            icon: this.ICON.confirmation,
            accept: () => {
                this.close();
            },
            reject: () => {
            }
        };
    }

    private buildFields(): void {
        this.contentField = {
            fieldName: 'content',
            control: new FormControl(XSUtils.isEmpty(this.smartCityArticle?.content) ? XS_STR_EMPTY : this.smartCityArticle?.content, Validators.required),
            label: this.TR_BASE + 'contentLabel',
            editorStyleClass: 'xs-height-500',
            mode: 'advanced'
        };
        this.descriptionField = {
            fieldName: 'description',
            control: new FormControl(XSUtils.isEmpty(this.smartCityArticle?.description) ? XS_STR_EMPTY : this.smartCityArticle?.description, Validators.required),
            label: this.TR_BASE + 'descriptionLabel',
            editorStyleClass: 'xs-height-150',
            mode: 'medium'
        };
        this.subTitleField = {
            fieldName: 'subTitle',
            control: new FormControl(XSUtils.isEmpty(this.smartCityArticle?.subTitle) ? XS_STR_EMPTY : this.smartCityArticle?.subTitle, Validators.required),
            label: this.TR_BASE + 'subTitleLabel'
        };
        this.titleField = {
            fieldName: 'title',
            control: new FormControl(XSUtils.isEmpty(this.smartCityArticle?.title) ? XS_STR_EMPTY : this.smartCityArticle?.title, Validators.required),
            label: this.TR_BASE + 'titleLabel'
        };
        this.typeField = {
            fieldName: 'type',
            placeholder: this.TR_BASE + 'typesPlaceholder',
            control: new FormControl(),
            items: this.TYPES
        };

        this.categoriesControl = new FormControl(LCEArticleCategoryUtils.toChips(this.smartCityArticle?.categories), Validators.required);
        this.tagsControl = new FormControl(LCEArticleTagUtils.toChips(this.smartCityArticle?.tags));
        this.reviewersControl = new FormControl([]);

        this.formGroup.addControl('categories', this.categoriesControl);
        this.formGroup.addControl('tags', this.tagsControl);
        this.formGroup.addControl('reviewers', this.reviewersControl);

        this.formGroup.addControl(this.typeField.fieldName, this.typeField.control!);
        this.formGroup.addControl(this.contentField.fieldName, this.contentField.control!);
        this.formGroup.addControl(this.descriptionField.fieldName, this.descriptionField.control!);
        this.formGroup.addControl(this.subTitleField.fieldName, this.subTitleField.control!);
        this.formGroup.addControl(this.titleField.fieldName, this.titleField.control!);
    }
}
