import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEMBOMunicipalitiesComponent} from './lce-mbo-municipalities.component';

const routes: Routes = [{path: 'municipalities', component: LCEMBOMunicipalitiesComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCEMBOMunicipalitiesRoutingModule {
}
