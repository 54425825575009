import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {LCEDistrictsFeatureService} from '@lce/shared';
import {LCEMBODistrictsRoutingModule} from './lce-mbo-districts-routing.module';
import {LCEMBODistrictsComponent} from './lce-mbo-districts.component';

@NgModule({
    imports: [CommonModule, LCEMBOSharedModule, LCEMBODistrictsRoutingModule],
    declarations: [LCEMBODistrictsComponent],
    exports: [
        LCEMBODistrictsComponent
    ],
    providers: [LCEDistrictsFeatureService]
})
export class LCEMBODistrictsModule {
}
