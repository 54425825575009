import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCETerminalPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSClickEvent, XSDeviceOsIconPipe} from '@xs/common';
import {XSAvatar} from '@xs/core';

@Component({
    selector: 'lce-terminal-partial',
    template: `
        <xs-ragnar
                (avatarClickEvent)="onClick($event)"
                (line1ClickEvent)="onClick($event)"
                [avatar]="showIcon ? deviceAvatar : undefined"
                [disabled]="disabled"
                [label]="label"
                [ellipsis]="true"
                [avatarClickable]="clickable"
                [line1Clickable]="clickable"
                [line1]="(data.device.name | xsIsNotEmpty) ? data.device.name : (data.device.os | xsDeviceOS)"
                [line2]="data.facility.fullName"
                [styleClass]="styleClass"
                iconStyleClass="lce-resource-partial-avatar-icon xs-color-secondary-imp xs-font-size-22-imp"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2StyleClass="xs-font-size-small-imp xs-color-secondary">
        </xs-ragnar>
    `,
    providers: [XSDeviceOsIconPipe],
    host: {class: 'xs-flex-row xs-width-full xs-position-relative'}
})
export class LCETerminalPartialComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() labelStyleClass?: string;
    @Input() label?: string;
    @Input() showLabel?: boolean;

    @Input() data: LCETerminalPartial;

    @Input() showIcon?: boolean;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<LCETerminalPartial>();

    constructor(private deviceOsIconPipe: XSDeviceOsIconPipe) {
    }

    get deviceAvatar(): XSAvatar | undefined {
        return {
            type: 'icon',
            data: this.deviceOsIconPipe.transform(this.data.device.os),
            size: 'extra-small'
        };
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (this.showLabel && XSUtils.isEmpty(this.label)) this.label = 'lce.core.label.terminal';
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }

    public onClick(event: XSClickEvent<string> | Event): void {
        if (this.disabled || !this.clickable) {
            ((event as any).event || event).stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(this.data);
    }
}
