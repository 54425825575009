<div class="xs-flex-column-center xs-width-full xs-mt-20 xs-mb-50 {{ styleClass }}">
    <div class="grid xs-width-full">
        <div
                [ngClass]="{ 'xs-background-icon xs-background-icon-font-size-150 xs-background-icon-opacity-040 lce-background-icon-customer': showBackgroundIcon }"
                class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile [error]="errorA" [loading]="loading" [value]="stats?.total" text="{{ TR_BASE }}stats.total"></xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile [error]="errorA" [loading]="loading" [value]="stats?.active" text="{{ TR_BASE }}stats.active"></xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile [error]="errorA" [loading]="loading" [value]="stats?.inactive" text="{{ TR_BASE }}stats.inactive"></xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile [error]="errorA" [loading]="loading" [value]="stats?.deleted" text="{{ TR_BASE }}stats.deleted"></xs-statistic-tile>
        </div>
    </div>
    <div class="grid xs-width-full xs-mt-40">
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile
                    (clickEvent)="onAdditionalStatsClick(STATE.REGISTERED)"
                    [error]="additionalStatsLoadingError.registered!.error"
                    [loading]="additionalStatsLoadingError.registered!.loading"
                    [value]="additionalStats.registered"
                    text="{{ TR_BASE }}additionalStats.registered">
            </xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile
                    (clickEvent)="onAdditionalStatsClick(STATE.UNREGISTERED)"
                    [error]="additionalStatsLoadingError.unregistered!.error"
                    [loading]="additionalStatsLoadingError.unregistered!.loading"
                    [value]="additionalStats.unregistered"
                    text="{{ TR_BASE }}additionalStats.unregistered">
            </xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile
                    (clickEvent)="onAdditionalStatsClick(STATE.ORDERED_AT_LEAST_ONCE)"
                    [error]="additionalStatsLoadingError.orderedAtLeastOnce!.error"
                    [loading]="additionalStatsLoadingError.orderedAtLeastOnce!.loading"
                    [value]="additionalStats.orderedAtLeastOnce"
                    text="{{ TR_BASE }}additionalStats.orderedAtLeastOnce">
            </xs-statistic-tile>
        </div>
        <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
            <xs-statistic-tile
                    (clickEvent)="onAdditionalStatsClick(STATE.NEVER_ORDERED)"
                    [error]="additionalStatsLoadingError.neverOrdered!.error"
                    [loading]="additionalStatsLoadingError.neverOrdered!.loading"
                    [value]="additionalStats.neverOrdered"
                    text="{{ TR_BASE }}additionalStats.neverOrdered">
            </xs-statistic-tile>
        </div>
    </div>
</div>
