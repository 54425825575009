import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {ChartModule} from 'primeng/chart';
import {LCEMBODashboardComponent} from './lce-mbo-dashboard.component';
import {LCETemplateModule} from '@lce-mbo/templates/lce-template.module';
import {LCEMBODashboardService} from '@lce-mbo/features/dashboard/lce-mbo-dashboard.service';

@NgModule({
    imports: [CommonModule, LCEMBOSharedModule, ChartModule, LCETemplateModule],
    declarations: [
        LCEMBODashboardComponent
    ],
    providers: [LCEMBODashboardService]
})
export class LCEMBODashboardModule {
}
