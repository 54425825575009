<div class="lce-facility-town-hall-stamp-token-historic-main {{ styleClass }}">
    <div class="lce-facility-town-hall-stamp-token-historic-main-container">

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-flex-column-center xs-width-full">
            <p-selectButton
                    (onChange)="onPredefinedPeriodStatFilterChange()"
                    [(ngModel)]="predefinedPeriodStatFilter"
                    [options]="predefinedPeriodStatFilterOptions"
                    class="xs-sm-display-none xs-mb-30 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>

            <div class="xs-max-width-full xs-mb-40">
                <xs-swiper [options]="swiperOptions">
                    <ng-template xsTemplate="item">
                        <div class="xs-flex-row">
                            <xs-statistic-tile
                                    [chipLabel]="'xs.common.predefinedPeriod.' + predefinedPeriodFilter"
                                    [error]="stampGenerated.error.consumed || stampGenerated.error.partiallyConsumed || stampGenerated.error.consumed"
                                    [loading]="stampGenerated.loading.consumed && stampGenerated.loading.partiallyConsumed && stampGenerated.loading.consumed"
                                    [showBackground]="true"
                                    [showSeparator]="true"
                                    [text]=" TR_BASE + 'stats.total'"
                                    [value]="formatValue(stampGenerated.value.consumed + stampGenerated.value.partiallyConsumed + stampGenerated.value.unconsumed)"
                                    class="xs-min-width-300 xs-mr-50"
                                    iconSize="small"
                                    orientation="horizontal"
                                    styleClass="xs-width-full">
                            </xs-statistic-tile>
                            <xs-statistic-tile
                                    [chipLabel]="'xs.common.predefinedPeriod.' + predefinedPeriodFilter"
                                    [error]="stampGenerated.error.consumed"
                                    [loading]="stampGenerated.loading.consumed"
                                    [showBackground]="true"
                                    [showSeparator]="true"
                                    [text]=" TR_BASE + 'stats.consumed'"
                                    [value]="formatValue(stampGenerated.value.consumed)"
                                    class="xs-min-width-300 xs-mr-50"
                                    iconSize="small"
                                    orientation="horizontal"
                                    styleClass="xs-width-full">
                            </xs-statistic-tile>
                            <xs-statistic-tile
                                    [chipLabel]="'xs.common.predefinedPeriod.' + predefinedPeriodFilter"
                                    [error]="stampGenerated.error.partiallyConsumed"
                                    [loading]="stampGenerated.loading.partiallyConsumed"
                                    [showBackground]="true"
                                    [showSeparator]="true"
                                    [text]=" TR_BASE + 'stats.partiallyConsumed'"
                                    [value]="formatValue(stampGenerated.value.partiallyConsumed)"
                                    class="xs-min-width-300 xs-mr-50"
                                    iconSize="small"
                                    orientation="horizontal"
                                    styleClass="xs-width-full">
                            </xs-statistic-tile>
                            <xs-statistic-tile
                                    [chipLabel]="'xs.common.predefinedPeriod.' + predefinedPeriodFilter"
                                    [error]="stampGenerated.error.unconsumed"
                                    [loading]="stampGenerated.loading.unconsumed"
                                    [showBackground]="true"
                                    [showSeparator]="true"
                                    [text]=" TR_BASE + 'stats.unconsumed'"
                                    [value]="formatValue(stampGenerated.value.unconsumed)"
                                    class="xs-min-width-300 xs-mr-50"
                                    iconSize="small"
                                    orientation="horizontal"
                                    styleClass="xs-width-full">
                            </xs-statistic-tile>
                        </div>
                    </ng-template>
                </xs-swiper>
            </div>

            <div class="xs-field xs-field-input xs-field-input-search xs-width-full xs-max-width-750 xs-mb-25">
                <label class="xs-font-size-medium-imp xs-text-primary-color-imp" for="{{ searchText }}">{{ TR_BASE + 'label.searchStampToken' | translate }}</label>

                <xs-input-search
                        (changeEvent)="search()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'label.searchTextPlaceholder'"
                        [searching]="searching"
                        inputId="searchText">
                </xs-input-search>
            </div>

            <!--            <div class="xs-field xs-field-input xs-width-full xs-max-width-750">-->
            <!--                <lce-input-field-user-autocomplete-multiple (selectEvent)="search()" [options]="createdByAutocompleteField"-->
            <!--                ></lce-input-field-user-autocomplete-multiple>-->
            <!--            </div>-->

            <p-selectButton
                    (onChange)="onPredefinedPeriodFilterChange()"
                    [(ngModel)]="predefinedPeriodFilter"
                    [options]="predefinedPeriodFilterOptions"
                    class="xs-sm-display-none xs-mb-15 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>
            <p-selectButton
                    (onChange)="onStatusFilterChange()"
                    [(ngModel)]="statusFilter"
                    [multiple]="true"
                    [options]="statusFilterOptions"
                    class="xs-sm-display-none xs-mb-15 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>
            <p-selectButton
                    (onChange)="onStateFilterChange()"
                    [(ngModel)]="stateFilter"
                    [multiple]="true"
                    [options]="stateFilterOptions"
                    class="xs-sm-display-none xs-mb-25 xs-width-full xs-max-width-500"
                    styleClass="xs-select-button xs-select-button-small">
            </p-selectButton>

            <div class="xs-width-full xs-max-width-550">
                <xs-chips
                        (valueChange)="onStampNumberFilter($event)"
                        [chips]="stampNumberFilterChips"
                        [clickable]="true"
                        [selectable]="true"
                        [swiperNavigation]="true"
                        [swiper]="true"
                        chipStyleClass="xs-plr-30-imp"
                        label="2">
                </xs-chips>
            </div>

        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Table === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <lce-facility-town-hall-stamp-token-table
                    (paginationEvent)="onPagination($event)"
                    (resetPreferencesEvent)="onResetPreferences()"
                    (resetSortEvent)="onResetSort()"
                    (sortEvent)="onSort($event)"
                    [caption]="tableCaption"
                    [data]="tableData"
                    [loadingState]="tableLoadingState"
                    [nResults]="tableNResults"
                    [readonly]="readonly"
                    [subCaption]="tableSubCaption">
            </lce-facility-town-hall-stamp-token-table>
        </div>
    </div>
</div>
