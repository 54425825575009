import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEUserCustomer, LCEUserCustomerAdditionalStats, LCEUserCustomerCreate, LCEUserCustomerPartial, LCEUserCustomerSearch} from '../../domain/user/lce-user-customer';
import {LCEValidatorUtils} from '../../utils/lce-validator-utils';
import {LCEHttpClientService} from '../lce-http-client.service';
import {LCEUserService} from './lce-user.service';

@Injectable({providedIn: 'root'})
export class LCEUserCustomerService extends LCEUserService<LCEUserCustomer, LCEUserCustomerPartial, LCEUserCustomerSearch> {

	constructor(protected httpClientService: LCEHttpClientService) {
		super(httpClientService, LCE_CORE_ENDPOINTS.customers.index, 'customer');
	}

	public retrieveAdditionalStats(): Observable<LCEUserCustomerAdditionalStats> {
		return this.httpClientService.get<LCEUserCustomerAdditionalStats>(this.buildEndpoint(LCE_CORE_ENDPOINTS.customers.additionalStats));
	}

	public create(customerCreate: LCEUserCustomerCreate): Observable<LCEUserCustomer> {
		LCEValidatorUtils.validateCustomerCreate(customerCreate);
		return this.httpClientService.post<LCEUserCustomer>(this.buildEndpoint(), customerCreate);
	}
}
