import {Component, Input, OnInit} from '@angular/core';
import {LCEFacilityTownHallStampLoadPartial, LCEFacilityTownHallStampLoadService} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-town-hall-stamp-load-last',
    template: `
        <div class="xs-position-relative {{styleClass}}">
            <div class="xs-absolute-center-vh">
                <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="20"></xs-loader>
            </div>
            <div *ngIf="hasError() && !loader.isRunning()">
                <xs-error
                        [error]="error"
                        [showActionButton]="true"
                        [showErrorDetailButton]="true"
                        [message]="TR_BASE_LABEL + 'errorMessageLastLoadRetrieve'"
                        mode="inline"
                        subMessage="xs.core.error.contactAdminMessage">
                </xs-error>
            </div>
            <xs-statistic-tile
                    *ngIf="!loader.isRunning()"
                    [icon]=" showIcon? ICON.stamp : undefined"
                    [loading]="loading"
                    [error]="error"
                    [showBackground]="showBackground"
                    iconSize="small"
                    [showSeparator]="true"
                    [value]="lastStampsLoad.size"
                    orientation="horizontal"
                    styleClass="xs-min-width-350 xs-width-fit-content"
                    [text]="TR_BASE_LABEL + 'lastLoad'">
            </xs-statistic-tile>
        </div>
    `
})
export class LCEFacilityTownHallStampLoadLastComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid() + 'facilityTownLoadStampRemaining';

    readonly TR_BASE_LABEL = 'lce.shared.facility.townHallStampLoad.label.';

    @Input() styleClass?: string;

    @Input() showBackground?: boolean;
    @Input() showIcon?: boolean;

    @Input() facilityID: string;

    loading: boolean;

    error: any;

    lastStampsLoad: LCEFacilityTownHallStampLoadPartial;

    constructor(private loaderService: XSLoaderService, private facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityID);
        this.refresh();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public refresh(): void {
        this.retrieve();
    }

    private retrieve(): void {
        this.error = null;
        this.loaderService.startLoader(this.LOADER_ID);
        this.facilityTownHallStampLoadService.findLastStampLoad(this.facilityID)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe(
                {
                    next: (lastStampsLoadRetrieved) => this.lastStampsLoad = lastStampsLoadRetrieved,
                    error: (error) => this.error = error
                }
            );

    }
}
