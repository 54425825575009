import {Injectable} from '@angular/core';
import {LCEUserCustomer} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEUserCustomerRecordComponent} from './record/lce-user-customer-record.component';

export interface LCECustomerDialogRecordArg {
    customerID?: string;
    customer?: LCEUserCustomer;
    onClose?: (customer?: LCEUserCustomer) => void;
}

@Injectable()
export class LCEUserCustomerDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCECustomerDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.customerID) && XSUtils.isEmpty(arg.customer)) {
            throw new Error('arg.customerID and arg.customer cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.customerID) && !XSUtils.isEmpty(arg.customer)) {
            throw new Error('arg.customerID and arg.customer cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                customerID: arg.customerID,
                customer: arg.customer,
                styleClass: 'xs-mt-30',
                loadingStyleClass: 'xs-min-height-500 xs-min-width-850'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: false,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-900'
        };

        const recordComponent: LCEUserCustomerRecordComponent = this.dialogService.openComponentDialog(LCEUserCustomerRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedSmartcityArticle: LCEUserCustomer) => {
            if (arg?.onClose) arg!.onClose!(savedSmartcityArticle);
        });
    }
}
