<div class="xs-width-full {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center">
        <lce-user-ivar [data]="comment.createdBy" [showOnlyAvatar]="false" [showOnlyAvatar]="true" [showStatus]="false" [showTooltip]="false" [viewDetail]="true">
        </lce-user-ivar>
        <div class="xs-flex-column xs-ml-10 xs-sm-ml-10-imp">
            <span class="xs-display-block xs-font-size-intermediate xs-font-weight-500">{{ comment.createdBy.name | xsPersonName }}</span>
            <span class="xs-display-block xs-mt-2 xs-font-size-small xs-color-secondary">{{ comment.createdOn | xsDate : createdOnDateFormat }}</span>
        </div>
    </div>
</div>
