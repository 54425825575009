import { Injectable } from '@angular/core';
import { XSAssert, XSUtils } from '@xs/base';
import { XSDialogConfig, XSDialogService } from '@xs/core';
import { LCEEvent, LCEEventType, LCEResourceType } from '@lce/core';
import { LCEEventRecordComponent } from './record/lce-event-record.component';

export interface LCEEventDialogRecordArg {
    eventID?: string;
    event?: LCEEvent;
    eventType?: LCEEventType;
    resourceType?: LCEResourceType;
    eventName?: string;
    onClose?: (event?: LCEEvent) => void;
}

@Injectable()
export class LCEEventDialogService {
    constructor(private dialogService: XSDialogService) {}

    public openRecordDialog(arg: LCEEventDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.eventID) && XSUtils.isEmpty(arg.event)) {
            throw new Error('arg.eventID and arg.event cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.eventID) && !XSUtils.isEmpty(arg.event)) {
            throw new Error('arg.eventID and arg.event cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                eventID: arg.eventID,
                event: arg.event,
                eventType: arg.eventType,
                resourceType: arg.resourceType,
                eventName: arg.eventName,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-500',
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: false,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000',
        };

        const recordComponent: LCEEventRecordComponent = this.dialogService.openComponentDialog(LCEEventRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((event: LCEEvent) => {
            if (arg?.onClose) arg!.onClose!(event);
        });
    }
}
