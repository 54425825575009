import {formatNumber} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT, LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenSearch, LCEFacilityTownHallStampTokenStatus} from '@lce/core';
import {LOG, XSInstantRange, XSPagination, XSPKDTOAuditFullState, XSPredefinedPeriod, XSResourceAuditCanOptions, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {XS_PREDEFINED_PERIOD_ITEMS, XSCommonUtils, XSLabelValueItem, XSLoaderState, XSSwiperOptions, XSText, XSTranslationService} from '@xs/common';
import {XSChip, XSInputFieldBaseOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEFacilityStampTokenFeatureService} from '../lce-facility-stamp-token-feature.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-history-global',
    templateUrl: 'lce-facility-town-hall-stamp-token-history-global.component.html',
    providers: [LCEFacilityStampTokenFeatureService]
})
export class LCEFacilityTownHallStampTokenHistoryGlobalComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.';
    readonly TR_BASE_STATUS = LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT;

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_TOTAL_RECORDS: number = 100;

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() canAudit?: XSResourceAuditCanOptions;

    @Output() updatedEvent = new EventEmitter<void>();

    createdByAutocompleteField: XSInputFieldBaseOptions;

    // === Table ===
    tableData: LCEFacilityTownHallStampTokenPartial[] = [];
    tableNResults: number = 0;
    tableCaption: XSText;
    tableSubCaption: XSText;
    tableLoadingState: XSLoaderState;

    // --- Search ---
    searchText: string;
    searching: boolean;

    // --- Filter ---
    predefinedPeriodStatFilterOptions: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XS_PREDEFINED_PERIOD_ITEMS);
    predefinedPeriodStatFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;

    predefinedPeriodFilterOptions: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XS_PREDEFINED_PERIOD_ITEMS);
    predefinedPeriodFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;

    statusFilter: LCEFacilityTownHallStampTokenStatus[];
    statusFilterOptions: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_STATUS + LCEFacilityTownHallStampTokenStatus.CONSUMED, value: LCEFacilityTownHallStampTokenStatus.CONSUMED},
        {trLabel: this.TR_BASE_STATUS + LCEFacilityTownHallStampTokenStatus.UNCONSUMED, value: LCEFacilityTownHallStampTokenStatus.UNCONSUMED},
        {trLabel: this.TR_BASE_STATUS + LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED, value: LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED}
    ];

    stateFilter: XSPKDTOAuditFullState[];
    stateFilterOptions: XSLabelValueItem[] = [
        {trLabel: 'xs.core.auditFullState.' + XSPKDTOAuditFullState.ACTIVE, value: XSPKDTOAuditFullState.ACTIVE},
        {trLabel: 'xs.core.auditFullState.' + XSPKDTOAuditFullState.INACTIVE, value: XSPKDTOAuditFullState.INACTIVE},
        {trLabel: 'xs.core.auditFullState.' + XSPKDTOAuditFullState.DELETED, value: XSPKDTOAuditFullState.DELETED}
    ];

    stampNumberFilterChips: XSChip[] = [
        {label: '1', value: 1},
        {label: '2', value: 2}, {label: '3', value: 3},
        {label: '4', value: 4}, {label: '5', value: 5},
        {label: '6', value: 6}, {label: '7', value: 7},
        {label: '8', value: 8}, {label: '9', value: 9}
    ];

    swiperOptions: XSSwiperOptions;

    stampGenerated = {
        loading: {all: false, consumed: false, unconsumed: false, partiallyConsumed: false},
        error: {all: undefined, consumed: undefined, unconsumed: undefined, partiallyConsumed: undefined},
        value: {all: 0, consumed: 0, unconsumed: 0, partiallyConsumed: 0}
    };

    private pagination: XSPagination = {page: 0, size: this.PAGINATOR_TOTAL_RECORDS};

    facilityTownHallStampTokenSearch: LCEFacilityTownHallStampTokenSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size
    };

    private updateState: {
        search: boolean;
        countConsumed: boolean;
        countUnconsumed: boolean;
        countPartiallyConsumed: boolean;
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private featureService: LCEFacilityStampTokenFeatureService) {
        this.initializeTranslation();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.update()));
        this.subscription.add(this.featureService.onStartSearch.subscribe(() => this.handleStartSearch()));
        this.subscription.add(this.featureService.onEndSearch.subscribe((searchResults) => this.handleEndSearch(searchResults)));
    }

    ngOnInit(): void {

        this.createdByAutocompleteField = {
            control: new FormControl(),
            fieldName: 'createdBy',
            belowText: this.TR_BASE + 'label.createdBy',
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small xs-mt-0-imp'
        };
        this.update();
        this.swiperOptions = this.buildSwiperOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onStampNumberFilter(stampNumber: any[]): void {
        this.facilityTownHallStampTokenSearch.numberOfStamps = stampNumber;
        this.search();
    }

    public onPagination(pagination: XSPagination): void {
        this.featureService.pagination = pagination;
        this.search();
    }

    public onSort(sort: XSSort): void {
        this.featureService.sort = sort;
        this.featureService.pagination.page = 1;
        this.search();
    }

    public onResetSort(): void {
        this.featureService.sort = undefined;
        this.search();
    }

    public onResetPreferences(): void {
        this.search();
    }

    public onPredefinedPeriodStatFilterChange(): void {
        this.counts();
    }

    public onPredefinedPeriodFilterChange(): void {
        this.facilityTownHallStampTokenSearch.createdOnPredefinedPeriod = this.predefinedPeriodFilter;
        this.search();
    }

    public onStatusFilterChange(): void {
        this.facilityTownHallStampTokenSearch.statuses = this.statusFilter;
        this.search();
    }

    public onStateFilterChange(): void {
        this.search();
    }

    public formatValue(value: any): any {
        if (!XSUtils.isNumber(value)) return value;
        if (value > 0 && value < 10) {
            return XSUtils.zeroLeftPad(value);
        }
        return formatNumber(value, this.translationService.getCurrentLanguage().toString());
    }

    public update(): void {
        this.initializeUpdateState();
        this.search(true);
        this.counts(true);
    }

    public search(update: boolean = false): void {
        this.facilityTownHallStampTokenSearch.query = !XSUtils.isEmpty(this.searchText) ? this.searchText.trim() : '';
        if (!XSUtils.isEmpty(this.predefinedPeriodFilter)) this.facilityTownHallStampTokenSearch.createdOnRange = XSInstantRange.fromPredefinedPeriod(this.predefinedPeriodFilter);
        if (!XSUtils.isEmpty(this.statusFilter)) this.facilityTownHallStampTokenSearch.statuses = this.statusFilter;

        this.featureService
            .search(this.facilityTownHallStampTokenSearch)
            .pipe(finalize(() => {
                if (update) {
                    this.updateState.search = true;
                    this.checkUpdateState();
                }
            }))
            .subscribe();
    }

    private handleEndSearch(searchResults: XSSearchResult<LCEFacilityTownHallStampTokenPartial>): void {
        this.tableData = searchResults.data;
        this.tableNResults = searchResults.total;
        this.tableCaption = {value: this.TR_BASE + 'table.caption'};

        this.searching = false;
        this.tableLoadingState = {state: false};

        LOG().debug(this.tableNResults + ' facility Town Hall Stamp Token Found.');
    }

    private handleStartSearch(loadingText?: string): void {
        this.searching = true;
        this.tableLoadingState = {state: true, text: loadingText};
    }

    private counts(update: boolean = false): void {
        this.countConsumed(update);
        this.countUnconsumed(update);
        this.countPartiallyConsumed(update);
    }

    private countConsumed(update: boolean = false): void {
        this.stampGenerated.loading.consumed = true;
        this.stampGenerated.value.consumed = 0;
        this.subscription.add(
            this.featureService.countSearch({createdOnPredefinedPeriod: this.predefinedPeriodStatFilter, statuses: [LCEFacilityTownHallStampTokenStatus.CONSUMED]})
                .pipe(finalize(() => {
                    this.stampGenerated.loading.consumed = false;
                    if (update) {
                        this.updateState.countConsumed = true;
                        this.checkUpdateState();
                    }
                }))
                .subscribe(
                    {
                        next: (count) => {
                            this.stampGenerated.value.consumed = count.total;
                        },
                        error: error => this.stampGenerated.error.consumed = error
                    }
                )
        );
    }

    private countUnconsumed(update: boolean = false): void {
        this.stampGenerated.loading.unconsumed = true;
        this.stampGenerated.value.unconsumed = 0;
        this.subscription.add(
            this.featureService.countSearch({createdOnPredefinedPeriod: this.predefinedPeriodStatFilter, statuses: [LCEFacilityTownHallStampTokenStatus.UNCONSUMED]})
                .pipe(finalize(() => {
                    this.stampGenerated.loading.unconsumed = false;
                    if (update) {
                        this.updateState.countUnconsumed = true;
                        this.checkUpdateState();
                    }
                }))
                .subscribe(
                    {
                        next: (count) => {
                            this.stampGenerated.value.unconsumed = count.total;
                        },
                        error: error => this.stampGenerated.error.unconsumed = error
                    }
                )
        );
    }

    private countPartiallyConsumed(update: boolean = false): void {
        this.stampGenerated.loading.partiallyConsumed = true;
        this.stampGenerated.value.partiallyConsumed = 0;
        this.subscription.add(
            this.featureService.countSearch({createdOnPredefinedPeriod: this.predefinedPeriodStatFilter, statuses: [LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED]})
                .pipe(finalize(() => {
                    this.stampGenerated.loading.partiallyConsumed = false;
                    if (update) {
                        this.updateState.countPartiallyConsumed = true;
                        this.checkUpdateState();
                    }
                }))
                .subscribe(
                    {
                        next: (count) => {
                            this.stampGenerated.value.partiallyConsumed = count.total;
                        },
                        error: error => this.stampGenerated.error.partiallyConsumed = error
                    }
                )
        );
    }

    private checkUpdateState(): void {
        if (this.updateState?.search &&
            this.updateState?.countConsumed &&
            this.updateState.countUnconsumed &&
            this.updateState?.countPartiallyConsumed) {
            this.updatedEvent.emit();
            this.initializeUpdateState();
        }
    }

    private initializeUpdateState(): void {
        this.updateState = {
            search: false,
            countConsumed: false,
            countUnconsumed: false,
            countPartiallyConsumed: false
        };
    }

    private buildSwiperOptions(): XSSwiperOptions {
        return {
            direction: 'horizontal',

            spaceBetween: 15,
            freeMode: true,
            slidesPerView: 'auto',
            grabCursor: true,

            navigation: true,
            navigationHideOnMouseLeave: true,

            navigationArrowVisibleAfterViewInit: false,

            pagination: false,
            paginationType: 'progressbar',

            autoplay: false,
            autoplayPauseOnMouseEnter: true
        };
    }

    private initializeTranslation(): void {
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.predefinedPeriodStatFilterOptions);
                this.predefinedPeriodStatFilterOptions = [...this.predefinedPeriodStatFilterOptions];
            })
        );

        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.predefinedPeriodFilterOptions);
                this.predefinedPeriodFilterOptions = [...this.predefinedPeriodFilterOptions];
            })
        );

        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statusFilterOptions);
                this.statusFilterOptions = [...this.statusFilterOptions];
            })
        );

        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.stateFilterOptions);
                this.stateFilterOptions = [...this.stateFilterOptions];
            })
        );
    }
}
