import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {LCEServicePointsFeatureService} from '@lce/shared';
import {LCEMBOServicePointsRoutingModule} from './lce-mbo-service-points-routing.module';
import {LCEMBOServicePointsComponent} from './lce-mbo-service-points.component';

@NgModule({
    imports: [CommonModule, LCEMBOSharedModule, LCEMBOServicePointsRoutingModule],
    declarations: [LCEMBOServicePointsComponent],
    exports: [
        LCEMBOServicePointsComponent
    ],
    providers: [LCEServicePointsFeatureService]
})
export class LCEMBOServicePointsModule {
}
