import {Component, OnInit} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCEMunicipalitiesFeatureService, LCEMunicipalityDialogService} from '@lce/shared';
import {LCEMunicipalityCanOptions} from '@lce/core';

@Component({
    selector: 'lce-mbo-municipalities',
    templateUrl: './lce-mbo-municipalities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEMunicipalityDialogService]
})
export class LCEMBOMunicipalitiesComponent implements OnInit {

    readonly ICON_PLUS: string = LCE_MBO_ICON.plus;
    readonly ICON_REFRESH: string = LCE_MBO_ICON.refresh;

    readonly TR_BASE: string = 'lce.shared.municipality.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    can: LCEMunicipalityCanOptions;

    constructor(private featureService: LCEMunicipalitiesFeatureService, private municipalityDialogService: LCEMunicipalityDialogService) {
    }

    ngOnInit(): void {
        this.can = {
            create: false
        };
    }

    public onCreate(): void {
        if (!this.can.create) return;
        this.municipalityDialogService.openCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
