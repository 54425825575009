<div class="lce-settings-main {{ styleClass }}">
    <div class="lce-settings-main-container">
        <div class="xs-absolute-center-vh">
            <xs-loader #mainLoader [loaderId]="loaderID.main" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="hasError() && !mainLoader.isRunning()" class="xs-absolute-center-vh">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error.main"
                    [showActionButton]="true"
                    [showErrorDetailButton]="true"
                    message="{{ TR_BASE }}errorMessageRetrieveSettings"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>

        <div
                [ngClass]="{ 'xs-background-icon xs-background-icon-font-size-100 xs-background-icon-opacity-040 lce-background-icon-settings': showBackgroundIcon }"
                class="xs-flex-row xs-align-items-center xs-justify-content-end xs-width-full xs-height-75">
            <div class="xs-position-relative xs-mr-25">
                <xs-loader #refreshLoader [loaderId]="loaderID.refresh" [loaderSize]="20" class="xs-absolute-center-vh"></xs-loader>
            </div>
        </div>

        <div
                *ngIf="!hasError() && !mainLoader.isRunning()"
                [ngClass]="{ 'xs-border-discrete xs-border-radius-default xs-p-15': showBorder, 'xs-disabled': refreshLoader.isRunning() }"
                class="xs-flex-column xs-width-full">
            <div class="xs-card xs-mtb-50">
                <div class="xs-card-header xs-card-header-separator">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="generalCard"
                            [expanded]="true" [text]="TR_BASE + 'general'"
                            textStyleClass="xs-font-size-medium-imp">
                    </xs-title>
                </div>
                <div #generalCard class="xs-card-content xs-pt-25-imp">
                    <div class="grid xs-fields xs-fields-spacer-30">
                        <div class="col-12 md:col-6">
                            <xs-input-field-contact-person [options]="primaryContactPersonFieldOptions"></xs-input-field-contact-person>
                        </div>

                        <div class="col-12 md:col-6">
                            <xs-input-field-contact-person [options]="secondaryContactPersonFieldOptions"></xs-input-field-contact-person>
                        </div>

                        <div class="col-12 md:col-6">
                            <div class="xs-data-field">
                                <xs-babavoss
                                        [data]="municipalitySettings.publicInformation!"
                                        [options]="publicInformationOptions"
                                        label="{{ TR_BASE }}publicInformation"
                                        overlayStyleClass="xs-max-width-500"
                                        styleClass="xs-ml-15">
                                </xs-babavoss>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="xs-card xs-mb-50">
                <div class="xs-card-header xs-card-header-separator">
                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="authenticationCard" [expanded]="true" [text]="TR_BASE + 'authentication'"
                              textStyleClass="xs-font-size-medium-imp">
                    </xs-title>
                </div>
                <div #authenticationCard class="xs-card-content xs-pt-25-imp">
                    <div class="xs-mb-40 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                        <xs-ivar [line1]="TR_BASE + 'sessionDuration'" [line2]="TR_BASE + 'sessionDurationDescription'"></xs-ivar>

                        <div class="xs-flex-column xs-align-items-end xs-position-relative">
                            <xs-input-dropdown
                                    (valueChange)="onSessionDurationChange($event)"
                                    [(value)]="municipalitySettings.sessionDuration"
                                    [disabled]="sessionDurationLoader.isRunning()"
                                    [items]="sessionDurationItems"
                                    [options]="sessionDurationOptions"
                                    panelStyleClass="xs-width-100-imp"
                                    dropdownStyleClass="xs-input-just-border-bottom">
                            </xs-input-dropdown>
                            <div class="xs-absolute-center-h xs-bottom-minus-12">
                                <xs-loader #sessionDurationLoader [loaderId]="loaderID.sessionDuration"></xs-loader>
                            </div>
                            <xs-error
                                    *ngIf="error.sessionDuration && !sessionDurationLoader.isRunning()"
                                    [error]="error.sessionDuration"
                                    [showErrorDetailButton]="true"
                                    [showIcon]="false"
                                    message="{{ TR_BASE }}settingsUpdateErrorMessage"
                                    mode="inline"
                                    styleClass="xs-mt-5">
                            </xs-error>
                        </div>
                    </div>

                    <div class="xs-mb-40 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                        <xs-ivar [line1]="TR_BASE + 'authenticationPrimaryPhoneNumberAsUsername'"
                                 [line2]="TR_BASE + 'authenticationPrimaryPhoneNumberAsUsernameDescription'"></xs-ivar>
                        <div class="xs-flex-column xs-align-items-end xs-position-relative">
                            <div class="xs-absolute-center-h xs-bottom-minus-12">
                                <xs-loader #authViaPhoneNumberLoader [loaderId]="loaderID.authenticationPrimaryPhoneNumberAsUsername"></xs-loader>
                            </div>
                            <xs-input-switch
                                    (changeEvent)="handleAuthenticationViaPhoneNumber($event)"
                                    [(value)]="municipalitySettings.authenticationPrimaryPhoneNumberAsUsername">
                            </xs-input-switch>
                            <xs-error
                                    *ngIf="error.authenticationPrimaryPhoneNumberAsUsername && !authViaPhoneNumberLoader.isRunning()"
                                    [error]="error.authenticationPrimaryPhoneNumberAsUsername"
                                    [showErrorDetailButton]="true"
                                    [showIcon]="false"
                                    message="{{ TR_BASE }}settingsUpdateErrorMessage"
                                    mode="inline"
                                    styleClass="xs-mt-5">
                            </xs-error>
                        </div>
                    </div>

                    <div class="xs-flex-row xs-justify-content-space-between xs-align-items-center">
                        <xs-ivar [line1]="TR_BASE + 'authenticationCodeAsUsername'" [line2]="TR_BASE + 'authenticationCodeAsUsernameDescription'"></xs-ivar>
                        <div class="xs-flex-column xs-align-items-end xs-position-relative">
                            <div class="xs-absolute-center-h xs-bottom-minus-12">
                                <xs-loader #authViaCodeLoader [loaderId]="loaderID.authenticationCodeAsUsername"></xs-loader>
                            </div>
                            <xs-input-switch (changeEvent)="handleAuthenticationViaCode($event)" [(value)]="municipalitySettings.authenticationCodeAsUsername"></xs-input-switch>
                            <xs-error
                                    *ngIf="error.authenticationCodeAsUsername && !authViaCodeLoader.isRunning()"
                                    [error]="error.authenticationCodeAsUsername"
                                    [showErrorDetailButton]="true"
                                    [showIcon]="false"
                                    message="{{ TR_BASE }}settingsUpdateErrorMessage"
                                    mode="inline"
                                    styleClass="xs-mt-5">
                            </xs-error>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
