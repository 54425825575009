import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LCE_MBO_AUTHENTICATION_ROUTE} from '@lce-mbo/core/constants/lce-mbo.constant';
import {XSAuthenticationBaseGuard} from '@xs/core';
import {Observable} from 'rxjs';
import {LCEMBOAuthenticationService} from '../services/lce-mbo-authentication.service';

@Injectable({providedIn: 'root'})
export class LCEMBOAuthenticationGuard extends XSAuthenticationBaseGuard implements CanActivate {

    constructor(protected router: Router, private authenticationService: LCEMBOAuthenticationService) {
        super(router);
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return super.baseCanActivate(next, state);
    }

    protected isAuthenticated(): boolean {
        return this.authenticationService.isAuthenticated();
    }

    protected getDashboardRoute(): string {
        return LCE_MBO_AUTHENTICATION_ROUTE.dashboard;
    }

    protected getAuthenticationBaseRoute(): string {
        return LCE_MBO_AUTHENTICATION_ROUTE.base;
    }

    protected getAuthenticationLoginRoute(): string {
        return LCE_MBO_AUTHENTICATION_ROUTE.login;
    }
}
