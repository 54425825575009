import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LCEArticleReviewer, LCENewsArticle, LCENewsArticleService} from '@lce/core';
import {XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSChip, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCENewsFeatureService} from '../lce-news-feature.service';

@Component({selector: 'lce-news-article-view', templateUrl: './lce-news-article-view.component.html'})
export class LCENewsArticleViewComponent extends XSDialogable implements OnInit, OnDestroy {
    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.news.articleView.';
    readonly TR_BASE_TOOLTIP: string = 'lce.shared.news.articleView.tooltip.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() newArticle?: LCENewsArticle;
    @Input() newsArticleID?: string;

    @Output() editEvent = new EventEmitter<LCENewsArticle>();
    @Output() closeEvent = new EventEmitter<LCENewsArticle>();

    categoryChips: XSChip[] = [];
    tagChips: XSChip[] = [];
    reviewers: LCEArticleReviewer[] = [];

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveArticle()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private newsService: LCENewsArticleService,
        private featureService: LCENewsFeatureService) {
        super();

        this.featureService.state.title = this.TR_BASE + 'title';
        this.featureService.state.subTitle = this.newArticle?.code;
        this.featureService.state.showNewArticleButton = true;
        this.featureService.state.showRefreshButton = true;
        this.featureService.state.showBackButton = true;
        this.featureService.onRefresh.subscribe(() => this.update());
    }

    get createdOnDateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN : XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.newsArticleID = this.dialogConfig.data.newsArticleID;
            this.newArticle = this.dialogConfig.data.newArticle;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
        }

        if (!XSUtils.isEmpty(this.newsArticleID)) {
            this.retrieveArticle();
        } else {
            if (XSUtils.isEmpty(this.newArticle)) {
                this.loaderService.startLoader(this.LOADER_ID);
                this.activatedRoute.params.pipe(first()).subscribe((params) => {
                    this.newsArticleID = params['id'];
                    this.retrieveArticle();
                });
            }
        }

        if (XSUtils.isEmpty(this.newsArticleID) && XSUtils.isEmpty(this.newArticle)) {
            throw new Error('newsArticleID and newArticle cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.newsArticleID) && !XSUtils.isEmpty(this.newArticle)) {
            throw new Error('newsArticleID and newArticle cannot both be set at the same time.');
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public update(): void {
        this.retrieveArticle();
    }

    private retrieveArticle(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.newsService
                .retrieve(this.newsArticleID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (article: LCENewsArticle) => {
                        this.newArticle = article;
                        this.categoryChips = this.buildChips(this.newArticle.categories?.map(category => category.name));
                        this.tagChips = this.buildChips(this.newArticle.tags);
                        this.reviewers = this.newArticle.reviewers;
                        if (!this.isDialog()) {
                            this.featureService.state.subTitle = this.newArticle?.code;
                        }
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

    private buildChips(strArray: string[] | undefined): XSChip[] {
        const chips: XSChip[] = [];
        if (XSUtils.isEmpty(strArray)) {
            return [];
        }
        strArray!.forEach(str => {
            chips.push({label: str});
        });
        return chips;
    }
}
