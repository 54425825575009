<div class="xs-flex-column xs-width-full xs-height-full  {{styleClass}}">
    <p-tabView [(activeIndex)]="currentTabIndex" class="xs-tabview xs-plr-15" styleClass="xs-tabview-nav-center">
        <p-tabPanel header="{{ TR_BASE + 'generator' | translate }}">
            <ng-template pTemplate="content">
                <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                    <span
                            [innerHTML]="TR_BASE + 'generator' | translate"
                            class="xs-flex-row xs-align-items-center xs-header-left-stripe xs-border-left-width-4-imp xs-font-size-large xs-color-secondary xs-pl-20-imp xs-ptb-20-imp">
                    </span>
                    <div class="xs-flex-row">
                        <xs-button
                                (clickEvent)="deleteLastOverlay.toggle($event)"
                                [icon]="ICON_DELETE"
                                [loading]="loading.deleteLast"
                                [transform]="false"
                                class="xs-mr-40"
                                loaderPosition="bottom"
                                type="icon">
                        </xs-button>
                        <xs-button
                                (clickEvent)="settingsOverlay.toggle($event)"
                                [icon]="ICON_SETTINGS"
                                [transform]="false"
                                class="xs-mr-40"
                                type="icon">
                        </xs-button>
                        <xs-button
                                (clickEvent)="refreshStampTokenGenerator()"
                                [icon]="ICON_REFRESH"
                                [loading]="loading.refreshStampTokenGenerator"
                                [transform]="false"
                                loaderPosition="bottom"
                                type="icon">
                        </xs-button>

                        <p-overlayPanel #deleteLastOverlay styleClass="xs-overlay-panel-content-no-padding">
                            <ng-template pTemplate>
                                <lce-facility-town-hall-stamp-token-delete-last
                                        (cancelEvent)="deleteLastOverlay.hide()"
                                        (errorEvent)="error = $event; errorMessage = ERROR_DELETE_LAST"
                                        (loadingEvent)="loading.deleteLast = $event">
                                </lce-facility-town-hall-stamp-token-delete-last>
                            </ng-template>
                        </p-overlayPanel>
                        <p-overlayPanel #settingsOverlay styleClass="xs-overlay-panel-content-no-padding">
                            <lce-facility-town-hall-stamp-token-settings>
                            </lce-facility-town-hall-stamp-token-settings>
                        </p-overlayPanel>
                    </div>
                </div>
                <div class="xs-flex-row xs-justify-content-center xs-height-40">
                    <xs-error
                            *ngIf="error && !loading.deleteLast"
                            [error]="error"
                            [message]="errorMessage"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                </div>
                <lce-facility-town-hall-stamp-token-generator
                    #stampTokenGenerator
                    (createdEvent)="stampTokenHistoryUser?.updateCounts()"
                    (errorEvent)="error = $event; errorMessage = ERROR_CREATE"
                    (updatedEvent)="loading.refreshStampTokenGenerator = false"
                    [facilityCode]="facilityCode">
                </lce-facility-town-hall-stamp-token-generator>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="{{ TR_BASE + 'history' | translate }}">
            <ng-template pTemplate="content">
                <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-align-items-center xs-mb-40">
                    <span
                            [innerHTML]="(TR_BASE + 'history') | translate"
                            class="xs-flex-row xs-align-items-center xs-header-left-stripe xs-border-left-width-4-imp xs-font-size-large xs-color-secondary xs-pl-20-imp xs-ptb-20-imp">
                    </span>
                    <xs-button
                            (clickEvent)="refreshStampTokenHistoryUser()"
                            [icon]="ICON_REFRESH"
                            [loading]="loading.refreshStampTokenHistoryUser"
                            [transform]="false"
                            loaderPosition="bottom"
                            type="icon">
                    </xs-button>
                </div>
                <lce-facility-town-hall-stamp-token-history-user
                    #stampTokenHistoryUser
                    (updatedEvent)="loading.refreshStampTokenHistoryUser = false"
                    [canFilterByCreatedBy]="true">
                </lce-facility-town-hall-stamp-token-history-user>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>
