import { XSUtils } from '@xs/base';
import { XSLabelValue } from '@xs/common';
import { XSChip } from '@xs/core';
import { LCECategoryPartial } from '@lce/core';

export class LCEArticleCategoryUtils {
    private constructor() {}

    public static getCategoryCodes(chips: XSChip[]): string[] {
        return chips.map((chip) => chip.value.code);
    }

    public static toLabelValues(categories: LCECategoryPartial[] | undefined): XSLabelValue[] {
        if (XSUtils.isEmpty(categories)) return [];
        return categories!.map((category) => {
            return { label: category.name, value: category.code };
        });
    }

    public static toChips(categories: LCECategoryPartial[] | undefined, removable?: boolean): XSChip[] {
        if (XSUtils.isEmpty(categories)) return [];
        return categories!.map((category) => {
            const chip: XSChip = { label: category.name, value: category };
            if (!XSUtils.isNull(removable)) chip.removable = removable;
            return chip;
        });
    }
}
