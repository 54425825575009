<div class="xs-flex-column xs-flex-1 xs-width-full {{ styleClass }}">
    <div class="xs-width-full xs-flex-column xs-align-items-center">
        <xs-input-search
                (changeEvent)="onSearchTextChange()"
                [(value)]="searchText"
                [delay]="SEARCH_TEXT_DELAY"
                [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                [placeholder]="'lce.shared.globalSearch.label.searchPlaceholder'"
                class="xs-max-width-700"
                inputId="searchText">
        </xs-input-search>

        <div class="xs-sm-display-none xs-flex xs-justify-content-end xs-width-700 xs-mt-4 xs-pr-12">
            <xs-button [disabled]="true" label="lce.shared.globalSearch.label.advancedSearchLabel" styleClass="xs-font-size-small-imp" type="text"></xs-button>
        </div>
    </div>

    <div class="xs-flex-column xs-height-full xs-mt-50">
        <p-tabView #pTabView
                   (onChange)="onTabViewChange($event)"
                   [scrollable]="navScrollable"
                   class="xs-tabview"
                   styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">

            <p-tabPanel [id]="RESOURCE_TYPE.CERTIFICATE_ORDER" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.mbo.features.certificateOrders.title' | translate }}</span>
                    <xs-badge [small]="true" [value]="formatNResults(numberOfResults.nOrders)" mode="primary" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-certificate-orders-tab-panel (totalResultsChange)="numberOfResults.nOrders = $event"></lce-global-search-certificate-orders-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.BIRTH_DECLARATION" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.birthDeclaration.label.shortTitle' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="formatNResults(numberOfResults.nBirthDeclarations)" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-birth-declarations-tab-panel (totalResultsChange)="numberOfResults.nBirthDeclarations = $event">
                    </lce-global-search-birth-declarations-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.NEWS_ARTICLE" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.news.articles.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="numberOfResults.nNewsArticles | xsToString" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-news-tab-panel></lce-global-search-news-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.SMARTCITY_ARTICLE" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.smartcity.articles.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="numberOfResults.nSmartCityArticles | xsToString" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-smartcity-tab-panel></lce-global-search-smartcity-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.DISTRICT" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.district.main.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="formatNResults(numberOfResults.nDistricts)" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-districts-tab-panel (totalResultsChange)="numberOfResults.nDistricts = $event"></lce-global-search-districts-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.MUNICIPALITY" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.municipality.main.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="formatNResults(numberOfResults.nMunicipalities)" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-municipalities-tab-panel (totalResultsChange)="numberOfResults.nMunicipalities = $event"></lce-global-search-municipalities-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.FACILITY" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.facility.main.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="formatNResults(numberOfResults.nFacilities)" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-facilities-tab-panel (totalResultsChange)="numberOfResults.nFacilities = $event"></lce-global-search-facilities-tab-panel>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel [id]="RESOURCE_TYPE.SERVICE_POINT" class="resource-panel">
                <ng-template pTemplate="header">
                    <span>{{ 'lce.shared.servicePoint.main.title' | translate }}</span>
                    <xs-badge [mode]="'primary'" [small]="true" [value]="formatNResults(numberOfResults.nServicePoints)" styleClass="xs-ml-7 xs-min-width-35"></xs-badge>
                </ng-template>

                <ng-template pTemplate="content">
                    <lce-global-search-service-points-tab-panel (totalResultsChange)="numberOfResults.nServicePoints = $event"></lce-global-search-service-points-tab-panel>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

    <div></div>
</div>
