import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LCEHttpClientService, LCEMunicipalityService, LCEMunicipalitySettings, LCESessionDuration} from '@lce/core';
import {LOG, XSContactPerson, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSBabavossOptions, XSButton, XSInputDropdownOptions, XSInputFieldContactPersonOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-municipality-settings', templateUrl: './lce-municipality-settings.component.html'})
export class LCEMunicipalitySettingsComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.municipalitySettings.label.';
    readonly TR_BASE_SESSION_DURATION: string = 'lce.shared.sessionDurations.';

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showBackgroundIcon?: boolean;

    @Input() municipalityID: string;

    municipalitySettings: LCEMunicipalitySettings;

    primaryContactPersonFieldOptions: XSInputFieldContactPersonOptions;
    secondaryContactPersonFieldOptions: XSInputFieldContactPersonOptions;
    publicInformationOptions: XSBabavossOptions;

    error: any = {
        main: undefined,
        sessionDuration: undefined,
        authenticationPrimaryPhoneNumberAsUsername: undefined,
        authenticationCodeAsUsername: undefined
    };

    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: LCE_SHARED_ICON.redo,
        onClick: () => this.retrieveSettings()
    };

    sessionDurationItems: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.ONE_HOUR, value: 1 * 3600},
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.TWO_HOUR, value: 2 * 3600},
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.FOUR_HOUR, value: 4 * 3600},
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.EIGHT_HOURS, value: 8 * 3600},
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.TWELVE_HOURS, value: 12 * 3600},
        {trLabel: this.TR_BASE_SESSION_DURATION + LCESessionDuration.TWENTY_FOUR_HOURS, value: 24 * 3600}
    ];
    sessionDurationOptions: XSInputDropdownOptions = {
        labelField: 'label',
        valueField: 'value',
        translateItemLabels: true
    };

    loaderID: any = {
        main: 'mainLoaderID',
        sessionDuration: 'sessionDurationLoaderID',
        authenticationPrimaryPhoneNumberAsUsername: 'authViaPhoneNumberLoaderID',
        authenticationCodeAsUsername: 'authViaCodeLoaderID',
        refresh: 'refreshLoaderID'
    };

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService,
                private loaderService: XSLoaderService,
                private httpClientService: LCEHttpClientService,
                private municipalityService: LCEMunicipalityService) {
        this.translationService.translateItems(this.sessionDurationItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.sessionDurationItems);
                this.sessionDurationItems = [...this.sessionDurationItems];
            })
        );
    }

    ngOnInit(): void {
        this.retrieveSettings();

        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public update(): void {
        this.retrieveSettings(this.loaderID.refresh);
    }

    public onSessionDurationChange(event: any) {
        this.save('sessionDuration', Number(event));
    }

    public handleAuthenticationViaPhoneNumber(enabled: boolean): void {
        this.save('authenticationPrimaryPhoneNumberAsUsername', enabled);
    }

    public handleAuthenticationViaCode(enabled: boolean): void {
        this.save('authenticationCodeAsUsername', enabled);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error?.main);
    }

    private save(key: string, value: any): void {
        const fieldValueData: { [key: string]: any; } = {};
        fieldValueData[key] = value;
        this.loaderService.startLoader(this.loaderID[key]);
        this.subscription.add(
            this.municipalityService
                .updateSettings(this.municipalityID, fieldValueData)
                .pipe(finalize(() => this.loaderService.stopLoader(this.loaderID[key])))
                .subscribe({
                    next: (savedSettings) => {
                        LOG().debug('Municipality Settings successfully updated :-) ', savedSettings);
                        this.municipalitySettings = savedSettings;
                    },
                    error: (error) => (this.error[key] = error)
                })
        );
    }

    private buildFields(): void {
        this.publicInformationOptions = {
            readonly: false,
            createUpdate: {
                addButtonLabel: 'lce.shared.municipalitySettings.label.addPublicInformation',
                title: this.TR_BASE + 'publicInformation',
                subTitle: this.TR_BASE + 'publicInformationCreateUpdateSubTitle',
                fnSave: (updateBabavoss) => {
                    LOG().debug('Updating Public Information ...');
                    const fieldValueData: { [key: string]: any; } = {};
                    fieldValueData['publicInformation'] = updateBabavoss;
                    return this.municipalityService.updateSettings(this.municipalityID, fieldValueData).pipe(map((response) => response.publicInformation!));
                }
            }
        };

        this.primaryContactPersonFieldOptions = {
            fieldName: 'primaryContactPerson',
            label: 'xs.core.label.primaryContactPerson',
            control: new FormControl(this.municipalitySettings?.primaryContactPerson),
            contactPersonOptions: {
                formOptions: {
                    showNoteField: false,
                    showDescriptionField: false,
                    showEmailField: true,
                    showSecondaryPhoneNumberField: false,
                    createUpdateShowType: 'overlay',
                    title: 'xs.core.label.primaryContactPerson',
                    onSave: (contactPerson) => {
                        LOG().debug('Updating Primary Contact ...');
                        const fieldValueData: { [key: string]: any; } = {};
                        fieldValueData['primaryContactPerson'] = contactPerson;
                        return this.municipalityService.updateSettings(this.municipalityID, fieldValueData).pipe(map((response) => response.primaryContactPerson));
                    }
                }
            }
        };

        this.secondaryContactPersonFieldOptions = {
            fieldName: 'secondaryContactPerson',
            label: 'xs.core.label.secondaryContactPerson',
            control: new FormControl(this.municipalitySettings?.secondaryContactPerson),
            contactPersonOptions: {
                formOptions: {
                    showNoteField: false,
                    showDescriptionField: false,
                    showEmailField: true,
                    showSecondaryPhoneNumberField: false,
                    createUpdateShowType: 'overlay',
                    title: 'xs.core.label.secondaryContactPerson',
                    onSave: (contactPerson: XSContactPerson) => {
                        LOG().debug('Updating Secondary Contact ...');
                        const fieldValueData: { [key: string]: any; } = {};
                        fieldValueData['secondaryContactPerson'] = contactPerson;
                        return this.municipalityService.updateSettings(this.municipalityID, fieldValueData).pipe(map((response) => response.secondaryContactPerson));
                    }
                }
            }
        };
    }

    private retrieveSettings(loaderIDStr?: string): void {
        const lID = XSUtils.isEmpty(loaderIDStr) ? this.loaderID.main : loaderIDStr!;
        this.loaderService.startLoader(lID);
        this.subscription.add(
            this.municipalityService
                .retrieveSettings(this.municipalityID)
                .pipe(finalize(() => this.loaderService.stopLoader(lID)))
                .subscribe({
                    next: (mSettings) => {
                        this.municipalitySettings = mSettings;
                        this.buildFields();
                    },
                    error: (error) => (this.error.main = error)
                })
        );
    }
}
