<div class="lce-facility-town-hall-stamps-load-main {{ styleClass }}">
    <div class="lce-facility-town-hall-stamps-load-main-container">

        <div class="xs-flex-row xs-flex-wrap xs-justify-content-space-between xs-align-items-center xs-height-65 xs-mb-50">
            <lce-facility-town-hall-stamp-remaining
                    *ngIf="facility"
                    [townHallFullName]="facility.fullName">
            </lce-facility-town-hall-stamp-remaining>
            <lce-facility-town-hall-stamp-load-last [facilityID]="facility.id"></lce-facility-town-hall-stamp-load-last>
        </div>
        <!-- === * === -->
        <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750">
            <ng-template xsTemplate="sort">
                <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
            </ng-template>

            <!-- === Row Result Template ===-->
            <ng-template let-oneResult xsTemplate="row">
                <lce-facility-town-hall-stamp-load-partial [data]="oneResult"></lce-facility-town-hall-stamp-load-partial>
            </ng-template>

            <!-- === Record Template ===-->
            <ng-template let-recordData xsTemplate="record">
                <lce-facility-town-hall-stamp-load-record
                        [facilityTownHallStampLoad]="recordData" loadingStyleClass="xs-min-height-500 xs-min-width-600"
                        styleClass="xs-width-full xs-max-width-1000 xs-p-25"></lce-facility-town-hall-stamp-load-record>
            </ng-template>
        </xs-data-manager>
    </div>
</div>