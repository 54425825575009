<div class="lce-customers-main {{ styleClass }}">
    <div class="lce-customers-main-container">
        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Statistics === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <lce-user-customer-statistics #statistics (additionalStatsClickEvent)="onAdditionalStatsClickEvent($event)">
        </lce-user-customer-statistics>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-flex-row-center xs-width-full xs-mt-75">
            <div class="xs-field xs-field-input xs-field-input-search xs-max-width-750">
                <label class="xs-font-size-medium-imp xs-text-primary-color-imp" for="{{ searchText }}">{{ TR_BASE + 'searchCustomers' | translate }}</label>

                <xs-input-search
                        (changeEvent)="search()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'searchCustomers'"
                        [searching]="searching"
                        inputId="searchText">
                </xs-input-search>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Quick Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-sm-display-none xs-flex-column-center xs-width-full xs-mt-25 xs-mb-25">
            <p-selectButton
                    (onChange)="search()"
                    [(ngModel)]="customerStateFilter"
                    [options]="customerStateFilterItems"
                    styleClass="xs-select-button xs-select-button-small xs-mb-15 xs-width-800">
            </p-selectButton>
            <p-selectButton
                    (onChange)="search()"
                    [(ngModel)]="requestorTypeFilter"
                    [multiple]="true"
                    [options]="requestorTypeFilterItems"
                    styleClass="xs-select-button xs-select-button-small xs-mb-15 xs-width-500">
            </p-selectButton>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Table === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <lce-user-customers-table
                    (paginationEvent)="onPagination($event)"
                    (resetPreferencesEvent)="onResetPreferences()"
                    (resetSortEvent)="onResetSort()"
                    (sortEvent)="onSort($event)"
                    [caption]="tableCaption"
                    [data]="tableData"
                    [loadingState]="tableLoadingState"
                    [nResults]="tableNResults"
                    [readonly]="readonly"
                    [subCaption]="tableSubCaption">
            </lce-user-customers-table>
        </div>

    </div>
</div>
