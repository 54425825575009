import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {environment} from '@lce-mbo/environments/environment';
import {LCEMBOEventProcessorService} from '@lce-mbo/services/lce-mbo-event-processor.service';
import {LCEMBOSharedService} from '@lce-mbo/services/lce-mbo-shared.service';
import {LCESuggestionService, LCESuggestionStatus} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSMenuItem} from '@xs/core';
import {Subscription} from 'rxjs';

interface LCEVerticalSidebarMenuItem extends XSMenuItem {
    trShortLabel?: string;
    badgeStyleClass?: string;
    badgeValue?: string;
}

@Component({selector: 'lce-vertical-sidebar', templateUrl: './lce-vertical-sidebar.component.html', styleUrls: ['./lce-vertical-sidebar.component.scss']})
export class LCEVerticalSidebarComponent implements OnInit {

    readonly ICON = LCE_MBO_ICON;


    @Input() expandMenu: boolean;

    @Input() expandCollapse?: boolean;

    @Input() bottomTextMenu?: boolean;

    @Output() expandMenuChange = new EventEmitter<boolean>();

    menuItems: LCEVerticalSidebarMenuItem[];

    suggestionMenuItem: LCEVerticalSidebarMenuItem;

    private subscription = new Subscription();

    constructor(
        private sharedService: LCEMBOSharedService,
        private eventProcessorService: LCEMBOEventProcessorService,
        private suggestionService: LCESuggestionService) {
    }

    ngOnInit() {
        this.menuItems = this.buildMenu();
        if (XSUtils.isNull(this.expandMenu)) this.expandMenu = true;
        if (XSUtils.isNull(this.expandCollapse)) this.expandCollapse = true;
        if (XSUtils.isNull(this.bottomTextMenu)) this.bottomTextMenu = false;
        this.countPendingSuggestions();
        this.sharedService.onDashboardRefresh.subscribe(() => this.countPendingSuggestions());

        this.sharedService.onSuggestionStatusChanged.subscribe(() => this.countPendingSuggestions());
        this.eventProcessorService.onNewSuggestion.subscribe(() => this.countPendingSuggestions());
        this.sharedService.onSuggestionRefresh.subscribe(() => this.countPendingSuggestions());
    }

    public isExpanded() {
        return this.expandMenu;
    }

    public onOverlayButtonClick() {
        this.expandMenu = !this.expandMenu;
        this.expandMenuChange.emit(this.expandMenu);
    }

    private buildMenu(): LCEVerticalSidebarMenuItem[] {
        const birthDeclarationMenuItem = environment.featureSwitch.enableBirthDeclaration ? {
            icon: LCE_MBO_ICON.birthDeclaration,
            iconStyleClass: 'xs-font-size-20-imp',
            trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.birthDeclaration',
            trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.birthDeclaration',
            url: '/birth-declarations'
        } : undefined!;
        this.suggestionMenuItem = {
            icon: LCE_MBO_ICON.suggestion,
            iconStyleClass: 'xs-font-size-16-imp',
            trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.suggestions',
            trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.suggestions',
            url: '/suggestions'
        };
        const menuItems = [
            {
                icon: LCE_MBO_ICON.home,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.dashboard',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.dashboard',
                url: '/dashboard'
            },
            {
                icon: LCE_MBO_ICON.certificateOrder,
                iconStyleClass: 'xs-width-20-imp xs-height-20-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.certificateOrders',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.certificateOrders',
                url: '/certificate-orders'
            },
            {
                icon: LCE_MBO_ICON.terminal,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.terminals',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.terminals',
                url: '/terminals'
            },
            {
                icon: LCE_MBO_ICON.stamp,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.stampTokens',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.stampTokens',
                url: '/facility-town-hall-stamp-tokens'
            },
            birthDeclarationMenuItem,
            this.suggestionMenuItem,
            {
                icon: LCE_MBO_ICON.news,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.news',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.news',
                url: '/news'
            },
            {
                icon: LCE_MBO_ICON.smartCity,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.smartcity',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.smartcity',
                url: '/smartcity'
            },
            {
                icon: LCE_MBO_ICON.customer,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.customers',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.customers',
                url: '/customers'
            },
            {
                icon: LCE_MBO_ICON.finances,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.finances',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.finances',
                disabled: true,
                url: '/finances'
            },
            {
                icon: LCE_MBO_ICON.resources,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.resources',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.resources',
                url: '/resources'
            },
            {
                icon: LCE_MBO_ICON.users,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.userManagement',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.userManagement',
                url: '/user-management'
            },
            {
                icon: LCE_MBO_ICON.settings,
                iconStyleClass: 'xs-font-size-17-imp',
                trLabel: 'lce.mbo.template.sheldon.verticalSidebar.menu.settings',
                trShortLabel: 'lce.mbo.template.sheldon.verticalSidebar.menuShort.settings',
                url: '/settings'
            }
        ];

        return menuItems.filter(item => !XSUtils.isEmpty(item));
    }

    private countPendingSuggestions(): void {
        this.suggestionMenuItem.badgeValue = '...';
        this.subscription.add(
            this.suggestionService.count({statuses: [LCESuggestionStatus.UNREAD]}).subscribe((count) => {
                this.suggestionMenuItem.badgeValue = count.total > 0 ? count.total.toString() : undefined;
            })
        );
    }
}
