import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {XSUtils} from '@xs/base';
import {XSCoreDomUtils} from '@xs/core';
import {TabView} from 'primeng/tabview';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {LCEGlobalSearchFeatureService} from './lce-global-search-feature-service';
import {LCEResourceType} from '@lce/core';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEGlobalSearchNResults} from './lce-global-search';

@Component({
    selector: 'lce-global-search',
    templateUrl: './lce-global-search.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEGlobalSearchFeatureService]
})
export class LCEGlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    RESOURCE_TYPE = LCEResourceType;

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 3;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    @Input() styleClass?: string;

    searchText: string;
    query: string;

    currentResourceTypeTabPanel: LCEResourceType = LCEResourceType.CERTIFICATE_ORDER;

    navScrollable: boolean = false;

    private tabView: TabView;
    private tabViewResizeObserver: ResizeObserver;
    private subscription: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private globalSearchFeatureService: LCEGlobalSearchFeatureService) {
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    get numberOfResults(): LCEGlobalSearchNResults {
        return this.globalSearchFeatureService.numberOfResults;
    }

    ngOnInit(): void {
        this.searchText = this.activatedRoute.snapshot.queryParamMap.get('query')!;

        this.globalSearchFeatureService.onSearch.subscribe(searchEvent => {
            if (XSUtils.isEmpty(searchEvent)) return;
            this.globalSearchFeatureService.countAll().subscribe();
        });

        this.globalSearchFeatureService.emitSearchEvent(this.searchText);

    }

    ngAfterViewInit() {
        this.globalSearchFeatureService.emitTabChangeEvent(this.currentResourceTypeTabPanel);
        this.globalSearchFeatureService.countAll().subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public update(): Observable<void> {
        return this.globalSearchFeatureService.update();
    }

    public onTabViewChange(event: any) {
        const elements = XSCoreDomUtils.findElements(this.tabView.el.nativeElement, '.p-tabview-panels .resource-panel')!;
        if (XSUtils.isEmpty(elements)) return;
        this.currentResourceTypeTabPanel = elements[event.index].id as LCEResourceType;
        this.globalSearchFeatureService.emitTabChangeEvent(this.currentResourceTypeTabPanel);
    }

    public onSearchTextChange(): void {
        this.globalSearchFeatureService.emitSearchEvent(this.searchText);
    }

    public formatNResults(n: number | undefined): string {
        return XSUtils.isNull(n) ? '...' : n!.toString();
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver(() => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }
}
