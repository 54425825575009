import {Component, Input, OnInit} from '@angular/core';
import {LCESuggestionsFeatureService} from '../lce-suggestions-feature.service';
import {LCESoundAlertEventArg, LCESoundAlertService} from '../../api/services/lce-sound-alert.service';
import {LCEEventType, LCEResourceType, LCESuggestionEventName} from '@lce/core';

@Component({selector: 'lce-suggestions-settings', templateUrl: './lce-suggestions-settings.component.html'})
export class LCESuggestionsSettingsComponent implements OnInit {

    readonly TR_BASE: string = 'lce.shared.suggestion.settings.';

    readonly newSuggestionEventArg: LCESoundAlertEventArg = {
        eventType: LCEEventType.RESOURCE,
        resourceType: LCEResourceType.SUGGESTION,
        eventName: LCESuggestionEventName.NEW_SUGGESTION
    };

    @Input() styleClass?: string;

    visibleLateralPanel: boolean;
    visibleLastSuggestion: boolean;

    constructor(private featureService: LCESuggestionsFeatureService, private soundAlertService: LCESoundAlertService) {
    }

    get soundAlertEnabled(): boolean {
        return this.soundAlertService.isEventSoundAlertEnabled(this.newSuggestionEventArg);
    }

    ngOnInit(): void {
        this.visibleLateralPanel = this.featureService.getVisibleLateralPanel();
        this.visibleLastSuggestion = this.featureService.getVisibleLastSuggestion();
    }

    public onSoundAlertEnabledChange(checked: boolean) {
        this.soundAlertService.setEventSoundAlertEnabled(checked, this.newSuggestionEventArg);
    }

    public onVisibleLastSuggestionChange(): void {
        this.featureService.setVisibleLastSuggestion(this.visibleLastSuggestion);
    }

    public onVisibleLateralPanelChange(): void {
        this.featureService.setVisibleLateralPanel(this.visibleLateralPanel);
    }
}
