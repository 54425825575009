import {Injectable} from '@angular/core';
import {LCEMBOSharedService} from '@lce-mbo/services/lce-mbo-shared.service';

@Injectable({providedIn: 'root'})
export class LCEMBODashboardService {

    constructor(private sharedService: LCEMBOSharedService) {
    }

    public emitRefresh() {
        this.sharedService.emitDashboardRefresh();
    }
}
