import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {LCEMunicipalitiesFeatureService} from '@lce/shared';
import {LCEMBOMunicipalitiesRoutingModule} from './lce-mbo-municipalities-routing.module';
import {LCEMBOMunicipalitiesComponent} from './lce-mbo-municipalities.component';

@NgModule({
    imports: [CommonModule, LCEMBOSharedModule, LCEMBOMunicipalitiesRoutingModule],
    declarations: [LCEMBOMunicipalitiesComponent],
    exports: [LCEMBOMunicipalitiesComponent],
    providers: [LCEMunicipalitiesFeatureService]
})
export class LCEMBOMunicipalitiesModule {
}
