import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCESuggestion, LCESuggestionService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-suggestion-record',
    templateUrl: './lce-suggestion-record.component.html',
    host: {class: 'xs-width-full'}
})
export class LCESuggestionRecordComponent extends XSDialogable implements OnInit {

    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.suggestion.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() suggestionID?: string;
    @Input() suggestion?: LCESuggestion;

    @Output() closeEvent = new EventEmitter<LCESuggestion>();

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveSuggestion()
    };

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    constructor(private suggestionService: LCESuggestionService, private loaderService: XSLoaderService) {
        super();
    }

    get headerTitle(): string {
        return this.TR_BASE + 'label.suggestion';
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.suggestion) ? 'LCE-...' : this.suggestion!.code;
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.suggestionID = this.dialogConfig.data.suggestionID;
            this.suggestion = this.dialogConfig.data.suggestion;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.suggestion));
        }
        if (XSUtils.isEmpty(this.suggestionID) && XSUtils.isEmpty(this.suggestion)) {
            throw new Error('suggestionID and suggestion cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.suggestionID) && !XSUtils.isEmpty(this.suggestion)) {
            throw new Error('suggestionID and suggestion cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.suggestionID)) {
            this.retrieveSuggestion();
        }
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.suggestion);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private retrieveSuggestion(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.suggestionService
                .retrieve(this.suggestionID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (suggestion: LCESuggestion) => {
                        this.suggestion = suggestion;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }
}
