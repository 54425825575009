import {Injectable} from '@angular/core';
import {LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenSearch, LCEFacilityTownHallStampTokenService} from '@lce/core';
import {XSCount, XSPagination, XSPKDTOStats, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class LCEFacilityStampTokenFeatureService {

    public sort: XSSort | undefined;
    public pagination: XSPagination;
    private readonly TABLE_DEFAULT_PAGINATION_ROWS: number = 10;

    private startSearchSubject = new Subject<void>();
    onStartSearch = this.startSearchSubject.asObservable();

    private endSearchSubject = new Subject<XSSearchResult<LCEFacilityTownHallStampTokenPartial>>();
    onEndSearch = this.endSearchSubject.asObservable();

    private refreshSubject = new Subject<void>();
    onRefresh = this.refreshSubject.asObservable();

    constructor(private facilityTownHallStampTokenService: LCEFacilityTownHallStampTokenService) {
        this.pagination = {page: 1, size: this.TABLE_DEFAULT_PAGINATION_ROWS};
    }

    public emitRefresh() {
        this.refreshSubject.next();
    }

    public search(facilityTownHallStampTokenSearch: LCEFacilityTownHallStampTokenSearch): Observable<XSSearchResult<LCEFacilityTownHallStampTokenPartial>> {
        this.startSearchSubject.next();
        facilityTownHallStampTokenSearch.paginationPage = this.pagination.page - 1;
        facilityTownHallStampTokenSearch.paginationSize = this.pagination.size;

        if (XSUtils.isEmpty(this.sort)) {
            facilityTownHallStampTokenSearch.sort = ['createdOn|desc'];
        } else {
            const sortStr = this.sort!.by.map((sortIem) => sortIem.field + '|' + sortIem.order).join(',');
            facilityTownHallStampTokenSearch.sort = [sortStr];
        }
        return this.facilityTownHallStampTokenService
            .search(facilityTownHallStampTokenSearch)
            .pipe(tap((response) => this.endSearchSubject.next(response)));
    }

    public countSearch(facilityTownHallStampTokenSearch: LCEFacilityTownHallStampTokenSearch): Observable<XSCount> {
        return this.facilityTownHallStampTokenService.count(facilityTownHallStampTokenSearch);
    }

    public retrieveStats(): Observable<XSPKDTOStats> {
        return this.facilityTownHallStampTokenService.retrieveStats();
    }
}
