<div class="xs-card xs-width-full xs-max-width-500 {{ styleClass }}">
    <div class="xs-card-header">
        <div class="xs-flex-row xs-align-items-center">
            <xs-icon [value]="ICON_SETTINGS" color="secondary" size="large" styleClass="xs-mr-10"></xs-icon>
            <div class="xs-flex-column">
                <span [innerHTML]="(TR_BASE + 'title') | translate" class="xs-text-block-secondary-500"></span>
                <span [innerHTML]="(TR_BASE + 'subtitle') | translate" class="xs-text-block-small-secondary"></span>
            </div>
        </div>
    </div>
    <div class="xs-card-content xs-ptb-40-imp xs-plr-30-imp">
        <div class="xs-flex-row">
            <xs-input-switch
                    (changeEvent)="handleUseMobilePhoneNumber($event)"
                    [value]="useMobilePhoneNumber"
                    styleClass="xs-mt-5 xs-mr-50">
            </xs-input-switch>
            <xs-ivar
                    [ellipsis]="false"
                    [line1]="TR_BASE + 'useMobilePhoneNumber'"
                    [line2]="TR_BASE + 'useMobilePhoneNumberDescription'"
                    line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                    line2StyleClass="xs-font-size-small-imp">
            </xs-ivar>
        </div>
        <div class="xs-flex-row xs-mt-25">
            <xs-input-switch
                    (changeEvent)="handleCreateOnEnter($event)"
                    [value]="createOnEnter"
                    styleClass="xs-mt-5 xs-mr-50">
            </xs-input-switch>
            <xs-ivar
                    [ellipsis]="false"
                    [line1]="TR_BASE + 'createOnEnter'"
                    [line2]="TR_BASE + 'createOnEnterDescription'"
                    line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                    line2StyleClass="xs-font-size-small-imp">
            </xs-ivar>
        </div>
    </div>
</div>
