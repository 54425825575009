import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCEBirthDeclarationPartial, LCEBirthDeclarationService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCEBirthDeclarationDialogService} from '../../../birth-declaration/lce-birth-declaration-dialog.service';

@Component({
    selector: 'lce-global-search-birth-declarations-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-birth-declaration-partial [data]="oneResult" [showIcon]="true"></lce-birth-declaration-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCEBirthDeclarationDialogService]
})
export class LCEGlobalSearchBirthDeclarationsTabPanelComponent {
    readonly RESOURCE_TYPE = LCEResourceType.BIRTH_DECLARATION;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCEBirthDeclarationPartial> = {
        search: searchFilter => this.birthDeclarationService.search(searchFilter),
        openRecord: birthDeclarationPartial => this.birthDeclarationDialogService.openRecordDialog({birthDeclarationID: birthDeclarationPartial.id}),
        captionNResultFound: 'lce.shared.birthDeclaration.main.resultsFound',
        biColumnResults: true
    }

    constructor(private birthDeclarationService: LCEBirthDeclarationService, private birthDeclarationDialogService: LCEBirthDeclarationDialogService) {
    }
}
