<div class="xs-card xs-border-radius-default xs-box-shadow-default xs-width-full xs-max-width-650 {{ styleClass }}">

    <div *ngIf="title | xsIsNotEmpty" [ngClass]="{ 'xs-card-header-separator': showHeaderSeparator }" class="xs-card-header">
        <div class="xs-flex-row xs-align-items-center">
            <div *ngIf="showLogoText && (logoText | xsIsNotEmpty)" class="xs-display-block xs-border-right-light xs-pr-15 xs-mr-15">
                <span [innerHTML]="logoText! | translate" [style.font-family]="'inherit'" class="xs-color-secondary xs-font-size-extra-large"></span>
            </div>
            <div class="xs-overflow-hidden">
                <xs-ivar
                        [ellipsis]="true"
                        [line1]="title!"
                        [line2]="showSubtitle ? subtitle : undefined"
                        line1StyleClass="xs-color-secondary xs-font-weight-500-imp xs-font-size-default-imp"
                        line2StyleClass="xs-color-secondary xs-font-size-small-imp xs-mt-0-imp">
                </xs-ivar>
            </div>
        </div>
    </div>

    <div class="xs-card-content xs-align-items-center xs-pt-30-imp {{ contentStyleClass }}">
        <div *ngIf="!messageTemplateRef" class="xs-flex-column-center xs-width-full">
            <xs-icon *ngIf="messageIcon | xsIsNotEmpty"
                     [color]="messageIconColor"
                     [size]="messageIconSize"
                     [value]="messageIcon!"
                     styleClass="xs-display-block xs-mb-10 {{ messageIconStyleClass }}">
            </xs-icon>
            <div *ngIf="messageLine1 | xsIsNotEmpty" class="xs-flex-column-center xs-text-align-center">
                <span
                        [innerHTML]="messageLine1! | translate"
                        class="xs-text-block-intermediate {{ messageLine1StyleClass }}">
                </span>
                <span
                        *ngIf="messageLine2 | xsIsNotEmpty"
                        [innerHTML]="messageLine2! | translate"
                        class="xs-text-block-small xs-mt-1 {{ messageLine2StyleClass }}">
                </span>
            </div>
        </div>

        <div *ngIf="messageTemplateRef" class="xs-flex-column-center xs-width-full {{ messageTemplateContainerStyleClass }}">
            <ng-container *ngTemplateOutlet="messageTemplateRef!"></ng-container>
        </div>

        <div class="xs-flex-row-center xs-position-relative xs-height-40">
            <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="loaderSize" [loaderType]="loaderType"></xs-loader>
            <xs-error
                    *ngIf="hasErrorMessage() && !loader.isRunning()"
                    [ellipsis]="false"
                    [error]="error"
                    [message]="errorMessage"
                    [showErrorDetailButton]="showErrorDetailButton"
                    errorDetailButtonClass="xs-mt-2-imp"
                    mode="inline">
            </xs-error>
        </div>

        <input
                [disabled]="loader.isRunning()"
                [formControl]="pinCodeControl"
                class="xs-max-width-300"
                pInputText
                type="text">
    </div>

    <div class="xs-card-footer xs-align-items-center xs-pt-0-imp xs-pb-50-imp">
        <div *ngIf="footerTemplateRef" class="xs-flex-row {{ footerTemplateContainerStyleClass }}">
            <ng-container *ngTemplateOutlet="footerTemplateRef!"></ng-container>
        </div>
    </div>
</div>
