import {Injectable} from '@angular/core';
import {XSUtils} from '@xs/base';
import {XSLocalStorageService} from '@xs/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class LCESuggestionsFeatureService {

    static readonly STORAGE_KEY_ROOT = 'suggestions';
    static readonly STORAGE_KEY_VISIBLE_LATERAL_PANEL = 'visible.lateralPanel';
    static readonly STORAGE_KEY_VISIBLE_LAST_SUGGESTION = 'visible.lastSuggestion';

    onVisibleLateralPanel: Observable<boolean>;
    onVisibleLastSuggestion: Observable<boolean>;

    private visibleLateralPanelSubject: BehaviorSubject<boolean>;
    private visibleLastSuggestionSubject: BehaviorSubject<boolean>;

    private refreshSubject = new Subject<void>();
    onRefresh = this.refreshSubject.asObservable();

    constructor(private localStorageService: XSLocalStorageService) {
        this.initializeVisibleLateralPanel();
        this.initializeVisibleLastSuggestion();
    }

    public emitRefresh() {
        this.refreshSubject.next();
    }

    public getVisibleLastSuggestion(): boolean {
        return this.visibleLastSuggestionSubject.getValue();
    }

    public setVisibleLastSuggestion(visible: boolean) {
        this.visibleLastSuggestionSubject.next(visible);
        this.localStorageService.setObjectProperty(LCESuggestionsFeatureService.STORAGE_KEY_ROOT, LCESuggestionsFeatureService.STORAGE_KEY_VISIBLE_LAST_SUGGESTION, visible);
    }

    public getVisibleLateralPanel(): boolean {
        return this.visibleLateralPanelSubject.getValue();
    }

    public setVisibleLateralPanel(visible: boolean) {
        this.visibleLateralPanelSubject.next(visible);
        this.localStorageService.setObjectProperty(LCESuggestionsFeatureService.STORAGE_KEY_ROOT, LCESuggestionsFeatureService.STORAGE_KEY_VISIBLE_LATERAL_PANEL, visible);
    }

    private initializeVisibleLastSuggestion(): void {
        const visible = this.localStorageService.getObjectProperty(LCESuggestionsFeatureService.STORAGE_KEY_ROOT, LCESuggestionsFeatureService.STORAGE_KEY_VISIBLE_LAST_SUGGESTION);
        this.visibleLastSuggestionSubject = new BehaviorSubject<boolean>(XSUtils.isNull(visible) ? true : visible);
        this.onVisibleLastSuggestion = this.visibleLastSuggestionSubject.asObservable();
    }

    private initializeVisibleLateralPanel(): void {
        const visible = this.localStorageService.getObjectProperty(LCESuggestionsFeatureService.STORAGE_KEY_ROOT, LCESuggestionsFeatureService.STORAGE_KEY_VISIBLE_LATERAL_PANEL);
        this.visibleLateralPanelSubject = new BehaviorSubject<boolean>(XSUtils.isNull(visible) ? true : visible);
        this.onVisibleLateralPanel = this.visibleLateralPanelSubject.asObservable();
    }
}
