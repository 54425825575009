import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCEMBOAppInitialData} from '@lce-mbo/core/domain/lce-mbo-app-initial-data';
import {environment} from '@lce-mbo/environments/environment';
import {LCEMBOContextService} from '@lce-mbo/services/lce-mbo-context.service';
import {LCEHttpClientService, LCEWebSocketService} from '@lce/core';
import {LOG, XS_STORAGE_KEY_LANGUAGE, XS_STORAGE_KEY_TOKEN, XSLanguage, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSTranslationService} from '@xs/common';
import {XSImageService, XSLocalStorageService} from '@xs/core';
import {LCEMBOAuthenticationService} from './lce-mbo-authentication.service';

@Injectable({providedIn: 'root'})
export class LCEMBOInitialDataService {

    constructor(
        private router: Router,
        private translationService: XSTranslationService,
        private localStorageService: XSLocalStorageService,
        private contextService: LCEMBOContextService,
        private httpClientService: LCEHttpClientService,
        private webSocketService: LCEWebSocketService,
        private imageService: XSImageService,
        private authenticationService: LCEMBOAuthenticationService) {

        this.authenticationService.onGotIn.subscribe(loginResponse => this.initialize(loginResponse.initialData as LCEMBOAppInitialData, '/dashboard'));
        this.authenticationService.onGotOut.subscribe(() => this.disconnectWebSocket());
    }

    public logout(): void {
        this.disconnectWebSocket();
    }

    public async initialize(appInitialData: LCEMBOAppInitialData, navigateURL?: string): Promise<void> {
        this.contextService.setUser(appInitialData.authenticatedUser!);

        if (!XSUtils.isEmpty(appInitialData.facility)) {
            this.contextService.setFacility(appInitialData.facility!);
        }
        if (!XSUtils.isEmpty(appInitialData.ipGeolocation)) {
            this.contextService.setIPGeolocation(appInitialData.ipGeolocation!);
        }

        const userLanguage: XSLanguage | undefined = appInitialData.authenticatedUser?.language;
        if (!XSUtils.isEmpty(userLanguage) && this.translationService.getCurrentLanguage() !== userLanguage) {
            await this.translationService.initialize(userLanguage!);
            this.httpClientService.setHeaderLanguage(userLanguage!);
            this.localStorageService.setItem(XS_STORAGE_KEY_LANGUAGE, userLanguage!);
        }

        if (!XSUtils.isEmpty(appInitialData.authenticatedUser!.profileImage)) {
            await this.imageService.cacheImage(appInitialData.authenticatedUser!.profileImage!).toPromise();
        }

        if (environment.mock !== true) {
            this.connectWebSocket();
        }

        const fullPersonName = XSCommonDomainUtils.getPersonFullName(appInitialData.authenticatedUser!.name!);
        const userEmail = appInitialData.authenticatedUser!.email;
        LOG().info('User [' + fullPersonName + '][' + userEmail + '] Successfully Authenticated.', appInitialData);
        LOG().debug('LCE Municipal BackOffice Successfully Initialized.');

        if (!XSUtils.isNull(navigateURL)) {
            this.router.navigate([navigateURL]).then();
        }
    }

    private disconnectWebSocket(): void {
        if (this.webSocketService.isConnected()) this.webSocketService.disconnect();
    }

    private connectWebSocket(): void {
        const token: string | undefined = this.localStorageService.getItem(XS_STORAGE_KEY_TOKEN);
        if (!XSUtils.isEmpty(token)) {
            this.webSocketService.connect(token!);
        }
    }
}
