import { Injectable } from '@angular/core';
import { LCE_CORE_ENDPOINTS } from '../../constants/lce-core-endpoint.constant';
import { LCECategoryService } from '../lce-category.service';
import { LCEHttpClientService } from '../lce-http-client.service';

@Injectable({ providedIn: 'root' })
export class LCENewsArticleCategoryService extends LCECategoryService {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.news.categories.index, 'newsCategory');
    }
}
