import {Component, Input, OnInit} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESuggestionPartial} from '@lce/core';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSTranslationService} from '@xs/common';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';

@Component({selector: 'lce-suggestion-partial', templateUrl: './lce-suggestion-partial.component.html', host: {class: 'xs-width-full'}})
export class LCESuggestionPartialComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCESuggestionPartial;

    @Input() showIcon?: boolean;

    constructor(private translationService: XSTranslationService) {
    }

    get dateFormat(): string {
        const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        return currentLanguage === XSLanguage.FRENCH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR : XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN;
    }

    get statusColorClass(): string {
        return LCESharedUtils.getSuggestionColorStatusStyleClass(this.data.status);
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
    }
}
