import { Injectable, NgZone } from '@angular/core';
import { LCEMBOErrorService } from '@lce-mbo/services/lce-mbo-error.service';
import { XSErrorHandler } from '@xs/core';

@Injectable({ providedIn: 'root' })
export class LCEMBOErrorHandler extends XSErrorHandler {
	constructor(protected ngZone: NgZone, protected errorService: LCEMBOErrorService) {
		super(ngZone, errorService);
	}
}
