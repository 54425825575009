import {Component, Input, OnInit} from '@angular/core';
import {LCEUserCustomer} from '@lce/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-user-customer-timeline', templateUrl: './lce-user-customer-timeline.component.html'})
export class LCEUserCustomerTimelineComponent implements OnInit {
	
	readonly ICON = LCE_SHARED_ICON;

	@Input() data: LCEUserCustomer;

	ngOnInit(): void {
		XSAssert.notEmpty(this.data, 'data');
	}
}
