import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEDistrictPartial, LCEDistrictService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';

@Component({
	selector: 'lce-input-field-district-autocomplete-multiple',
	template: `
        <xs-input-field-auto-complete styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-district xsTemplate="item">
                <lce-district-partial [data]="district"></lce-district-partial>
            </ng-template>
        </xs-input-field-auto-complete>
	`,
	host: {class: 'xs-width-full'}
})
export class LCEInputFieldDistrictAutocompleteMultipleComponent implements OnInit {

	@Input() styleClass?: string;

	@Input() options: XSInputFieldBaseOptions;

	@Input() completeOnFocus?: boolean;

	@Input() showDefaultBelowText?: boolean;

	@Input() placeholder?: string;

	@Input() appendTo?: any;

	@Output() selectEvent = new EventEmitter<LCEDistrictPartial>();

	autocompleteOptions: XSInputFieldAutoCompleteOptions;

	@ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

	constructor(private districtService: LCEDistrictService) {
	}

	ngOnInit(): void {
		if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.district.label.autocompletePlaceholder';
		if (XSUtils.isNull(this.completeOnFocus)) this.completeOnFocus = false;
		this.autocompleteOptions = {
			control: this.options.control,
			autoCompleteOptions: {
				labelField: 'fullName',
				multiple: true,
				forceSelection: true,
				completeOnFocus: this.completeOnFocus,
				search: (query: string) => {
					return this.districtService.autocomplete(query);
				}
			},
			placeholder: this.placeholder,
			belowText: this.showDefaultBelowText === true ? 'lce.core.label.districts' : undefined,
			belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
			selectEvent: (event: any) => this.handleSelectEvent(event),
			...this.options
		};
	}

	private handleSelectEvent(district: LCEDistrictPartial): void {
		this.selectEvent.emit(district);
	}
}
