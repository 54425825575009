<div *ngIf="nMunicipality > 1" class="xs-flex-column {{ styleClass }}">
    <span *ngIf="label | xsIsNotEmpty" class="xs-data-field-label xs-mb-6 {{ labelStyleClass }}">{{ label! | translate }}</span>
    <xs-button
            (clickEvent)="municipalitiesOverlay.toggle($event)"
            [label]="TR_BASE_LABEL + 'municipalitiesConcerned' | translate : { nMunicipality: nMunicipality }"
            styleClass="xs-mt-25"
            type="text">
    </xs-button>

    <p-overlayPanel #municipalitiesOverlay [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding xs-border-radius-7-imp">
        <div class="xs-overflow-y-auto xs-max-height-200 xs-min-width-200 xs-max-width-300 xs-pt-15 xs-plr-15 xs-mr-3">
            <lce-municipality-partial *ngFor="let municipalityPartial of data" [data]="municipalityPartial" styleClass="xs-mb-20"></lce-municipality-partial>
        </div>
    </p-overlayPanel>
</div>

<lce-municipality-partial
        *ngIf="nMunicipality === 1"
        [data]="data[0]"
        [labelStyleClass]="labelStyleClass"
        [label]="label"
        [showIcon]="singleDataShowIcon"
        [styleClass]="styleClass">
</lce-municipality-partial>
