import {Component, OnInit} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCEServicePointDialogService, LCEServicePointsFeatureService} from '@lce/shared';
import {LCEServicePointCanOptions} from '@lce/core';

@Component({
    selector: 'lce-mbo-service-points',
    templateUrl: './lce-mbo-service-points.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEServicePointDialogService]
})
export class LCEMBOServicePointsComponent implements OnInit {

    readonly ICON_PLUS: string = LCE_MBO_ICON.plus;
    readonly ICON_REFRESH: string = LCE_MBO_ICON.refresh;

    readonly TR_BASE: string = 'lce.shared.servicePoint.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    can: LCEServicePointCanOptions;

    constructor(private featureService: LCEServicePointsFeatureService, private servicePointDialogService: LCEServicePointDialogService) {
    }

    ngOnInit(): void {
        this.can = {
            create: false
        };
    }

    public onCreate(): void {
        if (!this.can.create) return;
        this.servicePointDialogService.openCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
