import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCE_TR_BASE_REQUESTOR_TYPE} from '@lce/core';
import {XS_STR_SPACE, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR, XSTranslationService} from '@xs/common';
import {XSDeviceDetectorService, XSDeviceInformations} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEAuthenticationLoginAttemptPartial} from '../../../api/domain/lce-authentication-login-attempt';

@Component({selector: 'lce-authentication-login-attempt-row', templateUrl: 'lce-authentication-login-attempt-row.component.html'})
export class LCEAuthenticationLoginAttemptRowComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.authentication.';

    readonly TR_BASE_REQUESTOR_TYPE = LCE_TR_BASE_REQUESTOR_TYPE;

    readonly DATE_FORMAT_EN = XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN;
    readonly DATE_FORMAT_FR = XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR;

    @Input() styleClass?: string;

    @Input() data: LCEAuthenticationLoginAttemptPartial;

    @Input() showAuthenticationStateColor?: boolean;

    deviceInfo: XSDeviceInformations;
    deviceStr: string;

    private subscription: Subscription = new Subscription();
    private trViaPINCode: string;

    constructor(private translationService: XSTranslationService, private deviceDetectorService: XSDeviceDetectorService) {
    }

    get stateIconClass(): string {
        return this.data.success ? this.ICON.thumbsUp : this.ICON.thumbsDown;
    }

    ngOnInit(): void {
        this.trUpdate();
        if (XSUtils.isNull(this.showAuthenticationStateColor)) this.showAuthenticationStateColor = false;

        if (!XSUtils.isEmpty(this.data.userAgent)) {
            this.deviceInfo = this.deviceDetectorService.parseUserAgent(this.data.userAgent!);
            this.deviceStr = this.deviceInfo.os + ', ' + this.deviceInfo.browser + XS_STR_SPACE + this.deviceInfo.browserVersion;
        }

        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.trUpdate()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasUserAgent(): boolean {
        return !XSUtils.isEmpty(this.data.userAgent);
    }

    public isUsernamePhoneNumber(): boolean {
        const username = this.getUsername();
        return XSUtils.isEmpty(username) ? false : username.startsWith('+');
    }

    public getUsername(): string {
        return this.data.username.trim() === '*' ? this.trViaPINCode : this.data.username;
    }

    private trUpdate(): void {
        this.trViaPINCode = this.translationService.translateKey(this.TR_BASE + 'viaPINCode');
    }
}
