import {Injectable} from '@angular/core';
import {LCEFacilityPartial, LCETerminal} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {LCETerminalRecordComponent} from './record/lce-terminal-record.component';
import {LCETerminalCreateUpdateComponent} from './create-update/lce-terminal-create-update.component';

export interface LCETerminalDialogCreateUpdateArg {
    terminalID?: string;
    terminal?: LCETerminal;
    facility?: LCEFacilityPartial;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (terminal?: LCETerminal) => void;
    onSave?: (terminal?: LCETerminal) => void;
}

export interface LCETerminalDialogRecordArg {
    terminalID?: string;
    terminal?: LCETerminal;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (terminal?: LCETerminal) => void;
    onEdit?: (terminal?: LCETerminal) => void;
}

@Injectable()
export class LCETerminalDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCETerminalDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.terminalID) && XSUtils.isEmpty(arg.terminal)) {
            throw new Error('arg.terminalID and arg.terminal cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.terminalID) && !XSUtils.isEmpty(arg.terminal)) {
            throw new Error('arg.terminalID and arg.terminal cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                terminalID: arg.terminalID,
                terminal: arg.terminal,
                showEditButton: arg.showEditButton,
                dataManager: arg.dataManager,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-00'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-900'
        };

        const recordComponent: LCETerminalRecordComponent = this.dialogService.openComponentDialog(LCETerminalRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedTerminal: LCETerminal) => {
            if (arg?.onClose) arg!.onClose!(savedTerminal);
        });
        recordComponent.editEvent.subscribe((terminal: LCETerminal) => {
            if (arg?.onEdit) arg.onEdit(terminal);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    terminal: terminal,
                    onClose: (terminal?: LCETerminal) => this.openRecordDialog({terminal: terminal, showEditButton: true}),
                    onSave: (terminal?: LCETerminal) => this.openRecordDialog({terminal: terminal, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCETerminalDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.terminal) && !XSUtils.isEmpty(arg?.terminalID)) {
            throw new Error('arg.terminalID and arg.terminal cannot both be set at the same time.');
        }
        console.log(arg?.terminal);
        const dConfig: XSDialogConfig = {
            data: {
                terminalID: arg?.terminalID,
                terminal: arg?.terminal,
                facility: arg?.facility,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-900'
        };

        const createUpdateComponent: LCETerminalCreateUpdateComponent = this.dialogService.openComponentDialog(LCETerminalCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((terminal: LCETerminal) => {
            if (arg?.onClose) arg!.onClose!(terminal);
        });
        createUpdateComponent.saveEvent.subscribe((terminal: LCETerminal) => {
            if (!terminal) {
                createUpdateComponent.closeDialog();
                return;
            }
            if (arg?.onSave) arg.onSave(terminal);
            createUpdateComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({terminal: terminal});
        });
    }
}
