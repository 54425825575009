import {HttpParams} from '@angular/common/http';
import {LCEArticleReviewer, LCEArticleReviewStatus} from '../../domain/article/lce-article-reviewer';
import {LCECoreContextService} from '../lce-core-context.service';
import {LCEHttpClientService} from '../lce-http-client.service';
import {XSAssert, XSBaseService, XSUtils} from '@xs/base';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';

export abstract class LCEArticleReviewerService extends XSBaseService {

    protected constructor(
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCECoreContextService,
        endpointIndex: string) {
        super(httpClientService, endpointIndex);
    }

    public requestChangesReview(articleID: string): Observable<LCEArticleReviewer> {
        return this.updateReviewStatus(articleID, LCEArticleReviewStatus.REQUEST_CHANGES);
    }

    public approveReview(articleID: string): Observable<LCEArticleReviewer> {
        return this.updateReviewStatus(articleID, LCEArticleReviewStatus.APPROVED);
    }

    public updateReviewStatus(articleID: string, reviewStatus: LCEArticleReviewStatus): Observable<LCEArticleReviewer> {
        XSAssert.notEmpty(articleID, 'articleID');
        XSAssert.notEmpty(reviewStatus, 'reviewStatus');
        let params = new HttpParams().set('reviewStatus', reviewStatus);
        const reviewerID = this.contextService.getUser().id;
        const endpoint = LCE_CORE_ENDPOINTS.articleReviewers.review
            .replace('{articleID}', articleID)
            .replace('{reviewerID}', reviewerID);
        return this.httpClientService.patch<LCEArticleReviewer>(this.buildEndpoint(endpoint), params);
    }

    public deleteReviewer(articleID: string, reviewerID: string): Observable<LCEArticleReviewer> {
        return this.deleteReviewers(articleID, [reviewerID]).pipe(map((reviewers: LCEArticleReviewer[]) => reviewers[0]));
    }

    public deleteReviewers(articleID: string, reviewerIDs: string[]): Observable<LCEArticleReviewer[]> {
        XSAssert.notEmpty(articleID, 'articleID');
        XSAssert.notEmpty(reviewerIDs, 'reviewerIDs');
        let params = new HttpParams().set('reviewerIDs', reviewerIDs.join(','));
        const endpoint = LCE_CORE_ENDPOINTS.articleReviewers.reviewers.replace('{articleID}', articleID);
        return this.httpClientService.delete<LCEArticleReviewer[]>(this.buildEndpoint(endpoint), params);
    }

    public retrieveReviewers(articleID: string): Observable<LCEArticleReviewer[]> {
        XSAssert.notEmpty(articleID, 'articleID');
        const endpoint = LCE_CORE_ENDPOINTS.articleReviewers.reviewers.replace('{articleID}', articleID);
        return this.httpClientService.get<LCEArticleReviewer[]>(this.buildEndpoint(endpoint));
    }

    public addReviewer(articleID: string, reviewerID: string): Observable<LCEArticleReviewer> {
        return this.addReviewers(articleID, [reviewerID]).pipe(map((reviewers: LCEArticleReviewer[]) => reviewers[0]));
    }

    public addReviewers(articleID: string, reviewerIDs: string[]): Observable<LCEArticleReviewer[]> {
        XSAssert.notEmpty(articleID, 'articleID');
        XSAssert.notEmpty(reviewerIDs, 'reviewerIDs');
        const endpoint = LCE_CORE_ENDPOINTS.articleReviewers.reviewers.replace('{articleID}', articleID);
        return this.httpClientService.post<LCEArticleReviewer[]>(this.buildEndpoint(endpoint), reviewerIDs);
    }

    public retrieveDefaultReviewers(categoryCodes?: string[]): Observable<LCEArticleReviewer[]> {
        let params = new HttpParams();
        if (!XSUtils.isEmpty(categoryCodes)) {
            params = params.set('categoryCodes', categoryCodes!.join(','));
        }
        return this.httpClientService.get<LCEArticleReviewer[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.articleReviewers.defaults), params);
    }
}
