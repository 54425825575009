<div class="xs-flex-column xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div *ngIf="canDisplayData()" class="xs-flex-column-center">
        <lce-user-detail-head [data]="customer"></lce-user-detail-head>
    </div>

    <div class="xs-position-relative xs-flex-column xs-width-full xs-min-height-400 xs-height-full xs-mt-50">
        <div class="xs-absolute-center-vh">
            <xs-loader #recordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>
        <div *ngIf="!recordLoader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showBorder]="false"
                    [showErrorDetailButton]="true"
                    message="{{ TR_BASE }}errorRetrieveCertificateOrder"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>
        <div *ngIf="canDisplayData()" class="xs-flex-column xs-height-full xs-width-full">

            <p-tabView #pTabView [scrollable]="navScrollable" class="xs-tabview" styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                <p-tabPanel header="{{ TR_BASE + 'general' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-customer-general [data]="customer"></lce-user-customer-general>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="{{ TR_BASE + 'orders' | translate }}">
                    <div class="xs-flex-column xs-min-height-400">
                        <lce-user-customer-orders [customerID]="customerID"></lce-user-customer-orders>
                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="true" header="{{ TR_BASE + 'usage' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-customer-usage [data]="customer"></lce-user-customer-usage>
                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="true" header="{{ TR_BASE + 'timeline' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-customer-timeline [data]="customer"></lce-user-customer-timeline>
                    </div>
                </p-tabPanel>
            </p-tabView>

        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.customer"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
