import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSLanguage} from '@xs/base';
import {LCENewsArticleComment} from '@lce/core';
import {XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR, XSTranslationService} from '@xs/common';

@Component({selector: 'lce-news-article-user-comment', templateUrl: './lce-news-article-user-comment.component.html'})
export class LCENewsArticleUserCommentComponent implements OnInit {
    @Input() styleClass?: string;

    @Input() comment: LCENewsArticleComment;

    constructor(private translationService: XSTranslationService) {
    }

    get createdOnDateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN : XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.comment, 'comment');
    }
}
