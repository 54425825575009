<div
        class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !loader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe
                    subtitle="{{ isLoaderRunning() ? '...' : facilityTownHallStampLoad!.facility.name }}"
                    title="{{isLoaderRunning() ? '...' : 'lce.shared.facility.townHallStampLoad.main.title' | translate }}">
            </xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Facility town hall Load  === -->
                <!-- -----------------------------------------------------------------------------------------------    ------- -->
                <div class="col-12 md:col-6">
                    <xs-data-field-text [upperCase]="true" [value]="facilityTownHallStampLoad!.facility.code" label="xs.core.label.code"
                                        valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [value]=" facilityTownHallStampLoad!.numberOfStamps | xsToString"
                            label="lce.shared.facility.townHallStampToken.label.numberOfStamps"
                    >
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [capitalize]="true"
                            [value]="facilityTownHallStampLoad!.previousTotalNumberOfStamps | xsToString"
                            label="lce.shared.facility.townHallStampToken.label.previousTotalNumberOfStamps">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label label="lce.shared.facility.townHallStampLoad.label.unload"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <span>{{ 'xs.core.label.' + facilityTownHallStampLoad!.unload | translate }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-textarea
                            [required]="false"
                            [value]="facilityTownHallStampLoad!.note"
                            label="xs.common.label.note"
                            valueStyleClass="xs-font-size-intermediate">
                    </xs-data-field-textarea>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="facilityTownHallStampLoad!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-header-stripe
            subtitle="{{ isLoaderRunning() ? '...' : facilityTownHallStampLoad!.facility.name }}"
            title="{{isLoaderRunning() ? '...' : 'lce.shared.facility.townHallStampLoad.main.title' | translate }}">
    </xs-header-stripe>
</ng-template>
