import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEEvent, LCEEventPartial} from '@lce/core';
import {XSPagination, XSSort} from '@xs/base';
import {XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {XSClipboardService, XSMenuItem, XSTableColumn, XSTableOptions} from '@xs/core';
import {LCEEventDialogService} from '../lce-event-dialog.service';
import {LCEEventsFeatureService} from '../lce-events-feature.service';

@Component({
    selector: 'lce-events-table',
    templateUrl: './lce-events-table.component.html',
    providers: [LCEEventsFeatureService, LCEEventDialogService]
})
export class LCEEventsTableComponent implements OnInit {
    readonly TR_BASE = 'lce.shared.event.';

    @Input() data: LCEEventPartial[] = [];
    @Input() nResults: number = 0;
    @Input() caption: XSText;
    @Input() subCaption?: XSText;
    @Input() loadingState: XSLoaderState;
    @Input() readonly?: boolean;

    @Output() resetPreferencesEvent = new EventEmitter();
    @Output() resetSortEvent = new EventEmitter();
    @Output() paginationEvent = new EventEmitter<XSPagination>();
    @Output() sortEvent = new EventEmitter<XSSort>();

    options: XSTableOptions;
    columns: XSTableColumn[];
    emptyMessage: XSText = {value: this.TR_BASE + 'table.noResultFound'};

    private readonly ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];

    constructor(
        private clipboardService: XSClipboardService,
        private translationService: XSTranslationService,
        private eventDialogService: LCEEventDialogService,
        private featureService: LCEEventsFeatureService) {
    }

    ngOnInit(): void {
        this.buildTableOptions();
        this.buildTableColumns();
    }

    public onViewRecord(selection: LCEEvent): void {
        this.eventDialogService.openRecordDialog({eventID: selection.id, eventName: selection.eventName, resourceType: selection.resourceType, eventType: selection.type});
    }

    private buildTableColumns(): void {
        this.columns = [
            {
                field: 'eventName',
                header: this.TR_BASE + 'label.eventName',
                visible: true,
                displayable: true
            },
            {
                field: 'resource',
                header: this.TR_BASE + 'label.resource',
                visible: true,
                displayable: true
            },
            {
                field: 'data',
                header: this.TR_BASE + 'label.data',
                visible: true,
                displayable: true
            }
        ];
    }

    private buildTableOptions(): void {
        this.options = {
            id: 'eventTable',
            localStorageKey: 'event.table',
            dataUniqueField: 'id',
            lazy: true,
            rowHover: true,
            selectionMode: 'single',
            contextMenu: true,
            sortMode: 'multiple',
            paginator: true,
            simplePaginator: true,
            paginatorRows: this.featureService.pagination.size,
            paginatorRowsPerPageOptions: this.ROWS_PER_PAGE_OPTIONS,
            resizableColumns: true,
            reorderableColumns: true,
            dynamicColumns: true,
            toolbar: {
                showExport: true,
                showPreferences: true,
                showResetSort: true,
                showResetColumnWidths: true
            },
            preferences: {
                showResetColumnWidths: true,
                showResetSort: true,
                showResetDefaultPreferences: true
            },
            exportOptions: {
                fileNameWithoutExtension: 'eventsResults',
                pdfOptions: {
                    pageWidthInPx: 1000,
                    firstPageMarginTop: 70,
                    title: 'Event From 2023-11-13 To 2023-11-13',
                    textTopLeft: 'LCE (La Commune Électronique)',
                    textTopRight: 'Events [{{nResults}}]',
                    textBottomRight: 'Generated By Ryane Alla',
                    cellAlignCenter: false,
                    autoComputePageWidth: false
                },
                xlsxOptions: {
                    worksheetName: 'Events',
                    title: 'This is a test Title',
                    subject: 'Event',
                    author: 'LCE (La Commune Électronique)',
                    company: 'Iro-XS Inc.',
                    keywords: ['charge', 'charge'],
                    comments: 'Generated By Ryane Alla'
                }
            },
            buildContextMenuItems: (selection: LCEEvent) => this.buildContextMenuItems(selection),
            loaderSize: 30
        };
    }

    private buildContextMenuItems(selection: LCEEvent): XSMenuItem[] {
        return [
            {label: this.translationService.translateKey('lce.shared.event.table.copyID'), command: () => this.copyEventID(selection)},
            {label: this.translationService.translateKey('xs.core.label.showDetail'), command: () => this.onViewRecord(selection)}
        ];
    }

    private copyEventID(selection: LCEEvent): void {
        this.clipboardService.copy(selection.id);
    }
}
