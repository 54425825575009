import {XSAuthenticationRoute} from '@xs/core';

export const LCE_MBO_USER_SYSTEM_ID: string = 'system';

export const LCE_MBO_BASE_LOGO_IMAGE: string = 'assets/images/app-logo-';

export const LCE_MBO_BASE_APP_NAME_IMAGE: string = 'assets/images/app-name-';

export const LCE_MBO_MAIN_LOGO: string = 'assets/images/lce-logo-header.png';

export const LCE_MBO_SECONDARY_LOGO_LIGHT: string = 'assets/images/app-logo-secondary-light.png';
export const LCE_MBO_SECONDARY_LOGO_DARK: string = 'assets/images/app-logo-secondary-dark.png';

// ------------------------------------------------------------------------------------------------------------
// === Authentication ===
// ------------------------------------------------------------------------------------------------------------

export const LCE_MBO_AUTHENTICATION_ROUTE: XSAuthenticationRoute = {
    dashboard: 'dashboard',
    base: 'authentication',
    login: 'authentication/login',
    forgotPassword: 'authentication/forgot-password',
    updatePasswordRequired: 'authentication/update-password-required',
    help: 'authentication/help'
};
