import {Component, OnInit} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCEDistrictDialogService, LCEDistrictsFeatureService} from '@lce/shared';
import {LCEDistrictCanOptions} from '@lce/core';

@Component({
    selector: 'lce-mbo-districts',
    templateUrl: './lce-mbo-districts.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEDistrictDialogService]
})
export class LCEMBODistrictsComponent implements OnInit {

    readonly ICON_PLUS: string = LCE_MBO_ICON.plus;
    readonly ICON_REFRESH: string = LCE_MBO_ICON.refresh;

    readonly TR_BASE: string = 'lce.shared.district.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    can: LCEDistrictCanOptions;

    constructor(private featureService: LCEDistrictsFeatureService, private districtDialogService: LCEDistrictDialogService) {
    }

    ngOnInit(): void {
        this.can = {
            create: false
        };
    }

    public onCreate(): void {
        if (!this.can.create) return;
        this.districtDialogService.openDistrictCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
