import {Injectable} from '@angular/core';
import {XSLocalStorageService} from '@xs/core';
import {Subject} from 'rxjs';
import {LCEFacilityTownHallStampTokenCreateSettings} from './lce-facility-town-hall-stamp-token';

@Injectable()
export class LCEFacilityStampTokenManagerService {

    private createSettingsChangeSubject = new Subject<LCEFacilityTownHallStampTokenCreateSettings>();
    onCreateSettingsChange = this.createSettingsChangeSubject.asObservable();

    private readonly STORAGE_KEY = 'stampToken';
    private readonly STORAGE_KEY_USE_MOBILE_PHONE_NUMBER = 'useMobilePhoneNumber';
    private readonly STORAGE_KEY_CREATE_ON_ENTER = 'createOnEnter';

    constructor(private localStorageService: XSLocalStorageService) {
    }

    public saveCreateOnEnter(state: boolean) {
        this.localStorageService.setObjectProperty(this.STORAGE_KEY, this.STORAGE_KEY_CREATE_ON_ENTER, state);
        this.emitEvent();
    }

    public saveUseMobilePhoneNumber(state: boolean): void {
        this.localStorageService.setObjectProperty(this.STORAGE_KEY, this.STORAGE_KEY_USE_MOBILE_PHONE_NUMBER, state);
        this.emitEvent();
    }

    public getUseMobilePhoneNumber(): boolean | undefined {
        return this.localStorageService.getObjectProperty(this.STORAGE_KEY, this.STORAGE_KEY_USE_MOBILE_PHONE_NUMBER);
    }

    public getCreateOnEnter(): boolean {
        return this.localStorageService.getObjectProperty(this.STORAGE_KEY, this.STORAGE_KEY_CREATE_ON_ENTER);
    }

    public getCreateSettings(): LCEFacilityTownHallStampTokenCreateSettings {
        return {
            useMobilePhoneNumber: this.getUseMobilePhoneNumber(),
            createOnEnter: this.getCreateOnEnter()
        };
    }

    private emitEvent(): void {
        this.createSettingsChangeSubject.next(this.getCreateSettings());
    }
}
