import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEMBODistrictsComponent} from './lce-mbo-districts.component';

const routes: Routes = [{path: 'districts', component: LCEMBODistrictsComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCEMBODistrictsRoutingModule {
}
