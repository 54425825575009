<div
        [ngClass]="{'xs-disabled':createUpdateLoading || loader.isRunning()}"
        class="xs-flex-column  xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !loader.isRunning()" class="xs-absolute-center-vh">
        <xs-error [actionButton]="retrieveErrorRetryButton" [error]="retrieveError" [showActionButton]="true"
                  [showErrorDetailButton]="true" message="{{ TR_BASE_LABEL }}errorMessageRetrieve" mode="block"
                  styleClass="xs-max-width-500" subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div *ngIf="!hasRetrieveError() && !loader.isRunning()" class="{{contentStyleClass}}">
        <div class="grid xs-fields xs-fields-spacer-15">
            <!-- ------------------------------------------------------------------------------------------------------ -->
            <!-- === Generals Informations  === -->
            <!-- ------------------------------------------------------------------------------------------------------ -->

            <div class="col-12 md:col-6">
                <lce-input-field-facility-autocomplete *ngIf="facility | xsIsEmpty" [options]="facilityField" [showIcon]="true">
                </lce-input-field-facility-autocomplete>

                <div *ngIf="facility | xsIsNotEmpty" class="xs-data-field-value-container xs-pl-30">
                    <lce-facility-partial
                            [data]="facility!"
                            [showCode]="true"
                            [showIcon]="true"
                            [showOverlay]="true"
                            label="Facility"
                            labelStyleClass="xs-font-weight-normal-imp">
                    </lce-facility-partial>
                </div>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-text [options]="nameField"></xs-input-field-text>
            </div>

            <div class="col-12">
                <xs-input-field-device [options]="deviceField"></xs-input-field-device>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-textarea [options]="descriptionField"></xs-input-field-textarea>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-textarea [options]="noteField"></xs-input-field-textarea>
            </div>
            <div class="col-12">
                <div class="xs-flex-row xs-align-items-center xs-width-full">
                    <xs-icon-box
                            [value]="INFO_ICON"
                            size="small"
                            styleClass="xs-mr-15">
                    </xs-icon-box>
                    <div class="xs-flex-column xs-position-relative xs-line-height-1-40 xs-text-align-justify xs-color-secondary">
                <span [innerHTML]="TR_BASE + 'info.line1' | translate" class="xs-display-block xs-font-size-intermediate">
                </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-mtb-15">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.terminal"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>

<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }"
         class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-error
                *ngIf="hasCreateUpdateError()"
                [ellipsis]="true"
                [error]="createUpdateError"
                [showErrorDetailButton]="true"
                errorDetailButtonClass="xs-mt-2-imp"
                message="{{ TR_BASE_LABEL }}errorMessageCreateUpdate"
                mode="inline">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button
                    (clickEvent)="fillForm()"
                    *ngIf="isCreateMode()"
                    class="xs-sm-display-none xs-mr-40"
                    label="Fill Form"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="reset()"
                    *ngIf="isCreateMode()"
                    [confirmation]="resetConfirmation"
                    [disabled]="createUpdateLoading"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="createUpdateLoading || loader.isRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.close"
                    severity="secondary"
                    styleClass="xs-mlr-15-imp xs-width-150-imp"
                    type="button">
            </xs-button>
            <xs-button
                    (clickEvent)="createUpdate()"
                    [disabled]="loader.isRunning()"
                    [loading]="createUpdateLoading"
                    label="xs.core.label.save"
                    styleClass="xs-width-175-imp"
                    type="button">
            </xs-button>
        </div>
    </div>
</ng-template>