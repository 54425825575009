<div class="lce-birth-declarations-main {{ styleClass }}">
    <div class="lce-birth-declarations-main-container">
        <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750">
            <ng-template xsTemplate="sort">
                <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
            </ng-template>

            <!-- === Row Result Template ===-->
            <ng-template let-oneResult xsTemplate="row">
                <lce-birth-declaration-partial
                        [data]="oneResult"
                        [showIcon]="true"
                        birthNumberStyleClass="xs-color-secondary-imp xs-font-weight-500">
                </lce-birth-declaration-partial>
            </ng-template>

            <!-- === Record Toolbar Template === -->
            <ng-template xsTemplate="recordToolbar">
                <div class="xs-flex-row xs-width-full xs-align-items-center xs-justify-content-end">
                    <xs-error
                            *ngIf="resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <div class="xs-flex-row">
                        <xs-button
                                (clickEvent)="onEdit()"
                                *ngIf="canEdit()"
                                [disabled]="!resourceAuditFullMenuAction.canDoAction()"
                                [icon]="ICON.edit"
                                class="xs-mr-40"
                                label="xs.core.label.edit"
                                size="intermediate"
                                type="text">
                        </xs-button>
                        <xs-pk-resource-audit-full-menu-action
                            #resourceAuditFullMenuAction
                            [dataManager]="dataManager"
                            [resourceService]="birthDeclarationService">
                        </xs-pk-resource-audit-full-menu-action>
                    </div>
                </div>
            </ng-template>
            <!-- === Record Template ===-->
            <ng-template let-recordData xsTemplate="record">
                <lce-birth-declaration-record
                        [birthDeclaration]="recordData"
                        loadingStyleClass="xs-min-height-500 xs-min-width-600"
                        styleClass="xs-min-width-750 xs-pt-25 xs-plr-15 xs-pb-0">
                </lce-birth-declaration-record>
            </ng-template>
        </xs-data-manager>
    </div>
</div>


