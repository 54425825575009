<div
        [ngClass]="{ 'xs-disabled': disabled === true, 'xs-border-discrete xs-p-15': showBorder }"
        class="xs-flex-row xs-width-full xs-align-items-center xs-border-radius-default xs-height-75 {{ styleClass }}">

    <div class="xs-flex-row xs-min-width-200 xs-mr-25 xs-border-right-discrete xs-min-width-150">

        <xs-ivar
                (avatarClick)="onAvatarClick()"
                *ngIf="showIcon"
                [avatarRounded]="false"
                [avatar]="ICON.suggestion"
                [showLines]="false"
                avatarSize="medium"
                avatarStyleClass="{{ statusColorStyleClass }}"
                avatarType="icon"
                class="xs-width-fit-content-imp xs-mr-15">
        </xs-ivar>
        <div class="xs-flex-column xs-position-relative">
            <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="LOADER_SIZE" [loaderType]="LOADER_TYPE" class="xs-absolute-center-vh xs-ml-10"></xs-loader>
        </div>
        <div *ngIf="!loader.isRunning() && (data | xsIsNotEmpty)" class="xs-flex-column">
            <span *ngIf="showLabel" class="xs-display-block xs-color-secondary xs-font-size-small xs-mb-5">{{ TR_BASE + 'lastSuggestion' | translate }}</span>
            <span
                    (click)="codeClickEvent.emit({id: data!.id, code: data!.code})"
                    class="xs-display-block xs-color-secondary xs-font-size-small xs-text-transform-uppercase xs-clickable">
                {{ data!.code }}
            </span>
            <xs-time-ago [givenDate]="data!.createdOn" [live]="true" styleClass="xs-display-block xs-font-size-small xs-color-secondary xs-mt-3"></xs-time-ago>
        </div>
    </div>
    <div class="xs-flex-row xs-align-items-center xs-max-height-40">
        <span *ngIf="loader.isRunning()" class="xs-display-block xs-color-light xs-font-weight-600 xs-font-size-extra-large">...</span>
        <p *ngIf="!loader.isRunning() && (data | xsIsNotEmpty)" class="xs-font-size-small xs-ellipsis-vertical-2-lines">{{ data!.message }}</p>
    </div>
</div>