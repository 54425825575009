import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCEFacilityPartial, LCEFacilityService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCEFacilityDialogService} from '../../../facility/lce-facility-dialog.service';

@Component({
    selector: 'lce-global-search-facilities-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-facility-partial [data]="oneResult" [showIcon]="true"></lce-facility-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCEFacilityDialogService]
})
export class LCEGlobalSearchFacilitiesTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.FACILITY;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCEFacilityPartial> = {
        search: searchFilter => this.facilityService.search(searchFilter),
        openRecord: facilityPartial => this.facilityDialogService.openRecordDialog({facilityID: facilityPartial.id}),
        captionNResultFound: 'lce.shared.facility.main.resultFound',
        biColumnResults: true,
        listingStyleClass: 'xs-max-width-500-imp',
        contentStyleClass: 'xs-max-width-1000-imp'
    }

    constructor(private facilityService: LCEFacilityService, private facilityDialogService: LCEFacilityDialogService) {
    }
}
