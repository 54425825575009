import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCEMunicipalityPartial, LCEMunicipalityService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCEMunicipalityDialogService} from '../../../municipality/lce-municipality-dialog.service';

@Component({
    selector: 'lce-global-search-municipalities-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-municipality-partial [data]="oneResult" [showIcon]="true"></lce-municipality-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCEMunicipalityDialogService]
})
export class LCEGlobalSearchMunicipalitiesTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.MUNICIPALITY;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCEMunicipalityPartial> = {
        search: searchFilter => this.municipalityService.search(searchFilter),
        openRecord: municipalityPartial => this.municipalityDialogService.openRecordDialog({municipalityID: municipalityPartial.id}),
        captionNResultFound: 'lce.shared.municipality.main.resultFound',
        biColumnResults: true,
        listingStyleClass: 'xs-max-width-500-imp',
        contentStyleClass: 'xs-max-width-1000-imp'
    }

    constructor(private municipalityService: LCEMunicipalityService, private municipalityDialogService: LCEMunicipalityDialogService) {
    }
}
