import {LCEMBOEnvironment} from '@lce-mbo/domain/lce-mbo-environment';
import {XSBuiltEnvironmentName} from '@xs/base';
import {commonEnvironment} from './environment-common';

export const environment: LCEMBOEnvironment = {
    production: true,
    name: XSBuiltEnvironmentName.WEB,
    common: commonEnvironment,
    featureSwitch: {
        enableBirthDeclaration: false
    }
};
