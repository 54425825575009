<div class="xs-card xs-width-full xs-max-width-450 {{ styleClass }}">
    <div class="xs-card-content">
        <div class="xs-flex-row xs-plr-15 xs-mtb-25">
            <xs-icon [value]="ICON_STAMP" color="light" size="50px" styleClass="xs-display-block xs-mr-20"></xs-icon>
            <div class="xs-flex-column">
                <span [innerHTML]="(TR_BASE + 'deleteLastMessageLine1') | translate" class="xs-text-block-intermediate-secondary-500"></span>
                <span [innerHTML]="(TR_BASE + 'deleteLastMessageLine2') | translate" class="xs-text-block-small-secondary xs-mt-2"></span>
            </div>
        </div>
    </div>
    <div class="xs-card-footer">
        <div class="xs-flex-row xs-justify-content-space-between xs-height-30">
            <div class="xs-display-block">
                <xs-error
                        *ngIf="hasError() && !loading"
                        [error]="error"
                        [showErrorDetailButton]="true"
                        message="xs.core.error.inlineDefault"
                        mode="inline">
                </xs-error>
            </div>
            <div class="xs-flex-row xs-align-items-center">
                <xs-button
                        (clickEvent)="cancelEvent.emit()"
                        [transform]="false"
                        class="xs-mr-20"
                        label="xs.core.label.cancel"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="delete()"
                        [icon]="ICON_DELETE"
                        [loading]="loading"
                        [transform]="false"
                        label="xs.core.label.delete"
                        loaderPosition="top"
                        size="intermediate"
                        type="button">
                </xs-button>
            </div>
        </div>
    </div>
</div>
