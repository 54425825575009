import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCECertificateOrderPartial, LCECertificateOrderService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCECertificateOrderDialogService} from '../../../certificate-order/lce-certificate-order-dialog.service';

@Component({
    selector: 'lce-global-search-certificate-orders-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-certificate-order-partial [data]="oneResult" [detailed]="true"></lce-certificate-order-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `
})
export class LCEGlobalSearchCertificateOrdersTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.CERTIFICATE_ORDER;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCECertificateOrderPartial> = {
        search: searchFilter => this.certificateOrderService.search(searchFilter),
        openRecord: certificateOrderPartial => this.certificateOrderDialogService.openRecordDialog({
            orderID: certificateOrderPartial.id,
            type: certificateOrderPartial.certificate.type,
            orderNumber: certificateOrderPartial.orderNumber
        }),
        captionNResultFound: 'lce.shared.certificateOrders.table.caption',
        biColumnResults: true
    };

    constructor(private certificateOrderService: LCECertificateOrderService, private certificateOrderDialogService: LCECertificateOrderDialogService) {
    }
}
