import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEMBOAuthenticationComponent} from '@lce-mbo/app/authentication/lce-mbo-authentication.component';
import {LCETemplateDefaultExceptionComponent} from '@lce-mbo/app/templates/default/exception/lce-template-default-exception.component';
import {LCETemplateExceptionType} from '@lce-mbo/app/templates/default/lce-template-default';
import {LCETemplateDefaultComponent} from '@lce-mbo/app/templates/default/lce-template-default.component';
import {LCETemplateModule} from '@lce-mbo/app/templates/lce-template.module';
import {LCEMBOAuthenticationGuard} from '@lce-mbo/core/guards/lce-mbo-authentication.guard';
import {LCEMBODashboardComponent} from '@lce-mbo/features/dashboard/lce-mbo-dashboard.component';
import {LCEMBODashboardModule} from '@lce-mbo/features/dashboard/lce-mbo-dashboard.module';
import {LCEMBOResourcesComponent} from '@lce-mbo/features/resources/lce-mbo-resources.component';
import {LCEMBOResourcesModule} from '@lce-mbo/features/resources/lce-mbo-resources.module';
import {LCETemplateSheldonComponent} from '@lce-mbo/templates/sheldon/lce-template-sheldon.component';
import {XSAuthenticationForgotPasswordComponent, XSAuthenticationHelpComponent, XSAuthenticationLoginComponent, XSAuthenticationUpdatePasswordRequiredComponent} from '@xs/core';
import {LCEShowcaseCertificateOrderProcessPageComponent} from '@lce-mbo/features/showcase/certificate-order-process/lce-showcase-certificate-order-process-page.component';

const routes: Routes = [
    {path: 'showcase/certificate-order/process/page', component: LCEShowcaseCertificateOrderProcessPageComponent},

    {
        path: 'stn',
        loadChildren: () => import(/* webpackChunkName: "lce-mbo-stn.module" */ '@lce-mbo/features/stn/lce-mbo-stn.module').then((m) => m.LCEMBOSTNModule)
    },
    {
        path: '',
        component: LCETemplateDefaultComponent,
        children: [
            {
                path: 'authentication',
                component: LCEMBOAuthenticationComponent,
                canActivate: [LCEMBOAuthenticationGuard],
                children: [
                    {path: '', redirectTo: 'login'},
                    {path: 'login', component: XSAuthenticationLoginComponent},
                    {path: 'forgot-password', component: XSAuthenticationForgotPasswordComponent},
                    {path: 'update-password-required', component: XSAuthenticationUpdatePasswordRequiredComponent},
                    {path: 'help', component: XSAuthenticationHelpComponent}
                ]
            },
            {path: 'error', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.ERROR}},
            {path: 'not-found', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.NOT_FOUND}},
            {path: 'access-denied', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.ACCESS_DENIED}},
            {path: 'login', redirectTo: 'authentication/login'},
            {path: '', redirectTo: 'authentication/login'}
        ]
    },
    {
        path: '',
        component: LCETemplateSheldonComponent,
        canActivate: [LCEMBOAuthenticationGuard],
        children: [
            {path: '', redirectTo: 'dashboard'},
            {path: 'dashboard', component: LCEMBODashboardComponent},
            {path: 'resources', component: LCEMBOResourcesComponent},
            {
                path: 'showcase',
                loadChildren: () => import(/* webpackChunkName: "lce-showcase.module" */ '@lce-mbo/features/showcase/lce-showcase.module').then((m) => m.LCEShowcaseModule)
            },
            {
                path: 'global-search',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-mbo-global-searches.module" */ '@lce-mbo/features/global-search/lce-mbo-global-search.module').then((m) => m.LCEMBOGlobalSearchModule)
            },
            {
                path: 'events',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-events.module" */ '@lce-mbo/features/events/lce-mbo-events.module').then((m) => m.LCEMBOEventsModule)
            },
            {
                path: 'facility-town-hall-stamp-tokens',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-facility-town-hall-stamp-tokens.module" */ '@lce-mbo/features/facilities/stamp-tokens/lce-mbo-facility-town-hall-stamp-tokens.module').then((m) => m.LCEMBOFacilityTownHallStampTokensModule)
            },
            {
                path: 'finances',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-finances.module" */ '@lce-mbo/features/finances/lce-mbo-finances.module').then((m) => m.LCEMBOFinancesModule)
            },
            {
                path: 'settings',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-settings.module" */ '@lce-mbo/features/settings/lce-mbo-settings.module').then((m) => m.LCEMBOSettingsModule)
            },
            {
                path: 'certificate-orders',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-mbo-certificate-orders.module" */ '@lce-mbo/features/certificate-orders/lce-mbo-certificate-orders.module').then(
                        (m) => m.LCEMBOCertificateOrdersModule
                    )
            },
            {
                path: 'smartcity',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-smartcity.module" */ '@lce-mbo/features/smartcity/lce-mbo-smartcity.module').then((m) => m.LCEMBOSmartCityModule)
            },
            {
                path: 'news',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-news.module" */ '@lce-mbo/features/news/lce-mbo-news.module').then((m) => m.LCEMBONewsModule)
            },
            {
                path: 'birth-declarations',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-mbo-birth-declaration.module" */ '@lce-mbo/features/birth-declarations/lce-mbo-birth-declarations.module').then(
                        (m) => m.LCEMBOBirthDeclarationModule
                    )
            },
            {
                path: 'customers',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-customer.module" */ '@lce-mbo/features/customers/lce-mbo-customers.module').then((m) => m.LCEMBOCustomersModule)
            },
            {
                path: 'suggestions',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-suggestions.module" */ '@lce-mbo/features/suggestions/lce-mbo-suggestions.module').then((m) => m.LCEMBOSuggestionsModule)
            },
            {
                path: 'permissions',
                loadChildren: () => import(/* webpackChunkName: "lce-mbo-permissions.module" */ '@lce-mbo/features/permissions/lce-mbo-permissions.module').then((m) => m.LCEMBOPermissionsModule)
            },
            {
                path: 'terminals',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-mbo-terminals.module" */ '@lce-mbo/features/terminals/lce-mbo-terminals.module').then((m) => m.LCEMBOTerminalsModule)
            },
            {
                path: 'user-management',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-mbo-user-management.module" */ '@lce-mbo/features/user-management/lce-mbo-user-management.module').then((m) => m.LCEMBOUserManagementModule)
            }
        ]
    },
    {path: '**', redirectTo: 'not-found'},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        LCETemplateModule, LCEMBODashboardModule,
        LCEMBOResourcesModule
    ],
    exports: [RouterModule]
})
export class LCEMBORoutingModule {
}
