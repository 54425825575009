import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCETerminal, LCETerminalCanOptions, LCETerminalPartial, LCETerminalSearch, LCETerminalService} from '@lce/core';
import {XSPagination, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCETerminalDialogService} from './lce-terminal-dialog.service';
import {LCETerminalsFeatureService} from './lce-terminals-feature.service';

@Component({
    selector: 'lce-terminals',
    templateUrl: './lce-terminals.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCETerminalDialogService, LCETerminalsFeatureService]
})
export class LCETerminalsComponent implements OnInit, OnDestroy {

    readonly ICON_TERMINAL: string = LCE_SHARED_ICON.terminal;

    readonly TR_BASE: string = 'lce.shared.terminal.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    @Input() styleClass?: string;

    @Input() showBackgroundIcon?: boolean;

    @Input() canOptions?: LCETerminalCanOptions;

    @Input() facilityCode?: string;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    private subscription: Subscription = new Subscription();

    constructor(
        private terminalService: LCETerminalService,
        private terminalDialogService: LCETerminalDialogService) {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
        this.retrieveStats();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEdit(): void {
        this.terminalDialogService.openCreateUpdateDialog({
            terminal: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: (terminal) => {
                this.dataManager.updateSelectedRecordData(terminal);
            }
        });
    }

    public refresh(): void {
        this.retrieveStats();
        this.dataManager.updateSearch();
    }

    public canEdit(): boolean {
        return this.canOptions?.update !== false;
    }

    private retrieveStats(): void {
        this.statsError = undefined;
        this.statsLoading = true;
        this.subscription.add(
            this.terminalService
                .retrieveStats()
                .pipe(finalize(() => (this.statsLoading = false)))
                .subscribe({
                    next: (terminalStats) => (this.stats = terminalStats),
                    error: (error) => (this.statsError = error)
                })
        );
    }

    private retrieveTerminal(selectedRowResult: LCETerminalPartial): Observable<LCETerminal> {
        return this.terminalService.retrieve(selectedRowResult.id);
    }

    private searchTerminal(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCETerminalPartial>> {
        const terminalSearch: LCETerminalSearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            facilityCodes: XSUtils.isEmpty(this.facilityCode) ? undefined : [this.facilityCode!],
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.terminalService.search(terminalSearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchTerminal(query, pagination),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveTerminal(selectedRowData),

            search: {
                defaultPaginationSize: 10,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultFound',
                listingItemStyleClass: 'xs-min-width-250'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
