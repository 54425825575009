<div [ngClass]="{ 'xs-border-discrete xs-border-radius-default xs-p-15 xs-pt-20-imp': showBorder }" class="xs-flex-column xs-width-full {{ styleClass }}">
    <div class="grid">
        <div class="col-12">
            <p-selectButton [(ngModel)]="eventTypes" [multiple]="true" [options]="eventTypeItems" styleClass="xs-select-button xs-select-button-small xs-mb-10 xs-width-full"></p-selectButton>
        </div>
        <div class="col-4">
            <xs-input-period-select></xs-input-period-select>
        </div>
        <div class="col-4">
            <xs-input-multi-select
                    (valueChange)="update()"
                    [(value)]="resourceTypes"
                    [belowText]="TR_BASE + 'label.eventTypes'"
                    [items]="resourceTypeItems"
                    [options]="resourceTypeField"
                    belowTextStyleClass="xs-color-secondary xs-font-size-small xs-mt-0-imp"></xs-input-multi-select>
        </div>
        <div class="col-4">
            <xs-input-multi-select
                    (selectEvent)="update()"
                    (valueChange)="update()"
                    [(value)]="eventNames"
                    [belowText]="TR_BASE + 'label.eventNames'"
                    [items]="eventNameItems"
                    [options]="eventNameField"
                    belowTextStyleClass="xs-color-secondary xs-font-size-small xs-mt-0-imp">
            </xs-input-multi-select>
        </div>
        <div class="col-12">
            <lce-input-field-user-autocomplete-multiple (selectEvent)="update()" [options]="createdByAutocompleteField" inputStyleClass="xs-pl-30-imp"></lce-input-field-user-autocomplete-multiple>
        </div>
        <div class="col-4">
            <lce-input-field-district-autocomplete-multiple (selectEvent)="update()" [options]="districtsAutoCompleteField"></lce-input-field-district-autocomplete-multiple>
        </div>
        <div class="col-4">
            <lce-input-field-municipality-autocomplete-multiple (selectEvent)="update()" [options]="municipalitiesAutoCompleteField"></lce-input-field-municipality-autocomplete-multiple>
        </div>
        <div class="col-4">
            <lce-input-field-facility-autocomplete-multiple (selectEvent)="update()" [options]="facilitiesAutoCompleteField"></lce-input-field-facility-autocomplete-multiple>
        </div>
    </div>
</div>
