<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-header xs-pb-15">
        <div class="lce-template-sheldon-router-title-container">
            <span class="lce-template-sheldon-router-title">{{ TR_BASE + 'title' | translate }}</span>
            <span class="lce-template-sheldon-router-sub-title">{{ TR_BASE + 'subTitle' | translate }}</span>
        </div>
        <div class="lce-template-sheldon-router-action-container">
            <xs-button (clickEvent)="refresh()" [icon]="ICON.refresh" [transform]="false" tooltipText="lce.mbo.features.dashboard.refreshTooltip" type="icon">
            </xs-button>
            <xs-button (clickEvent)="onSettingsClick()" [icon]="ICON.setting" [transform]="false" tooltipText="lce.mbo.features.dashboard.settingsTooltip" type="icon">
            </xs-button>
        </div>
    </div>
    <div class="lce-template-sheldon-router-content xs-mt-50">
        <div class="xs-flex-column-center xs-width-full">

            <div [style.max-width]="'2000px'" class="grid xs-width-full">
                <div class="col-12">
                    <lce-dashboard-stats-global
                        #dashboardStatsGlobal
                        (certificateOrderValueClickEvent)="goToCertificateOrders()"
                        (customerValueClickEvent)="goToCustomers()"
                        (newsArticleValueClickEvent)="goToNewsArticles()"
                        (smartCityArticleValueClickEvent)="goToSmartCityArticles()"
                        (suggestionValueClickEvent)="goToSuggestions()"
                        [municipalityCode]="municipalityCode"
                        [municipalityFullName]="municipalityFullName"
                        class="xs-flex-column-center xs-width-full xs-mb-50">
                    </lce-dashboard-stats-global>
                </div>
                <div class="col-6">
                    <lce-dashboard-certificate-order-stats
                        #dashboardCertificateOrderStats
                        [facilityCode]="facilityCode"
                        [municipalityCode]="municipalityCode"
                        class="xs-flex-column-center xs-width-full"
                    >
                    </lce-dashboard-certificate-order-stats>
                </div>
                <div class="col-6">
                    <div class="xs-flex-column-center">
                        <div class="xs-min-width-500 xs-flex-column xs-justify-content-center xs-border-discrete xs-border-radius-default xs-p-15 xs-justify-content-center">
                            <div class="xs-flex-row xs-justify-content-space-between xs-mb-50">
                                <xs-statistic-tile
                                        (valueClickEvent)="goToNewsArticlesInReview()"
                                        [error]="tileData.news.inReview.error"
                                        [icon]="ICON.review"
                                        [loading]="tileData.news.inReview.loading"
                                        [subText]="TR_BASE + 'newsArticles'"
                                        [text]="TR_BASE + 'newsArticlesInReview'"
                                        [value]="tileData.news.inReview.value"
                                        [zeroLeftPadding]="true"
                                        class="xs-width-fit-content"
                                        iconSize="small"
                                        orientation="vertical">
                                </xs-statistic-tile>
                                <xs-statistic-tile
                                        (valueClickEvent)="goToNewsArticlesScheduledPublication()"
                                        [error]="tileData.news.scheduled.error"
                                        [icon]="ICON.scheduled"
                                        [loading]="tileData.news.scheduled.loading"
                                        [subText]="TR_BASE + 'newsArticles'"
                                        [text]="TR_BASE + 'newsArticlesScheduledPublication'"
                                        [value]="tileData.news.scheduled.value"
                                        [zeroLeftPadding]="true"
                                        class="xs-width-fit-content"
                                        iconSize="small"
                                        orientation="vertical">
                                </xs-statistic-tile>
                            </div>

                            <lce-dashboard-news-last-article #dashboardNewsLastArticle class="xs-flex-column-center xs-width-full" styleClass="xs-width-full-imp">
                            </lce-dashboard-news-last-article>
                        </div>
                        <lce-dashboard-smartcity-last-article #dashboardSmartCityLastArticle class="xs-flex-column-center xs-width-full" styleClass="xs-mt-20">
                        </lce-dashboard-smartcity-last-article>
                    </div>
                </div>
                <div class="col-12 xl:col-6">
                    <lce-dashboard-certificate-order-chart-doughnut
                        #certificateOrderChartDoughnut
                        [municipalityCode]="municipalityCode"
                        [showSeparators]="true"
                        class="xs-flex-column-center xs-width-full"
                        styleClass="xs-max-width-500">
                    </lce-dashboard-certificate-order-chart-doughnut>
                </div>
                <div class="col-12 xl:col-6">
                    <lce-dashboard-certificate-order-chart-line
                        #certificateOrderChartLine
                        [municipalityCode]="municipalityCode"
                        class="xs-flex-column-center xs-width-full">
                    </lce-dashboard-certificate-order-chart-line>
                </div>
            </div>
        </div>
    </div>
</div>
