import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LCECoreContextService, LCENewsArticle, LCENewsArticleCategoryService, LCENewsArticleCreate, LCENewsArticleReviewerService, LCENewsArticleService} from '@lce/core';
import {LOG, XS_LOREM_IPSUM, XS_STR_EMPTY, XS_STR_SPACE, XSEnvironmentName, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldEditorOptions, XSInputFieldTextOptions, XSNavigationService} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEArticleCategoriesOptions} from '../../article/categories/lce-article-categories-options';
import {LCEArticleCategoryUtils} from '../../article/categories/lce-article-category-utils';
import {LCEArticleReviewersOptions} from '../../article/reviewers/lce-article-reviewers-options';
import {LCEArticleTagUtils} from '../../article/tags/lce-article-tag-utils';
import {LCEArticleTagsOptions} from '../../article/tags/lce-article-tags-options';
import {LCENewsFeatureService} from '../lce-news-feature.service';

@Component({selector: 'lce-news-article-create-update', templateUrl: './lce-news-article-create-update.component.html'})
export class LCENewsArticleCreateUpdateComponent extends XSDialogable implements OnInit, OnDestroy {
    readonly LCE_SHARED_ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.news.articleCreateUpdate.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateArticleCentralLoader';

    @Input() styleClass?: string;

    @Input() newsArticle: LCENewsArticle;
    @Input() newsArticleID: string;

    @Output() saveEvent = new EventEmitter<LCENewsArticle>();
    @Output() closeEvent = new EventEmitter<LCENewsArticle>();

    titleField: XSInputFieldTextOptions;
    subTitleField: XSInputFieldTextOptions;
    contentField: XSInputFieldEditorOptions;
    descriptionField: XSInputFieldEditorOptions;
    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;
    cancelWithConfirmation: boolean = false;
    createUpdateLoading: boolean = false;
    categoriesControl: FormControl;
    categoriesOptions: LCEArticleCategoriesOptions;
    tagsControl: FormControl;
    tagsOptions: LCEArticleTagsOptions;
    reviewersControl: FormControl;
    reviewersOptions: LCEArticleReviewersOptions;
    autoSave: boolean = true;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.LCE_SHARED_ICON.redo,
        onClick: () => this.retrieveArticle()
    };
    createUpdateError: any;

    private subscription: Subscription = new Subscription();

    private formGroup: FormGroup = new FormGroup({});

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private navigationService: XSNavigationService,
        private contextService: LCECoreContextService,
        private loaderService: XSLoaderService,
        private newsArticleService: LCENewsArticleService,
        private newsArticleCategoryService: LCENewsArticleCategoryService,
        private newsArticleReviewerService: LCENewsArticleReviewerService,
        private featureService: LCENewsFeatureService) {
        super();
    }

    get mode(): string {
        return this.isCreateMode() ? 'create' : 'update';
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.newsArticleID = this.dialogConfig.data.newsArticleID;
            this.newsArticle = this.dialogConfig.data.newArticle;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.newsArticle));
        }

        this.featureService.state.title = this.TR_BASE + this.mode + 'Title';
        this.featureService.state.subTitle = this.isCreateMode() ? this.TR_BASE + 'createSubTitle' : this.newsArticle?.code;
        this.featureService.state.showNewArticleButton = false;
        this.featureService.state.showRefreshButton = false;
        this.featureService.state.showBackButton = true;
        this.featureService.onRefresh.subscribe(() => this.retrieveArticle());

        this.activatedRoute.params.pipe(first()).subscribe((params) => {
            this.newsArticleID = params['id'];
            if (XSUtils.isEmpty(this.newsArticleID)) {
                this.initialize();
            } else {
                this.retrieveArticle();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.newsArticleID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.newsArticleID);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) {
            this.create();
        } else {
            this.update();
        }
    }

    public reset() {
        this.formGroup.reset();
    }

    public close() {
        const defaultURL = this.isCreateMode() ? '/news' : '/news/articles/' + this.newsArticleID;
        this.navigationService.back(defaultURL);
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) {
            return !this.isFormEmpty();
        } else {
            return this.formGroup.dirty;
        }
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.titleField.control?.value) ||
            !XSUtils.isEmpty(this.subTitleField.control?.value) ||
            !XSUtils.isEmpty(this.descriptionField.control?.value) ||
            !XSUtils.isEmpty(this.contentField.control?.value) ||
            !XSUtils.isEmpty(this.categoriesControl.value) ||
            !XSUtils.isEmpty(this.tagsControl?.value)
        ) {
            return false;
        }
        return true;
    }

    public canShowFillForm(): boolean {
        const environmentName: XSEnvironmentName = this.contextService.getAppConfig().environment;
        return environmentName === XSEnvironmentName.NOBACKEND || environmentName === XSEnvironmentName.LOCAL || environmentName === XSEnvironmentName.DEV;
    }

    public fillForm(): void {
        this.titleField.control?.setValue(XS_LOREM_IPSUM.medium);
        this.subTitleField.control?.setValue(XS_LOREM_IPSUM.long);
        this.descriptionField.control?.setValue('<p>' + XS_LOREM_IPSUM.long + XS_STR_SPACE + XS_LOREM_IPSUM.long + '</p>');
        this.contentField.control?.setValue('<p>' + XS_LOREM_IPSUM.veryLong + '</p>');
        this.categoriesControl.setValue(
            LCEArticleCategoryUtils.toChips([
                {id: '9ddd4d58-ac8c-4e86-97a8-4fef63194324', name: 'Immobilier', code: 'LCE-NES-CAT-0325'},
                {id: '546f363b-6430-42cc-89b2-bcdea1af709d', name: 'Smartphone', code: 'LCE-NES-CAT-0326'}
            ])
        );
        this.tagsControl.setValue(LCEArticleTagUtils.toChips(['can', 'can2024', 'iphone13', 'cie', 'sodecie']));
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
        this.buildOptions();
    }

    private retrieveArticle(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.newsArticleService
                .retrieve(this.newsArticleID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: (retrievedArticle) => {
                        this.newsArticle = retrievedArticle;
                        this.initialize();
                    },
                    error: (error) => (this.retrieveError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        LOG().debug('Updating Article ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildArticleUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Article not updated. No change detected ! [code: ' + this.newsArticle!.code + ', id: ' + this.newsArticleID + ']');
            this.router.navigate(['/news/articles', this.newsArticleID]).then();
            return;
        }

        this.subscription.add(
            this.newsArticleService
                .update(this.newsArticleID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (article) => {
                        const updatedArticleID = article.id;
                        LOG().debug('Article successfully updated :-) [code: ' + article.code + ', id: ' + updatedArticleID + ']');
                        this.router.navigate(['/news/articles', updatedArticleID]);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        LOG().debug('Creating Article ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const articleCreate = this.buildArticleCreate(formData);

        this.subscription.add(
            this.newsArticleService
                .create(articleCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (article) => {
                        const savedArticleID = article.id;
                        LOG().debug('Article successfully saved :-) [code: ' + article.code + ', id: ' + savedArticleID + ']');
                        this.router.navigate(['news/articles', savedArticleID]);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildArticleUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        const currentArticle = this.buildArticleCreate(formData);
        if (!XSUtils.equal(this.newsArticle?.title, currentArticle.title)) {
            fieldValueMap.set('title', currentArticle.title);
        }
        if (!XSUtils.equal(this.newsArticle?.subTitle, currentArticle.subTitle)) {
            fieldValueMap.set('subTitle', currentArticle.subTitle);
        }
        if (!XSUtils.equal(this.newsArticle?.description, currentArticle.description)) {
            fieldValueMap.set('description', currentArticle.description);
        }
        if (!XSUtils.equal(this.newsArticle?.content, currentArticle.content)) {
            fieldValueMap.set('content', currentArticle.content);
        }

        const areReviewersEqual = XSUtils.equal(
            this.newsArticle?.reviewers.map((reviewer) => reviewer.user.id),
            currentArticle.reviewerIDs
        );
        if (!areReviewersEqual) {
            fieldValueMap.set('reviewerIDs', currentArticle.reviewerIDs);
        }

        const areCategoriesEqual = XSUtils.equal(
            this.newsArticle?.categories.map((category) => category.code),
            currentArticle.categoryCodes
        );
        if (!areCategoriesEqual) {
            fieldValueMap.set('categoryCodes', currentArticle.categoryCodes);
        }

        if (!XSUtils.equal(this.newsArticle?.tags, currentArticle.tags)) {
            fieldValueMap.set('tags', currentArticle.tags);
        }

        console.log('fieldValueMap', fieldValueMap);
        return fieldValueMap;
    }

    private buildArticleCreate(formData: any): LCENewsArticleCreate {
        let newsArticleCreate: LCENewsArticleCreate = {
            title: {en: XSUtils.trim(formData.title), fr: XSUtils.trim(formData.title)},
            subTitle: XSUtils.isEmpty(formData.subTitle) ? undefined : {
                en: XSUtils.trim(formData.subTitle),
                fr: XSUtils.trim(formData.subTitle)
            },
            description: {en: XSUtils.trim(formData.description), fr: XSUtils.trim(formData.description)},
            content: {en: XSUtils.trim(formData.content), fr: XSUtils.trim(formData.content)},

            coverImage: formData.coverImage,

            reviewerIDs: formData.reviewers,

            categoryCodes: LCEArticleCategoryUtils.getCategoryCodes(formData.categories),
            tags: LCEArticleTagUtils.getTags(formData.tags)
        };
        return newsArticleCreate;
    }

    private buildOptions(): void {
        this.categoriesOptions = {
            mode: 'input',
            canAdd: true,
            autocomplete: (query: string, limit: number) => {
                return this.newsArticleCategoryService.autocomplete(query, limit);
            }
        };
        this.tagsOptions = {
            mode: 'input',
            canAdd: true,
            autocomplete: (query: string, limit: number) => {
                return this.newsArticleService.autocompleteTags(query, limit);
            }
        };
        this.reviewersOptions = {
            canAdd: true,
            canRemoveAlreadyAdded: true,
            showSectionTitles: true,
            retrieveDefaultReviewers: () => {
                const categoryCodes: string[] = LCEArticleCategoryUtils.getCategoryCodes(this.categoriesControl.value);
                return this.newsArticleReviewerService.retrieveDefaultReviewers(categoryCodes);
            }
        };
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: this.TR_BASE + 'resetConfirmation',
            icon: this.LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: this.TR_BASE + 'closeConfirmation',
            icon: this.LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.close();
            },
            reject: () => {
            }
        };
    }

    private buildFields(): void {
        this.contentField = {
            fieldName: 'content',
            control: new FormControl(XSUtils.isEmpty(this.newsArticle?.content) ? XS_STR_EMPTY : this.newsArticle?.content, Validators.required),
            label: this.TR_BASE + 'contentLabel',
            editorStyleClass: 'xs-height-500',
            mode: 'advanced'
        };
        this.descriptionField = {
            fieldName: 'description',
            control: new FormControl(XSUtils.isEmpty(this.newsArticle?.description) ? XS_STR_EMPTY : this.newsArticle?.description, Validators.required),
            label: this.TR_BASE + 'descriptionLabel',
            editorStyleClass: 'xs-height-150',
            mode: 'medium'
        };
        this.subTitleField = {
            fieldName: 'subTitle',
            control: new FormControl(XSUtils.isEmpty(this.newsArticle?.subTitle) ? XS_STR_EMPTY : this.newsArticle?.subTitle, Validators.required),
            label: this.TR_BASE + 'subTitleLabel'
        };
        this.titleField = {
            fieldName: 'title',
            control: new FormControl(XSUtils.isEmpty(this.newsArticle?.title) ? XS_STR_EMPTY : this.newsArticle?.title, Validators.required),
            label: this.TR_BASE + 'titleLabel'
        };

        this.categoriesControl = new FormControl(LCEArticleCategoryUtils.toChips(this.newsArticle?.categories), Validators.required);
        this.tagsControl = new FormControl(LCEArticleTagUtils.toChips(this.newsArticle?.tags));
        this.reviewersControl = new FormControl([]);

        this.formGroup.addControl('categories', this.categoriesControl);
        this.formGroup.addControl('tags', this.tagsControl);
        this.formGroup.addControl('reviewers', this.reviewersControl);

        this.formGroup.addControl(this.contentField.fieldName, this.contentField.control!);
        this.formGroup.addControl(this.descriptionField.fieldName, this.descriptionField.control!);
        this.formGroup.addControl(this.subTitleField.fieldName, this.subTitleField.control!);
        this.formGroup.addControl(this.titleField.fieldName, this.titleField.control!);
    }
}
