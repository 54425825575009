<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #suggestionRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !suggestionRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}label.errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div class="xs-card xs-flex-1">
        <div class="xs-card-header">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum xs-header-left-stripe">
                    <span class="xs-card-header-title">
                        {{ TR_BASE + 'label.suggestion' | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex xs-flex-wrap xs-justify-content-space-between xs-mtb-15">
                <xs-data-field-text
                        [upperCase]="true"
                        [value]="suggestion!.code"
                        label="xs.core.label.code" styleClass="xs-mb-15"
                        valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                </xs-data-field-text>
                <lce-data-field-user-resource-audit-inline
                        [label]="TR_BASE + 'label.receivedOn'"
                        [on]="suggestion!.createdOn"
                        [required]="true"
                        [showCalendarIcon]="true"
                        styleClass="xs-mb-5 xs-mlr-20"
                        valueStyleClass="xs-font-size-intermediate-imp">
                </lce-data-field-user-resource-audit-inline>
                <lce-data-field-user-resource-audit-inline
                        [by]="suggestion!.updatedBy"
                        [on]="suggestion!.updatedOn"
                        [required]="false"
                        label="xs.core.audit.updatedOn"
                        styleClass="xs-mb-5">
                </lce-data-field-user-resource-audit-inline>
            </div>

            <hr class="xs-hr xs-hr-discrete"/>

            <div class="xs-flex xs-flex-wrap xs-align-items-center xs-justify-content-space-between xs-mt-15 xs-mb-25">
                <div class="xs-flex-row xs-mt-15">
                    <lce-user-partial *ngIf="suggestion!.anonymous !== true" [data]="suggestion!.author!" [showStatus]="false"></lce-user-partial>
                    <lce-user-customer-anonymous *ngIf="suggestion!.anonymous === true"></lce-user-customer-anonymous>
                </div>
                <div *ngIf="suggestion?.targetMunicipalities | xsIsNotEmpty" class="xs-flex-row-center xs-mt-15 xs-plr-20">
                    <lce-municipality-partials
                            [data]="suggestion!.targetMunicipalities!"
                            label="{{ TR_BASE + 'label.targetMunicipalities' }}">
                    </lce-municipality-partials>
                </div>
                <div *ngIf="suggestion?.targetAllMunicipalities === true" class="xs-flex-row xs-mt-15 xs-plr-20">
                    <xs-ivar
                            line1="{{ TR_BASE + 'label.targetMunicipalities' }}"
                            line1StyleClass="xs-data-field-label-imp xs-mb-5-imp"
                            line2="{{ TR_BASE + 'label.allMunicipalities' }}"
                            line2StyleClass="xs-font-size-intermediate-imp xs-color-secondary xs-font-weight-500">
                    </xs-ivar>
                </div>
                <div class="xs-flex-row xs-mt-15">
                    <lce-suggestion-status [data]="suggestion!"></lce-suggestion-status>
                </div>
            </div>
            <div class="xs-flex-column xs-width-full xs-mt-25">
                <span
                        *ngIf="suggestion!.subject | xsIsNotEmpty"
                        class="xs-display-block xs-font-size-default xs-font-weight-500 xs-mb-5">
                    {{ suggestion!.subject! }}
                </span>
                <p class="xs-font-size-intermediate xs-text-align-justify">{{ suggestion!.message | xsTrim }}</p>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="suggestion!.id"></xs-data-id>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <xs-ivar [ellipsis]="true" [icon]="ICON.suggestion" [line1]="headerTitle" [line2]="headerSubTitle" styleClass="xs-dialog-header"></xs-ivar>
</ng-template>