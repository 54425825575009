import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {LCEFacilitiesFeatureService, LCEFacilityTownHallPaymentTransferFeatureService} from '@lce/shared';
import {LCEMBOFacilitiesRoutingModule} from './lce-mbo-facilities-routing.module';
import {LCEMBOFacilitiesComponent} from './lce-mbo-facilities.component';

@NgModule({
    imports: [CommonModule, LCEMBOSharedModule, LCEMBOFacilitiesRoutingModule],
    declarations: [LCEMBOFacilitiesComponent],
    exports: [
        LCEMBOFacilitiesComponent
    ],
    providers: [LCEFacilitiesFeatureService, LCEFacilityTownHallPaymentTransferFeatureService]
})
export class LCEMBOFacilitiesModule {
}
