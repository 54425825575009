import {Injectable} from '@angular/core';
import {LCEResourceType} from '@lce/core';
import {LCEBirthDeclarationDialogService} from '../birth-declaration/lce-birth-declaration-dialog.service';
import {LCECertificateOrderDialogService} from '../certificate-order/lce-certificate-order-dialog.service';
import {LCEDistrictDialogService} from '../district/lce-district-dialog.service';
import {LCEFacilityDialogService} from '../facility/lce-facility-dialog.service';
import {LCEMunicipalityDialogService} from '../municipality/lce-municipality-dialog.service';
import {LCENewsArticleDialogService} from '../news/lce-news-article-dialog.service';
import {LCEServicePointDialogService} from '../service-point/lce-service-point-dialog.service';
import {LCESmartCityArticleDialogService} from '../smartcity/lce-smartcity-article-dialog.service';

@Injectable()
export class LCEEventsResourceService {
    constructor(
        private districtDialogService: LCEDistrictDialogService,
        private certificateOrderDialogService: LCECertificateOrderDialogService,
        private birthDeclarationDialogService: LCEBirthDeclarationDialogService,
        private facilityDialogService: LCEFacilityDialogService,
        private municipalityDialogService: LCEMunicipalityDialogService,
        private newsArticleDialogService: LCENewsArticleDialogService,
        private servicePointDialogService: LCEServicePointDialogService,
        private smartCityDialogService: LCESmartCityArticleDialogService
    ) {
    }

    public openResourceRecordDialog(resourceID: string, resourceType: LCEResourceType): void {
        // TODO:  .openRecordDialog pour tous les services de dialog
        switch (resourceType) {
            case LCEResourceType.DISTRICT:
                return this.districtDialogService.openDistrictRecordDialog({districtID: resourceID});
            case LCEResourceType.CERTIFICATE_ORDER:
                return this.certificateOrderDialogService.openRecordDialog({orderID: resourceID});
            case LCEResourceType.BIRTH_DECLARATION:
                return this.birthDeclarationDialogService.openRecordDialog({birthDeclarationID: resourceID});
            case LCEResourceType.FACILITY:
                return this.facilityDialogService.openRecordDialog({facilityID: resourceID});
            case LCEResourceType.MUNICIPALITY:
                return this.municipalityDialogService.openRecordDialog({municipalityID: resourceID});
            case LCEResourceType.NEWS_ARTICLE:
                return this.newsArticleDialogService.openArticleDialog({articleID: resourceID});
            case LCEResourceType.SERVICE_POINT:
                return this.servicePointDialogService.openRecordDialog({servicePointID: resourceID});
            case LCEResourceType.SMARTCITY_ARTICLE:
                return this.smartCityDialogService.openArticleDialog({articleID: resourceID});
            case LCEResourceType.SUGGESTION:
                return;
        }
    }
}
