import {XSUtils} from '@xs/base';
import {XSLabelValue} from '@xs/common';
import {XSChip} from '@xs/core';

export class LCEArticleTagUtils {

	public static getTags(chips: XSChip[]): string[] {
		return chips.map(chip => chip.value);
	}

	public static toLabelValues(tags: string[] | undefined): XSLabelValue[] {
		if (XSUtils.isEmpty(tags)) return [];
		return tags!.map(tag => {
			return {label: tag, value: tag};
		});
	}

	public static toChips(tags: string[] | undefined, removable?: boolean): XSChip[] {
		if (XSUtils.isEmpty(tags)) return [];
		return tags!.map(tag => {
			const chip: XSChip = {label: tag, value: tag};
			if (!XSUtils.isNull(removable)) chip.removable = removable;
			return chip;
		});
	}
}
