<div class="xs-flex-column xs-width-full {{ styleClass }}">
    <div class="xs-flex-column-center xs-width-full xs-sm-display-none xs-mb-30">
        <p-selectButton (onChange)="onPredefinedPeriodFilterChange()" [(ngModel)]="predefinedPeriodFilter"
                        [options]="predefinedPeriodFilterOptions" class="xs-width-full xs-max-width-400"
                        styleClass="xs-select-button xs-select-button-small">
        </p-selectButton>


        <div *ngIf="hasError() && !primaryLoader.isRunning() && firstInitialization" class="xs-absolute-center-vh">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showActionButton]="true"
                    [showErrorDetailButton]="true"
                    errorDetailButtonClass="xs-mt-5-imp"
                    message="lce.shared.news.label.errorMessageSearchArticles"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>
    </div>
    <div class="xs-flex-row-center xs-position-relative xs-width-full">


        <div class="xs-flex-column xs-justify-content-center xs-width-full xs-mt-25">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full xs-mb-10 xs-plr-15">
                <xs-input-checkbox
                        [(value)]="showAuthenticationStateColor"
                        [binary]="true"
                        label="{{ TR_BASE }}colorAuthenticationState"
                        labelStyleClass="xs-font-size-small-imp">
                </xs-input-checkbox>

                <div class="xs-flex-row xs-align-items-center">
                    <!-- === Error & Secondary Loader === -->
                    <div class="xs-position-relative xs-mr-50">
                        <xs-loader #secondaryLoader [loaderId]="SECONDARY_LOADER_ID" [loaderSize]="20"></xs-loader>
                    </div>
                    <xs-button
                            (clickEvent)="onExport()"
                            [disabled]="true"
                            [icon]="ICON.download"
                            [loading]="exporting"
                            label="{{ TR_BASE }}export"
                            loaderPosition="left"
                            size="intermediate"
                            type="text">
                    </xs-button>
                </div>

            </div>
            <div class="xs-position-relative xs-border-discrete xs-border-radius-default xs-min-height-300">
                <div class="xs-absolute-center-vh">
                    <xs-loader #primaryLoader [loaderId]="PRIMARY_LOADER_ID" [loaderSize]="30"></xs-loader>
                </div>
                <xs-no-result-found *ngIf="!primaryLoader.isRunning() && !secondaryLoader.isRunning() && loginAttempts | xsIsEmpty" styleClass="xs-mt-50"></xs-no-result-found>

                <ul *ngIf="!primaryLoader.isRunning() && loginAttempts | xsIsNotEmpty" class="xs-listing xs-listing-separator xs-width-full ">
                    <li *ngFor="let loginAttempt of loginAttempts" class="xs-listing-item">
                        <lce-authentication-login-attempt-row [data]="loginAttempt" [showAuthenticationStateColor]="showAuthenticationStateColor">
                        </lce-authentication-login-attempt-row>
                    </li>
                </ul>
                <div *ngIf="isPageable()">
                    <div class="xs-flex-row xs-justify-content-center xs-width-full">
                        <p-paginator
                                (onPageChange)="handlePagination($event)"
                                [ngClass]="{ 'xs-disabled': hasError() }"
                                [rowsPerPageOptions]="PAGINATOR_ROWS_PER_PAGE_OPTIONS"
                                [rows]="pagination.size"
                                [totalRecords]="nLoginAttempts">
                        </p-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>