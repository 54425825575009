import { Component, Input, OnInit } from '@angular/core';
import { LCEEventResource, LCEResourceType } from '@lce/core';
import { XSAssert } from '@xs/base';
import { LCEEventsResourceService } from '../lce-events-resource.service';
import { LCEServicePointDialogService } from '../../service-point/lce-service-point-dialog.service';
import { LCEBirthDeclarationDialogService } from '../../birth-declaration/lce-birth-declaration-dialog.service';
import { LCEDistrictDialogService } from '../../district/lce-district-dialog.service';
import { LCECertificateOrderDialogService } from '../../certificate-order/lce-certificate-order-dialog.service';
import { LCEFacilityDialogService } from '../../facility/lce-facility-dialog.service';
import { LCEMunicipalityDialogService } from '../../municipality/lce-municipality-dialog.service';
import { LCENewsArticleDialogService } from '../../news/lce-news-article-dialog.service';
import { LCESmartCityArticleDialogService } from '../../smartcity/lce-smartcity-article-dialog.service';

@Component({
    selector: 'lce-event-resource',
    templateUrl: './lce-event-resource.component.html',
    providers: [
        LCEEventsResourceService,
        LCEServicePointDialogService,
        LCEBirthDeclarationDialogService,
        LCEDistrictDialogService,
        LCECertificateOrderDialogService,
        LCEFacilityDialogService,
        LCEMunicipalityDialogService,
        LCENewsArticleDialogService,
        LCESmartCityArticleDialogService,
    ],
})
export class LCEEventResourceComponent implements OnInit {
    @Input() styleClass?: string;

    @Input() data: LCEEventResource;

    constructor(private resourceService: LCEEventsResourceService) {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public onViewResourceRecord(resourceID: string, resourceType: LCEResourceType): void {
        this.resourceService.openResourceRecordDialog(resourceID, resourceType);
    }
}
