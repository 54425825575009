import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCE_FACILITY_PARTIAL_YOPOUGON, LCEFacilityPartial, LCETerminal, LCETerminalCreate, LCETerminalService} from '@lce/core';
import {LOG, XS_LOREM_IPSUM, XSDeviceIDType, XSDeviceOS, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService, XSValidatorDevice} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldBaseOptions, XSInputFieldDeviceOptions, XSInputFieldTextAreaOptions, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-create-update',
    templateUrl: './lce-terminal-create-update.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCETerminalCreateUpdateComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;
    readonly INFO_ICON = LCE_SHARED_ICON.info;

    readonly TR_BASE: string = 'lce.shared.terminal.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly LOADER_ID: string = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() terminalID?: string;
    @Input() terminal?: LCETerminal;

    @Output() saveEvent = new EventEmitter<LCETerminal>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCETerminal>();

    formGroup: FormGroup = new FormGroup({});
    deviceField: XSInputFieldDeviceOptions;
    facilityField: XSInputFieldBaseOptions;
    nameField: XSInputFieldTextOptions;
    descriptionField: XSInputFieldTextAreaOptions;
    noteField: XSInputFieldTextAreaOptions;

    resetConfirmation: XSConfirmation = {
        key: 'resetConfirmationKey',
        trMessage: 'xs.core.label.confirmationResetForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.reset();
        },
        reject: () => {
        }
    };
    closeConfirmation: XSConfirmation = {
        key: 'closeConfirmationKey',
        trMessage: 'xs.core.label.confirmationLeaveForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.closeDialog();
        },
        reject: () => {
        }
    };

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieve()
    };
    createUpdateError: any;

    @Input() facility?: LCEFacilityPartial;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private terminalService: LCETerminalService) {
        super();
    }

    get headerTitle(): string {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.terminal?.device.name;
        if (XSUtils.isEmpty(title)) title = 'Terminal';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.terminal?.device.os;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.terminalID = this.dialogConfig.data.terminalID;
            this.terminal = this.dialogConfig.data.terminal;
            this.facility = this.dialogConfig.data.facility;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.contentStyleClass = this.dialogConfig.data.contentStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.terminal));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.terminal)) {
            if (!XSUtils.isEmpty(this.terminal)) this.terminalID = this.terminal!.id;
            this.initialize();
        } else this.retrieve();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasTerminal(): boolean {
        return !XSUtils.isEmpty(this.terminal);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.terminalID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.terminalID) && XSUtils.isEmpty(this.terminal);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.hasFormDataChanged();
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.deviceField.control?.value) ||
            !XSUtils.isEmpty(this.nameField.control?.value) ||
            !XSUtils.isEmpty(this.facilityField.control?.value) ||
            !XSUtils.isEmpty(this.descriptionField.control?.value) ||
            !XSUtils.isEmpty(this.noteField.control?.value));

    }

    // TODO : TO BE remove
    public fillForm(): void {
        this.facilityField.control?.setValue(LCE_FACILITY_PARTIAL_YOPOUGON);
        this.nameField.control?.setValue('Zeus');
        this.deviceField.control?.setValue({id: XSUtils.uuid(), idType: XSDeviceIDType.MAC_ADDRESS, os: XSDeviceOS.IOS});
        this.descriptionField.control?.setValue(XS_LOREM_IPSUM.medium);
        this.noteField.control?.setValue(!XS_LOREM_IPSUM.medium);
    }

    private initialize(): void {
        this.buildFields();
    }

    private retrieve(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.retrieveError = undefined;
        this.subscription.add(
            this.terminalService
                .retrieve(this.terminalID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (retrievedTerminal) => {
                        this.terminal = retrievedTerminal;
                        this.initialize();
                    },
                    error: (error) => (this.retrieveError = error)
                })
        );
    }

    private validateFormGroup(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
    }

    private update(): void {
        this.validateFormGroup();
        if (!this.formGroup.valid) return;
        LOG().debug('Updating terminal [terminalID: ' + this.terminalID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('terminal not updated. No change detected ! [code: ' + this.terminal!.code + ', id: ' + this.terminalID + ']');
            this.saveEvent.emit(undefined);
            return;
        }

        this.createUpdateLoading = true;

        this.subscription.add(
            this.terminalService
                .update(this.terminalID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (updatedTerminal) => {
                        LOG().debug('Terminal successfully updated :-) [code: ' + updatedTerminal.code + ', id: ' + updatedTerminal.id + ']');
                        this.saveEvent.emit(updatedTerminal);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        this.validateFormGroup();
        if (!this.formGroup.valid) return;
        LOG().debug('Creating Terminal ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const terminalCreate = this.buildCreate(formData);
        this.subscription.add(
            this.terminalService
                .create(terminalCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (terminal) => {
                        LOG().debug('terminal successfully saved :-) [code: ' + terminal.code + ', id: ' + terminal.id + ']');
                        this.saveEvent.emit(terminal);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private hasFormDataChanged(): boolean {
        return this.buildUpdate(this.formGroup.getRawValue()).size > 0;
    }

    private buildUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (formData.deviceIDType !== this.terminal?.device.idType) fieldValueMap.set('deviceIDType', formData.deviceIDType);
        if (XSUtils.trim(formData.name) !== this.terminal?.device.name) fieldValueMap.set('name', XSUtils.trim(formData.name));
        if (XSUtils.trim(formData.description) !== this.terminal?.description) fieldValueMap.set('description', XSUtils.trim(formData.description));
        if (XSUtils.trim(formData.note) !== this.terminal?.note) fieldValueMap.set('note', XSUtils.trim(formData.note));
        if (formData.facility?.code !== this.terminal?.facility.code) fieldValueMap.set('facility', formData.facility);
        if (XSUtils.trim(formData.deviceOS) !== this.terminal?.device.os) fieldValueMap.set('deviceOS', XSUtils.trim(formData.deviceOS));
        if (XSUtils.trim(formData.deviceID) !== this.terminal?.device.id) fieldValueMap.set('deviceID', XSUtils.trim(formData.id));

        return fieldValueMap;
    }

    private buildCreate(formData: any): LCETerminalCreate {
        return {
            facilityCode: XSUtils.trim(formData.facility.code),
            name: XSUtils.trim(formData.name),
            description: XSUtils.trim(formData.description),
            deviceID: XSUtils.trim(formData.device.id),
            deviceIDType: formData.device.idType,
            deviceOS: formData.device.os,
            note: XSUtils.trim(formData.note)
        };
    }

    private buildFields(): void {

        this.deviceField = {
            label: this.TR_BASE_LABEL + 'device.label',
            belowText: this.TR_BASE_LABEL + 'device.belowText',
            fieldName: 'device',
            control: new FormControl(this.terminal?.device, XSValidatorDevice.required()),
            idTypeOptions: {
                placeholder: this.TR_BASE_LABEL + 'idType.placeholder'
            },
            osOptions: {
                placeholder: this.TR_BASE_LABEL + 'deviceOS.placeholder'
            },
            identifierOptions: {
                placeholder: this.TR_BASE_LABEL + 'deviceID.placeholder'
            }
        };

        this.facilityField = {
            fieldName: 'facility',
            control: new FormControl(!XSUtils.isEmpty(this.facility) ? this.facility! : this.terminal?.facility, Validators.required),
            label: 'lce.shared.label.facility'
        };
        this.nameField = {
            fieldName: 'name',
            control: new FormControl(this.terminal?.device.name, Validators.required),
            label: this.TR_BASE_LABEL + 'name.label',
            belowText: this.TR_BASE_LABEL + 'name.belowText'
        };

        this.descriptionField = {
            fieldName: 'description',
            control: new FormControl(this.terminal?.description),
            label: 'xs.common.label.description',
            belowText: this.TR_BASE_LABEL + 'description.belowText',
            maxLength: 150,
            rows: 2
        };

        this.noteField = {
            fieldName: 'note',
            label: 'xs.common.label.note',
            belowText: this.TR_BASE_LABEL + 'note.belowText',
            control: new FormControl(this.terminal?.note),
            maxLength: 300,
            rows: 2
        };

        this.formGroup.addControl(this.facilityField.fieldName, this.facilityField.control!);
        this.formGroup.addControl(this.deviceField.fieldName, this.deviceField.control!);
        this.formGroup.addControl(this.nameField.fieldName, this.nameField.control!);
        this.formGroup.addControl(this.descriptionField.fieldName, this.descriptionField.control!);
        this.formGroup.addControl(this.noteField.fieldName, this.noteField.control!);
    }
}
