import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LCENewsArticleComment, LCENewsArticleCommentSearch, LCENewsArticleCommentService} from '@lce/core';
import {XS_LOREM_IPSUM, XS_STR_EMPTY, XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSInputFieldTextAreaOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-news-article-comments', templateUrl: './lce-news-article-comments.component.html'})
export class LCEArticleCommentsComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.news.articleComments.';

    readonly ROWS_PER_PAGE_OPTIONS: number[] = [5, 10, 15];

    readonly MAIN_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() articleID: string;

    @Input() total: number;

    comments: LCENewsArticleComment[] = [];
    search: LCENewsArticleCommentSearch = {
        paginationPage: 0,
        paginationSize: 5
    };
    commentMessageField: XSInputFieldTextAreaOptions;
    createCommentLoading: boolean = false;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveComments()
    };

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private newsArticleCommentService: LCENewsArticleCommentService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.articleID, 'articleID');
        this.search.articleIDs = [this.articleID];
        this.retrieveComments();
        this.buildMessageField();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public onRefresh() {
        this.retrieveComments();
    }

    public hasComment(): boolean {
        return !XSUtils.isNull(this.total) && this.total > 0;
    }

    public handlePagination(event: any) {
        this.search.paginationPage = event.page;
        this.retrieveComments();
    }

    public onCreateComment(): void {
        this.createCommentLoading = true;
        this.commentMessageField.disabled = true;
        this.subscription.add(
            this.newsArticleCommentService
                .create(this.articleID, this.commentMessageField.control?.value)
                .pipe(finalize(() => {
                    this.createCommentLoading = false;
                    this.commentMessageField.disabled = false;
                }))
                .subscribe(() => {
                    this.commentMessageField.control!.reset();
                    this.search.paginationPage = 0;
                    this.retrieveComments();
                })
        );
    }

    public isMessageEmpty(): boolean {
        return XSUtils.isEmpty(this.commentMessageField.control?.value);
    }

    public onFillCommentMessage(): void {
        this.commentMessageField.control?.setValue(XSUtils.randomElement([XS_LOREM_IPSUM.long, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.short]));
    }

    private retrieveComments(): void {
        this.loaderService.startLoader(this.MAIN_LOADER_ID);
        this.subscription.add(
            this.newsArticleCommentService
                .search(this.search)
                .pipe(finalize(() => this.loaderService.stopLoader(this.MAIN_LOADER_ID)))
                .subscribe({
                    next: (response) => {
                        this.comments = response.data;
                        this.total = response.total;
                        this.search.paginationPage = response.pagination!.page;
                        this.search.paginationSize = response.pagination!.size;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

    private buildMessageField(): void {
        this.commentMessageField = {
            fieldName: 'message',
            placeholder: this.TR_BASE + 'postCommentPlaceholder',
            control: new FormControl(XS_STR_EMPTY),
            textareaStyleClass: 'xs-pl-15-imp'
        };
    }
}
