import {Injectable} from '@angular/core';
import {XSAssert, XSBaseService, XSSearchResult} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCENewsArticleComment, LCENewsArticleCommentSearch} from '../../domain/news/lce-news-article-comment';
import {LCEValidatorUtils} from '../../utils/lce-validator-utils';
import {LCEHttpClientService} from '../lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCENewsArticleCommentService extends XSBaseService {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.news.comments.index);
    }

    public search(search: LCENewsArticleCommentSearch): Observable<XSSearchResult<LCENewsArticleComment>> {
        XSAssert.notEmpty(search, 'search');
        return this.httpClientService.get<XSSearchResult<LCENewsArticleComment>>(this.buildEndpoint(LCE_CORE_ENDPOINTS.news.comments.search), this.toHttpParameters(search));
    }

    public deleteOneByID(commentID: string): Observable<void> {
        XSAssert.notEmpty(commentID, 'commentID');
        return this.httpClientService.delete<void>(this.buildEndpoint(commentID));
    }

    public retrieve(commentID: string): Observable<LCENewsArticleComment> {
        XSAssert.notEmpty(commentID, 'commentID');
        return this.httpClientService.get<LCENewsArticleComment>(this.buildEndpoint(commentID));
    }

    public create(articleID: string, content: string): Observable<LCENewsArticleComment> {
        XSAssert.notEmpty(articleID, 'articleID');
        LCEValidatorUtils.validateNewsArticleComment(content?.trim());
        const body = {content: content.trim()};
        return this.httpClientService.post<LCENewsArticleComment>(LCE_CORE_ENDPOINTS.news.comments.create.replace('${articleID}', articleID), body);
    }
}
