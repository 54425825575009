<div class="xs-flex-row-center xs-position-relative xs-width-fit-content xs-ptb-30 xs-plr-40 xs-border-discrete xs-border-radius-8 {{styleClass}}">

    <div class="xs-flex-row xs-align-items-start xs-position-absolute xs-top-7 xs-right-8">
        <xs-error-icon
                *ngIf="countError"
                [error]="countError"
                [showErrorDialogOnClick]="true"
                class="xs-flex-row xs-align-self-center"
                size="small"
                styleClass="xs-mr-10">
        </xs-error-icon>
        <xs-icon
                [color]="countLoading ? 'light' : 'secondary'"
                [value]="ICON_STAMP"
                size="intermediate"
                styleClass="xs-display-block">
        </xs-icon>
        <span
                [innerHTML]="nStampsGeneratedToday"
                [ngClass]="{ 'xs-color-light-imp': countLoading }"
                class="xs-text-block-intermediate-secondary-500 xs-width-20 xs-text-align-right">
        </span>
    </div>

    <div [ngClass]="{ 'xs-disabled': loading }" class="xs-card xs-width-300 xs-pl-0-imp xs-ptb-0-imp xs-pr-30-imp">
        <div *ngIf="hasDetail()" class="xs-card-header xs-pt-3-imp xs-align-items-center xs-justify-content-center">
            <div class="xs-flex-row xs-align-items-center">
                <xs-icon [value]="ICON_STAMP" color="secondary" size="large" styleClass="xs-display-block xs-mr-10"></xs-icon>
                <div class="xs-flex-column">
                    <span class="xs-text-block-intermediate-secondary-500">
                    {{ detail.numberOfStamps }} {{ 'lce.shared.facility.townHallStampToken.label.generatedStamps' | translate }}
                    </span>
                    <span
                            [innerHTML]="'lce.shared.facility.townHallStampToken.create.monetaryValueOf' | translate : {amount: computeMonetaryValue()}"
                            class="xs-text-block-extra-small-secondary xs-mt-1">
                    </span>
                </div>
            </div>
        </div>

        <div class="xs-card-content xs-align-items-center xs-justify-content-center">
            <div *ngIf="!hasDetail()" class="xs-flex-column-center">
                <xs-icon [value]="ICON_QRCODE" color="discrete" size="170px"></xs-icon>
                <span [innerHTML]="DEFAULT_STAMP_TOKEN_CODE" class="xs-text-block-small-light-500 xs-text-transform-uppercase xs-mt-15"></span>
            </div>
            <lce-facility-town-hall-stamp-token-qrcode
                    *ngIf="hasDetail()"
                    [code]="detail.code"
                    [qrCode]="detail.qrCode"
                    [showCode]="true"
                    [showTimeAgoShortMode]="true"
                    [showTimeAgo]="true"
                    [timeAgoPrefix]="'lce.shared.facility.townHallStampToken.label.generated'"
                    [timeAgoValue]="detail.createdOn">
            </lce-facility-town-hall-stamp-token-qrcode>
        </div>

        <div *ngIf="hasMobilePhoneNumber()" class="xs-card-footer xs-position-relative xs-pb-0-imp xs-align-items-center xs-justify-content-center">
            <div class="xs-flex-row xs-align-items-center">
                <xs-icon
                        [value]="ICON_MOBILE"
                        color="secondary"
                        size="21px"
                        styleClass="xs-display-block xs-mr-10">
                </xs-icon>
                <div class="xs-flex-column">
                    <span [innerHTML]="detail.mobilePhoneNumber | xsPhoneNumber: false" class="xs-text-block-small-secondary-500"></span>
                    <span
                            [innerHTML]="'lce.shared.facility.townHallStampToken.create.mobilePhoneNumberDescription' | translate"
                            class="xs-text-block-extra-small-secondary">
                    </span>
                </div>
            </div>
        </div>
    </div>

    <lce-facility-town-hall-stamp-token-create
            (createEvent)="create($event)"
            [loading]="loading"
            [title]="'lce.shared.facility.townHallStampToken.create.title'"
            description="lce.shared.facility.townHallStampToken.create.description"
            styleClass="xs-pl-30 xs-border-left-discrete"
            subDescription="lce.shared.facility.townHallStampToken.create.subDescription"
            subtitle="lce.shared.facility.townHallStampToken.create.subtitle">
    </lce-facility-town-hall-stamp-token-create>
</div>
