import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCEFacilityTownHallStampTokenService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-delete-last',
    templateUrl: 'lce-facility-town-hall-stamp-token-delete-last.component.html'
})
export class LCEFacilityTownHallStampTokenDeleteLastComponent implements OnInit, OnDestroy {

    readonly ICON_STAMP = LCE_SHARED_ICON.stamp;
    readonly ICON_DELETE = LCE_SHARED_ICON.delete;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.label.';

    readonly ERROR_MESSAGE = 'lce.shared.facility.townHallStampToken.error.deleteLast';

    @Input() styleClass?: string;

    @Output() cancelEvent = new EventEmitter<void>();
    @Output() deletedEvent = new EventEmitter<string>();
    @Output() loadingEvent = new EventEmitter<boolean>();
    @Output() errorEvent = new EventEmitter<any>();

    loading: boolean = false;
    error: any;

    private subscription: Subscription = new Subscription();

    constructor(private stampTokenService: LCEFacilityTownHallStampTokenService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public delete(): void {
        this.setLoading(true);
        this.subscription.add(
            this.stampTokenService.deleteLast()
                .pipe(finalize(() => this.setLoading(false)))
                .subscribe({
                    next: response => this.deletedEvent.emit(response.value),
                    error: error => this.setError(error)
                })
        );
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    private setError(error: any): void {
        this.error = error;
        this.errorEvent.emit(this.error);
    }

    private setLoading(state: boolean): void {
        if (state) {
            this.loading = true;
            this.setError(undefined);
        } else {
            this.loading = false;
        }
        this.loadingEvent.emit(this.loading);
    }
}
