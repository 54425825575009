<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-content">

        <div class="xs-flex-column xs-height-full">
            <p-tabView #pTabView [scrollable]="navScrollable" class="xs-tabview" styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                <p-tabPanel header="{{ 'lce.shared.district.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-mbo-districts></lce-mbo-districts>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.municipality.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-mbo-municipalities></lce-mbo-municipalities>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.facility.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-mbo-facilities></lce-mbo-facilities>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.servicePoint.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-mbo-service-points></lce-mbo-service-points>
                    </ng-template>
                </p-tabPanel>

            </p-tabView>
        </div>

    </div>
</div>
