import {Injectable} from '@angular/core';
import {LCEEventPartial, LCEEventSearch, LCEEventService} from '@lce/core';
import {XSPagination, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class LCEEventsFeatureService {
    public sort: XSSort | undefined;
    public pagination: XSPagination;
    private readonly TABLE_DEFAULT_PAGINATION_ROWS: number = 10;
    private startSearchSubject = new Subject<void>();
    onStartSearch = this.startSearchSubject.asObservable();

    private endSearchSubject = new Subject<XSSearchResult<LCEEventPartial>>();
    onEndSearch = this.endSearchSubject.asObservable();

    private refreshSubject = new Subject<void>();
    onRefresh = this.refreshSubject.asObservable();

    constructor(private eventService: LCEEventService) {
        this.pagination = {page: 1, size: this.TABLE_DEFAULT_PAGINATION_ROWS};
    }

    public emitRefresh() {
        this.refreshSubject.next();
    }

    public search(eventSearch: LCEEventSearch): Observable<XSSearchResult<LCEEventPartial>> {
        this.startSearchSubject.next();
        eventSearch.paginationPage = this.pagination.page - 1;
        eventSearch.paginationSize = this.pagination.size;

        if (XSUtils.isEmpty(this.sort)) {
            eventSearch.sort = ['createdOn|desc'];
        } else {
            const sortStr = this.sort!.by.map((sortIem) => sortIem.field + '|' + sortIem.order).join(',');
            eventSearch.sort = [sortStr];
        }
        return this.eventService.search(eventSearch).pipe(tap((response) => this.endSearchSubject.next(response)));
    }
}
