import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEMunicipalityPartial, LCEMunicipalityService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';

@Component({
	selector: 'lce-input-field-municipality-autocomplete-multiple',
	template: `
        <xs-input-field-auto-complete styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-municipality xsTemplate="item">
                <lce-municipality-partial [data]="municipality"></lce-municipality-partial>
            </ng-template>
        </xs-input-field-auto-complete>
	`,
	host: {class: 'xs-width-full'}
})
export class LCEInputFieldMunicipalityAutocompleteMultipleComponent implements OnInit {
	
	@Input() styleClass?: string;

	@Input() options: XSInputFieldBaseOptions;

	@Input() completeOnFocus?: boolean;

	@Input() showDefaultBelowText?: boolean;

	@Input() placeholder?: string;

	@Input() appendTo?: any;

	@Output() selectEvent = new EventEmitter<LCEMunicipalityPartial>();

	autocompleteOptions: XSInputFieldAutoCompleteOptions;

	@ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

	constructor(private municipalityService: LCEMunicipalityService) {
	}

	ngOnInit(): void {
		if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.municipality.label.autocompletePlaceholder';
		this.autocompleteOptions = {
			control: this.options.control,
			autoCompleteOptions: {
				multiple: true,
				labelField: 'fullName',
				forceSelection: true,
				completeOnFocus: this.completeOnFocus,
				search: (query: string) => {
					return this.municipalityService.autocomplete(query);
				}
			},
			placeholder: this.placeholder,
			belowText: this.showDefaultBelowText === true ? 'lce.core.label.municipalities' : undefined,
			belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
			selectEvent: (event: any) => {
				this.handleSelectEvent(event);
			},
			...this.options
		};
	}

	private handleSelectEvent(municipality: LCEMunicipalityPartial): void {
		this.selectEvent.emit(municipality);
	}
}
