import { Component, Input, OnInit } from '@angular/core';
import { XSAssert } from '@xs/base';
import { LCEEventType, LCEResourceType } from '@lce/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';

@Component({ selector: 'lce-event-header', templateUrl: './lce-event-header.component.html' })
export class LCEEventHeaderComponent implements OnInit {
    readonly TR_BASE = 'lce.shared.event.';
    ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subTitleStyleClass?: string;
    @Input() iconStyleClass?: string;

    @Input() title: string;
    @Input() subTitle: LCEEventType;
    @Input() resourceType: LCEResourceType;

    ngOnInit(): void {
        XSAssert.notEmpty(this.title, 'title');
        XSAssert.notEmpty(this.title, 'title');
        XSAssert.notEmpty(this.resourceType, 'resourceType');
    }

    public buildIcon(): string {
        switch (this.resourceType) {
            case LCEResourceType.DISTRICT:
                return this.ICON.district;
            case LCEResourceType.CERTIFICATE_ORDER:
                return this.ICON.certificateOrder;
            case LCEResourceType.BIRTH_DECLARATION:
                return this.ICON.birthDeclaration;
            case LCEResourceType.FACILITY:
                return this.ICON.facility;
            case LCEResourceType.MUNICIPALITY:
                return this.ICON.municipality;
            case LCEResourceType.NEWS_ARTICLE:
                return this.ICON.news;
            case LCEResourceType.SERVICE_POINT:
                return this.ICON.servicePoint;
            case LCEResourceType.SMARTCITY_ARTICLE:
                return this.ICON.smartCity;
            case LCEResourceType.SUGGESTION:
                return this.ICON.suggestion;
            default:
                throw new Error(`resourceType (${this.resourceType?.toString()}) not handled !`);
        }
    }
}
