import {Component, Input, OnInit} from '@angular/core';
import {LCEEventPartial, LCEEventSearch} from '@lce/core';
import {LOG, XSPagination, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {XSLoaderState, XSText} from '@xs/common';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEEventsFeatureService} from './lce-events-feature.service';
import {LCEEventsResourceService} from './lce-events-resource.service';

@Component({
    selector: 'lce-events',
    templateUrl: './lce-events.component.html',
    providers: [LCEEventsFeatureService, LCEEventsResourceService]
})
export class LCEEventsComponent implements OnInit {
    readonly TR_BASE = 'lce.shared.event.';
    readonly ICON = LCE_SHARED_ICON;

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_TOTAL_RECORDS: number = 100;

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    // --- Table ---
    tableData: LCEEventPartial[] = [];
    tableNResults: number = 0;
    tableCaption: XSText = {value: this.TR_BASE + 'table.caption'};
    tableSubCaption: XSText;
    tableLoadingState: XSLoaderState;

    // --- Search ---
    searchText: string;
    searching: boolean;
    showFilter: boolean = false;
    private pagination: XSPagination = {page: 0, size: this.PAGINATOR_TOTAL_RECORDS};
    eventSearch: LCEEventSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size
    };
    private subscription: Subscription = new Subscription();

    constructor(private featureService: LCEEventsFeatureService) {
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
        this.subscription.add(this.featureService.onStartSearch.subscribe(() => this.handleStartSearch()));
        this.subscription.add(this.featureService.onEndSearch.subscribe((searchResults) => this.handleEndSearch(searchResults)));
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSort(sort: XSSort): void {
        this.featureService.sort = sort;
        this.featureService.pagination.page = 1;
        this.search();
    }

    public onResetSort(): void {
        this.featureService.sort = undefined;
        this.search();
    }

    public onPagination(pagination: XSPagination): void {
        this.featureService.pagination = pagination;
        this.search();
    }

    public onResetPreferences(): void {
        this.search();
    }

    public search(): void {
        this.eventSearch.query = !XSUtils.isEmpty(this.searchText) ? this.searchText.trim() : '';
        this.featureService.search(this.eventSearch).subscribe();
    }

    private handleStartSearch(loadingText?: string): void {
        this.searching = true;
        this.tableLoadingState = {state: true, text: loadingText};
    }

    private handleEndSearch(searchResults: XSSearchResult<LCEEventPartial>): void {
        this.tableData = searchResults.data;
        this.tableNResults = searchResults.total;
        this.tableCaption = {value: this.TR_BASE + 'table.caption'};

        this.searching = false;
        this.tableLoadingState = {state: false};

        LOG().debug(this.tableNResults + ' event(s) Found.');
    }

    private refresh(): void {
        this.search();
    }
}
