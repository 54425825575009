import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEUser, LCEUserPartial, LCEUserSearch, LCEUserService} from '@lce/core';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
	selector: 'lce-input-field-user-autocomplete-multiple',
	template: `
        <xs-input-field-auto-complete styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-user xsTemplate="item">
                <lce-user-ivar avatarSize="small" [data]="user" [showStatus]="false" [showPosition]="true" [showCode]="false"></lce-user-ivar>
            </ng-template>
        </xs-input-field-auto-complete>
	`,
	host: {class: 'xs-width-full'}
})
export class LCEInputFieldUserAutoCompleteComponentMultiple implements OnInit {
	@Input() styleClass?: string;

	@Input() options: XSInputFieldBaseOptions;

	@Input() placeholder?: string;

	@Input() appendTo?: any;

	@Output() selectEvent = new EventEmitter<LCEUserPartial>();

	autocompleteOptions: XSInputFieldAutoCompleteOptions;

	@ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

	constructor(private userService: LCEUserService<LCEUser, LCEUserPartial, LCEUserSearch>) {
	}

	ngOnInit(): void {
		this.autocompleteOptions = {
			labelContainerStyleClass: 'xs-ml-35-imp',
			autoCompleteOptions: {
				multiple: true,
				labelField: 'email',
				search: (query: string) => {
					return this.userService.autocomplete(query);
				},
				clearOnSelect: true
			},
			leftIcon: LCE_SHARED_ICON.user,
			leftIconStyleClass: 'xs-color-secondary',
			placeholder: this.placeholder,
			selectEvent: (event: any) => {
				this.handleSelectEvent(event);
			},
			...this.options
		};
	}

	private handleSelectEvent(user: LCEUserPartial): void {
		this.selectEvent.emit(user);
	}
}
