import {Component, Input, OnInit} from '@angular/core';
import {LCEUserMunicipalEmployeeAuthenticationService} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSAuthenticationPINCodeRequest, XSBaseEnvironmentService, XSBuiltEnvironmentName, XSEnvironmentName, XSUtils} from '@xs/base';
import {XS_CORE_ICON, XSCoreContextService} from '@xs/core';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'lce-authentication-pin-code',
    template: `
        <xs-authentication-pin-code
                class="xs-flex-row-center xs-width-full"
                [styleClass]="styleClass"
                (pinCodeChange)="onPINCodeChange($event)"
                [title]="TR_BASE + 'title'"
                [subtitle]="TR_BASE + 'subtitle'"
                [messageLine1]="TR_BASE + 'message'"
                messageLine1StyleClass="xs-font-size-small xs-color-secondary"
                [loading]="loading"
                [error]="error"
                [errorMessage]="errorMessage"
                [showErrorDetailButton]="canShowErrorDetailButton()">
            <ng-template let-oneResult xsTemplate="footer">
                <div class="xs-flex-column-center">
                    <div class="xs-flex-row">
                        <span [innerHTML]="TR_BASE + 'forgotMessage' | translate" class="xs-text-block-small"></span>
                        <xs-button
                                [disabled]="loading"
                                [label]="TR_BASE + 'resetPINCode'"
                                class="xs-ml-10"
                                size="small"
                                type="text">
                        </xs-button>
                    </div>
                    <span class="xs-text-block-extra-small-secondary xs-mt-3" [innerHTML]="TR_BASE + 'generateNewPINCode' | translate"></span>
                </div>
            </ng-template>
        </xs-authentication-pin-code>
    `,
    host: {class: 'xs-flex-row-center xs-width-full'}
})
export class LCEAuthenticationPINCodeComponent implements OnInit {

    readonly ICON_LOCK: string = XS_CORE_ICON.lock;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.authentication.pinCode.';

    readonly TR_ERROR_MESSAGE_INVALID_PIN_CODE: string = this.TR_BASE + 'error.invalidPINCode';
    readonly TR_ERROR_MESSAGE_UNKNOWN: string = this.TR_BASE + 'error.unknown';

    @Input() styleClass?: string;

    error: any;
    errorMessage: string;

    loading?: boolean = false;

    private readonly PIN_CODE_LENGTH: number = 4;

    constructor(
        private environmentService: XSBaseEnvironmentService,
        private contextService: XSCoreContextService,
        private authenticationService: LCEUserMunicipalEmployeeAuthenticationService) {
    }

    ngOnInit() {
        //this.loading = true;
        //this.errorMessage = this.TR_ERROR_MESSAGE_INVALID_PIN_CODE;
    }

    public onPINCodeChange(pinCode: string | undefined): void {
        console.log('Pin Code : ' + pinCode);
        if (pinCode?.length !== this.PIN_CODE_LENGTH) return;
        this.loading = true;
        this.clearError();
        const authenticationRequest: XSAuthenticationPINCodeRequest = {pinCode: pinCode!};
        this.authenticationService.login(authenticationRequest)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: response => {
                    if (response.authenticated) {
                        LOG().debug(`Successfully Authenticated via PIN Code [user : ${response.response?.initialData.authenticatedUser?.code}]`);
                    } else {
                        this.errorMessage = this.TR_ERROR_MESSAGE_INVALID_PIN_CODE;
                    }
                },
                error: error => {
                    this.error = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE_UNKNOWN;
                }
            });
    }

    public canShowErrorDetailButton(): boolean {
        return this.contextService.getAppConfig().environment !== XSEnvironmentName.PROD &&
            (this.environmentService.getBuiltEnvironmentName() === XSBuiltEnvironmentName.LOCAL || this.environmentService.getBuiltEnvironmentName() === XSBuiltEnvironmentName.NOBACKEND);
    }

    private clearError(): void {
        this.error = undefined;
        this.errorMessage = XS_STR_EMPTY;
    }
}
