<div [ngClass]="{ 'xs-disabled': disabled }" class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-width-full">
        <xs-button
                (clickEvent)="doLockUser()"
                *ngIf="canAction('lock') && !isAccountStatusLocked()"
                [disabled]="isDisabled('lock')"
                [icon]="ICON.lock"
                [loading]="loading.lock"
                [style.margin-left]="spaceBetween"
                iconStyleClass="xs-font-size-13-imp"
                label="{{ TR_BASE }}lockUser"
                loaderContainerStyleClass="xs-mt-minus-1"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
        <xs-button
                (clickEvent)="doUnlockUser()"
                *ngIf="canAction('unlock') && isAccountStatusLocked()"
                [disabled]="isDisabled('unlock')"
                [icon]="ICON.unlock"
                [loading]="loading.unlock"
                [style.margin-left]="spaceBetween"
                iconStyleClass="xs-font-size-13-imp"
                label="{{ TR_BASE }}unlockUser"
                loaderContainerStyleClass="xs-mt-minus-1"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
    </div>
    
    <xs-button
            (clickEvent)="onMenuClick($event)"
            [disabled]="menuDisabled"
            [icon]="ICON.ellipsisVertical"
            [loading]="auditLoading"
            [style.margin-left]="spaceBetween"
            loaderContainerStyleClass="xs-mt-minus-1"
            loaderPosition="bottom"
            styleClass="{{ buttonStyleClass }}"
            type="text">
    </xs-button>
    <p-tieredMenu #actionMenu [appendTo]="'body'" [model]="menuItems" [popup]="true" styleClass="xs-min-width-225"></p-tieredMenu>
</div>