<div class="xs-width-full xs-height-full xs-flex-row xs-justify-content-center {{ styleClass }}">
    <div class="xs-card xs-width-full xs-align-items-center">
        <div class="xs-card-content xs-position-relative xs-align-items-center xs-min-width-500 xs-min-height-500 xs-width-full xs-max-width-1500 xs-pt-40 {{ contentStyleClass }} {{ options.contentStyleClass }}">
            <div class="xs-absolute-center-vh">
                <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
            </div>
            <div *ngIf="!hasError() && !hasResults() && !loader.isRunning()" class="xs-absolute-center-h xs-flex-row-center xs-mt-100">
                <xs-no-result-found></xs-no-result-found>
            </div>

            <div *ngIf="hasError() && !loader.isRunning()" class="xs-absolute-center-vh">
                <xs-error
                        [actionButton]="errorRetryButton"
                        [error]="error"
                        [showActionButton]="true"
                        [showErrorDetailButton]="true"
                        errorDetailButtonClass="xs-mt-5-imp"
                        message="{{ TR_BASE }}errorMessage"
                        mode="block"
                        subMessage="xs.core.error.contactAdminMessage">
                </xs-error>
            </div>
            <div *ngIf="!hasError() && hasResults()" class="xs-flex-column-center xs-width-full ">
                <span class="xs-display-block xs-font-size-medium xs-color-secondary xs-text-align-center xs-font-weight-500 xs-mb-30">
                    {{  options.captionNResultFound | translate : {nResults: totalResults}  }}
                </span>

                <div *ngIf="!options.biColumnResults!" class="xs-width-full xs-flex-row xs-justify-content-center">
                    <ul
                            *ngIf="(results | xsIsNotEmpty)"
                            class="xs-listing xs-listing-separator xs-width-full xs-max-width-750 {{ options.listingStyleClass }}">
                        <li
                                (dblclick)="onDoubleClick(oneResult)"
                                *ngFor="let oneResult of results"
                                class="xs-listing-item xs-cursor-pointer {{ options.listingItemStyleClass }}">
                            <ng-container *ngTemplateOutlet="rowResultTemplateRef; context: { $implicit: oneResult }"></ng-container>
                        </li>
                    </ul>
                </div>

                <div *ngIf="options.biColumnResults!" class="xs-width-full xs-flex-row xs-justify-content-center">

                    <div class="grid xs-width-full">
                        <div
                                [ngClass]="{ 'lg:col-6 xs-lg-pr-50': (rightColumnResults | xsIsNotEmpty), 'xs-flex xs-justify-content-center':  (rightColumnResults | xsIsEmpty)}"
                                class="col-12">
                            <ul class="xs-listing xs-listing-separator xs-pt-5-imp xs-width-full xs-max-width-750 {{ options.listingStyleClass }}">
                                <li
                                        (dblclick)="onDoubleClick(oneResult)"
                                        *ngFor="let oneResult of leftColumnResults"
                                        class="xs-listing-item xs-cursor-pointer {{ options.listingItemStyleClass }}">
                                    <ng-container *ngTemplateOutlet="rowResultTemplateRef; context: { $implicit: oneResult }"></ng-container>
                                </li>
                            </ul>

                        </div>
                        <div *ngIf="(rightColumnResults | xsIsNotEmpty)" class="col-12 lg:col-6 xs-lg-pl-50 xs-lg-border-left-discrete">
                            <ul class="xs-listing xs-listing-separator xs-pt-5-imp xs-width-full xs-max-width-750 xs-md-border-top-discrete xs-lg-border-top-none-imp {{ options.listingStyleClass }}">
                                <li
                                        (dblclick)="onDoubleClick(oneResult)"
                                        *ngFor="let oneResult of rightColumnResults"
                                        class="xs-listing-item xs-cursor-pointer {{ options.listingItemStyleClass }}">
                                    <ng-container *ngTemplateOutlet="rowResultTemplateRef; context: { $implicit: oneResult }"></ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div *ngIf="!hasError() && hasResults() && isPaginable()" class="xs-card-footer xs-flex-row xs-justify-content-center xs-width-full">
            <p-paginator (onPageChange)="handlePagination($event)" [rows]="paginationSize!" [totalRecords]="totalResults"></p-paginator>
        </div>
    </div>
</div>
