import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {XSButton, XSInputFieldAmountOptions, XSInputFieldTextOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderPriceSettings, LCECertificateOrderPriceTripDefinition, LCECertificateOrderService} from '@lce/core';
import {finalize} from 'rxjs/operators';
import {LOG, XSAmount, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({selector: 'lce-certificate-orders-price-settings', templateUrl: './lce-certificate-orders-price-settings.component.html'})
export class LCECertificateOrdersPriceSettingsComponent implements OnInit, OnDestroy {

    readonly TR_BASE: string = 'lce.shared.certificateOrders.';

    @Input() styleClass?: string;

    loaderID: any = {
        main: 'mainLoaderID',
        refresh: 'refreshLoaderID',

        unitPrice: 'unitPriceID',
        tax: 'taxLoaderID',
        fees: 'feesLoaderID',
        dhl: 'DHLLoaderID',
        ups: 'UPSLoaderID',
        servicePointDeliveryFees: 'servicePointDeliveryFeesLoaderID'
    };

    error: any = {
        main: undefined,

        unitPrice: undefined,
        tax: undefined,
        fees: undefined,
        dhl: undefined,
        ups: undefined,
        servicePointDeliveryFees: undefined
    };

    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: LCE_SHARED_ICON.redo,
        onClick: () => this.retrieveSettings()
    };

    priceSettings: LCECertificateOrderPriceSettings;

    formGroup = new FormGroup({});
    tripDefinitionTitleField: XSInputFieldTextOptions;
    tripDefinitionStartField: XSInputFieldTextOptions;
    tripDefinitionEndField: XSInputFieldTextOptions;
    tripDefinitionPriceField: XSInputFieldAmountOptions;

    private subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private certificateOrderService: LCECertificateOrderService) {
    }

    ngOnInit(): void {
        this.buildFields();
        this.retrieveSettings();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.priceSettings);
    }

    public onDeleteTripDefinition(tripDefinition: LCECertificateOrderPriceTripDefinition): void {

    }

    public onSaveTripDefinition(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        const formData = this.formGroup.value;
        const tripDefinitionShort = this.buildTripDefinitionShort(formData);
        this.save('tripDefinitions', tripDefinitionShort);

        console.log('|=== On save trip definition ===|');
    }

    public update(): void {
        this.retrieveSettings(this.loaderID.refresh);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error?.main);
    }

    public onPriceChange(name: string, event: XSAmount): void {
        this.save(name, event);
    }

    private retrieveSettings(loaderIDStr?: string): void {
        const lID = XSUtils.isEmpty(loaderIDStr) ? this.loaderID.main : loaderIDStr!;
        this.loaderService.startLoader(lID);
        this.subscription.add(
            this.certificateOrderService
                .retrieveSettings()
                .pipe(finalize(() => this.loaderService.stopLoader(lID)))
                .subscribe({
                    next: (pSettings) => {
                        this.priceSettings = pSettings;
                        console.log('priceSettings ', this.priceSettings);
                    },
                    error: (error) => (this.error.main = error)
                })
        );
    }

    private isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.loaderID.main);
    }

    private save(key: string, value: any): void {
        const fieldValueMap = new Map<string, any>();
        fieldValueMap.set(key, value);
        this.loaderService.startLoader(this.loaderID[key]);
        this.subscription.add(
            this.certificateOrderService
                .updatePriceSettings(fieldValueMap)
                .pipe(finalize(() => this.loaderService.stopLoader(this.loaderID[key])))
                .subscribe({
                    next: (savedSettings) => {
                        console.log('priceSettings ', savedSettings);
                        LOG().debug('Price settings successfully updated :-) ', savedSettings);
                        this.priceSettings = savedSettings;
                    },
                    error: (error) => (this.error[key] = error)
                })
        );
    }

    private buildFields(): void {

        this.tripDefinitionTitleField = {
            fieldName: 'title',
            control: new FormControl(undefined, Validators.required),
            inputContainerStyleClass: 'xs-input-just-border-bottom'
        };

        this.tripDefinitionStartField = {
            fieldName: 'start',
            control: new FormControl(undefined, Validators.required),
            type: 'number',
            inputContainerStyleClass: 'xs-input-just-border-bottom'
        };

        this.tripDefinitionEndField = {
            fieldName: 'end',
            control: new FormControl(undefined, Validators.required),
            type: 'number',
            inputContainerStyleClass: 'xs-input-just-border-bottom'
        };

        this.tripDefinitionPriceField = {
            fieldName: 'price',
            control: new FormControl(undefined, Validators.required),
            currency: 'XOF',
            inputContainerStyleClass: 'xs-input-just-border-bottom'
        };

        this.formGroup.addControl(this.tripDefinitionTitleField.fieldName, this.tripDefinitionTitleField.control!);
        this.formGroup.addControl(this.tripDefinitionStartField.fieldName, this.tripDefinitionStartField.control!);
        this.formGroup.addControl(this.tripDefinitionEndField.fieldName, this.tripDefinitionEndField.control!);
        this.formGroup.addControl(this.tripDefinitionPriceField.fieldName, this.tripDefinitionPriceField.control!);
    }

    private buildTripDefinitionShort(formData: any): { title: string, start: number, end: number, price: XSAmount } {
        return {
            title: formData.title,
            start: formData.start,
            end: formData.end,
            price: formData.price
        };
    }
}