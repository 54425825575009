import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCEResourceType, LCESmartCityArticleService} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCESmartCityArticleDialogService} from '../../../smartcity/lce-smartcity-article-dialog.service';

@Component({
    selector: 'lce-global-search-smartcity-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-smartcity-article-partial [data]="oneResult" [showAvatar]="true" [showOverlay]="false" [showDescription]="true" avatarSize="medium">
                </lce-smartcity-article-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCESmartCityArticleDialogService]
})
export class LCEGlobalSearchSmartCityTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.SMARTCITY_ARTICLE;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions = {
        search: searchFilter => this.smartCityArticleService.search(searchFilter),
        openRecord: articlePartial => this.smartCityArticleDialogService.openArticleDialog({articleID: articlePartial.id}),
        captionNResultFound: 'lce.shared.smartcity.articles.resultsFound',
        biColumnResults: true,
        defaultPaginationSize: 10,
        columnResultSize: 5
    }

    constructor(private smartCityArticleService: LCESmartCityArticleService, private smartCityArticleDialogService: LCESmartCityArticleDialogService) {
    }
}
