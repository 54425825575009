import {Component, Input, OnInit} from '@angular/core';
import {LCECoreContextService, LCEUserPartial} from '@lce/core';
import {XSAuthenticationBaseService} from '@xs/core';
import {LCEUserAccountDialogService} from '../lce-user-account-dialog.service';
import {XSUtils} from '@xs/base';

@Component({
    selector: 'lce-user-account-avatar',
    templateUrl: './lce-user-account-avatar.component.html',
    providers: [LCEUserAccountDialogService]
})
export class LCEUserAccountAvatarComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() overlayStyleClass?: string;

    @Input() viewAccount?: boolean;
    @Input() showLogoutIcon?: boolean;

    user: LCEUserPartial;

    loggingOut: boolean = false;

    constructor(
        private contextService: LCECoreContextService,
        private authenticationService: XSAuthenticationBaseService,
        private userAccountDialogService: LCEUserAccountDialogService) {
    }

    ngOnInit(): void {
        this.user = this.contextService.getUser();
        if (XSUtils.isNull(this.viewAccount)) this.viewAccount = false;
        if (XSUtils.isNull(this.showLogoutIcon)) this.showLogoutIcon = false;
    }

    public openAccountDialog(): void {
        this.userAccountDialogService.openRecordDialog({userID: this.user.id, userType: this.user.type, header: false});
    }

    public onLogout(): void {
        this.loggingOut = true;
        this.authenticationService.logout();
    }
}
