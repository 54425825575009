import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCESmartCityArticleCard, LCESmartCityArticlePredefinedFilter, LCESmartCityArticleSearch, LCESmartCityArticleService, LCESmartCityArticleType} from '@lce/core';
import {XSPagination, XSUtils} from '@xs/base';

import {XSLabelValueItem, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSChip, XSDeviceDetectorService} from '@xs/core';
import {Paginator} from 'primeng/paginator/paginator';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESmartCityFeatureService} from '../lce-smartcity-feature.service';

@Component({
    selector: 'lce-smartcity-articles',
    templateUrl: './lce-smartcity-articles.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCESmartCityArticlesComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.smartcity.articles.';

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 3;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_ROWS: number = 6;
    readonly PAGINATOR_ROWS_PER_PAGE_OPTIONS: number[] = [6, 12, 24];

    readonly PRIMARY_LOADER_ID = XSUtils.uuid();
    readonly SECONDARY_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;

    @ViewChild('pPaginator') pPaginatorComponent: Paginator;

    searchText: string;

    nArticles: number = 0;
    articles: LCESmartCityArticleCard[] = [];
    resultSubText: string;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    firstInitialization: boolean = true;

    typeFilters: LCESmartCityArticleType[];
    typesFilterOptions: XSLabelValueItem[] = [
        {trLabel: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.NOTIFICATION, value: LCESmartCityArticleType.NOTIFICATION},
        {trLabel: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.AMBER_ALERT, value: LCESmartCityArticleType.AMBER_ALERT},
        {trLabel: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.PUBLIC_EVENT, value: LCESmartCityArticleType.PUBLIC_EVENT}
    ];

    pagination: XSPagination = {page: 0, size: this.PAGINATOR_ROWS};
    articleSearch: LCESmartCityArticleSearch = {paginationPage: this.pagination.page, paginationSize: this.pagination.size};

    predefinedFilter: LCESmartCityArticlePredefinedFilter = LCESmartCityArticlePredefinedFilter.LATEST;
    articleTypeChips: XSChip[] = [
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.NOTIFICATION, value: LCESmartCityArticleType.NOTIFICATION},
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.AMBER_ALERT, value: LCESmartCityArticleType.AMBER_ALERT},
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.PUBLIC_EVENT, value: LCESmartCityArticleType.AMBER_ALERT}];

    isMobile: boolean;

    private readonly TR_BASE_PREDEFINED_FILTER: string = 'lce.shared.smartcity.predefinedFilter.';
    predefinedFilterOptions: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.LATEST, value: LCESmartCityArticlePredefinedFilter.LATEST},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.POPULAR, value: LCESmartCityArticlePredefinedFilter.POPULAR},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_READ, value: LCESmartCityArticlePredefinedFilter.MOST_READ},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_SCANNED, value: LCESmartCityArticlePredefinedFilter.MOST_SCANNED},
        {trLabel: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_SHARED, value: LCESmartCityArticlePredefinedFilter.MOST_SHARED}
    ];
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translationService: XSTranslationService,
        private deviceDetectorService: XSDeviceDetectorService,
        private loaderService: XSLoaderService,
        private smartCityArticleService: LCESmartCityArticleService,
        private featureService: LCESmartCityFeatureService) {

        this.featureService.state.title = this.TR_BASE + 'title';
        this.featureService.state.subTitle = this.TR_BASE + 'subTitle';
        this.featureService.state.showNewArticleButton = true;
        this.featureService.state.showRefreshButton = true;
        this.featureService.state.showBackButton = false;
        this.featureService.onRefresh.subscribe(() => this.update());
        this.isMobile = this.deviceDetectorService.isMobile();

        this.initializeTranslation();
        this.handleRouting();
    }

    ngOnInit(): void {
        this.search();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public update(): void {
        this.search();
    }

    public onInputSearchChange(): void {
        this.search();
    }

    public onPredefinedFilterChange(): void {
        this.resetPagination();
        this.search();
    }

    public onPaginationNumberChange() {
        this.search();
    }

    public handlePagination(event: any) {
        this.pagination.page = event.page;
        this.pagination.size = event.rows;
        this.search();
    }

    public isPaginable(): boolean {
        return this.nArticles > this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[0];
    }

    public isSecondaryLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID);
    }

    private resetPagination(): void {
        this.pagination.page = 0;
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.typesFilterOptions);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.typesFilterOptions);
                this.typesFilterOptions = [...this.typesFilterOptions];
            })
        );

        this.translationService.translateItems(this.predefinedFilterOptions);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.predefinedFilterOptions);
                this.predefinedFilterOptions = [...this.predefinedFilterOptions];
            })
        );
    }

    private search(): void {
        this.startLoader();
        this.error = undefined;
        this.buildSearch();
        this.subscription.add(this.smartCityArticleService
            .searchAsCards(this.articleSearch)
            .pipe(finalize(() => this.stopLoader()))
            .subscribe({
                next: searchResult => {
                    this.articles = searchResult.data;
                    this.nArticles = searchResult.total;
                    this.firstInitialization = false;
                    this.buildResultSubText();
                },
                error: error => this.error = error
            })
        );
    }

    private buildSearch(): void {
        this.articleSearch = {
            query: this.searchText?.trim(),
            types: this.typeFilters,
            paginationPage: this.pagination.page,
            paginationSize: this.pagination.size
        };
        XSUtils.removeNullAndUndefinedEntries(this.articleSearch);
        XSUtils.removeEmptyObjectEntries(this.articleSearch);
    }

    private startLoader(): void {
        if (this.firstInitialization) {
            this.loaderService.startLoader(this.PRIMARY_LOADER_ID);
        } else {
            this.loaderService.startLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private stopLoader(): void {
        if (this.loaderService.isLoaderRunning(this.PRIMARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.PRIMARY_LOADER_ID);
        }
        if (this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private buildResultSubText(): void {
        // TODO: Build custom message depending of the filters below the total number of results.
        //const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        this.resultSubText = 'Articles les plus scannés depuis le début de l\'année 2023';
    }

    private handleRouting(): void {
        const routerStateData = this.router.getCurrentNavigation()?.extras?.state;
        if (!XSUtils.isEmpty(routerStateData?.type)) {
            this.typeFilters = [this.toType(routerStateData!.type)];
        }

        const paramType = this.activatedRoute.snapshot.queryParamMap.get('type');
        if (!XSUtils.isEmpty(paramType)) {
            this.typeFilters = [this.toType(paramType)];
        }
    }

    private toType(paramType: any): LCESmartCityArticleType {
        const pType = XSUtils.toEnum<LCESmartCityArticleType>(paramType, LCESmartCityArticleType);
        if (XSUtils.isEmpty(pType)) {
            throw new Error(`failed to cast parameter [${paramType}] to LCESmartCityArticleType.`);
        }
        return pType!;
    }
}
