<div class="lce-template-sheldon-header">
    <div class="grid grid-nogutter xs-width-full">
        <div class="col-2 xs-flex-row xs-align-items-center xs-justify-content-start">

            <lce-context-menu [appSecondaryName]="APP_SECONDARY_NAME"></lce-context-menu>

            <div *ngIf="municipalityName | xsIsNotEmpty" class="xs-flex-column xs-ml-15">
				<span class="xs-display-block xs-font-size-small xs-color-secondary xs-mb-1">
					{{ 'lce.core.label.municipality' | translate }}
				</span>
                <span class="xs-display-block xs-font-size-intermediate xs-color-secondary">{{ municipalityName }}</span>
            </div>
        </div>

        <div class="col-10 xs-flex-row xs-align-items-center xs-justify-content-end">
            <lce-global-search-input class="xs-width-full xs-max-width-400 xs-min-width-150 xs-mr-0 md:mr-6 sm:block hidden"></lce-global-search-input>

            <lce-certificate-orders-pending-orders-indicator
                    (clickEvent)="goToCertificatePendingOrders()"
                    [municipalityCode]="municipalityCode"
                    [showBottomLabel]="false"
                    styleClass="xs-ml-40">
            </lce-certificate-orders-pending-orders-indicator>

            <!--<lce-notifications styleClass="xs-ml-40"></lce-notifications>-->

            <lce-websocket-connection-status styleClass="xs-ml-40"></lce-websocket-connection-status>

            <xs-health-indicator-icon
                    [options]="healthIndicatorOptions"
                    iconSize="18px"
                    openMode="overlay"
                    overlayStyleClass="xs-mt-33-imp"
                    styleClass="xs-ml-50 xs-mt-minus-15">
            </xs-health-indicator-icon>

            <lce-user-account-avatar
                    [showLogoutIcon]="true"
                    [viewAccount]="true"
                    overlayStyleClass="lce-template-sheldon-user-menu-overlay"
                    styleClass="xs-ml-40">
            </lce-user-account-avatar>
        </div>
    </div>
</div>
