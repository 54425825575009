import {Injectable} from '@angular/core';
import {XSPKResourceService} from '@xs/base';
import {LCE_SHARED_ENDPOINT} from '../constants/lce-shared-endpoint.constant';
import {LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttemptSearch} from '../domain/lce-authentication-login-attempt';
import {LCEHttpClientService} from '@lce/core';

@Injectable({providedIn: 'root'})
export class LCEAuthenticationLoginAttemptService extends XSPKResourceService<LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttemptSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_SHARED_ENDPOINT.authenticationLoginAttempts.index, 'authenticationLoginAttempt');
    }
}
