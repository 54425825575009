import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {XSUtils} from '@xs/base';
import {LCECertificateOrderProcessOptions} from '@lce/shared';

@Component({
    selector: 'lce-showcase-certificate-order-process-page',
    template: `
        <div class="xs-background-white lce-no-template xs-p-10">
            <lce-certificate-order-process [options]="options"></lce-certificate-order-process>
        </div>
    `,
    host: {class: 'xs-flex-column xs-width-full xs-height-full'}
})
export class LCEShowcaseCertificateOrderProcessPageComponent {

    options: LCECertificateOrderProcessOptions;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (XSUtils.isEmpty(params)) return;
            this.options = {countryISO: params.countryISO, showFillFormButton: true};
        });
    }


}