<div
        [ngClass]="{ 'xs-border-discrete xs-p-15 xs-border-radius-default': showBorder === true }"
        class="xs-flex-column xs-position-relative xs-width-full xs-min-height-700 {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #paymentChargeLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !paymentChargeLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="xs.core.dataManager.errorMessageRetrieveRecord"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div class="xs-card-header">
            <lce-event-header [resourceType]="resourceType" [subTitle]="eventType" [title]="eventName" iconStyleClass="{{ canDisplayData() ? buildIconColor() : '' }}"></lce-event-header>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content xs-pt-25-imp">
            <div class="grid xs-fields xs-fields-spacer-15">
                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.eventName'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <lce-event-name-type [eventName]="data!.eventName" [type]="data!.type"></lce-event-name-type>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-label [label]="TR_BASE + 'label.resource'" styleClass="xs-mb-6"></xs-data-field-label>
                    <div class="xs-data-field">
                        <lce-event-resource [data]="{ resourceID: data!.resourceID, resourceCode: data!.resourceCode, resourceType: data!.resourceType }"></lce-event-resource>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.districtCodes'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <div *ngFor="let code of data!.districtCodes" class="xs-flex-wrap">
                                <span class="xs-display-block xs-data-field-value xs-font-size-intermediate xs-mb-4">{{ code }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.notifiable'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <span class="xs-display-block xs-data-field-value">{{ 'xs.core.label.' + data!.notifiable | translate }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.municipalityCodes'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <div *ngFor="let code of data!.municipalityCodes" class="xs-flex-wrap">
                                <span class="xs-display-block xs-data-field-value xs-font-size-intermediate xs-mb-4">{{ code }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.notificationDelayInSeconds'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <span class="xs-display-block xs-data-field-value">{{ data!.notificationDelayInSeconds }} {{ 'xs.core.label.seconds' | translate }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.facilityCodes'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <div *ngFor="let code of data!.facilityCodes" class="xs-flex-wrap">
                                <span class="xs-display-block xs-data-field-value xs-font-size-intermediate xs-mb-4">{{ code }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.notificationChannels'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <div *ngFor="let notificationChannel of data!.notificationChannels" class="xs-flex-wrap">
                                <span class="xs-data-field-value xs-display-block xs-font-size-intermediate xs-mb-4">
                                    {{ TR_BASE + 'notificationChannel.' + notificationChannel | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'label.notificationProcessed'" styleClass="xs-mb-6"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <span class="xs-display-block xs-data-field-value">{{ 'xs.core.label.' + data!.notificationProcessed | translate }}</span>
                            <xs-data-calendar [options]="calendarOptions" [value]="data!.notificationProcessedOn" styleClass="xs-font-size-small xs-color-secondary"></xs-data-calendar>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <xs-title [text]="TR_BASE + 'label.data'" styleClass="xs-mtb-20"></xs-title>
                    <div>
                        <lce-event-data [data]="data!.data" [resourceType]="data!.resourceType"></lce-event-data>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-card-footer">
            <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end">
                <xs-data-id [value]="data!.id"></xs-data-id>
            </div>
        </div>
    </div>
</div>
