<div [ngClass]="{'xs-border-discrete xs-border-radius-default xs-p-10': showBorder}"
     class="xs-flex-column xs-position-relative xs-width-full xs-min-width-350 xs-max-width-800 {{styleClass}} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe
                    subtitle="{{(facility | xsIsNotEmpty) ? facility.fullName : '...'}}"
                    title="lce.shared.facility.townHallStampLoad.main.title">
            </xs-header-stripe>
        </div>

        <div class="xs-card-content">
            <div class="xs-flex-row xs-flex-wrap xs-justify-content-space-between xs-align-items-center xs-height-65 xs-mb-20">
                <lce-facility-town-hall-stamp-remaining
                        *ngIf="facility"
                        [townHallFullName]="facility.fullName">
                </lce-facility-town-hall-stamp-remaining>
                <lce-facility-town-hall-stamp-load-last [facilityID]="facility.id" styleClass="xs-min-width-300"></lce-facility-town-hall-stamp-load-last>
            </div>
            <div class="xs-flex-row xs-justify-content-center xs-mt-40">
                <div class="grid xs-fields xs-fields-spacer-15 xs-max-width-600">
                    <div class="col-12 md:col-4">
                        <xs-input-field-text
                                [disabled]="isLoaderRunning()"
                                [options]="stampsTokensField"
                                styleClass="xs-mb-5">
                        </xs-input-field-text>

                    </div>
                    <div class="col-12 md:col-8">
                        <xs-input-field-textarea
                                [disabled]="isLoaderRunning()"
                                [options]="noteField">
                        </xs-input-field-textarea>
                    </div>

                </div>
            </div>
            <div class="xs-width-full xs-mtb-25">
                <xs-input-field-checkbox
                        [disabled]="isLoaderRunning()"
                        [options]="unloadField"
                >
                </xs-input-field-checkbox>
            </div>
        </div>

        <div *ngIf="!isDialog()" class="xs-card-footer">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>

    </div>
</div>
<ng-template #dHeader>
    <xs-header-stripe subtitle="{{(facility | xsIsNotEmpty) ? facility.fullName : '...'}}" title="lce.shared.facility.townHallStampLoad.main.title"></xs-header-stripe>
</ng-template>

<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasError() }" class="xs-flex-row xs-align-items-center xs-justify-content-end">

        <xs-error *ngIf="hasError() && !loader.isRunning()" [actionButton]="errorRetryButton" [ellipsis]="true" [error]="error"
                  [showActionButton]="true" [showErrorDetailButton]="true" message="lce.shared.facility.townHallStampLoad.label.errorMessageCreate" mode="block"
                  styleClass="xs-max-width-500" subMessage="xs.core.error.contactAdminMessage">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center xs-justify-content-end">
            <xs-button
                    (clickEvent)="reset()"
                    [confirmation]="resetConfirmation"
                    [disabled]="loader.isRunning()"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate" type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="loader.isRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.close"
                    severity="secondary"
                    styleClass="xs-mlr-15-imp xs-button-small"
                    type="button">
            </xs-button>
            <xs-button
                    (clickEvent)="save()"
                    [disabled]="loader.isRunning()"
                    [loading]="loader.isRunning()"
                    label="xs.core.label.save"
                    styleClass="xs-button-small"
                    type="button">
            </xs-button>
        </div>

    </div>
</ng-template>
