<div [ngClass]="{'xs-border-discrete xs-border-radius-default xs-p-10': showBorder, ' xs-border-radius-default xs-p-10 xs-background-color-discrete': showBackgroundColor}" [style.filter]="'brightness(106%)'"
     class="xs-position-relative xs-width-full xs-min-width-400  {{styleClass}}">
    <div class="xs-absolute-center-vh xs-min-width-200">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="20"></xs-loader>
    </div>
    <div *ngIf="hasError() && !loader.isRunning()">
        <xs-error
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}paymentTransfer.errorMessageLastPaymentRetrieve"
                mode="inline"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <xs-payment-transfer-partial
            *ngIf="canDisplayData()"
            [data]="data"
            [showLabel]="true" label="lce.shared.facility.paymentTransfer.lastTransfer">
    </xs-payment-transfer-partial>
</div>
