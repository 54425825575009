import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCERequestorType, LCEUserCustomerPartial, LCEUserCustomerSearch, LCEUserCustomerState} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSIntegerRange, XSPagination, XSPKDTOStats, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEUserCustomersFeatureService} from './lce-user-customers-feature.service';
import {LCEUserCustomerStatisticsComponent} from './statistic/lce-user-customer-statistics.component';

@Component({
    selector: 'lce-user-customers',
    templateUrl: './lce-user-customers.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEUserCustomersFeatureService]
})
export class LCEUserCustomersComponent implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.customer.';
    readonly TR_BASE_REQUESTOR_TYPE: string = 'lce.core.requestorType.';
    readonly TR_BASE_STATE: string = this.TR_BASE + 'state.';

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_TOTAL_RECORDS: number = 100;

    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @ViewChild('statistics') statistics: LCEUserCustomerStatisticsComponent;

    // --- Search ---
    searchText: string;
    searching: boolean;

    // --- Filters ---
    requestorTypeFilter?: LCERequestorType[];
    requestorTypeFilterItems: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_REQUESTOR_TYPE + LCERequestorType.WEBSITE_APP, value: LCERequestorType.WEBSITE_APP},
        {trLabel: this.TR_BASE_REQUESTOR_TYPE + LCERequestorType.PUBLIC_MOBILE_APP, value: LCERequestorType.PUBLIC_MOBILE_APP},
        {trLabel: this.TR_BASE_REQUESTOR_TYPE + LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP, value: LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP}
    ];

    customerStateFilter: string | LCEUserCustomerState = 'all';
    customerStateFilterItems: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_STATE + 'all', value: 'all'},
        {trLabel: this.TR_BASE_STATE + 'registered', value: LCEUserCustomerState.REGISTERED},
        {trLabel: this.TR_BASE_STATE + 'unregistered', value: LCEUserCustomerState.UNREGISTERED},
        {trLabel: this.TR_BASE_STATE + 'orderedAtLeastOnce', value: LCEUserCustomerState.ORDERED_AT_LEAST_ONCE},
        {trLabel: this.TR_BASE_STATE + 'neverOrdered', value: LCEUserCustomerState.NEVER_ORDERED}
    ];

    // --- Table ---
    tableData: LCEUserCustomerPartial[] = [];
    tableNResults: number = 0;
    tableCaption: XSText = {value: this.TR_BASE + 'table.caption'};
    tableSubCaption: XSText;
    tableLoadingState: XSLoaderState;
    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    private subscription: Subscription = new Subscription();

    private pagination: XSPagination = {page: 0, size: this.PAGINATOR_TOTAL_RECORDS};

    customerSearch: LCEUserCustomerSearch = {paginationPage: this.pagination.page, paginationSize: this.pagination.size};

    constructor(
        private translationService: XSTranslationService,
        private featureService: LCEUserCustomersFeatureService) {
        this.initializeTranslation();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.update()));
        this.subscription.add(this.featureService.onStartSearch.subscribe(() => this.handleStartSearch()));
        this.subscription.add(this.featureService.onEndSearch.subscribe((searchResults) => this.handleEndSearch(searchResults)));
    }

    ngOnInit(): void {
        this.update(false);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onAdditionalStatsClickEvent(state: LCEUserCustomerState): void {
        this.customerStateFilter = state;
        this.search();
    }

    public onSort(sort: XSSort): void {
        this.featureService.sort = sort;
        this.featureService.pagination.page = 1;
        this.search();
    }

    public onResetSort(): void {
        this.featureService.sort = undefined;
        this.search();
    }

    public onPagination(pagination: XSPagination): void {
        this.featureService.pagination = pagination;
        this.search();
    }

    public onResetPreferences(): void {
        this.search();
    }

    public search(): void {
        this.customerSearch.query = !XSUtils.isEmpty(this.searchText) ? this.processSearchText(this.searchText) : XS_STR_EMPTY;

        if (!XSUtils.isEmpty(this.requestorTypeFilter)) {
            this.customerSearch.registeredFrom = this.requestorTypeFilter;
            this.customerSearch.createdFrom = this.requestorTypeFilter;
        } else {
            this.customerSearch.registeredFrom = [];
            this.customerSearch.createdFrom = [];
        }

        this.handleStateFilter();

        this.featureService.search(this.customerSearch).subscribe();
    }

    public update(doUpdateStatistics: boolean = true): void {
        this.search();
        if (this.statistics && doUpdateStatistics) {
            this.statistics.update();
        }
    }

    private handleStateFilter(): void {
        this.customerSearch.registered = undefined;
        this.customerSearch.numberOfOrdersRange = undefined;

        if (this.customerStateFilter === LCEUserCustomerState.REGISTERED) {
            this.customerSearch.registered = true;
        }
        if (this.customerStateFilter === LCEUserCustomerState.UNREGISTERED) {
            this.customerSearch.registered = false;
        }
        if (this.customerStateFilter === LCEUserCustomerState.ORDERED_AT_LEAST_ONCE) {
            this.customerSearch.numberOfOrdersRange = new XSIntegerRange(1, undefined);
        }
        if (this.customerStateFilter === LCEUserCustomerState.NEVER_ORDERED) {
            this.customerSearch.numberOfOrdersRange = new XSIntegerRange(0, 0);
        }
    }

    private handleEndSearch(searchResults: XSSearchResult<LCEUserCustomerPartial>): void {
        this.tableData = searchResults.data;
        this.tableNResults = searchResults.total;
        this.tableCaption = {value: this.TR_BASE + 'table.caption'};

        this.searching = false;
        this.tableLoadingState = {state: false};

        LOG().debug(this.tableNResults + ' Customer(s) Found.');
    }

    private handleStartSearch(loadingText?: string): void {
        this.searching = true;
        this.tableLoadingState = {state: true, text: loadingText};
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.requestorTypeFilterItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.requestorTypeFilterItems);
                this.requestorTypeFilterItems = [...this.requestorTypeFilterItems];
            })
        );
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.customerStateFilterItems);
                this.customerStateFilterItems = [...this.customerStateFilterItems];
            })
        );
    }

    private processSearchText(text: string): string {
        let finalText = text.trim();

        if (XSUtils.containsOnlyDigits(finalText.replace(new RegExp('\\+', 'g'), XS_STR_EMPTY).replace(new RegExp('-', 'g'), XS_STR_EMPTY), true)) {
            finalText = XSUtils.removeWhiteSpaces(finalText).replace(new RegExp('-', 'g'), XS_STR_EMPTY);
        }

        return finalText;
    }
}
