<div class="xs-position-relative">
    <xs-table
            (onPagination)="paginationEvent.emit($event)"
            (onResetPreferences)="resetPreferencesEvent.emit()"
            (onResetSort)="resetSortEvent.emit()"
            (onRowDoubleClick)="onViewCustomer($event)"
            (onSort)="sortEvent.emit($event)"
            [caption]="caption"
            [columns]="columns"
            [data]="data"
            [emptyMessage]="emptyMessage"
            [loadingState]="loadingState"
            [nRecords]="nResults"
            [options]="options"
            [subCaption]="subCaption"
            captionStyleClass="xs-font-size-medium xs-font-weight-500"
            columnsStyleClass="xs-color-secondary xs-font-size-percent-90"
            subCaptionStyleClass="xs-font-size-percent-90"
            tableStyleClass="xs-table-border xs-table-border-discrete">

        <ng-template let-oneData xsTemplate="code">
            <div class="xs-flex-column">
                <xs-data-field-identifier
                        (clickEvent)="onViewCustomer(oneData)"
                        [belowText]="oneData.batchProcessCode"
                        [copyable]="false"
                        [ellipsis]="false"
                        [required]="true"
                        [testColored]="true"
                        [value]="oneData.code"
                        belowTextStyleClass="xs-font-size-8-imp xs-color-secondary xs-text-transform-uppercase"
                        type="code"
                        valueStyleClass="xs-font-size-intermediate">
                </xs-data-field-identifier>
            </div>
        </ng-template>

        <ng-template let-oneData xsTemplate="name">
            <span *ngIf="oneData.name | xsIsNotEmpty" class="xs-font-size-default">{{ oneData.name | xsPersonName }}</span>
            <span *ngIf="oneData.name | xsIsEmpty">---</span>
        </ng-template>

        <ng-template let-oneData xsTemplate="contact">
            <xs-data-field-contact
                    [value]="{ primaryPhoneNumber: oneData.primaryPhoneNumber, email: oneData.email }"
                    emailStyleClass="xs-font-weight-normal-imp"
                    primaryPhoneNumberStyleClass="xs-font-weight-normal-imp">
            </xs-data-field-contact>
        </ng-template>

        <ng-template let-oneData xsTemplate="created">
            <div class="xs-flex-column">
                <span [innerHTML]="'lce.core.requestorType.' + oneData.createdFrom | translate" class="xs-text-block-intermediate-secondary-500"></span>
                <xs-time-ago [givenDate]="oneData!.createdOn" [live]="true" styleClass="xs-text-block-small-secondary xs-mt-6"></xs-time-ago>
            </div>
        </ng-template>

        <ng-template let-oneData xsTemplate="registered">
            <div *ngIf="oneData.registeredOn | xsIsNotEmpty" class="xs-flex-column">
                <span [innerHTML]="'lce.core.requestorType.' + oneData.registeredFrom | translate" class="xs-text-block-intermediate-secondary-500"></span>
                <xs-time-ago [givenDate]="oneData!.registeredOn" [live]="true" styleClass="xs-text-block-small-secondary xs-mt-6"></xs-time-ago>
            </div>
            <span *ngIf="oneData.registeredOn | xsIsEmpty">---</span>
        </ng-template>
    </xs-table>
</div>
