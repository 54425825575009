import {LCECertificateOrderTiny} from '../certificate/order/lce-certificate-order-tiny';
import {LCERequestorType} from '../lce-core';
import {LCENewsCustomerData} from '../news/lce-news-customer-data';
import {LCESmartCityCustomerData} from '../smartcity/lce-smartcity-customer-data';
import {LCEUser, LCEUserCreate, LCEUserPartial, LCEUserSearch, LCEUserType} from './lce-user';
import {LCEMunicipalityPartial} from '../lce-municipality';
import {XSInstantRange, XSIntegerRange} from '@xs/base';

export enum LCEUserCustomerState {
    REGISTERED = 'registered',
    UNREGISTERED = 'unregistered',
    ORDERED_AT_LEAST_ONCE = 'orderedAtLeastOnce',
    NEVER_ORDERED = 'neverOrdered',
}

export interface LCEUserCustomerAdditionalStats {
    total: number;
    registered: number;
    unregistered: number;
    orderedAtLeastOnce: number;
    neverOrdered: number;
}

export interface LCEUserCustomerPartial extends LCEUserPartial {
    createdOn?: string;

    registered?: boolean;
    registeredOn?: string;

    createdFrom?: LCERequestorType;
    registeredFrom?: LCERequestorType;
}

export interface LCEUserCustomerSearch extends LCEUserSearch {
    registered?: boolean;
    registeredOnRange?: XSInstantRange;

    createdFrom?: LCERequestorType[];
    registeredFrom?: LCERequestorType[];

    numberOfOrdersRange?: XSIntegerRange;

    test?: boolean;
}

export interface LCEUserCustomerCreate extends LCEUserCreate {
    termsAndConditionsAccepted?: boolean;
    sessionID?: string;
    municipalityOfResidence?: LCEMunicipalityPartial;
}

export interface LCEUserCustomer extends LCEUser {
    type: LCEUserType.CUSTOMER;
    newsData?: LCENewsCustomerData;
    smartCityData?: LCESmartCityCustomerData;
    orders?: LCECertificateOrderTiny[];

    registered?: boolean;
    registeredOn?: string;

    createdFrom?: LCERequestorType;
    registeredFrom?: LCERequestorType;
}

export interface LCEUserCustomerSettings {
}
