<div class="xs-flex-row xs-align-items-center xs-justify-content-end xs-width-full xs-position-relative {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-flex-wrap">
        <xs-button
                [confirmation]="markAsReadConfirmation"
                [disabled]="!canMarkAsRead() || disabled === true"
                [iconStyleClass]="'xs-font-size-12-imp ' + getStatusColorStyleClass(STATUS.READ)"
                [icon]="ICON.circle"
                [label]="TR_BASE + 'label.markAsRead'"
                [loading]="loading.markAsRead"
                class="xs-mr-40 xs-mt-15"
                confirmationPopupStyleClass="xs-font-size-small-imp"
                loaderPosition="top"
                size="intermediate"
                type="text">
        </xs-button>
        <xs-button
                [confirmation]="markAsUnreadConfirmation"
                [disabled]="!canMarkAsUnread() || disabled === true"
                [iconStyleClass]="'xs-font-size-12-imp ' + getStatusColorStyleClass(STATUS.UNREAD)"
                [icon]="ICON.circle"
                [label]="TR_BASE + 'label.markAsUnread'"
                [loading]="loading.markAsUnread"
                class="xs-mr-40 xs-mt-15"
                confirmationPopupStyleClass="xs-font-size-small-imp"
                loaderPosition="top"
                size="intermediate"
                type="text">
        </xs-button>

        <xs-button
                [confirmation]="considerConfirmation"
                [disabled]="!canMarkAsConsidered() || disabled === true"
                [iconStyleClass]="'xs-font-size-12-imp ' + getStatusColorStyleClass(STATUS.CONSIDERED)"
                [icon]="ICON.circle"
                [label]="TR_BASE + 'label.consider'"
                [loading]="loading.markAsConsidered"
                class="xs-mr-40 xs-mt-15"
                confirmationPopupStyleClass="xs-font-size-small-imp"
                loaderPosition="top"
                size="intermediate"
                type="text">
        </xs-button>
        <xs-button
                [confirmation]="selectedConfirmation"
                [disabled]="!canMarkAsSelected() || disabled === true"
                [iconStyleClass]="'xs-font-size-12-imp ' + getStatusColorStyleClass(STATUS.SELECTED)"
                [icon]="ICON.circle"
                [label]="TR_BASE + 'label.select'"
                [loading]="loading.markAsSelected"
                class="xs-mt-15"
                confirmationPopupStyleClass="xs-font-size-small-imp"
                loaderPosition="top"
                size="intermediate"
                type="text">
        </xs-button>
    </div>
</div>