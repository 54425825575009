import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECertificateOrderPartial, LCEUserCustomer, LCEUserCustomerService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSCoreDomUtils, XSDialogable} from '@xs/core';
import {TabView} from 'primeng/tabview';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-customer-record',
    templateUrl: './lce-user-customer-record.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEUserCustomerRecordComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.customer.customerRecord.';

    readonly LOADER_ID: string = 'recordLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() customerID: string;
    @Input() customer: LCEUserCustomer;

    certificateOrders: LCECertificateOrderPartial[];

    @Input() readonly?: boolean;

    @Output() closeEvent = new EventEmitter<void>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    navScrollable: boolean = false;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        icon: this.ICON.refresh,
        label: 'xs.core.label.pleaseTryAgain',
        onClick: () => this.retrieveCustomer()
    };

    private subscription: Subscription = new Subscription();

    private tabViewResizeObserver: ResizeObserver;
    private tabView: TabView;

    constructor(
        private loaderService: XSLoaderService,
        private customerService: LCEUserCustomerService) {
        super();
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    get headerTitle(): string {
        let title = XSUtils.isEmpty(this.customer) ? 'lce.shared.customer.label.title' : XSUtils.isEmpty(this.customer!.name) ? 'lce.shared.customer.label.title' : `${this.customer!.name!.firstName} ${this.customer!.name!.lastName}`;
        return title! as string;
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.customer) ? '...' : `${this.customer!.code}`;
        return subTitle! as string;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.customerID = this.dialogConfig.data.customerID;
            this.customer = this.dialogConfig.data.customer;
            this.readonly = this.dialogConfig.data.readonly;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        if (XSUtils.isEmpty(this.customerID) && XSUtils.isEmpty(this.customer)) {
            throw new Error('customerID and customer cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.customerID) && !XSUtils.isEmpty(this.customer)) {
            throw new Error('customerID and customer cannot both be set at the same time.');
        }
        if (!XSUtils.isNull(this.customerID)) {
            this.retrieveCustomer();
        }
    }

    ngOnDestroy(): void {
        if (this.tabViewResizeObserver) this.tabViewResizeObserver.disconnect();
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.customer);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    private retrieveCustomer(): void {
        this.error = undefined;
        this.startLoader();
        this.subscription.add(
            this.customerService
                .retrieve(this.customerID)
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: (customer) => {
                        this.customer = customer;
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver((entries) => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }
}
