import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCEFacilityTownHallStampTokenCreate, LCEFacilityTownHallStampTokenDetail, LCEFacilityTownHallStampTokenService} from '@lce/core';
import {XS_LOREM_IPSUM, XS_STR_EMPTY, XSAmount, XSAssert, XSCurrency, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XS_COMMON_ICON, XSAmountPipe} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCESharedContextService} from '../../../api/services/lce-shared-context.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-generator',
    templateUrl: './lce-facility-town-hall-stamp-token-generator.component.html',
    host: {class: 'xs-flex-row-center xs-width-full'},
    providers: [XSAmountPipe]
})
export class LCEFacilityTownHallStampTokenGeneratorComponent implements OnInit, OnDestroy {

    readonly ICON_MOBILE = XS_COMMON_ICON.mobile;
    readonly ICON_QRCODE = LCE_SHARED_ICON.qrCode;
    readonly ICON_STAMP = LCE_SHARED_ICON.stamp;

    readonly LOREM_IPSUM = XS_LOREM_IPSUM;

    readonly DEFAULT_STAMP_TOKEN_CODE = 'LCE-STO-YOP-00-0000-00';

    @Input() styleClass?: string;

    @Input() facilityCode: string;

    @Output() errorEvent = new EventEmitter<any>();
    @Output() updatedEvent = new EventEmitter<void>();
    @Output() createdEvent = new EventEmitter<LCEFacilityTownHallStampTokenDetail>();

    detail: LCEFacilityTownHallStampTokenDetail;

    loading: boolean = false;
    countLoading: boolean = false;

    error: any;
    countError: any;

    stampUnitPrice: XSAmount = {value: 500, currency: XSCurrency.XOF};

    nStampsGeneratedToday: string = '0';

    private subscription: Subscription = new Subscription();

    constructor(
        private amountPipe: XSAmountPipe,
        private contextService: LCESharedContextService,
        private stampTokenService: LCEFacilityTownHallStampTokenService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityCode, 'facilityCode');
        this.update();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public update(): void {
        this.setError(undefined);
        this.countStampsGeneratedToday(true);
    }

    public computeMonetaryValue(): string {
        if (XSUtils.isNull(this.detail.numberOfStamps)) return XS_STR_EMPTY;
        const computedAmount = this.stampUnitPrice.value * this.detail.numberOfStamps;
        return this.amountPipe.transform({value: computedAmount, currency: XSCurrency.XOF});
    }

    public hasMobilePhoneNumber(): boolean {
        return this.hasDetail() && !XSUtils.isEmpty(this.detail.mobilePhoneNumber);
    }

    public hasDetail(): boolean {
        return !XSUtils.isEmpty(this.detail);
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    public create(event: { numberOfStamps: number; mobilePhoneNumber?: string }): void {
        this.setLoading(true);
        const stampTokenCreate: LCEFacilityTownHallStampTokenCreate = {
            facilityCode: this.facilityCode,
            mobilePhoneNumber: event.mobilePhoneNumber,
            numberOfStamps: event.numberOfStamps
        };
        this.subscription.add(
            this.stampTokenService.create(stampTokenCreate)
                .pipe(finalize(() => this.setLoading(false)))
                .subscribe({
                    next: stampTokenDetail => {
                        this.detail = stampTokenDetail;
                        this.createdEvent.emit(this.detail);
                        this.countStampsGeneratedToday();
                    },
                    error: error => this.setError(error)
                })
        );
    }

    private countStampsGeneratedToday(update: boolean = false): void {
        this.countLoading = true;
        this.countError = undefined;
        const countSearch = {
            facilityCodes: [this.facilityCode],
            createdBy: this.contextService.getUser().id,
            createdOnPredefinedPeriod: XSPredefinedPeriod.TODAY
        };
        this.subscription.add(
            this.stampTokenService.count(countSearch)
                .pipe(finalize(() => {
                    this.countLoading = false;
                    if (update) this.updatedEvent.emit();
                }))
                .subscribe({
                    next: count => this.nStampsGeneratedToday = XSUtils.zeroLeftPad(count.total),
                    error: error => this.countError = error
                })
        );
    }

    private setError(error: any): void {
        this.error = error;
        this.errorEvent.emit(this.error);
    }

    private setLoading(state: boolean): void {
        if (state) {
            this.loading = true;
            this.setError(undefined);
        } else {
            this.loading = false;
        }
    }
}
