import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceCodeAuditFullService} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../constants/lce-core-endpoint.constant';
import {LCEHttpClientService} from './lce-http-client.service';
import {LCETerminal, LCETerminalCreate, LCETerminalGenerateSecurityCodeResponse, LCETerminalPartial, LCETerminalRegistrationRequest, LCETerminalRegistrationResponse, LCETerminalSearch} from '../domain/lce-terminal';

@Injectable({providedIn: 'root'})
export class LCETerminalService extends XSPKResourceCodeAuditFullService<LCETerminal, LCETerminalPartial, LCETerminalSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.terminals.index, 'terminal');
    }

    public register(request: LCETerminalRegistrationRequest): Observable<LCETerminalRegistrationResponse> {
        XSAssert.notEmpty(request, 'request');
        return this.httpClientService.post<LCETerminalRegistrationResponse>(LCE_CORE_ENDPOINTS.terminals.register, request);
    }

    public generateSecurityCode(terminalID: string): Observable<LCETerminalGenerateSecurityCodeResponse> {
        XSAssert.notEmpty(terminalID, 'terminalID');
        return this.httpClientService.patch<LCETerminalGenerateSecurityCodeResponse>(
            LCE_CORE_ENDPOINTS.terminals.generateSecurityCode.replace('${terminalID}', terminalID)
        );
    }
    
    public create(terminalCreate: LCETerminalCreate): Observable<LCETerminal> {
        XSAssert.notEmpty(terminalCreate, 'terminalCreate');
        return this.httpClientService.post<LCETerminal>(this.buildEndpoint(), terminalCreate);
    }
}
