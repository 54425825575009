<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card xs-min-height-350">

        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-colum">
                <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
                    {{  nOrders }} {{ TR_BASE + 'orders' | translate }}
                </span>
                <span *ngIf="(certificateOrders | xsIsEmpty) && !loader.isRunning()" class="xs-card-header-sub-title xs-color-secondary">{{ TR_BASE + 'noOrderFound' | translate }}</span>
            </div>
        </div>

        <div class="xs-card-content xs-position-relative xs-pt-30-imp">
            <div class="xs-absolute-center-vh xs-min-height-200">
                <xs-loader #loader [loaderId]="ORDERS_LOADER" [loaderSize]="30"></xs-loader>
            </div>
            <div *ngIf="!loader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full">
                <xs-error
                        [actionButton]="errorRetryButton"
                        [error]="error"
                        [showBorder]="false"
                        [showErrorDetailButton]="true"
                        message="{{ TR_BASE }}errorRetrieveCustomerOrders"
                        mode="block"
                        subMessage="xs.core.error.contactAdminMessage">
                </xs-error>
            </div>
            <i
                    *ngIf="(certificateOrders | xsIsEmpty) && !loader.isRunning() && !hasError()"
                    [style.height]="'150px!important'"
                    [style.width]="'150px!important'"
                    class="xs-display-block xs-color-discrete xs-opacity-40 xs-absolute-center-vh {{ ICON.certificate }}">
            </i>

            <ul *ngIf="(certificateOrders | xsIsNotEmpty) && !loader.isRunning()" class="xs-listing xs-width-full xs-height-full">
                <li *ngFor="let certificateOrder of certificateOrders" class="xs-listing-item xs-p-10-imp xs-mb-20-imp">
                    <div class="grid grid-nogutter">
                        <div class="col-8">
                            <lce-certificate-order-partial [clickable]="true" [data]="certificateOrder" [detailed]="true"></lce-certificate-order-partial>
                        </div>
                        <div class="col-4 xs-flex-row-center">
                            <xs-data-field-calendar
                                    [calendarOptions]="calendarOptions"
                                    [timeAgoLive]="true"
                                    [timeAgo]="true"
                                    [value]="certificateOrder.createdOn"
                                    valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                            </xs-data-field-calendar>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
