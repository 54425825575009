<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-header xs-pb-15">
        <div class="lce-template-sheldon-router-title-container">
            <span class="lce-template-sheldon-router-title">{{ TR_BASE_MAIN + 'title' | translate }}</span>
            <span class="lce-template-sheldon-router-sub-title">{{ TR_BASE_MAIN + 'subTitle' | translate }}</span>
        </div>
        <div class="lce-template-sheldon-router-action-container">
            <xs-button
                    (clickEvent)="onCreate()"
                    *ngIf="can.create"
                    [icon]="ICON_PLUS"
                    [label]="TR_BASE_MAIN + 'createMunicipality'"
                    [transform]="false" class="xs-mr-40"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="onRefresh()"
                    [icon]="ICON_REFRESH"
                    [transform]="false"
                    type="icon">
            </xs-button>
        </div>
    </div>
    <div class="lce-template-sheldon-router-content">
        <lce-municipalities [canOptions]="can" styleClass="xs-mt-25"></lce-municipalities>
    </div>
</div>