import {Injectable} from '@angular/core';
import {LCEUserCustomerPartial, LCEUserCustomerSearch, LCEUserCustomerService} from '@lce/core';
import {XSPagination, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class LCEUserCustomersFeatureService {

	public sort: XSSort | undefined;
	public pagination: XSPagination;

	private readonly USER_TABLE_DEFAULT_PAGINATION_ROWS: number = 10;

	private startSearchSubject = new Subject<void>();
	onStartSearch = this.startSearchSubject.asObservable();

	private endSearchSubject = new Subject<XSSearchResult<LCEUserCustomerPartial>>();
	onEndSearch = this.endSearchSubject.asObservable();

	private refreshSubject = new Subject<void>();
	onRefresh = this.refreshSubject.asObservable();

	constructor(private customerService: LCEUserCustomerService) {
		this.pagination = {page: 1, size: this.USER_TABLE_DEFAULT_PAGINATION_ROWS};
	}

	public emitRefresh() {
		this.refreshSubject.next();
	}

	public search(customerSearch: LCEUserCustomerSearch): Observable<XSSearchResult<LCEUserCustomerPartial>> {
		this.startSearchSubject.next();
		customerSearch.paginationPage = this.pagination.page - 1;
		customerSearch.paginationSize = this.pagination.size;

		if (XSUtils.isEmpty(this.sort)) {
			customerSearch.sort = ['createdOn|desc'];
		} else {
			const sortStr = this.sort!.by.map((sortIem) => sortIem.field + '|' + sortIem.order).join(',');
			customerSearch.sort = [sortStr];
		}

		XSUtils.removeNullAndUndefinedEntries(customerSearch);
		XSUtils.removeEmptyObjectEntries(customerSearch);

		return this.customerService
			.search(customerSearch)
			.pipe(tap((response) => this.endSearchSubject.next(response)));
	}
}
