import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LCEArticleReviewer, LCEUserPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSInputFieldBaseOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEArticleReviewersOptions} from './lce-article-reviewers-options';

@Component({selector: 'lce-article-reviewers', templateUrl: 'lce-article-reviewers.component.html'})
export class LCEArticleReviewersComponent implements OnInit, OnDestroy {
    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.news.articleCreateUpdate.';

    readonly LOADER_ID_DEFAULT_REVIEWERS: string = 'defaultReviewersLoader';
    @Input() styleClass?: string;
    @Input() disabled?: boolean;
    @Input() reviewers?: LCEArticleReviewer[];
    @Input() options: LCEArticleReviewersOptions;
    @Input() control?: FormControl;
    @Output() addEvent = new EventEmitter<LCEArticleReviewer>();
    @Output() removeEvent = new EventEmitter<string>();
    defaultReviewers: LCEArticleReviewer[] = [];
    additionalReviewers: LCEArticleReviewer[] = [];
    addReviewerField: XSInputFieldBaseOptions;
    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveReviewers()
    };
    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.options, 'options');
        if (XSUtils.isNull(this.control)) this.control = new FormControl();
        this.handleDefaults();
        this.installEvents();
        if (this.options.canAdd === true) this.buildAddReviewerField();
        this.retrieveReviewers();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public onAddAdditionalReviewer(user: LCEUserPartial) {
        if (this.additionalReviewers.find((reviewer) => reviewer.user.id === user.id)) return;
        let additionalReviewer: LCEArticleReviewer = {user: user, addedOn: new Date().toISOString()};
        this.additionalReviewers.push(additionalReviewer);
        this.additionalReviewers = [...this.additionalReviewers];
        this.updateReviewersControl();
        this.addEvent.emit(additionalReviewer);
    }

    public onDeleteAdditionalReviewer(additionalReviewer: LCEArticleReviewer) {
        const index = this.additionalReviewers.indexOf(additionalReviewer);
        if (index !== -1) {
            const reviewerUserID: string = this.additionalReviewers[index].user.id;
            this.additionalReviewers.splice(index, 1);
            this.additionalReviewers = [...this.additionalReviewers];
            this.updateReviewersControl();
            this.removeEvent.emit(reviewerUserID);
        }
    }

    private updateReviewersControl(): void {
        const allReviewers: string[] = [];
        this.defaultReviewers.forEach((reviewer) => allReviewers.push(reviewer.user.id));
        this.additionalReviewers.forEach((reviewer) => allReviewers.push(reviewer.user.id));
        this.control!.setValue(allReviewers);
    }

    private retrieveReviewers(): void {
        if (XSUtils.isEmpty(this.reviewers)) {
            this.loaderService.startLoader(this.LOADER_ID_DEFAULT_REVIEWERS);
            this.error = undefined;
            this.subscription.add(
                this.options
                    .retrieveDefaultReviewers()
                    .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_DEFAULT_REVIEWERS)))
                    .subscribe({
                        next: (reviewers) => {
                            this.defaultReviewers = reviewers;
                            this.updateReviewersControl();
                        },
                        error: (error) => (this.error = error)
                    })
            );
        } else {
            this.defaultReviewers = this.reviewers!.filter((reviewer) => reviewer.isDefault === true);
            this.additionalReviewers = this.reviewers!.filter((reviewer) => reviewer.isDefault !== true);
            this.updateReviewersControl();
        }
    }

    private buildAddReviewerField(): void {
        this.addReviewerField = {
            fieldName: 'inputReviewer',
            belowText: this.TR_BASE + 'addReviewerMessage',
            belowTextStyleClass: 'xs-color-secondary',
            control: new FormControl()
        };
    }

    private installEvents(): void {
        this.addEvent.subscribe((reviewer) => {
            if (this.options.onAdd) this.options.onAdd(reviewer);
        });
        this.removeEvent.subscribe((userID) => {
            if (this.options.onRemove) this.options.onRemove(userID);
        });
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.options.canAdd)) this.options.canAdd = false;
        if (XSUtils.isNull(this.options.canRemoveAlreadyAdded)) {
            if (XSUtils.isNull(this.options.canAdd)) this.options.canRemoveAlreadyAdded = true;
            else this.options.canRemoveAlreadyAdded = false;
        }
    }
}
