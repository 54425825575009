import {formatNumber} from '@angular/common';
import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateOrderStatsPayment, LCECertificateOrderStatsPaymentPartial, LCECertificateType} from '@lce/core';
import {XSAssert, XSCurrency, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XSIconText, XSSize, XSTranslationService} from '@xs/common';
import {XSAvatar, XSDataBox} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateUtils} from '../../certificate/lce-certificate.utils';

@Component({
    selector: 'lce-certificate-order-stats-payment',
    template: `
        <div class="xs-flex-row xs-width-full xs-position-relative {{ styleClass }}">
            <xs-data-boxes
                    dataBoxStyleClass="xs-min-width-500-imp"
                    [dataBoxLoading]="loading"
                    [dataBoxShowBorder]="false"
                    [data]="dataBoxes"
                    [error]="error"
                    [swiperPagination]="false"
                    type="swiper">
            </xs-data-boxes>
        </div>
    `,
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCECertificateOrderStatsPaymentComponent implements OnInit {

    readonly LCE_CERTIFICATE_PAYMENT_TR_BASE_TYPE = 'lce.shared.certificateOrders.stats.payment.';

    @Input() styleClass?: string;

    @Input() predefinedPeriod?: XSPredefinedPeriod;

    @Input() showTotalBox?: boolean;
    @Input() showBirthBox?: boolean;
    @Input() showMarriageBox?: boolean;
    @Input() showDeathBox?: boolean;

    @Input() facilityCode: string;

    dataBoxes: XSDataBox[] = [];
    data: LCECertificateOrderStatsPayment;

    loading: boolean = false;

    search: LCECertificateOrderSearch = {};

    error: any;

    birthTrKey = 'lce.core.certificateTypeLetter.birth';
    marriageTrKey = 'lce.core.certificateTypeLetter.marriage';
    deathTrKey = 'lce.core.certificateTypeLetter.death';

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService, private certificateOrderService: LCECertificateOrderService) {

        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                console.log('|--- langage changed');
                this.birthTrKey = this.translationService.translateKey(this.birthTrKey);
                this.marriageTrKey = this.translationService.translateKey(this.marriageTrKey);
                this.deathTrKey = this.translationService.translateKey(this.deathTrKey);

                this.updateDataBoxes();
            })
        );
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityCode, 'facilityCode');

        this.handleDefaults();
        this.initializeDataBoxes();
        this.retrieveData(this.predefinedPeriod);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.predefinedPeriod) && !changes.predefinedPeriod!.isFirstChange()) {
            this.updateDataBoxesPredefinedPeriod();
            this.update(this.predefinedPeriod);
        }
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            this.updateDataBoxes();
        }
    }

    public refresh(): void {
        this.retrieveData();
    }

    public update(predefinedPeriod?: XSPredefinedPeriod): void {
        this.retrieveData(predefinedPeriod);
    }

    private retrieveData(predefinedPeriod?: XSPredefinedPeriod): void {
        this.loading = true;
        this.search.facilityCodes = [this.facilityCode];
        if (!XSUtils.isEmpty(predefinedPeriod)) this.predefinedPeriod = predefinedPeriod!;
        this.search.createdOnPredefinedPeriod = this.predefinedPeriod;

        this.subscription.add(
            this.certificateOrderService.statsFinances(this.search)
                .pipe(finalize(() => this.loading = false))
                .subscribe(
                    {
                        next: stats => {
                            this.data = stats;
                            this.updateDataBoxes();
                        },
                        error: error => this.error = error
                    }
                ));

    }

    private updateDataBoxesPredefinedPeriod(): void {
        if (this.showTotalBox) this.getDataBox('all')!.bottomLeft = this.predefinedPeriod;
        if (this.showBirthBox) this.getDataBox(LCECertificateType.BIRTH)!.bottomLeft = this.predefinedPeriod;
        if (this.showMarriageBox) this.getDataBox(LCECertificateType.MARRIAGE)!.bottomLeft = this.predefinedPeriod;
        if (this.showDeathBox) this.getDataBox(LCECertificateType.DEATH)!.bottomLeft = this.predefinedPeriod;
    }

    private updateDataBoxes(): void {
        if (this.showTotalBox && !XSUtils.isEmpty(this.data?.total)) this.updateDataBox(this.data.total);
        if (this.showBirthBox && !XSUtils.isEmpty(this.data?.birth)) this.updateDataBox(this.data.birth);
        if (this.showMarriageBox && !XSUtils.isEmpty(this.data?.marriage)) this.updateDataBox(this.data.marriage);
        if (this.showDeathBox && !XSUtils.isEmpty(this.data?.death)) this.updateDataBox(this.data.death);
        this.dataBoxes = [...this.dataBoxes];
    }

    private updateDataBox(statistic: LCECertificateOrderStatsPaymentPartial): void {
        const dataBox = this.getDataBox(statistic.certificateType!);
        if (XSUtils.isEmpty(dataBox)) {
            return;
        }
        const percentage = ((statistic.totalDueAmount - statistic.totalDueAmountVariation) / statistic.totalDueAmount) * 100;
        const direction = percentage === 0 ? 'equal' : (percentage > 0 ? 'up' : 'down');

        dataBox!.main.value = {value: statistic.totalDueAmount, currency: XSCurrency.XOF};
        dataBox!.main.afterValue = {value: Math.round(Math.abs(percentage)), direction: direction, unit: '%', showBackground: true};
        dataBox!.main.subValue = {
            value: this.translationService.translate(this.LCE_CERTIFICATE_PAYMENT_TR_BASE_TYPE + 'nSuccessfulOrders', {total: this.formatValue(statistic.numberOfOrders)})
        };
        dataBox!.bottomLeft = this.predefinedPeriod;

        (dataBox?.bottomRight as XSIconText).text.value = this.formatValue(statistic.numberOfCopies);
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.showTotalBox)) this.showTotalBox = true;
        if (XSUtils.isNull(this.showBirthBox)) this.showBirthBox = true;
        if (XSUtils.isNull(this.showDeathBox)) this.showDeathBox = true;
        if (XSUtils.isNull(this.showMarriageBox)) this.showMarriageBox = true;
        if (XSUtils.isEmpty(this.predefinedPeriod)) this.predefinedPeriod = XSPredefinedPeriod.THIS_MONTH;
    }

    private getDataBox(certificateType: LCECertificateType | 'all' | undefined): XSDataBox | undefined {
        const cType = LCECertificateUtils.getCertificateType(certificateType);
        return this.dataBoxes.find(dataBox => cType.toString() === dataBox.id);
    }

    private initializeDataBoxes(): void {
        this.dataBoxes = [];
        if (this.showTotalBox) this.dataBoxes.push(this.initializeDataBox('all'));
        if (this.showBirthBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.BIRTH));
        if (this.showMarriageBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.MARRIAGE));
        if (this.showDeathBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.DEATH));
    }

    private initializeDataBox(certificateType: LCECertificateType | 'all'): XSDataBox {
        const cType = LCECertificateUtils.getCertificateType(certificateType);
        return {
            id: certificateType.toString(),
            topLeft: {
                title: {value: this.LCE_CERTIFICATE_PAYMENT_TR_BASE_TYPE + 'certificateRequest', styleClass: 'xs-font-weight-500'},
                subTitle: {
                    value: this.LCE_CERTIFICATE_PAYMENT_TR_BASE_TYPE + LCECertificateUtils.getCertificateType(certificateType),
                    styleClass: 'xs-font-size-extra-small'
                }
            },
            bottomLeft: this.predefinedPeriod,
            bottomRight: {
                _type: 'iconText',
                icon: {
                    value: LCE_SHARED_ICON.print,
                    size: XSSize.SMALL,
                    styleClass: 'xs-color-secondary'
                },
                iconPosition: 'before',
                text: {value: '...', styleClass: 'xs-color-secondary xs-font-size-intermediate'}
            } as XSIconText,
            main: {
                avatar: {...this.buildAvatar(cType)!, size: XSSize.MEDIUM},
                value: undefined!,
                subValue: undefined
            }
        };
    }

    private buildAvatar(cType: LCECertificateType | 'all'): XSAvatar {
        switch (cType) {
            case (LCECertificateType.BIRTH):
                return {type: 'label', data: this.translationService.translateKey(this.birthTrKey)};
            case (LCECertificateType.MARRIAGE):
                return {type: 'label', data: this.translationService.translateKey(this.marriageTrKey)};
            case (LCECertificateType.DEATH):
                return {type: 'label', data: this.translationService.translateKey(this.deathTrKey)};
            case ('all'):
                return {type: 'label', data: 'T'};
        }
    }

    private formatValue(value: any): any {
        if (!XSUtils.isNumber(value)) return value;
        if (value > 0 && value < 10) {
            return XSUtils.zeroLeftPad(value);
        }
        return formatNumber(value, this.translationService.getCurrentLanguage().toString());
    }
}
