<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #terminalRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasErrorRetrieve() && !terminalRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error.retrieveError"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}error.retrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="headerAvatar" [subtitle]="headerSubTitle" [title]="headerTitle"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-justify-content-space-between xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="terminal!"></lce-user-resource-audit>

                <div class="xs-flex-row xs-align-items-center xs-justify-content-end">
                    <xs-error
                            *ngIf=" (resourceAuditFullMenuAction | xsIsNotEmpty) && resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <xs-button
                            (clickEvent)="onEdit()"
                            [disabled]="(resourceAuditFullMenuAction | xsIsNotEmpty) && !resourceAuditFullMenuAction?.canDoAction()"
                            [icon]="ICON_EDIT"
                            label="xs.core.label.edit"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <xs-pk-resource-audit-full-menu-action
                        #resourceAuditFullMenuAction
                        *ngIf="canShowAuditFullMenuActions()"
                        [dataManager]="dataManager!"
                        [resourceService]="terminalService"
                        styleClass="xs-ml-40">
                    </xs-pk-resource-audit-full-menu-action>
                </div>
            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Terminal General  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-12">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="generalContainer"
                            styleClass="xs-mb-20"
                            text="{{ TR_BASE_LABEL }}general">
                    </xs-title>
                </div>
                <div #generalContainer class="col-12 grid">
                    <div class="col-6">
                        <xs-data-field-identifier
                                [copyable]="true"
                                [ellipsis]="true"
                                [testColored]="true"
                                [value]="terminal!.code"
                                label="xs.core.label.code"
                                type="code"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-identifier>
                    </div>
                    <div class="col-6">
                        <xs-data-field-resource-state [value]="terminal!" styleClass="xs-mt-15"></xs-data-field-resource-state>
                    </div>

                    <div class="col-6">
                        <xs-data-field-text
                                [capitalize]="true"
                                [label]="TR_BASE_LABEL + 'name.label'"
                                [required]="false"
                                [value]="terminal!.device.name">
                        </xs-data-field-text>
                    </div>
                    <div class="col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE_LABEL + 'registered'" styleClass="xs-mb-6"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <xs-text [fontWeight500]="true" [value]="registeredStateLabel" size="intermediate" styleClass="xs-mb-5"></xs-text>
                                <lce-user-resource-audit-inline
                                        *ngIf="terminal!.registeredOn | xsIsNotEmpty"
                                        [by]="terminal!.registeredBy!"
                                        [on]="terminal!.registeredOn!"
                                        onPrefix="xs.core.audit.onCapitalize"
                                        styleClass="xs-mt-5 xs-color-secondary">
                                </lce-user-resource-audit-inline>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <xs-data-field-text
                                [required]="false"
                                [value]="terminal!.description"
                                label="xs.common.label.description"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-text>
                    </div>
                    <div class="col-6">
                        <xs-data-field-text
                                [required]="false"
                                [value]="terminal!.note"
                                label="xs.common.label.note"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-text>
                    </div>

                    <div class="col-12">
                        <div class="grid grid-nogutter xs-container-left-stripe-discrete-5 xs-pt-20">
                            <div class="col-6">
                                <div class="xs-data-field xs-mt-20">
                                    <div class="xs-data-field-value-container">
                                        <xs-device [data]="deviceData" [label]="TR_BASE_LABEL + 'device.label'"></xs-device>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="xs-data-field xs-mt-20">
                                    <div class="xs-data-field-value-container">
                                        <lce-facility-partial
                                                [data]="terminal!.facility"
                                                [showIcon]="true"
                                                label="lce.shared.label.facility"
                                                styleClass="xs-data-field-value">
                                        </lce-facility-partial>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Terminal Security  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-12">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="securityContainer"
                            styleClass="xs-mtb-20"
                            text="{{ TR_BASE_LABEL }}security">
                    </xs-title>
                </div>
                <div #securityContainer class="col-12 grid">
                    <div class="col-12 md:col-6 xs-min-height-125">
                        <xs-data-field-text
                                [value]="securityCode"
                                label="{{ TR_BASE_LABEL }}securityCode"
                                styleClass="xs-data-field-spacer-20"
                                valueStyleClass="xs-font-size-medium">
                            <ng-template xsTemplate="below">
                                <div class="xs-flex-column xs-mt-5">
                                    <xs-button
                                            (clickEvent)="onSecurityCodeShow()"
                                            label="{{ TR_BASE_LABEL }}showFiveSeconds"
                                            size="small"
                                            type="text">
                                    </xs-button>
                                    <lce-user-resource-audit-inline
                                            *ngIf="terminal!.securityCodeGeneratedOn | xsIsNotEmpty"
                                            [by]="terminal!.securityCodeGeneratedBy"
                                            [on]="terminal!.securityCodeGeneratedOn"
                                            onPrefix="xs.core.audit.updatedOn"
                                            styleClass="xs-mt-10">
                                    </lce-user-resource-audit-inline>
                                </div>
                            </ng-template>
                        </xs-data-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-button
                                [confirmation]="generateSecurityCodeConfirmation"
                                [icon]="ICON_REDO"
                                [loading]="loading.generateSecurityCode"
                                label="{{ TR_BASE_LABEL }}generateSecurityCode"
                                loaderPosition="top"
                                size="intermediate"
                                type="text">
                        </xs-button>
                        <span
                                [innerHTML]="'lce.shared.terminal.label.generateSecurityCodeDescription' | translate"
                                class="xs-display-block xs-color-secondary xs-font-size-small xs-mt-10 xs-mb-15">
                        </span>
                        <div *ngIf="hasErrorGenerateNewCode() && !loading.generateSecurityCode">
                            <xs-error
                                    [error]="error.generateSecurityCode"
                                    [showErrorDetailButton]="true"
                                    message="{{ TR_BASE_LABEL }}error.generateSecurityCode"
                                    mode="inline">
                            </xs-error>
                        </div>
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Terminal Statistics  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-12">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="statisticsContainer"
                            styleClass="xs-mtb-20"
                            text="{{ TR_BASE }}statistics.title">
                    </xs-title>
                </div>
                <div #statisticsContainer class="col-12 grid">
                    <div class="col-12">
                        <xs-coming-soon
                                [showIcon]="true"
                                subtext="{{ TR_BASE }}statistics.comingSoon.line2"
                                text="{{ TR_BASE }}statistics.comingSoon.line1">
                        </xs-coming-soon>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="terminal!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="headerIcon"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>