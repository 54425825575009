import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT, LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenSearch, LCEFacilityTownHallStampTokenStatus} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSInstantRange, XSPagination, XSPredefinedPeriod, XSResourceAuditCanOptions, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {XS_PREDEFINED_PERIOD_ITEMS, XSCommonUtils, XSLabelValueItem, XSLoaderState, XSTranslationService} from '@xs/common';
import {XSChip, XSDeviceDetectorService} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCESharedContextService} from '../../../api/services/lce-shared-context.service';
import {LCEFacilityStampTokenFeatureService} from '../lce-facility-stamp-token-feature.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-history-user',
    templateUrl: 'lce-facility-town-hall-stamp-token-history-user.component.html',
    providers: [LCEFacilityStampTokenFeatureService]
})
export class LCEFacilityTownHallStampTokenHistoryUserComponent implements OnInit {

    readonly ICON_STAMP = LCE_SHARED_ICON.stamp;

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.label.';

    readonly PREDEFINED_PERIOD = XSPredefinedPeriod;

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATOR_TOTAL_RECORDS: number = 100;

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() canAudit?: XSResourceAuditCanOptions;
    @Input() canFilterByCreatedBy?: boolean;

    @Output() updatedEvent = new EventEmitter<void>();

    // === Table ===
    tableData: LCEFacilityTownHallStampTokenPartial[] = [];
    tableNResults: number = 0;
    tableLoadingState: XSLoaderState;

    // --- Search ---
    searchText: string;
    searching: boolean;

    userID?: string;

    // --- Filter ---
    predefinedPeriodFilterOptions: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XS_PREDEFINED_PERIOD_ITEMS);
    predefinedPeriodFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;

    statusFilter: LCEFacilityTownHallStampTokenStatus[];
    statusFilterOptions: XSLabelValueItem[] = [
        {trLabel: LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT + LCEFacilityTownHallStampTokenStatus.CONSUMED, value: LCEFacilityTownHallStampTokenStatus.CONSUMED},
        {trLabel: LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT + LCEFacilityTownHallStampTokenStatus.UNCONSUMED, value: LCEFacilityTownHallStampTokenStatus.UNCONSUMED},
        {trLabel: LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT + LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED, value: LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED}
    ];

    numberOfStampsFilter: number[];
    numberOfStampsFilterChips: XSChip[] = [
        {label: '1', value: 1},
        {label: '2', value: 2}, {label: '3', value: 3},
        {label: '4', value: 4}, {label: '5', value: 5},
        {label: '6', value: 6}, {label: '7', value: 7},
        {label: '8', value: 8}, {label: '9', value: 9}
    ];

    // --- Count Search ---
    stampsGeneratedTodayLoading: boolean = false;
    stampGeneratedTodayError: any;
    stampsGeneratedToday: number;

    stampsGeneratedThisWeekLoading: boolean = false;
    stampsGeneratedThisWeekError: any;
    stampsGeneratedThisWeek: number;

    isMobile: boolean;

    private pagination: XSPagination = {page: 0, size: this.PAGINATOR_TOTAL_RECORDS};

    private stampTokenSearch: LCEFacilityTownHallStampTokenSearch;

    private updateState: { search: boolean; countGeneratedStampsToday: boolean; countGeneratedStampsThisWeek: boolean; };

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCESharedContextService,
        private translationService: XSTranslationService,
        private featureService: LCEFacilityStampTokenFeatureService,
        private deviceDetectorService: XSDeviceDetectorService) {
        this.initializeTranslation();
        this.userID = this.contextService.getUser().id;
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.update()));
        this.subscription.add(this.featureService.onStartSearch.subscribe(() => this.handleStartSearch()));
        this.subscription.add(this.featureService.onEndSearch.subscribe((searchResults) => this.handleEndSearch(searchResults)));
        this.isMobile = this.deviceDetectorService.isMobile();
    }

    ngOnInit(): void {
        this.search();
        this.counts();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onNumberOfStampsFilterChange(selection: any[]): void {
        this.numberOfStampsFilter = selection;
        this.search();
    }

    public onStatValueClick(predefinedPeriod: XSPredefinedPeriod) {
        this.predefinedPeriodFilter = predefinedPeriod;
        this.statusFilter = [];
        this.stampTokenSearch.query = XS_STR_EMPTY;
        this.stampTokenSearch.createdOnPredefinedPeriod = undefined;
        this.search();
    }

    public onPagination(pagination: XSPagination): void {
        this.featureService.pagination = pagination;
        this.search();
    }

    public updateCounts(): void {
        this.counts();
    }

    public update(): void {
        this.initializeUpdateState();
        this.search(true);
        this.counts(true);
    }

    public onSort(sort: XSSort): void {
        this.featureService.sort = sort;
        this.featureService.pagination.page = 1;
        this.search();
    }

    public onResetSort(): void {
        this.featureService.sort = undefined;
        this.search();
    }

    public onResetPreferences(): void {
        this.search();
    }

    public onPredefinedPeriodFilterChange(): void {
        this.stampTokenSearch.createdOnPredefinedPeriod = this.predefinedPeriodFilter;
        this.search();
    }

    public search(update: boolean = false): void {
        this.stampTokenSearch = {
            createdBy: this.userID,
            query: XSUtils.isEmpty(this.searchText) ? undefined : this.searchText.trim()
        };
        if (!XSUtils.isEmpty(this.predefinedPeriodFilter)) this.stampTokenSearch.createdOnRange = XSInstantRange.fromPredefinedPeriod(this.predefinedPeriodFilter);
        if (!XSUtils.isEmpty(this.statusFilter)) this.stampTokenSearch.statuses = this.statusFilter;
        if (!XSUtils.isEmpty(this.numberOfStampsFilterChips)) this.stampTokenSearch.numberOfStamps = this.numberOfStampsFilter;

        this.featureService
            .search(this.stampTokenSearch)
            .pipe(finalize(() => {
                if (update) {
                    this.updateState.search = true;
                    this.checkUpdateState();
                }
            }))
            .subscribe();
    }

    private counts(update: boolean = false): void {
        this.countGeneratedStampsToday(update);
        this.countGeneratedStampsThisWeek(update);
    }

    private countGeneratedStampsToday(update: boolean = false): void {
        this.stampsGeneratedTodayLoading = true;
        this.stampsGeneratedToday = 0;
        this.subscription.add(
            this.featureService.countSearch({createdOnPredefinedPeriod: XSPredefinedPeriod.TODAY, createdBy: this.contextService.getUser().id})
                .pipe(finalize(() => {
                    this.stampsGeneratedTodayLoading = false;
                    if (update) {
                        this.updateState.countGeneratedStampsToday = true;
                        this.checkUpdateState();
                    }
                }))
                .subscribe(
                    {
                        next: (count) => this.stampsGeneratedToday = count.total,
                        error: err => this.stampGeneratedTodayError = err
                    }
                )
        );
    }

    private countGeneratedStampsThisWeek(update: boolean = false): void {
        this.stampsGeneratedThisWeekLoading = true;
        this.stampsGeneratedThisWeek = 0;
        this.subscription.add(
            this.featureService.countSearch({createdOnPredefinedPeriod: XSPredefinedPeriod.THIS_WEEK, createdBy: this.contextService.getUser().id})
                .pipe(finalize(() => {
                    this.stampsGeneratedThisWeekLoading = false;
                    if (update) {
                        this.updateState.countGeneratedStampsThisWeek = true;
                        this.checkUpdateState();
                    }
                }))
                .subscribe(
                    {
                        next: (count) => this.stampsGeneratedThisWeek = count.total,
                        error: err => this.stampsGeneratedThisWeekError = err
                    }
                )
        );
    }

    private initializeTranslation(): void {
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.predefinedPeriodFilterOptions);
                this.predefinedPeriodFilterOptions = [...this.predefinedPeriodFilterOptions];
            })
        );
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statusFilterOptions);
                this.statusFilterOptions = [...this.statusFilterOptions];
            })
        );
    }

    private handleEndSearch(searchResults: XSSearchResult<LCEFacilityTownHallStampTokenPartial>): void {
        this.tableData = searchResults.data;
        this.tableNResults = searchResults.total;

        this.searching = false;
        this.tableLoadingState = {state: false};

        LOG().debug(this.tableNResults + ' facility Town Hall Stamp Tokens Found.');
    }

    private handleStartSearch(loadingText?: string): void {
        this.searching = true;
        this.tableLoadingState = {state: true, text: loadingText};
    }

    private checkUpdateState(): void {
        if (this.updateState?.search && this.updateState?.countGeneratedStampsToday && this.updateState.countGeneratedStampsThisWeek) {
            this.updatedEvent.emit();
            this.initializeUpdateState();
        }
    }

    private initializeUpdateState(): void {
        this.updateState = {
            search: false,
            countGeneratedStampsToday: false,
            countGeneratedStampsThisWeek: false
        };
    }
}
