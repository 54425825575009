<div class="lce-terminals-main {{ styleClass }}">
    <div class="lce-terminals-main-container">
        <div class="xs-flex-row-center xs-width-full xs-mt-25 xs-mb-50">
            <div class="grid xs-width-full">
                <div class="col-12 md:col-3 xs-flex-row-center xs-position-relative xs-ptb-15">
                    <xs-background-icon
                            *ngIf="showBackgroundIcon"
                            [absoluteCenterVH]="true"
                            [data]="{ value: ICON_TERMINAL, color: 'discrete', size: '150px' }"
                            [opacity]="0.1">
                    </xs-background-icon>
                    <xs-statistic-tile
                            [error]="statsError"
                            [loading]="statsLoading"
                            [valueBoxBackground]="true"
                            [valueBox]="true"
                            [value]="stats?.total"
                            [zeroLeftPadding]="true"
                            text="{{ TR_BASE }}stats.total">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [error]="statsError"
                            [loading]="statsLoading"
                            [valueBoxBackground]="true"
                            [valueBox]="true"
                            [value]="stats?.active"
                            [zeroLeftPadding]="true"
                            text="{{ TR_BASE }}stats.active">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [error]="statsError"
                            [loading]="statsLoading"
                            [valueBoxBackground]="true"
                            [valueBox]="true"
                            [value]="stats?.inactive"
                            [zeroLeftPadding]="true"
                            text="{{ TR_BASE }}stats.inactive">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [error]="statsError"
                            [loading]="statsLoading"
                            [valueBoxBackground]="true"
                            [valueBox]="true"
                            [value]="stats?.deleted"
                            [zeroLeftPadding]="true"
                            text="{{ TR_BASE }}stats.deleted">
                    </xs-statistic-tile>
                </div>
            </div>
        </div>
        <!-- === * === -->
        <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750 xs-mt-50">
            <ng-template xsTemplate="sort">
                <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
            </ng-template>

            <!-- === Row Result Template ===-->
            <ng-template let-oneResult xsTemplate="row">
                <lce-terminal-partial [data]="oneResult" [showIcon]="true"></lce-terminal-partial>
            </ng-template>

            <!-- === Record Template ===-->
            <ng-template let-recordData xsTemplate="record">
                <lce-terminal-record
                        (editEvent)="onEdit()"
                        [canOptions]="canOptions"
                        [dataManager]="dataManager"
                        [terminal]="recordData"
                        loadingStyleClass="xs-min-height-500 xs-min-width-600"
                        styleClass="xs-width-full xs-max-width-1000 xs-p-25">
                </lce-terminal-record>
            </ng-template>
        </xs-data-manager>
    </div>
</div>