<div #reviewersContainer class="xs-flex-column {{ styleClass }}">
    <div class="xs-flex-column">
        <span *ngIf="options.showSectionTitles !== false" class="xs-display-block xs-font-size-intermediate xs-font-weight-500 xs-mb-15">
            {{ TR_BASE + 'defaultReviewers' | translate }}
        </span>
        <div class="xs-flex-column">
            <xs-loader #defaultReviewersLoader [loaderId]="LOADER_ID_DEFAULT_REVIEWERS" [ngClass]="{ 'xs-mt-15 xs-mb-30': defaultReviewersLoader.isRunning() }"
                       class="xs-position-relative">
            </xs-loader>

            <div *ngIf="!hasError() && (defaultReviewers | xsIsEmpty) && !defaultReviewersLoader.isRunning() && options.showSectionTitles !== false"
                 class="xs-flex-row-center xs-mb-30">
                <span class="xs-display-block xs-font-size-small xs-color-secondary">
                    {{ TR_BASE + 'noDefaultReviewer' | translate }}
                </span>
            </div>
            <xs-error
                    *ngIf="hasError() && !defaultReviewersLoader.isRunning()"
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showActionButton]="true"
                    [showErrorDetailButton]="true"
                    message="{{ TR_BASE }}errorDefaultReviewer"
                    mode="inline"
                    styleClass="xs-mb-30">
            </xs-error>

            <div *ngIf="(defaultReviewers | xsIsNotEmpty) && !hasError() && !defaultReviewersLoader.isRunning()" class="xs-flex-column">
                <lce-user-ivar *ngFor="let defaultReviewer of defaultReviewers" [data]="defaultReviewer.user" avatarSize="small" styleClass="xs-mb-15"></lce-user-ivar>
            </div>
        </div>
    </div>

    <div class="xs-flex-column">
        <div *ngIf="options.showSectionTitles !== false" class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mb-15">
            <span class="xs-display-block xs-font-size-intermediate xs-font-weight-500">
                {{ TR_BASE + 'additionalReviewers' | translate }}
            </span>
            <xs-button (clickEvent)="addReviewerPanel.toggle($event)" *ngIf="options.canAdd" [icon]="ICON.plus" [label]="TR_BASE + 'addReviewer'" iconPosition="right"
                       size="intermediate"
                       type="text">
                <p-overlayPanel #addReviewerPanel>
                    <div class="xs-flex-row xs-width-300 xs-pb-10">
                        <lce-input-field-user-autocomplete
                                (selectEvent)="onAddAdditionalReviewer($event); addReviewerPanel.hide()"
                                [options]="addReviewerField"
                                [placeholder]="TR_BASE + 'addReviewerPlaceholder'">
                        </lce-input-field-user-autocomplete>
                    </div>
                </p-overlayPanel>
            </xs-button>
        </div>

        <div class="xs-flex-column">
            <div *ngIf="(additionalReviewers | xsIsEmpty) && options.showSectionTitles !== false" class="xs-flex-row-center xs-mt-15 xs-mb-30">
                <span class="xs-display-block xs-font-size-small xs-color-secondary">
                    {{ TR_BASE + 'noAdditionalReviewer' | translate }}
                </span>
            </div>
            <div *ngFor="let additionalReviewer of additionalReviewers" class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mb-15">
                <lce-user-ivar [data]="additionalReviewer.user" avatarSize="small"></lce-user-ivar>
                <xs-button
                        (clickEvent)="onDeleteAdditionalReviewer(additionalReviewer)"
                        [icon]="ICON.delete"
                        class="xs-ml-30"
                        iconStyleClass="xs-font-size-intermediate-imp"
                        type="icon">
                </xs-button>
            </div>
        </div>
    </div>
</div>
