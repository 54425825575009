import {Component, Input} from '@angular/core';
import {XSCoreContextService, XSCoreEnvironmentService} from '@xs/core';

@Component({selector: 'lce-template-footer', templateUrl: './lce-template-footer.component.html', styles: [':host {width: 100%;}']})
export class LCETemplateFooterComponent {
    
    readonly copyrightCurrentYear: number = new Date().getFullYear();

    @Input() styleClass?: string;

    appDisplayName: string;
    appVersion: string;
    builtEnvironmentName: string;
    configEnvironmentName: string;
    isProduction: boolean;

    constructor(environmentService: XSCoreEnvironmentService, contextService: XSCoreContextService) {
        this.appDisplayName = environmentService.getAppDisplayName();
        this.appVersion = environmentService.getAppVersion();
        this.builtEnvironmentName = environmentService.getBuiltEnvironmentName();
        this.configEnvironmentName = contextService.getAppConfig().environment;
        this.isProduction = contextService.getAppConfig().production;
    }
}
