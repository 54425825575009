import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSUtils} from '@xs/base';
import {XS_COMMON_ICON, XSFormUtils, XSLoaderService, XSPhoneNumberUtils, XSValidatorPhoneNumber} from '@xs/common';
import {max} from 'rxjs/operators';
import {LCEFacilityStampTokenManagerService} from '../lce-facility-stamp-token-manager.service';
import {LCEFacilityTownHallStampTokenCreateSettings} from '../lce-facility-town-hall-stamp-token';

@Component({selector: 'lce-facility-town-hall-stamp-token-create', templateUrl: 'lce-facility-town-hall-stamp-token-create.component.html'})
export class LCEFacilityTownHallStampTokenCreateComponent implements OnInit, OnChanges {

    readonly LOADER_ID = XSUtils.uuid() + 'lceFacilityTownHallStampTokenCreate';

    readonly ICON_MOBILE = XS_COMMON_ICON.mobile;

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;
    @Input() descriptionStyleClass?: string;
    @Input() subDescriptionStyleClass?: string;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() description?: string;
    @Input() subDescription?: string;

    @Input() maxNumberOfStamps?: number;

    @Input() loading: boolean;

    @Input() error: any;

    @Output() createEvent = new EventEmitter<{ numberOfStamps: number; mobilePhoneNumber?: string }>();

    countryISO: string = 'ci';

    formGroup: FormGroup = new FormGroup({});
    numberOfStampsControl: FormControl;
    mobilePhoneNumberControl: FormControl;

    settings: LCEFacilityTownHallStampTokenCreateSettings;
    protected readonly max = max;

    constructor(
        private loaderService: XSLoaderService,
        private stampTokenManagerService: LCEFacilityStampTokenManagerService) {
        this.stampTokenManagerService.onCreateSettingsChange.subscribe(event => this.updateSettings(event));
    }

    get numberOfStamps(): number {
        const value = this.numberOfStampsControl.value;
        if (XSUtils.isEmpty(value)) return 0;
        return parseInt(value);
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.title)) this.title = 'lce.shared.facility.townHallStampToken.create.title';
        if (XSUtils.isNull(this.maxNumberOfStamps)) this.maxNumberOfStamps = 15;

        this.numberOfStampsControl = new FormControl(undefined, [Validators.max(this.maxNumberOfStamps!)]);
        this.mobilePhoneNumberControl = new FormControl(undefined, XSValidatorPhoneNumber.isValid(this.countryISO));
        this.formGroup.addControl('numberOfStamps', this.numberOfStampsControl);
        this.formGroup.addControl('mobilePhoneNumber', this.mobilePhoneNumberControl);

        this.settings = this.stampTokenManagerService.getCreateSettings();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isNull(changes.loading) && !changes.loading!.isFirstChange()) {
            if (this.loading) this.loaderService.startLoader(this.LOADER_ID);
            else {
                this.loaderService.stopLoader(this.LOADER_ID);
                this.numberOfStampsControl.setValue(undefined);
                this.mobilePhoneNumberControl.setValue(undefined);
            }
        }
    }

    public isFormValid(): boolean {
        if (this.numberOfStamps <= 0) return false;
        XSFormUtils.validateFormGroup(this.formGroup);
        return this.formGroup.valid;
    }

    public onKeyUp(): void {
        const nStamps = this.numberOfStamps;
        if (nStamps > this.maxNumberOfStamps!) this.numberOfStampsControl.setValue(this.maxNumberOfStamps!);
    }

    public onKeyEnterHit(): void {
        if (!this.settings.createOnEnter) return;
        this.create();
    }

    public onNumberClick(nStamps: number): void {
        this.numberOfStampsControl.setValue(nStamps);
        if (!this.settings.createOnEnter) return;
        this.create();
    }

    public create(): void {
        if (!this.isFormValid()) return;

        let mobilePhoneNumber = this.mobilePhoneNumberControl.value?.trim();
        if (XSUtils.isEmpty(mobilePhoneNumber)) mobilePhoneNumber = undefined;
        const event = {
            numberOfStamps: this.numberOfStamps,
            mobilePhoneNumber: XSPhoneNumberUtils.cleanPhoneNumber(mobilePhoneNumber)
        };
        this.createEvent.emit(event);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    private updateSettings(settings: LCEFacilityTownHallStampTokenCreateSettings): void {
        this.settings = settings;
    }
}
