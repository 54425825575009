import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

// TODO moved to stamp load. Note errorMessageStampRemainingRetrieve has been deleted form the translation files.
@Component({
    selector: 'lce-facility-town-hall-stamp-remaining',
    template: `
        <div class="xs-position-relative xs-min-width-350 {{styleClass}}">
            <div class="xs-absolute-center-vh">
                <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="20"></xs-loader>
            </div>
            <div *ngIf="hasError() && !loader.isRunning()">
                <xs-error
                        [error]="error"
                        [showActionButton]="true"
                        [showErrorDetailButton]="true"
                        message="{{ TR_BASE_LABEL }}errorMessageStampRemainingRetrieve"
                        mode="inline"
                        subMessage="xs.core.error.contactAdminMessage">
                </xs-error>
            </div>
            <xs-statistic-tile
                    *ngIf="!loader.isRunning()"
                    [icon]=" showIcon? ICON_STAMP : undefined"
                    [loading]="loading"
                    [error]="error"
                    [showBackground]="false"
                    iconSize="small"
                    [showSeparator]="true"
                    [value]="stampsRemaining"
                    orientation="horizontal"
                    styleClass="xs-min-width-350 xs-width-fit-content"
                    [subText]="townHallFullName"
                    [text]="TR_BASE_LABEL + 'stampRemaining'">
            </xs-statistic-tile>
        </div>
    `
})
export class LCEFacilityTownHallStampRemainingComponent implements OnInit {

    readonly ICON_STAMP = LCE_SHARED_ICON.stamp;

    readonly LOADER_ID = XSUtils.uuid() + 'facilityTownLoadStampRemaining';

    readonly TR_BASE_LABEL = 'lce.shared.facility.townHallStampToken.label.';

    @Input() styleClass?: string;

    @Input() townHallFullName: string;

    @Input() showLoaderText?: boolean;

    @Input() showIcon?: boolean;

    loading: boolean;

    error: any;

    stampsRemaining: number;

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.townHallFullName, 'townHallFullName');
        this.update();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public update(): void {
        this.retrieve();
    }

    private retrieve(): void {
        this.stampsRemaining = 300;
        this.loaderService.startLoader(this.LOADER_ID, {text: this.showLoaderText ? this.TR_BASE_LABEL + 'stampRemainingLoading' : undefined});
        setTimeout(() => this.loaderService.stopLoader(this.LOADER_ID), 5000);
    }
}
