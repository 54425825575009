import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-facility-town-hall-stamp-load-record', templateUrl: './lce-facility-town-hall-stamp-load-record.component.html'})
export class LCEFacilityTownHallStampLoadRecordComponent extends XSDialogable implements OnInit {

    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid() + 'stampLoad';

    readonly TR_BASE: string = 'lce.shared.facility.stampLoad';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() facilityTownHallStampLoadID?: string;
    @Input() facilityTownHallStampLoad: LCEFacilityTownHallStampLoad;

    @Input() editable?: boolean;

    @Output() closeEvent = new EventEmitter<LCEFacilityTownHallStampLoad>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveFacility()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService
    ) {
        super();
    }

    get headerTitle(): string {
        let title = XSUtils.isEmpty(this.facilityTownHallStampLoad) ? '...' : 'lce.shared.facility.townHallStampLoad.main.title';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.facilityTownHallStampLoad) ? '...' : this.facilityTownHallStampLoad!.facility.name;
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facilityTownHallStampLoadID = this.dialogConfig.data.facilityTownHallStampLoadID;
            this.facilityTownHallStampLoad = this.dialogConfig.data.facilityTownHallStampLoad;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.facilityTownHallStampLoad));
        }

        if (XSUtils.isEmpty(this.facilityTownHallStampLoadID) && XSUtils.isEmpty(this.facilityTownHallStampLoad)) {
            throw new Error('facilityTownHallStampLoadID and facilityTownHallStampLoad cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityTownHallStampLoadID) && !XSUtils.isEmpty(this.facilityTownHallStampLoad)) {
            throw new Error('facilityTownHallStampLoadID and facilityTownHallStampLoad cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityTownHallStampLoadID)) this.retrieveFacility();

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canEdit(): boolean {
        return this.editable !== false;
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.facilityTownHallStampLoad);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public retrieveFacility(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.facilityTownHallStampLoadService
                .retrieve(this.facilityTownHallStampLoadID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (facility) => {
                        this.facilityTownHallStampLoad = facility;
                    },
                    error: error => (this.error = error)
                })
        );
    }
}
