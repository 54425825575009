<div class="{{ styleClass }}">
    <lce-user-avatar
            (avatarClickEvent)="userOverlay.toggle($event)"
            [clickable]="true"
            [data]="user"
            [showStatus]="false"
            avatarSize="medium">
    </lce-user-avatar>
    <p-overlayPanel #userOverlay styleClass="xs-min-width-500 {{ overlayStyleClass }}">
        <lce-user-detail-head
                (accountClickEvent)="openAccountDialog(); userOverlay.hide();"
                (logoutClickEvent)="onLogout()"
                [account]="viewAccount"
                [data]="user"
                [loggingOut]="loggingOut"
                [logout]="showLogoutIcon"
                class="xs-width-full-imp">
        </lce-user-detail-head>
    </p-overlayPanel>
</div>
