<div class="lce-facility-town-hall-stamp-token-create xs-card xs-width-full {{styleClass}}">
    <div class="xs-card-header xs-pt-0-imp">
        <span class="xs-card-header-title {{ titleStyleClass }}"> {{ title! | translate }}</span>
        <span
                *ngIf="subtitle | xsIsNotEmpty"
                [innerHTML]="subtitle! | translate: {maxNumberOfStamps: maxNumberOfStamps}"
                class="xs-card-header-subTitle xs-font-size-small {{ subtitleStyleClass }}">
        </span>
    </div>
    <div class="xs-card-content xs-position-relative xs-pt-30-imp xs-max-width-400">
        <div *ngIf="error" class="xs-absolute-center">
            <xs-error
                    [showErrorDetailButton]="true"
                    message="lce.shared.facility.townHallStampToken.error.create"
                    mode="block"
                    styleClass="xs-max-width-500"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>

        <xs-input-phone-number
                *ngIf="settings.useMobilePhoneNumber"
                [countryISO]="countryISO"
                [disabled]="isLoaderRunning()"
                [errorDisplay]="false"
                [formControl]="mobilePhoneNumberControl"
                [maxLength]="14"
                [showCountryFlag]="false"
                class="xs-width-full"
                placeholder="lce.shared.facility.townHallStampToken.create.mobilePhoneNumberPlaceholder"
                styleClass="xs-mb-20 lce-facility-town-hall-stamp-token-phone-number">
        </xs-input-phone-number>

        <div [ngClass]="{ 'xs-mt-25': !settings.useMobilePhoneNumber}" class="xs-flex-row xs-flex-wrap">
            <div class="xs-flex-column xs-mr-30 xs-mb-20">
                <input
                        (keyup)="onKeyUp()"
                        (keyup.enter)="onKeyEnterHit()"
                        [disabled]="isLoaderRunning()"
                        [formControl]="numberOfStampsControl"
                        class="lce-facility-town-hall-stamp-token-number-of-stamps"
                        pInputText
                        placeholder="0"
                        type="number">
                <xs-button
                        (clickEvent)="create()"
                        [disabled]="(numberOfStamps | xsIsNull) || isLoaderRunning() || !isFormValid()"
                        [loaderId]="LOADER_ID"
                        [loading]="isLoaderRunning()"
                        class="xs-width-full"
                        label="lce.shared.facility.townHallStampToken.create.generate"
                        loaderPosition="bottom"
                        size="intermediate"
                        styleClass="xs-width-full"
                        type="button">
                </xs-button>
            </div>
            <div class="xs-flex-column">
                <div class="xs-flex-row xs-mb-20">
                    <xs-chip
                            (clickEvent)="onNumberClick(2)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 2"
                            label="2"
                            styleClass="xs-mr-20 xs-plr-30-imp">
                    </xs-chip>
                    <xs-chip
                            (clickEvent)="onNumberClick(3)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 3"
                            label="3"
                            styleClass="xs-plr-30-imp">
                    </xs-chip>
                </div>
                <div class="xs-flex-row xs-mb-20">
                    <xs-chip
                            (clickEvent)="onNumberClick(4)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 4"
                            label="4"
                            styleClass="xs-mr-20 xs-plr-30-imp"></xs-chip>
                    <xs-chip
                            (clickEvent)="onNumberClick(5)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 5"
                            label="5"
                            styleClass="xs-plr-30-imp">
                    </xs-chip>
                </div>
                <div class="xs-flex-row xs-mb-20">
                    <xs-chip
                            (clickEvent)="onNumberClick(6)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 6"
                            label="6"
                            styleClass="xs-mr-20 xs-plr-30-imp">
                    </xs-chip>
                    <xs-chip
                            (clickEvent)="onNumberClick(7)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 7"
                            label="7"
                            styleClass="xs-plr-30-imp">
                    </xs-chip>
                </div>
                <div class="xs-flex-row">
                    <xs-chip
                            (clickEvent)="onNumberClick(8)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()" [primary]="numberOfStamps === 8"
                            label="8"
                            styleClass="xs-mr-20 xs-plr-30-imp">
                    </xs-chip>
                    <xs-chip
                            (clickEvent)="onNumberClick(9)"
                            [clickable]="true"
                            [disabled]="isLoaderRunning()"
                            [primary]="numberOfStamps === 9"
                            label="9"
                            styleClass="xs-plr-30-imp">
                    </xs-chip>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="description | xsIsNotEmpty" class="xs-card-footer xs-pb-0-imp xs-sm-display-none">
        <span
                [innerHTML]="description! | translate"
                class="xs-text-block-small-500 {{ descriptionStyleClass }}">
        </span>
        <span
                *ngIf="subDescription | xsIsNotEmpty"
                [innerHTML]="subDescription! | translate"
                class="xs-text-block-extra-small xs-mt-1 {{ subDescriptionStyleClass }}">
        </span>
    </div>
</div>
