import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LCESuggestion, LCESuggestionStatus, LCEUserPartial} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSStyleHelper} from '@xs/core';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';

@Component({selector: 'lce-suggestion-status', templateUrl: './lce-suggestion-status.component.html'})
export class LCESuggestionStatusComponent implements OnInit, OnChanges {

    readonly TR_BASE_STATUS: string = 'lce.core.suggestionStatus.';
    readonly TR_STATUS_DATE_TIME_PREFIX: string = 'lce.shared.suggestion.statusDateTimePrefix.';

    @Input() styleClass?: string;

    @Input() disabled?: boolean;
    @Input() ellipsis?: boolean;

    @Input() data: LCESuggestion;

    statusStr: string;

    auditOn: string;
    auditBy: LCEUserPartial;

    constructor(private translationService: XSTranslationService) {
    }

    get isUnread(): boolean {
        return this.data.status === LCESuggestionStatus.UNREAD;
    }

    get isRead(): boolean {
        return this.data.status === LCESuggestionStatus.READ;
    }

    get isConsidered(): boolean {
        return this.data.status === LCESuggestionStatus.CONSIDERED;
    }


    get isSelected(): boolean {
        return this.data.status === LCESuggestionStatus.SELECTED;
    }

    get statusColorStyleClass(): string {
        return XSUtils.isNull(this.data) ? XSStyleHelper.CLASS.color.secondaryImp : LCESharedUtils.getSuggestionColorStatusStyleClass(this.data!.status);
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.ellipsis)) {
            this.ellipsis = true;
        }
        this.update();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            this.update();
        }
    }

    public update(): void {
        switch (this.data.status) {
            case LCESuggestionStatus.READ:
                this.statusStr = this.TR_BASE_STATUS + LCESuggestionStatus.READ;
                this.auditOn = this.data.readOn!;
                this.auditBy = this.data.readBy!;
                break;
            case LCESuggestionStatus.UNREAD:
                this.statusStr = this.TR_BASE_STATUS + LCESuggestionStatus.UNREAD;
                this.auditOn = this.data.unreadOn!;
                this.auditBy = this.data.unreadBy!;
                break;
            case LCESuggestionStatus.CONSIDERED:
                this.statusStr = this.TR_BASE_STATUS + LCESuggestionStatus.CONSIDERED;
                this.auditOn = this.data.consideredOn!;
                this.auditBy = this.data.consideredBy!;
                break;
            case LCESuggestionStatus.SELECTED:
                this.statusStr = this.TR_BASE_STATUS + LCESuggestionStatus.SELECTED;
                this.auditOn = this.data.selectedOn!;
                this.auditBy = this.data.selectedBy!;
                break;
        }
    }
}
