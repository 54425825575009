<div
        [ngClass]="{ 'xs-border-discrete': showBorder === true }"
        [style.box-shadow]="'4px -2px 9px 6px var(--xs-color-discrete)'"
        class="xs-flex-column xs-width-full xs-p-15 xs-border-radius-default {{ styleClass }}">
    <lce-news-article-user-comment [comment]="comment"></lce-news-article-user-comment>
    <p class="xs-font-size-intermediate xs-mt-12-imp xs-mb-10-imp">{{ comment.content }}</p>
    <xs-time-ago
            [givenDate]="comment.createdOn"
            [live]="true"
            [shortMode]="true"
            styleClass="xs-display-block xs-color-secondary xs-font-size-small xs-font-weight-500">
    </xs-time-ago>
</div>
