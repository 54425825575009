import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCEDistrictPartial, LCEDistrictService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCEDistrictDialogService} from '../../../district/lce-district-dialog.service';

@Component({
    selector: 'lce-global-search-districts-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-district-partial [data]="oneResult" [showIcon]="true"></lce-district-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCEDistrictDialogService]
})
export class LCEGlobalSearchDistrictsTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.DISTRICT;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCEDistrictPartial> = {
        search: searchFilter => this.districtService.search(searchFilter),
        openRecord: districtPartial => this.districtDialogService.openDistrictRecordDialog({districtID: districtPartial.id}),
        captionNResultFound: 'lce.shared.district.main.resultFound',
        biColumnResults: true,
        listingStyleClass: 'xs-max-width-500-imp',
        contentStyleClass: 'xs-max-width-1000-imp'
    }

    constructor(private districtService: LCEDistrictService, private districtDialogService: LCEDistrictDialogService) {
    }
}
