<div class="xs-position-relative">
    <xs-table
        (onPagination)="paginationEvent.emit($event)"
        (onResetPreferences)="resetPreferencesEvent.emit()"
        (onResetSort)="resetSortEvent.emit()"
        (onRowDoubleClick)="onViewRecord($event)"
        (onSort)="sortEvent.emit($event)"
        [caption]="caption"
        [columns]="columns"
        [data]="data"
        [emptyMessage]="emptyMessage"
        [loadingState]="loadingState"
        [nRecords]="nResults"
        [options]="options"
        [subCaption]="subCaption"
        captionStyleClass="xs-font-size-medium xs-font-weight-500"
        columnsStyleClass="xs-color-secondary xs-font-size-percent-90"
        subCaptionStyleClass="xs-font-size-percent-90"
        tableStyleClass="xs-table-border xs-table-border-discrete">
        <ng-template let-oneData xsTemplate="eventName">
            <lce-event-name-type [eventName]="oneData.eventName" [type]="oneData.type"></lce-event-name-type>
        </ng-template>
        <ng-template let-oneData xsTemplate="resource">
            <lce-event-resource [data]="{ resourceID: oneData.resourceID, resourceCode: oneData.resourceCode, resourceType: oneData.resourceType }"></lce-event-resource>
        </ng-template>
        <ng-template let-oneData xsTemplate="data">
            <div class="xs-flex-column">
                <lce-event-data-row [data]="oneData.data" [resourceType]="oneData.resourceType"></lce-event-data-row>
            </div>
        </ng-template>
    </xs-table>
</div>
