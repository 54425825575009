<div class="lce-events-main {{ styleClass }}">
    <div class="lce-events-main-container">
        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-row-center xs-width-full xs-mt-20">
            <div class="xs-field xs-field-input xs-field-input-search xs-max-width-750">
                <label class="xs-font-size-medium-imp xs-text-primary-color-imp" for="{{ searchText }}">{{ TR_BASE + 'main.searchEvent' | translate }}</label>

                <xs-input-search
                    (changeEvent)="search()"
                    [(value)]="searchText"
                    [delay]="SEARCH_TEXT_DELAY"
                    [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                    [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                    [placeholder]="TR_BASE + 'main.searchEvent'"
                    [searching]="searching"
                    inputId="searchText">
                </xs-input-search>
                <div class="xs-flex-row xs-justify-content-end xs-mt-10 xs-pr-10">
                    <xs-button
                        (clickEvent)="showFilter = !showFilter"
                        [icon]="showFilter ? ICON.minus : ICON.plus"
                        iconStyleClass="xs-font-size-small"
                        label="lce.shared.event.label.advancedSearch"
                        size="intermediate"
                        type="text"></xs-button>
                </div>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Quick Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div *ngIf="showFilter" class="xs-width-full xs-flex-row xs-justify-content-center">
            <lce-event-filters [showBorder]="true" styleClass="xs-mtb-50 xs-max-width-1000"></lce-event-filters>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Table === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <lce-events-table
                (paginationEvent)="onPagination($event)"
                (resetPreferencesEvent)="onResetPreferences()"
                (resetSortEvent)="onResetSort()"
                (sortEvent)="onSort($event)"
                [caption]="tableCaption"
                [data]="tableData"
                [loadingState]="tableLoadingState"
                [nResults]="tableNResults"
                [readonly]="readonly"
                [subCaption]="tableSubCaption">
            </lce-events-table>
        </div>
    </div>
</div>
