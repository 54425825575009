import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESuggestionService, LCESuggestionStatsStatuses, LCESuggestionStatus} from '@lce/core';
import {XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XS_TR_BASE_CORE_PERIOD, XSInputDropdownOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {XSLabelValueItem, XSTranslationService} from '@xs/common';
import {LCESharedService} from '../../api/services/lce-shared.service';

@Component({
    selector: 'lce-suggestion-lateral-panel',
    templateUrl: './lce-suggestion-lateral-panel.component.html',
    host: {class: 'xs-width-full'}
})
export class LCESuggestionLateralPanelComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly STATUS = LCESuggestionStatus;

    readonly TR_BASE: string = 'lce.shared.suggestion.';

    readonly TR_BASE_CORE_PERIOD: string = XS_TR_BASE_CORE_PERIOD;

    @Input() styleClass?: string;

    @Input() municipalityCode: string;

    @Input() showBackgroundIcon?: boolean;

    @Output() statClickEvent = new EventEmitter<LCESuggestionStatus | undefined>();
    @Output() selectedByMayorClickEvent = new EventEmitter<number>();

    data: LCESuggestionStatsStatuses;

    loading: boolean = false;
    error: any;

    predefinedPeriod: XSPredefinedPeriod = XSPredefinedPeriod.THIS_MONTH;
    periodFilterItems: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE_CORE_PERIOD + XSPredefinedPeriod.YESTERDAY, value: XSPredefinedPeriod.YESTERDAY},
        {trLabel: this.TR_BASE_CORE_PERIOD + XSPredefinedPeriod.TODAY, value: XSPredefinedPeriod.TODAY},
        {trLabel: this.TR_BASE_CORE_PERIOD + XSPredefinedPeriod.THIS_WEEK, value: XSPredefinedPeriod.THIS_WEEK},
        {trLabel: this.TR_BASE_CORE_PERIOD + XSPredefinedPeriod.THIS_MONTH, value: XSPredefinedPeriod.THIS_MONTH},
        {trLabel: this.TR_BASE_CORE_PERIOD + XSPredefinedPeriod.THIS_YEAR, value: XSPredefinedPeriod.THIS_YEAR}
    ];
    periodFilterOptions: XSInputDropdownOptions = {
        labelField: 'label',
        valueField: 'value',
        showClear: false,
        showBorder: false
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private sharedService: LCESharedService,
        private suggestionService: LCESuggestionService) {

        this.translationService.translateItems(this.periodFilterItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.periodFilterItems);
                this.periodFilterItems = [...this.periodFilterItems];
            })
        );
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.data)) {
            this.update();
        }
        this.sharedService.onSuggestionStatusChanged.subscribe(() => this.update());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onPeriodFilterChange(): void {
        this.update();
    }

    public update(): void {
        this.loading = true;
        this.error = undefined;
        this.subscription.add(
            this.suggestionService.retrieveStatsStatuses(this.municipalityCode, this.predefinedPeriod)
                .pipe(finalize(() => this.loading = false))
                .subscribe({
                    next: statsStatuses => this.data = statsStatuses,
                    error: error => this.error = error
                })
        );
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }
}
