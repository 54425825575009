import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LCEMBOModule } from '@lce-mbo/app/lce-mbo.module';
import { environment } from '@lce-mbo/environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(LCEMBOModule)
    .catch((err) => console.error(err));
