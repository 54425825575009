import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCEUserCustomer, LCEUserCustomerPartial} from '@lce/core';
import {XSPagination, XSSort, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {XSCalendarOptions, XSClipboardService, XSMenuItem, XSTableColumn, XSTableOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCEUserCustomerDialogService} from '../lce-user-customer-dialog.service';
import {LCEUserCustomersFeatureService} from '../lce-user-customers-feature.service';

@Component({
    selector: 'lce-user-customers-table',
    templateUrl: './lce-user-customers-table.component.html',
    providers: [LCEUserCustomerDialogService, LCEUserCustomersFeatureService]
})
export class LCEUserCustomersTableComponent implements OnInit, OnDestroy {

    readonly TR_BASE = 'lce.shared.customer.table.';

    @Input() data: LCEUserCustomerPartial[] = [];

    @Input() nResults: number = 0;

    @Input() caption: XSText;
    @Input() subCaption?: XSText;

    @Input() loadingState: XSLoaderState;

    @Input() readonly?: boolean;

    @Output() resetPreferencesEvent = new EventEmitter();
    @Output() resetSortEvent = new EventEmitter();
    @Output() paginationEvent = new EventEmitter<XSPagination>();
    @Output() sortEvent = new EventEmitter<XSSort>();

    options: XSTableOptions;
    columns: XSTableColumn[];

    emptyMessage: XSText = {value: this.TR_BASE + 'noResultFound'};

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    private readonly ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];

    private subscription: Subscription = new Subscription();

    constructor(
        private clipboardService: XSClipboardService,
        private translationService: XSTranslationService,
        private customerDialogService: LCEUserCustomerDialogService,
        private featureService: LCEUserCustomersFeatureService
    ) {
    }

    ngOnInit(): void {
        this.buildTableOptions();
        this.buildTableColumns();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onViewCustomer(selection: LCEUserCustomer): void {
        this.customerDialogService.openRecordDialog({customerID: selection.id});
    }

    private buildTableColumns(): void {
        this.columns = [
            {
                field: 'id',
                header: 'ID',
                visible: false,
                displayable: false
            },
            {
                field: 'code',
                header: 'xs.common.label.code',
                visible: true,
                displayable: true
            },
            {
                field: 'name',
                header: 'xs.common.label.name',
                visible: true,
                sortable: true,
                sortableField: 'name.firstName'
            },
            {
                field: 'contact',
                header: 'xs.core.label.contact',
                visible: true,
                displayable: true
            },
            {
                field: 'created',
                header: 'lce.shared.customer.label.create',
                visible: true,
                displayable: true,
                sortable: true,
                sortableField: 'createdOn'
            },
            {
                field: 'registered',
                header: 'lce.shared.customer.label.registration',
                visible: true,
                displayable: true,
                sortable: true,
                sortableField: 'registeredOn'
            }
        ];
    }

    private buildTableOptions(): void {
        this.options = {
            id: 'customerTable',
            localStorageKey: 'customer.table',
            dataUniqueField: 'id',
            lazy: true,
            rowHover: true,
            selectionMode: 'single',
            contextMenu: true,
            sortMode: 'multiple',
            paginator: true,
            simplePaginator: true,
            paginatorRows: this.featureService.pagination.size,
            paginatorRowsPerPageOptions: this.ROWS_PER_PAGE_OPTIONS,
            resizableColumns: true,
            reorderableColumns: true,
            dynamicColumns: true,
            toolbar: {
                showExport: true,
                showPreferences: true,
                showResetSort: true,
                showResetColumnWidths: true
            },
            preferences: {
                showResetColumnWidths: true,
                showResetSort: true,
                showResetDefaultPreferences: true
            },
            exportOptions: {
                fileNameWithoutExtension: 'customersResults',
                pdfOptions: {
                    pageWidthInPx: 1000,
                    firstPageMarginTop: 70,
                    title: 'Customers From 2023-01-01 To 2023-01-31',
                    textTopLeft: 'LCE (La Commune Électronique)',
                    textTopRight: 'Customers [{{nResults}}]',
                    textBottomRight: 'Generated By Ryane Alla',
                    cellAlignCenter: false,
                    autoComputePageWidth: false
                },
                xlsxOptions: {
                    worksheetName: 'Customers',
                    title: 'This is a test Title',
                    subject: 'Customers',
                    author: 'LCE (La Commune Électronique)',
                    company: 'Iro-XS Inc.',
                    keywords: ['customer', 'customer'],
                    comments: 'Generated By Ryane Alla'
                }
            },
            buildContextMenuItems: (selection: LCEUserCustomer) => this.buildContextMenuItems(selection),
            loaderSize: 30
        };
    }

    private buildContextMenuItems(selection: LCEUserCustomer): XSMenuItem[] {
        return [
            {label: this.translationService.translateKey(this.TR_BASE + 'viewCustomerDetail'), command: () => this.onViewCustomer(selection)},
            {label: this.translationService.translateKey('xs.common.label.primaryPhoneNumber'), command: () => this.copyPrimaryPhoneNumber(selection)},
            {label: this.translationService.translateKey('xs.common.label.email'), command: () => this.copyEmail(selection)}
        ];
    }

    private copyPrimaryPhoneNumber(selection: LCEUserCustomer): void {
        this.clipboardService.copy(selection.primaryPhoneNumber);
    }

    private copyEmail(selection: LCEUserCustomer): void {
        if (XSUtils.isNull(selection.email)) this.clipboardService.copy(selection.email!);
    }
}
