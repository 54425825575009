import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFR from '@angular/common/locales/fr';
import {APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {LCE_MBO_ENDPOINT} from '@lce-mbo/app/core/constants/lce-mbo-endpoint.constant';
import {LCE_MBO_ICON} from '@lce-mbo/app/core/constants/lce-mbo-icon.constant';
import {LCEMBOErrorHelper} from '@lce-mbo/app/core/helpers/lce-mbo-error.helper';
import {LCEMBOHttpInterceptor} from '@lce-mbo/app/core/interceptors/lce-mbo-http-interceptor';
import {LCEMBORoutingModule} from '@lce-mbo/app/lce-mbo-routing.module';
import {LCEMBOComponent} from '@lce-mbo/app/lce-mbo.component';
import {LCEMBOErrorHandler} from '@lce-mbo/core/handlers/lce-mbo-error-handler';
import {environment} from '@lce-mbo/environments/environment';
import {ADDITIONAL_IMPORTS, ADDITIONAL_PROVIDERS} from '@lce-mbo/environments/environment-common';
import {LCEMBOAppInitializerService} from '@lce-mbo/services/lce-mbo-app-initializer.service';
import {LCEMBOContextService} from '@lce-mbo/services/lce-mbo-context.service';
import {LCEMBOEnvironmentService} from '@lce-mbo/services/lce-mbo-environment.service';
import {LCEMBOErrorService} from '@lce-mbo/services/lce-mbo-error.service';
import {LCEMBOEventProcessorService} from '@lce-mbo/services/lce-mbo-event-processor.service';
import {LCEMBOUserAuthenticationService} from '@lce-mbo/services/lce-mbo-user-authentication.service';
import {LCECoreContextService, LCEEnvironmentService, LCEHttpClientService, LCEUserMunicipalEmployeeAuthenticationService, LCEUserMunicipalEmployeeService, LCEUserService} from '@lce/core';
import {LCEEventProcessorService, LCESharedAuthorizationService, LCESharedContextService} from '@lce/shared';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {XSAppInitializerBaseService, XSBaseContextService, XSBaseEnvironmentService, XSHttpClientService, XSLoggerService, XSStorageService, XSWebSocketClientService} from '@xs/base';
import {XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, XSCommonContextService, XSCommonEnvironmentService, XSCountryService, XSLoaderType} from '@xs/common';
import {
    XS_TOKEN_CORE_ENDPOINT_GEOLOCATE,
    XS_TOKEN_CORE_ICON,
    XSAppInitializerCoreService,
    XSAuthenticationBaseService,
    XSCoreContextService,
    XSCoreEnvironmentService,
    XSCoreModule,
    XSEnsureModuleImportedOnce,
    XSErrorHelper,
    XSErrorService,
    XSLocalStorageService
} from '@xs/core';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {LCEMBOAuthenticationComponent} from './authentication/lce-mbo-authentication.component';
import {LCEMBOAuthenticationService} from './core/services/lce-mbo-authentication.service';
import {LCEMBOAuthorizationService} from '@lce-mbo/services/lce-mbo-authorization.service';

// ------------------------------------------------------------------------------------------------------
// ---------------------------------------- Translation -------------------------------------------------
// ------------------------------------------------------------------------------------------------------

registerLocaleData(localeFR);

export function HttpLoaderFactory(httpBackend: HttpBackend) {
    const version = Date.now(); // Workaround to force the browser to take the new version of translation files if needed.
    return new MultiTranslateHttpLoader(new HttpClient(httpBackend), [
        {prefix: './assets/i18n/xs-primeng/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-common/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-base/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-checkout/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-core-authentication/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-country/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-shared/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-template/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-features/', suffix: '.json?v=' + version}
    ]);
}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------

@NgModule({
    declarations: [
        // ***
        LCEMBOComponent,
        LCEMBOAuthenticationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.common.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        LoggerModule.forRoot({level: NgxLoggerLevel.TRACE, timestampFormat: XSLoggerService.TIMESTAMP_FORMAT}),
        XSCoreModule,
        LCEMBORoutingModule,
        ADDITIONAL_IMPORTS
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        // --- * ---
        ADDITIONAL_PROVIDERS,
        // --- Initializers ---
        {provide: XSAppInitializerBaseService, useExisting: LCEMBOAppInitializerService},
        {provide: XSAppInitializerCoreService, useExisting: LCEMBOAppInitializerService},
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializerService: LCEMBOAppInitializerService) => () => appInitializerService.initialize(),
            deps: [LCEMBOAppInitializerService],
            multi: true // means we could have multiple APP_INITIALIZER.
        },
        // --- Interceptors ---
        {provide: HTTP_INTERCEPTORS, useClass: LCEMBOHttpInterceptor, multi: true},

        // --- Services ---
        {provide: XSHttpClientService, useExisting: LCEHttpClientService},

        {provide: XSErrorHelper, useExisting: LCEMBOErrorHelper},
        {provide: ErrorHandler, useExisting: LCEMBOErrorHandler},
        {provide: XSErrorService, useExisting: LCEMBOErrorService},

        {provide: XSBaseEnvironmentService, useExisting: LCEMBOEnvironmentService},
        {provide: XSCommonEnvironmentService, useExisting: LCEMBOEnvironmentService},
        {provide: XSCoreEnvironmentService, useExisting: LCEMBOEnvironmentService},
        {provide: LCEEnvironmentService, useExisting: LCEMBOEnvironmentService},

        {provide: XSBaseContextService, useExisting: LCEMBOContextService},
        {provide: XSCommonContextService, useExisting: LCEMBOContextService},
        {provide: XSCoreContextService, useExisting: LCEMBOContextService},
        {provide: LCECoreContextService, useExisting: LCEMBOContextService},
        {provide: LCESharedContextService, useExisting: LCEMBOContextService},

        {provide: XSStorageService, useExisting: XSLocalStorageService},

        {provide: XSAuthenticationBaseService, useExisting: LCEMBOAuthenticationService},
        {provide: LCEUserMunicipalEmployeeAuthenticationService, useExisting: LCEMBOUserAuthenticationService},
        {provide: LCEUserService, useExisting: LCEUserMunicipalEmployeeService},

        {provide: LCESharedAuthorizationService, useExisting: LCEMBOAuthorizationService},

        {provide: LCEEventProcessorService, useExisting: LCEMBOEventProcessorService},

        // --- * ---
        XSWebSocketClientService,

        // --- * ---
        XSCountryService,

        // --- Tokens ---
        {provide: XS_TOKEN_CORE_ENDPOINT_GEOLOCATE, useValue: LCE_MBO_ENDPOINT.geolocate},
        {provide: XS_TOKEN_CORE_ICON, useValue: LCE_MBO_ICON},
        {provide: XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, useValue: XSLoaderType.RECTANGLE_BOUNCE}
    ],
    bootstrap: [LCEMBOComponent]
})
export class LCEMBOModule extends XSEnsureModuleImportedOnce {
    public constructor(@SkipSelf() @Optional() module: LCEMBOModule) {
        super(module);
    }
}
