import { Component, Input, OnInit } from '@angular/core';
import { XSAssert, XSUtils } from '@xs/base';
import { LCEResourceType } from '@lce/core';
import { XSTranslationService } from '@xs/common';

@Component({ selector: 'lce-event-data', templateUrl: './lce-event-data.component.html' })
export class LCEEventDataComponent implements OnInit {
    @Input() styleClass?: string;

    @Input() resourceType: LCEResourceType;

    @Input() data: any;

    constructor(private translationService: XSTranslationService) {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        XSAssert.notEmpty(this.resourceType, 'resourceType');
    }

    public isCertificateOrder(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.CERTIFICATE_ORDER;
    }

    public isSuggestion(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.SUGGESTION;
    }

    public isBirthDeclaration(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.BIRTH_DECLARATION;
    }

    public isNews(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.NEWS_ARTICLE;
    }

    public isSmartcity(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.SMARTCITY_ARTICLE;
    }

    public isDistrict(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.DISTRICT;
    }

    public isMunicipality(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.MUNICIPALITY;
    }

    public isFacility(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.FACILITY;
    }

    public isServicePoint(): boolean {
        return !XSUtils.isNull(this.resourceType) && this.resourceType === LCEResourceType.SERVICE_POINT;
    }

    buildArticleTitle(): string {
        const currentLanguage = this.translationService.getCurrentLanguage();
        return 'title' + currentLanguage.toString().toUpperCase();
    }
}
