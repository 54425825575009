import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCETemplateSTNComponent} from '@lce-mbo/templates/stn/lce-template-stn.component';
import {LCESharedModule} from '@lce/shared';
import {LCETemplateFooterComponent} from './common/lce-template-footer.component';
import {LCETemplateDefaultExceptionComponent} from './default/exception/lce-template-default-exception.component';
import {LCETemplateDefaultComponent} from './default/lce-template-default.component';
import {LCEHeaderComponent} from './sheldon/header/lce-header.component';
import {LCETemplateSheldonComponent} from './sheldon/lce-template-sheldon.component';
import {LCEVerticalSidebarComponent} from './sheldon/vertical-sidebar/lce-vertical-sidebar.component';

@NgModule({
    imports: [
        CommonModule,
        LCESharedModule
    ],
    declarations: [
        LCETemplateFooterComponent,
        LCETemplateDefaultComponent,
        LCETemplateDefaultExceptionComponent,
        LCETemplateSheldonComponent,
        LCEHeaderComponent,
        LCEVerticalSidebarComponent,
        LCETemplateSTNComponent
    ],
    exports: [
        LCESharedModule,
        LCETemplateFooterComponent,
        LCETemplateDefaultComponent,
        LCETemplateDefaultExceptionComponent,
        LCETemplateSheldonComponent,
        LCETemplateSTNComponent
    ]
})
export class LCETemplateModule {
}
