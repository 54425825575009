<div class="xs-flex-column-center xs-width-full xs-height-full {{ styleClass }}">
    <div class="lce-news-main-container">
        <!-- === Search & Filters === -->
        <div class="xs-width-full xs-flex-column-center xs-mb-15">
            <div class="xs-field xs-field-input xs-field-input-search xs-max-width-600">
                <label class="xs-font-size-20" for="searchText">{{ TR_BASE + 'searchLabel' | translate }}</label>
                <xs-input-search
                        (changeEvent)="onInputSearchChange()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'searchPlaceholder'"
                        [searching]="isSecondaryLoaderRunning()"
                        inputId="searchText">
                </xs-input-search>
            </div>
            <div class="xs-sm-display-none xs-flex-column-center xs-width-full xs-mt-30">
                <p-selectButton
                        (onChange)="onStatusFilterChange()"
                        [(ngModel)]="statusFilters"
                        [multiple]="true"
                        [options]="statusFilterOptions"
                        class="xs-width-full xs-max-width-600"
                        styleClass="xs-select-button xs-select-button-small xs-mb-15">
                </p-selectButton>
                <p-selectButton
                        (onChange)="onPredefinedFilterChange()"
                        [(ngModel)]="predefinedFilter"
                        [options]="predefinedFilterOptions"
                        class="xs-width-full xs-max-width-600"
                        styleClass="xs-select-button xs-select-button-small xs-mb-15">
                </p-selectButton>
            </div>
        </div>

        <!-- === Result Title & SubTitle === -->
        <div *ngIf="!primaryLoader.isRunning()" class="xs-flex-row xs-align-items-end xs-justify-content-space-between xs-border-bottom-discrete xs-width-full xs-mtb-30 xs-pb-10">
            <div class="xs-flex-row xs-align-items-end xs-position-relative">
                <xs-button [icon]="ICON.last" type="icon"></xs-button>
                <!-- === Error & Secondary Loader === -->
                <div class="xs-position-relative xs-ml-50 xs-top-minus-7">
                    <xs-loader [loaderId]="SECONDARY_LOADER_ID" [loaderSize]="25"></xs-loader>
                </div>
                <div class="xs-position-relative xs-ml-50 xs-max-width-400">
                    <xs-error
                            *ngIf="hasError() && !primaryLoader.isRunning() && !isSecondaryLoaderRunning() && !firstInitialization"
                            [error]="error"
                            [showErrorDetailButton]="true"
                            message="xs.core.label.somethingWentWrong"
                            mode="inline">
                    </xs-error>
                </div>
            </div>

            <div *ngIf="!hasError()" class="xs-flex-column-center">
                <span class="xs-display-block xs-font-size-medium xs-font-weight-500">{{ TR_BASE + 'resultsFound' | translate : {nResults: isSecondaryLoaderRunning() ? '...' : nArticles} }}</span>
                <span *ngIf="resultSubText | xsIsNotEmpty" class="xs-display-block xs-font-size-small xs-mt-4">{{ resultSubText }}</span>
            </div>

            <div class="xs-display-block">
                <xs-paginator-number
                        (paginationChange)="onPaginationNumberChange()"
                        *ngIf="isPaginable()"
                        [(pagination)]="pagination"
                        [ngClass]="{ 'xs-disabled': isSecondaryLoaderRunning() || hasError() }"
                        [pPaginator]="pPaginatorComponent"
                        [totalRecords]="nArticles"
                        styleClass="xs-height-fit-content-imp">
                </xs-paginator-number>
            </div>
        </div>

        <!-- === Listing === -->
        <div class="xs-card xs-flex-1">
            <div class="xs-card-content xs-align-items-center xs-justify-content-start xs-min-height-200">
                <div class="xs-absolute-center-vh">
                    <xs-loader #primaryLoader [loaderId]="PRIMARY_LOADER_ID" [loaderSize]="30"></xs-loader>
                </div>
                <div *ngIf="hasError() && !primaryLoader.isRunning() && firstInitialization" class="xs-absolute-center-vh">
                    <xs-error
                            [actionButton]="errorRetryButton"
                            [error]="error"
                            [showActionButton]="true"
                            [showErrorDetailButton]="true"
                            errorDetailButtonClass="xs-mt-5-imp"
                            message="lce.shared.news.label.errorMessageSearchArticles"
                            mode="block"
                            subMessage="xs.core.error.contactAdminMessage">
                    </xs-error>
                </div>
                <xs-no-result-found *ngIf="(articles | xsIsEmpty) && !primaryLoader.isRunning() && !hasError()" size="large" styleClass="xs-absolute-center-vh">
                </xs-no-result-found>
                <div
                        *ngIf="(articles | xsIsNotEmpty) && !primaryLoader.isRunning()"
                        [ngClass]="{ 'xs-disabled': isSecondaryLoaderRunning() }"
                        class="grid xs-width-full">
                    <div *ngFor="let article of articles" class="col-12 md:col-6 lg:col-4 xs-flex-row-center xs-justify-content-center xs-p-15">
                        <lce-news-article-card [data]="article"></lce-news-article-card>
                    </div>
                </div>
            </div>

            <!-- === Paginator === -->
            <div *ngIf="isPaginable()" class="xs-card-footer">
                <div class="xs-flex-row xs-justify-content-center xs-width-full">
                    <p-paginator
                        #pPaginator
                        (onPageChange)="handlePagination($event)"
                        [ngClass]="{ 'xs-disabled': isSecondaryLoaderRunning() || hasError() }"
                        [rowsPerPageOptions]="PAGINATOR_ROWS_PER_PAGE_OPTIONS"
                        [rows]="pagination.size"
                        [totalRecords]="nArticles">
                    </p-paginator>
                </div>
            </div>
        </div>
    </div>
</div>