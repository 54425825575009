import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEMunicipalityPartial} from '@lce/core';
import {XSAssert} from '@xs/base';
import {XSDialogable} from '@xs/core';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
	selector: 'lce-municipality-partials',
	templateUrl: './lce-municipality-partials.component.html'
})
export class LCEMunicipalityPartialsComponent extends XSDialogable implements OnInit {

	readonly TR_BASE_LABEL: string = 'lce.shared.suggestion.label.';

	@Input() styleClass?: string;
	@Input() labelStyleClass?: string;

	@Input() data: LCEMunicipalityPartial[];

	@Input() label?: string;
	@Input() singleDataShowIcon?: boolean;

	@ViewChild('municipalitiesOverlay') municipalitiesOverlay: OverlayPanel;

	nMunicipality: number;

	ngOnInit(): void {
		XSAssert.notEmpty(this.data, 'data');
		this.nMunicipality = this.data.length;
	}

	openMunicipalities(event: any) {
		this.municipalitiesOverlay.toggle(event);
	}
}
