import {Injectable} from '@angular/core';
import {LCEFacilityTownHallStampLoad} from '@lce/core';
import {XSAssert, XSIDCodeFullName, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEFacilityTownHallStampLoadRecordComponent} from './record/lce-facility-town-hall-stamp-load-record.component';
import {LCEFacilityTownHallStampLoadCreateComponent} from './create/lce-facility-town-hall-stamp-load-create.component';

export interface LCEFacilityTownHallStampLoadDialogCreateArg {
    showRecordAfterSave?: boolean;
    facility: XSIDCodeFullName;
    lastLoadDate: string | Date,
    onCancel?: () => void;
    onClose?: (facilityTownHallStampLoad?: LCEFacilityTownHallStampLoad) => void;
    onSave?: (facilityTownHallStampLoad?: LCEFacilityTownHallStampLoad) => void;
}

export interface LCEFacilityTownHallStampLoadDialogRecordArg {
    facilityTownHallStampLoadID?: string;
    facilityTownHallStampLoad?: LCEFacilityTownHallStampLoad;
    onClose?: (facilityTownHallStampLoad?: LCEFacilityTownHallStampLoad) => void;
}

@Injectable()
export class LCEFacilityTownHallStampLoadDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEFacilityTownHallStampLoadDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.facilityTownHallStampLoadID) && XSUtils.isEmpty(arg.facilityTownHallStampLoad)) {
            throw new Error('arg.facilityTownHallStampLoadID and arg.facilityTownHallStampLoad cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.facilityTownHallStampLoadID) && !XSUtils.isEmpty(arg.facilityTownHallStampLoad)) {
            throw new Error('arg.facilityTownHallStampLoadID and arg.facilityTownHallStampLoad cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                facilityTownHallStampLoadID: arg.facilityTownHallStampLoadID,
                facilityTownHallStampLoad: arg.facilityTownHallStampLoad,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: false,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEFacilityTownHallStampLoadRecordComponent = this.dialogService.openComponentDialog(LCEFacilityTownHallStampLoadRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedFacilityTownHallStampLoadRecord: LCEFacilityTownHallStampLoad) => {
            if (arg?.onClose) arg!.onClose!(savedFacilityTownHallStampLoadRecord);
        });
    }

    public openCreateDialog(arg: LCEFacilityTownHallStampLoadDialogCreateArg): void {
        XSAssert.notEmpty(arg.facility);

        const dConfig: XSDialogConfig = {
            data: {
                facility: arg.facility,
                lastLoadDate: arg.lastLoadDate,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-450 xs-min-width-800'
            },
            header: true,
            footer: true,
            headerSeparator: false,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-800'
        };

        const createComponent: LCEFacilityTownHallStampLoadCreateComponent = this.dialogService.openComponentDialog(LCEFacilityTownHallStampLoadCreateComponent, dConfig);

        createComponent.closeEvent.subscribe((facilityTownHallStampLoad: LCEFacilityTownHallStampLoad) => {
            if (arg?.onClose) arg!.onClose!();
        });
        createComponent.saveEvent.subscribe((facilityTownHallStampLoad: LCEFacilityTownHallStampLoad) => {
            if (!facilityTownHallStampLoad) {
                createComponent.closeDialog();
                return;
            }
            if (arg?.onSave) arg.onSave(facilityTownHallStampLoad);
            createComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({facilityTownHallStampLoad: facilityTownHallStampLoad});
        });
    }
}
