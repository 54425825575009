import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceAuditFullService, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../constants/lce-core-endpoint.constant';
import {LCESuggestion, LCESuggestionCreate, LCESuggestionPartial, LCESuggestionSearch, LCESuggestionStatsStatuses, LCESuggestionStatus} from '../domain/lce-suggestion';
import {LCEHttpClientService} from './lce-http-client.service';
import {LCEValidatorUtils} from '../utils/lce-validator-utils';
import {HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LCESuggestionService extends XSPKResourceAuditFullService<LCESuggestion, LCESuggestionPartial, LCESuggestionSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.suggestions.index, 'suggestion');
    }

    public retrieveStatsStatuses(municipalityCode: string, predefinedPeriod: XSPredefinedPeriod): Observable<LCESuggestionStatsStatuses> {
        let params = new HttpParams();
        if (!XSUtils.isEmpty(municipalityCode)) {
            params = params.set('municipalityCode', municipalityCode);
        }
        if (!XSUtils.isEmpty(predefinedPeriod)) {
            params = params.set('predefinedPeriod', predefinedPeriod);
        }
        return this.httpClientService.get<LCESuggestionStatsStatuses>(this.buildEndpoint(LCE_CORE_ENDPOINTS.suggestions.statsStatuses), params);
    }

    public updateStatus(suggestionID: string, status: LCESuggestionStatus): Observable<LCESuggestion> {
        XSAssert.notEmpty(suggestionID, 'suggestionID');
        XSAssert.notEmpty(status, 'status');
        const params = new HttpParams().set('status', status);
        return this.httpClientService.patch<LCESuggestion>(this.buildEndpoint(suggestionID, LCE_CORE_ENDPOINTS.suggestions.status), null, params);
    }

    public create(suggestionCreate: LCESuggestionCreate): Observable<LCESuggestion> {
        LCEValidatorUtils.validateSuggestionCreate(suggestionCreate);
        return this.httpClientService.post<LCESuggestion>(this.buildEndpoint(LCE_CORE_ENDPOINTS.suggestions.index), suggestionCreate);
    }
}
