import {Injectable} from '@angular/core';
import {LCESharedService} from '@lce/shared';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LCEMBOSharedService extends LCESharedService {

    private refreshDashboardSubject = new Subject<void>();
    onDashboardRefresh = this.refreshDashboardSubject.asObservable();

    private refreshSuggestionSubject = new Subject<void>();
    onSuggestionRefresh = this.refreshSuggestionSubject.asObservable();

    constructor() {
        super();
    }

    public emitSuggestionRefresh() {
        this.refreshSuggestionSubject.next();
    }

    public emitDashboardRefresh() {
        this.refreshDashboardSubject.next();
    }
}
