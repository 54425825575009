import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {XSPagination, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XS_PREDEFINED_PERIOD_ITEMS, XSCommonUtils, XSLabelValueItem, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSCoreContextService} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEAuthenticationLoginAttemptPartial, LCEAuthenticationLoginAttemptSearch} from '../../api/domain/lce-authentication-login-attempt';
import {LCEAuthenticationLoginAttemptService} from '../../api/services/lce-authentication-login-attempt.service';

@Component({selector: 'lce-authentication-login-attempts', templateUrl: 'lce-authentication-login-attempts.component.html'})
export class LCEAuthenticationLoginAttemptsComponent implements OnInit, OnDestroy {

    readonly TR_BASE: string = 'xs.core.authentication.';
    readonly ICON = LCE_SHARED_ICON;
    readonly PAGINATOR_ROWS_PER_PAGE_OPTIONS: number[] = [5, 10, 20];
    readonly PAGINATOR_ROWS: number = this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[1];

    readonly PRIMARY_LOADER_ID = XSUtils.uuid();
    readonly SECONDARY_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() showAuthenticationStateColor?: boolean;

    @Input() exporting?: boolean;

    loginAttempts: LCEAuthenticationLoginAttemptPartial[];
    nLoginAttempts: number;

    predefinedPeriodFilterOptions: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XSCommonUtils.removeItemByValues(XS_PREDEFINED_PERIOD_ITEMS, XSPredefinedPeriod.THIS_YEAR));
    predefinedPeriodFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;

    // --- Pagination ---
    pagination: XSPagination = {page: 0, size: this.PAGINATOR_ROWS};
    loginAttemptSearch: LCEAuthenticationLoginAttemptSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size
    };

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    firstInitialization: boolean = true;

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: XSCoreContextService,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private lceAuthenticationLoginAttemptService: LCEAuthenticationLoginAttemptService) {
        this.initializeFilterOptions(this.predefinedPeriodFilterOptions);
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showAuthenticationStateColor)) this.showAuthenticationStateColor = false;
        if (XSUtils.isNull(this.exporting)) this.exporting = false;
        if (!XSUtils.isNull(this.contextService.getUser().email)) this.loginAttemptSearch.username?.push(this.contextService.getUser().email!);
        this.loginAttemptSearch.username?.push(this.contextService.getUser().code, this.contextService.getUser().primaryPhoneNumber);
        this.search();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isPageable(): boolean {
        return this.nLoginAttempts > 0 && this.nLoginAttempts > this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[0];
    }

    public handlePagination(event: any) {
        this.pagination.page = event.page;
        this.pagination.size = event.rows;
        this.search();
    }

    public search(): void {
        this.startLoader();
        this.error = undefined;
        this.subscription.add(
            this.lceAuthenticationLoginAttemptService
                .search(this.loginAttemptSearch)
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: searchResult => {
                        this.loginAttempts = searchResult.data;
                        this.nLoginAttempts = searchResult.total;
                        this.firstInitialization = false;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

    public onExport(): void {
        console.log('onExport ...');
    }

    public onPredefinedPeriodFilterChange(): void {
        this.loginAttemptSearch.createdOnPredefinedPeriod = this.predefinedPeriodFilter;
        this.search();
    }

    private startLoader(): void {
        if (this.firstInitialization) {
            this.loaderService.startLoader(this.PRIMARY_LOADER_ID);
        } else {
            this.loaderService.startLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private stopLoader(): void {
        if (this.loaderService.isLoaderRunning(this.PRIMARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.PRIMARY_LOADER_ID);
        }
        if (this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private initializeFilterOptions(filterOptions: XSLabelValueItem[]): void {
        this.translationService.translateItems(filterOptions);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(filterOptions);
                filterOptions = [...filterOptions];
            })
        );
    }
}
