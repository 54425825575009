import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMBOSharedModule} from '@lce-mbo/app/shared/lce-mbo-shared.module';
import {LCEMBOResourcesComponent} from '@lce-mbo/features/resources/lce-mbo-resources.component';
import {LCEMBODistrictsModule} from '@lce-mbo/features/districts/lce-mbo-districts.module';
import {LCEMBOMunicipalitiesModule} from '@lce-mbo/features/municipalities/lce-mbo-municipalities.module';
import {LCEMBOFacilitiesModule} from '@lce-mbo/features/facilities/lce-mbo-facilities.module';
import {LCEMBOServicePointsModule} from '@lce-mbo/features/service-points/lce-mbo-service-points.module';

@NgModule({
    imports: [
        CommonModule,
        LCEMBOSharedModule,
        LCEMBODistrictsModule,
        LCEMBOMunicipalitiesModule,
        LCEMBOFacilitiesModule,
        LCEMBOServicePointsModule
    ],
    declarations: [
        LCEMBOResourcesComponent
    ]
})
export class LCEMBOResourcesModule {
}
