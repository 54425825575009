<div class="xs-card xs-min-width-600 {{styleClass}}">
    <div class="xs-absolute-center-vh">
        <xs-loader #mainLoader [loaderId]="loaderID.main" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !mainLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error.main"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}priceSettings.errorMessageRetrieveSettings"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div *ngIf="canDisplayData()"
         [ngClass]="{'xs-disabled': refreshLoader.isRunning()}">
        <div class="xs-card-header xs-card-header-separator">
            <xs-title
                    [expandCollapseEnable]="true"
                    [expandCollapseTarget]="priceSettingsContent"
                    [expanded]="true"
                    [subText]="TR_BASE + 'priceSettings.subtitle'"
                    [text]="TR_BASE + 'priceSettings.title'"
                    textStyleClass="xs-font-size-medium-imp">
            </xs-title>
        </div>
        <div #priceSettingsContent class="xs-card-content xs-pt-50-imp">
            <div class="xs-position-relative xs-mr-25">
                <xs-loader #refreshLoader [loaderId]="loaderID.refresh" [loaderSize]="20" class="xs-absolute-center-vh"></xs-loader>
            </div>

            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.unitPrice'"
                        [line2]="TR_BASE + 'priceSettings.label.unitPriceDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>
                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('unitPrice',$event)"
                            [data]="priceSettings!.orderUnitPrice"
                            [disabled]="unitPriceLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #unitPriceLoader [loaderId]="loaderID.unitPrice"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.unitPrice && !unitPriceLoader.isRunning()"
                            [error]="error.unitPrice"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>

            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.taxes'"
                        [line2]="TR_BASE + 'priceSettings.label.taxesDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>

                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('tax',$event)"
                            [data]="priceSettings!.taxAmount"
                            [disabled]="taxLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #taxLoader [loaderId]="loaderID.tax"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.tax && !taxLoader.isRunning()"
                            [error]="error.tax"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>

            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.fees'"
                        [line2]="TR_BASE + 'priceSettings.label.feesDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>

                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('fees',$event)"
                            [data]="priceSettings!.feesAmount"
                            [disabled]="feesLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #feesLoader [loaderId]="loaderID.fees"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.tax && !feesLoader.isRunning()"
                            [error]="error.tax"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>

            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.dhlUnitPrice'"
                        [line2]="TR_BASE + 'priceSettings.label.dhlUnitPriceDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>

                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('dhl',$event)"
                            [data]="priceSettings!.dhlUnitPrice"
                            [disabled]="dhlUnitPriceLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #dhlUnitPriceLoader [loaderId]="loaderID.dhl"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.dhl && !dhlUnitPriceLoader.isRunning()"
                            [error]="error.dhl"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>

            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.upsUnitPrice'"
                        [line2]="TR_BASE + 'priceSettings.label.upsUnitPriceDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>

                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('ups',$event)"
                            [data]="priceSettings!.upsUnitPrice"
                            [disabled]="upsUnitPriceLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #upsUnitPriceLoader [loaderId]="loaderID.ups"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.ups && !upsUnitPriceLoader.isRunning()"
                            [error]="error.ups"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>
            <div class="xs-mb-30 xs-flex-row xs-justify-content-space-between xs-align-items-center">
                <xs-ragnar
                        [line1]="TR_BASE + 'priceSettings.label.servicePointDeliveryFees'"
                        [line2]="TR_BASE + 'priceSettings.label.servicePointDeliveryFeesDescription'"
                        line1StyleClass="xs-font-size-medium xs-font-weight-600">
                </xs-ragnar>

                <div class="xs-flex-column xs-position-relative">
                    <xs-amount-editable
                            (saveEvent)="onPriceChange('servicePointDeliveryFees',$event)"
                            *ngIf="priceSettings!.servicePointDeliveryFees | xsIsNotEmpty"
                            [data]="priceSettings!.servicePointDeliveryFees!"
                            [disabled]="servicePointLoader.isRunning()"
                            styleClass="xs-min-width-150 xs-align-items-end">
                    </xs-amount-editable>
                    <div class="xs-position-absolute xs-bottom-4 xs-left-80">
                        <xs-loader #servicePointLoader [loaderId]="loaderID.servicePointDeliveryFees"></xs-loader>
                    </div>
                    <xs-error
                            *ngIf="error.servicePoint && !servicePointLoader.isRunning()"
                            [error]="error.servicePoint"
                            [showErrorDetailButton]="true"
                            [showIcon]="false"
                            message="{{ TR_BASE }}priceSettings.settingsUpdateErrorMessage"
                            mode="inline"
                            styleClass="xs-mt-5">
                    </xs-error>
                </div>
            </div>

        </div>

        <!--  =================================== TRIP DEFINITION ========================================-->


        <div class="xs-card-header xs-card-header-separator">
            <xs-title
                    [expandCollapseEnable]="true"
                    [expandCollapseTarget]="tripDefinitions"
                    [expanded]="true"
                    [subText]="TR_BASE + 'tripDefinitions.subtitle'"
                    [text]="TR_BASE + 'tripDefinitions.title'"
                    textStyleClass="xs-font-size-medium-imp">
            </xs-title>
        </div>

        <div #tripDefinitions class="xs-card-content xs-pt-50-imp">
            <div class="grid xs-align-items-center xs-mb-25">
                <div class="col-2">
                    <xs-input-field-text [options]="tripDefinitionTitleField"></xs-input-field-text>
                </div>
                <div class="col-2">
                    <xs-input-field-text [options]="tripDefinitionStartField"></xs-input-field-text>

                </div>
                <div class="col-2">
                    <xs-input-field-text [options]="tripDefinitionEndField"></xs-input-field-text>

                </div>
                <div class="col-4">
                    <xs-input-field-amount [options]="tripDefinitionPriceField"></xs-input-field-amount>
                </div>
                <div class="col-2">
                    <xs-button
                            (clickEvent)="onSaveTripDefinition()"
                            label="xs.core.label.save"
                            size="small"
                            type="text">
                    </xs-button>
                </div>
            </div>
            <ng-container *ngIf="priceSettings!.tripDefinitions | xsIsNotEmpty">
                <ul class="xs-listing xs-listing-separator">

                    <li>
                        <div class="grid">
                            <div class="col-2">{{ TR_BASE + 'tripDefinitions.label.title' | translate }}</div>
                            <div class="col-2">{{ TR_BASE + 'tripDefinitions.label.start' | translate }}</div>
                            <div class="col-2">{{ TR_BASE + 'tripDefinitions.label.end' | translate }}</div>
                            <div class="col-4">{{ TR_BASE + 'tripDefinitions.label.price' | translate }}</div>
                            <div class="col-2">
                                Action
                            </div>
                        </div>
                    </li>
                    <li *ngFor="let tripDefinition of  priceSettings!.tripDefinitions" class="xs-listing-item">
                        <div class="grid">
                            <div class="col-2">{{ tripDefinition.title }}</div>
                            <div class="col-2">{{ tripDefinition.minKM }}</div>
                            <div class="col-2">{{ tripDefinition.maxKM }}</div>
                            <div class="col-4">
                                <xs-amount [data]="tripDefinition.price" size="medium"></xs-amount>
                            </div>
                            <div class="col-2">
                                <xs-button
                                        (clickEvent)="onDeleteTripDefinition(tripDefinition)"
                                        label="xs.core.label.delete"
                                        size="small"
                                        type="text">
                                </xs-button>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>

        </div>
    </div>

</div>