import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { XSAssert, XSUtils } from '@xs/base';
import { XSInputFieldChipsOptions } from '@xs/core';
import { map } from 'rxjs/operators';
import { LCEArticleTagUtils } from './lce-article-tag-utils';
import { LCEArticleTagsOptions } from './lce-article-tags-options';

@Component({ selector: 'lce-article-tags', template: `<xs-input-field-chips [options]="inputField"></xs-input-field-chips>` })
export class LCEArticleTagsComponent implements OnInit {
    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() options: LCEArticleTagsOptions;

    @Input() control?: FormControl;
    @Input() tags: string[];

    @Output() addEvent = new EventEmitter<string>();
    @Output() removeEvent = new EventEmitter<string>();

    inputField: XSInputFieldChipsOptions;

    constructor() {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.options, 'options');

        if (this.options.mode === 'input') {
            XSAssert.notNull(this.control, 'control');
            if (!XSUtils.isEmpty(this.tags)) throw new Error('tags cannot be set in input mode.');
        } else {
            XSAssert.notNull(this.tags, 'tags');
            if (!XSUtils.isNull(this.control)) throw new Error('control cannot be set in view mode.');
        }

        if (XSUtils.isNull(this.control)) this.control = new FormControl();
        if (!XSUtils.isEmpty(this.tags)) {
            this.control = new FormControl(LCEArticleTagUtils.toChips(this.tags, this.options.canRemoveAlreadyAdded));
        }

        this.buildField();
        this.installEvents();
    }

    private installEvents(): void {
        this.addEvent.subscribe((tag) => {
            if (this.options.onAdd) this.options.onAdd(tag);
        });
        this.removeEvent.subscribe((tag) => {
            if (this.options.onRemove) this.options.onRemove(tag);
        });
    }

    private buildField(): void {
        this.inputField = {
            fieldName: 'tags',
            control: this.control,
            chipsRemovable: true,
            disabled: this.disabled,
            canAdd: this.options.canAdd,
            toggleMode: this.options.mode === 'view',
            addButtonLabel: 'lce.shared.news.label.addTags',
            autoCompletePlaceholder: 'lce.shared.news.label.addTags',
            chipStyleClass: 'xs-background-color-transparent xs-border-light',
            addChipEvent: (selectedChip) => {
                this.addEvent.emit(selectedChip.value);
            },
            removeChipEvent: (removedChip) => {
                this.removeEvent.emit(removedChip.value);
            },
            autoCompleteOptions: {
                labelField: 'label',
                maxNumberOfResults: 5,
                search: (query: string) => this.options.autocomplete(query, 5).pipe(map((tags) => LCEArticleTagUtils.toLabelValues(tags))),
            },
        };
    }
}
