import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEFacilityTownHallStampTokenGeneratorComponent} from '../generator/lce-facility-town-hall-stamp-token-generator.component';
import {LCEFacilityTownHallStampTokenHistoryUserComponent} from '../history/lce-facility-town-hall-stamp-token-history-user.component';
import {LCEFacilityStampTokenManagerService} from '../lce-facility-stamp-token-manager.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-manager',
    templateUrl: './lce-facility-town-hall-stamp-token-manager.component.html',
    providers: [LCEFacilityStampTokenManagerService]
})
export class LCEFacilityTownHallStampTokenManagerComponent implements OnInit {

    readonly ICON_REFRESH = LCE_SHARED_ICON.refresh;
    readonly ICON_SETTINGS = LCE_SHARED_ICON.setting;
    readonly ICON_DELETE = LCE_SHARED_ICON.delete;

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.label.';

    readonly ERROR_DELETE_LAST: string = 'lce.shared.facility.townHallStampToken.error.deleteLast';
    readonly ERROR_CREATE: string = 'lce.shared.facility.townHallStampToken.error.create';

    @Input() styleClass?: string;

    @Input() facilityCode: string;

    @ViewChild('stampTokenGenerator') stampTokenGenerator: LCEFacilityTownHallStampTokenGeneratorComponent;
    @ViewChild('stampTokenHistoryUser') stampTokenHistoryUser: LCEFacilityTownHallStampTokenHistoryUserComponent;

    error: any;
    errorMessage?: string;

    fullScreenState: boolean = false;

    currentTabIndex: number = 0;

    loading = {
        deleteLast: false,
        refreshStampTokenGenerator: false,
        refreshStampTokenHistoryUser: false
    };

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.facilityCode, 'facilityCode');
    }

    public refreshStampTokenGenerator(): void {
        this.loading.refreshStampTokenGenerator = true;
        this.stampTokenGenerator.update();
    }

    public refreshStampTokenHistoryUser(): void {
        this.loading.refreshStampTokenHistoryUser = true;
        this.stampTokenHistoryUser.update();
    }
}
