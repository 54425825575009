import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LCENewsArticlePartial, LCENewsArticleService, LCEResourceType} from '@lce/core';
import {LCEGlobalSearchTabPanelOptions} from '../../lce-global-search';
import {LCENewsArticleDialogService} from '../../../news/lce-news-article-dialog.service';

@Component({
    selector: 'lce-global-search-news-tab-panel',
    template: `
        <lce-global-search-tab-panel
                (errorEvent)="errorEvent.emit($event)"
                (totalResultsChange)="totalResultsChange.emit($event)"
                [options]="searchTabPanelOptions"
                [resourceType]="RESOURCE_TYPE"
                [styleClass]="styleClass">

            <ng-template let-oneResult xsTemplate="rowResult">
                <lce-news-article-partial [data]="oneResult" [showAvatar]="true" [showOverlay]="false" [showDescription]="true" avatarSize="medium">
                </lce-news-article-partial>
            </ng-template>

        </lce-global-search-tab-panel>
    `,
    providers: [LCENewsArticleDialogService]
})
export class LCEGlobalSearchNewsTabPanelComponent {

    readonly RESOURCE_TYPE = LCEResourceType.NEWS_ARTICLE;

    @Input() styleClass?: string;

    @Output() totalResultsChange = new EventEmitter<number>();
    @Output() errorEvent = new EventEmitter<any>();

    searchTabPanelOptions: LCEGlobalSearchTabPanelOptions<LCENewsArticlePartial> = {
        search: searchFilter => this.newsArticleService.search(searchFilter),
        openRecord: articlePartial => this.newsArticleDialogService.openArticleDialog({articleID: articlePartial.id}),
        captionNResultFound: 'lce.shared.news.articles.resultsFound',
        biColumnResults: true,
        defaultPaginationSize: 10,
        columnResultSize: 5
    }

    constructor(private newsArticleService: LCENewsArticleService, private newsArticleDialogService: LCENewsArticleDialogService) {
    }
}
