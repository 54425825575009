import {Component, OnInit} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCEFacilitiesFeatureService, LCEFacilityDialogService} from '@lce/shared';
import {LCEFacilityCanOptions} from '@lce/core';

@Component({
    selector: 'lce-mbo-facilities',
    templateUrl: './lce-mbo-facilities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEFacilityDialogService]
})
export class LCEMBOFacilitiesComponent implements OnInit {

    readonly ICON_PLUS: string = LCE_MBO_ICON.plus;
    readonly ICON_REFRESH: string = LCE_MBO_ICON.refresh;

    readonly TR_BASE: string = 'lce.shared.facility.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    can: LCEFacilityCanOptions;

    constructor(private featureService: LCEFacilitiesFeatureService, private facilityDialogService: LCEFacilityDialogService) {
    }

    ngOnInit(): void {
        this.can = {
            create: false
        };
    }

    public onCreate(): void {
        if (!this.can.create) return;
        this.facilityDialogService.openCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
