<div class="xs-width-full {{styleClass}}">
    <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750">
        <ng-template xsTemplate="sort">
            <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
        </ng-template>

        <!-- === Row Result Template ===-->
        <ng-template let-oneResult xsTemplate="row">
            <lce-facility-town-hall-payment-transfer-partial [data]="oneResult"></lce-facility-town-hall-payment-transfer-partial>
        </ng-template>

        <!-- === Record Template ===-->
        <ng-template let-recordData xsTemplate="record">
            <xs-payment-transfer-record
                    [transfer]="recordData"
                    loadingStyleClass="xs-min-height-500 xs-min-width-600"
                    styleClass="xs-width-full xs-max-width-1000 xs-p-25">
            </xs-payment-transfer-record>
        </ng-template>
    </xs-data-manager>
</div>
