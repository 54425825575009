import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LCECoreContextService, LCEUserCustomer, LCEUserCustomerService} from '@lce/core';
import {LOG, XSAddress, XSAddressType, XSAssert, XSUtils} from '@xs/base';
import {XS_TR_BASE_GENDER, XS_TR_BASE_LANGUAGE, XSIcon, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSAddressFieldOptions, XSButton, XSClipboardService, XSContactPersonFieldOptions, XSInputFieldTextAreaOptions, XSMenuItem} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-user-customer-general', templateUrl: './lce-user-customer-general.component.html'})
export class LCEUserCustomerGeneralComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_GENDER = XS_TR_BASE_GENDER;
    readonly TR_BASE_LANGUAGE = XS_TR_BASE_LANGUAGE;

    readonly TR_BASE: string = 'lce.shared.customer.label.';

    readonly userIcon = {value: LCE_SHARED_ICON.user, styleClass: 'xs-color-secondary'};
    readonly usedIDCopyIcon: XSIcon = {value: LCE_SHARED_ICON.copy, styleClass: 'xs-font-size-12-imp', clickable: true, onClick: () => this.copyCustomerID()};

    readonly NOTE_LOADER: string = XSUtils.uuid();

    @Input() styleClass?: string;

    @Input() data: LCEUserCustomer;
    @Output() dataChange = new EventEmitter<LCEUserCustomer>();

    @Output() editEvent = new EventEmitter<LCEUserCustomer>();
    @Output() purgeEvent = new EventEmitter<string>();

    loading: any = {
        lockCustomer: false,
        unlockCustomer: false,
        actionMenu: false
    };

    @ViewChild('actionMenu') actionMenu: TieredMenu;
    actionMenuItems: XSMenuItem[];

    contentDisabled: boolean = false;

    addressFieldOptions: XSAddressFieldOptions;
    emergencyContactPersonFieldOptions: XSContactPersonFieldOptions;
    noteFieldOptions: XSInputFieldTextAreaOptions;
    editNote: boolean = false;

    noteError: any;
    noteErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.updateNote()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private translationService: XSTranslationService,
        private clipboardService: XSClipboardService,
        private contextService: LCECoreContextService,
        private customerService: LCEUserCustomerService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildAddressFieldOptions();
        this.buildNoteFieldOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEdit(): void {
        this.editEvent.emit(this.data);
    }

    public onEditNote(): void {
        this.editNote = true;
    }

    public onCancelEditNote(): void {
        this.editNote = false;
    }

    public onSaveNote(): void {
        this.updateNote();
    }

    public isEditNote(): boolean {
        return this.editNote;
    }

    public isNoteLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.NOTE_LOADER);
    }

    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // -----------------------------------------------------------------------------------------------------------------------------------------------

    public onMenuClick(event: any): void {
        this.buildActionMenuItems();
        this.actionMenu.toggle(event);
    }

    private updateNote(): void {
        this.loaderService.startLoader(this.NOTE_LOADER);
        this.noteError = undefined;
        this.subscription.add(
            this.customerService
                .updateNote(this.data.id, this.noteFieldOptions.control?.value!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.NOTE_LOADER)))
                .subscribe({
                    next: (response) => {
                        this.data.note = response.note;
                        this.data.updatedOn = response.updatedOn;
                        this.data.updatedBy = this.contextService.getUser();
                        this.editNote = false;
                    },
                    error: (error) => (this.noteError = error)
                })
        );
    }

    private buildActionMenuItems(): void {
        this.actionMenuItems = [];
        this.translationService.translateItems(this.actionMenuItems);
    }

    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // -----------------------------------------------------------------------------------------------------------------------------------------------

    private buildNoteFieldOptions(): void {
        this.noteFieldOptions = {
            fieldName: 'note',
            control: new FormControl(this.data?.note)
        };
    }

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            formOptions: {
                type: this.data?.address?.type ? this.data.address.type : undefined!,
                typeSelection: true,
                allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED],
                showResetButton: false,
                title: false,
                onSave: (address: XSAddress) => {
                    LOG().debug('Saving Customer Address (' + this.data!.id + ') ...', address);
                    return this.customerService.updateAddress(this.data!.id, address).pipe(
                        tap((response) => {
                            this.data.address = response.address;
                            this.data.updatedOn = response.updatedOn;
                            this.data.updatedBy = this.contextService.getUser();
                        })
                    );
                }
            }
        };
    }

    private copyCustomerID(): void {
        this.clipboardService.copy(this.data.id);
    }
}
