<div class="xs-flex-column-center xs-width-full xs-flex-1 {{ styleClass }}">
    <div class="lce-smartcity-main-container lce-smartcity-create-container">
        <div class="lce-smartcity-create">
            <div class="lce-smartcity-create-inner">
                <div class="xs-absolute-center-vh">
                    <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
                </div>
                <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
                    <xs-error
                            [actionButton]="retrieveErrorRetryButton"
                            [error]="retrieveError"
                            [showActionButton]="true"
                            [showErrorDetailButton]="true"
                            message="lce.features.smartcity.label.errorMessageRetrieveArticle"
                            mode="block"
                            styleClass="xs-max-width-500"
                            subMessage="xs.core.error.contactAdminMessage">
                    </xs-error>
                </div>
                <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()"
                     class="xs-flex-row xs-width-full xs-justify-content-space-between xs-plr-15 xs-mb-40">
                    <div class="xs-flex-row"></div>
                    <xs-error
                            *ngIf="hasCreateUpdateError()"
                            [error]="createUpdateError"
                            [showBorder]="true"
                            [showErrorDetailButton]="true"
                            message="{{ TR_BASE }}{{ mode }}ErrorMessage"
                            mode="inline"
                            subMessage="xs.core.error.contactAdminMessage">
                    </xs-error>
                    <div class="xs-flex-row">
                        <xs-button
                                (clickEvent)="fillForm()"
                                *ngIf="canShowFillForm()"
                                class="xs-mr-50"
                                label="Fill Form"
                                size="intermediate"
                                size="intermediate"
                                type="text">
                        </xs-button>
                        <xs-button
                                (clickEvent)="reset()"
                                *ngIf="isCreateMode()"
                                [confirmation]="resetConfirmation"
                                [disabled]="createUpdateLoading"
                                [showConfirmation]="shouldShowResetConfirmation()"
                                confirmationPopupStyleClass="xs-font-size-10"
                                label="xs.core.label.reset"
                                size="intermediate"
                                type="text">
                        </xs-button>
                        <xs-button
                                (clickEvent)="close()"
                                [confirmation]="closeConfirmation"
                                [disabled]="createUpdateLoading"
                                [outlined]="true"
                                [showConfirmation]="shouldShowCloseConfirmation()"
                                confirmationPopupStyleClass="xs-font-size-10"
                                label="xs.core.label.close"
                                severity="secondary"
                                styleClass="xs-mlr-15-imp"
                                type="button">
                        </xs-button>
                        <xs-button
                                (clickEvent)="createUpdate()"
                                [loading]="createUpdateLoading"
                                label="xs.core.label.save"
                                loaderPosition="bottom" type="button">
                        </xs-button>
                    </div>
                </div>

                <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()"
                     class="grid grid-nogutter xs-flex-1 xs-width-full">
                    <div class="col-8">
                        <div class="lce-smartcity-create-left xs-border-discrete xs-border-radius-10">
                            <div [ngClass]="{ 'xs-disabled': createUpdateLoading }"
                                 class="grid xs-fields xs-fields-spacer-25">
                                <div class="col-12">
                                    <xs-input-field-text [options]="titleField"></xs-input-field-text>
                                </div>
                                <div class="col-12">
                                    <xs-input-field-text [options]="subTitleField"></xs-input-field-text>
                                </div>
                                <div class="col-12">
                                    <xs-input-field-editor [options]="descriptionField"></xs-input-field-editor>
                                </div>
                                <div class="col-12">
                                    <xs-input-field-editor [options]="contentField"></xs-input-field-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="lce-smartcity-create-right">
                            <div class="xs-card xs-card-border xs-mb-30">
                                <div class="xs-card-header">
                                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="typesCard"
                                              [expanded]="true" [text]="TR_BASE + 'typeTitle'"></xs-title>
                                </div>
                                <div #typesCard class="xs-card-content">
                                    <xs-input-field-dropdown [options]="typeField"></xs-input-field-dropdown>
                                </div>
                            </div>
                            <div class="xs-card xs-card-border xs-mb-30">
                                <div class="xs-card-header">
                                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="categoriesCard"
                                              [expanded]="true" [text]="TR_BASE + 'categoryTitle'"></xs-title>
                                </div>
                                <div #categoriesCard class="xs-card-content">
                                    <lce-article-categories
                                            [control]="categoriesControl"
                                            [options]="categoriesOptions">
                                    </lce-article-categories>
                                </div>
                            </div>

                            <div class="xs-card xs-card-border xs-mb-30">
                                <div class="xs-card-header">
                                    <xs-title [expandCollapseEnable]="true"
                                              [expandCollapseTarget]="tagsCard"
                                              [expanded]="true" [text]="TR_BASE + 'tagTitle'">
                                    </xs-title>
                                </div>
                                <div #tagsCard class="xs-card-content">
                                    <lce-article-tags [control]="tagsControl"
                                                      [options]="tagsOptions">
                                    </lce-article-tags>
                                </div>
                            </div>

                            <div class="xs-card xs-card-border">
                                <div class="xs-card-header">
                                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="reviewersContainer"
                                              [expanded]="true"
                                              [text]="TR_BASE + 'reviewerTitle'">
                                    </xs-title>
                                </div>
                                <div #reviewersContainer class="xs-card-content">
                                    <lce-article-reviewers
                                            [control]="reviewersControl"
                                            [options]="reviewersOptions"
                                            [reviewers]="this.smartCityArticle?.reviewers">
                                    </lce-article-reviewers>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

