<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card">
        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
                    <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
                        {{ 'lce.shared.customer.customerRecord.general' | translate }}
                    </span>
                </div>
                <div class="xs-flex-row">
                    <lce-customer-actions
                        #actions (dataChange)="dataChange.emit($event)"
                        (editEvent)="editEvent.emit($event)"
                        (purgeEvent)="purgeEvent.emit($event)"
                        [(data)]="data">
                    </lce-customer-actions>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'xs-disabled': actions.isLoading() }" class="xs-card-content xs-pt-50-imp">
            <div class="grid xs-fields xs-fields-spacer-30">
                <div class="col-12 md:col-8">
                    <lce-user-resource-audit *ngIf="data.createdBy | xsIsNotEmpty" [data]="data" styleClass="xs-mb-40"></lce-user-resource-audit>
                </div>
                <div class="col-12 md:col-4 xs-flex-row xs-align-items-center xs-justify-content-end">
                    <xs-data-field-resource-state [value]="data"></xs-data-field-resource-state>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Personal Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title styleClass="xs-mb-15" text="lce.shared.user.label.personalInformation"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [required]="false" [value]="data.name?.firstName" label="xs.common.label.firstName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [required]="false" [value]="data.name?.lastName" label="xs.common.label.lastName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [required]="false" [valueBaseTranslation]="TR_BASE_GENDER" [value]="data.gender" label="xs.common.label.gender"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [required]="false" [valueBaseTranslation]="TR_BASE_LANGUAGE" [value]="data.language" label="xs.common.label.language"></xs-data-field-text>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Account === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title styleClass="xs-mb-15" text="lce.shared.user.label.account"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [copyable]="true"
                            [ellipsis]="true"
                            [valueTooltip]="{ text: data.id }"
                            [value]="data.id"
                            label="lce.shared.user.label.userID"
                            valueStyleClass="xs-font-size-small xs-color-secondary xs-font-weight-normal-imp">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [copyable]="true"
                            [value]="data.code"
                            label="lce.shared.user.label.userCode"
                            valueStyleClass="xs-font-size-intermediate">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-calendar [required]="false" [value]="data.lastLoginOn" label="lce.core.label.lastLogin"></xs-data-field-calendar>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Contact === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title styleClass="xs-mb-15" text="xs.core.label.contact"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [value]="data.primaryPhoneNumber" label="xs.common.label.primaryPhoneNumber"></xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.secondaryPhoneNumber!" label="xs.common.label.secondaryPhoneNumber"></xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-email [required]="false" [value]="data.email" label="xs.common.label.email"></xs-data-field-email>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.whatsAppPhoneNumber!" label="xs.common.label.whatsAppPhoneNumber"></xs-data-field-phone-number>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-address
                            [editable]="false"
                            [options]="addressFieldOptions"
                            [required]="false"
                            [value]="data!.address"
                            label="xs.common.label.address">
                    </xs-data-field-address>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="lce.shared.user.label.note" textStyleClass="xs-color-secondary">
                        <ng-template xsTemplate="rightSection">
                            <xs-button (clickEvent)="onEditNote()" *ngIf="!isEditNote()" label="xs.core.label.edit" size="small" type="text"></xs-button>
                        </ng-template>
                    </xs-title>
                </div>

                <div class="col-12">
                    <div *ngIf="isEditNote()" class="xs-flex-column">
                        <div [ngClass]="{ 'xs-disabled': isNoteLoaderRunning() }">
                            <xs-input-field-textarea [options]="noteFieldOptions" styleClass="xs-mt-10 xs-mb-10-imp"></xs-input-field-textarea>
                        </div>
                        <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-plr-5">
                            <div class="xs-flex-row">
                                <!-- TODO: LCE-134 : error handling (error-inline) -->
                            </div>
                            <div class="xs-flex-row xs-align-items-center">
                                <xs-button
                                        (clickEvent)="onCancelEditNote()"
                                        [disabled]="isNoteLoaderRunning()"
                                        class="xs-mr-30"
                                        label="xs.core.label.cancel"
                                        size="intermediate"
                                        type="text">
                                </xs-button>
                                <xs-button
                                        (clickEvent)="onSaveNote()"
                                        [disabled]="isNoteLoaderRunning()"
                                        [loading]="isNoteLoaderRunning()"
                                        label="xs.core.label.save"
                                        loaderPosition="bottom"
                                        size="intermediate"
                                        type="button">
                                </xs-button>
                            </div>
                        </div>
                    </div>
                    <xs-data-field-textarea *ngIf="!isEditNote()" [required]="false" [value]="data.note" valueStyleClass="xs-font-size-intermediate"></xs-data-field-textarea>
                </div>
            </div>
        </div>

        <div class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="data.id"></xs-data-id>
        </div>
    </div>
</div>
