<div class="xs-flex-column xs-p-15 {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center">
        <xs-input-switch (changeEvent)="onVisibleLateralPanelChange()" [(value)]="visibleLateralPanel" styleClass="xs-mt-5 xs-mr-50">
        </xs-input-switch>
        <xs-ivar
                [line1]="TR_BASE + 'visibleLateralPanelLabel'"
                [line2]="TR_BASE + 'visibleLateralPanelSubLabel'"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
    <div class="xs-flex-row xs-align-items-center xs-mt-20">
        <xs-input-switch (changeEvent)="onVisibleLastSuggestionChange()" [(value)]="visibleLastSuggestion" styleClass="xs-mt-5 xs-mr-50">
        </xs-input-switch>
        <xs-ivar
                [line1]="TR_BASE + 'visibleLastSuggestionLabel'"
                [line2]="TR_BASE + 'visibleLastSuggestionSubLabel'"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
    <div class="xs-flex-row xs-mt-20">
        <div class="xs-flex-center xs-mt-5 xs-mr-50">
            <xs-input-switch (changeEvent)="onSoundAlertEnabledChange($event)" [value]="soundAlertEnabled"></xs-input-switch>
        </div>
        <xs-ivar
                line1="lce.shared.label.enableSoundAlertLabel"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2="lce.shared.label.enableSoundAlertSubLabel"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
</div>