<div class="xs-flex-row xs-align-items-center xs-justify-content-space-between {{ styleClass }}">
    <xs-ivar
        [disabled]="disabled"
        [icon]="ICON.circle"
        class="xs-width-fit-content"
        iconStyleClass="xs-font-size-10-imp xs-display-block xs-mb-15 {{ statusColorClass }}"
        line1="{{ data.code }}"
        line1StyleClass="xs-color-secondary xs-font-weight-500 xs-font-size-intermediate-imp"
        line2="{{ data.createdOn | xsDate : dateFormat }}"
        line2StyleClass="xs-text-default-color-imp xs-font-size-intermediate-imp">
    </xs-ivar>
    <div class="xs-overflow-hidden">
        <span *ngIf="data.anonymous == true" class="xs-display-block xs-font-size-extra-small xs-color-secondary xs-ml-10 xs-ellipsis">{{ 'lce.shared.label.anonymous' | translate }}</span>
    </div>
</div>
