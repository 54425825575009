import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LCECertificateOrderEventName, LCEEventSearch, LCEEventType, LCEResourceType } from '@lce/core';
import { XSInputFieldBaseOptions, XSInputMultiSelectOptions } from '@xs/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';
import { XSLabelValueItem, XSTranslationService } from '@xs/common';
import { XSUtils } from '@xs/base';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({ selector: 'lce-event-filters', templateUrl: './lce-event-filters.component.html' })
export class LCEEventFiltersComponent implements OnInit, OnDestroy {
    readonly TR_BASE = 'lce.shared.event.';
    readonly TR_EVENT_TYPE_BASE = 'lce.shared.event.eventType.';
    readonly TR_RESOURCE_TYPE_BASE = 'lce.shared.resourceType.';
    readonly TR_EVENT_NAME_BASE = 'lce.shared.event.eventName.';
    readonly ICON = LCE_SHARED_ICON;
    readonly ALL_EVENT_NAME_ITEMS = this.buildEventNameItems();

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;

    @Output() onFiltersChange = new EventEmitter<LCEEventSearch>();

    eventSearch: LCEEventSearch = {};

    eventTypes: LCEEventType[];
    eventTypeItems: XSLabelValueItem[] = [
        { trLabel: this.TR_EVENT_TYPE_BASE + LCEEventType.ADVERTISING, value: LCEEventType.ADVERTISING },
        { trLabel: this.TR_EVENT_TYPE_BASE + LCEEventType.ALERT, value: LCEEventType.ALERT },
        { trLabel: this.TR_EVENT_TYPE_BASE + LCEEventType.INFORMATION, value: LCEEventType.INFORMATION },
        { trLabel: this.TR_EVENT_TYPE_BASE + LCEEventType.RESOURCE, value: LCEEventType.RESOURCE },
        { trLabel: this.TR_EVENT_TYPE_BASE + LCEEventType.SYSTEM, value: LCEEventType.SYSTEM },
    ];

    resourceTypes: LCEResourceType[];
    resourceTypeItems: XSLabelValueItem[] = [
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.BIRTH_DECLARATION, value: LCEResourceType.BIRTH_DECLARATION },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.CERTIFICATE_ORDER, value: LCEResourceType.CERTIFICATE_ORDER },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.DISTRICT, value: LCEResourceType.DISTRICT },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.FACILITY, value: LCEResourceType.FACILITY },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.MUNICIPALITY, value: LCEResourceType.MUNICIPALITY },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.NEWS_ARTICLE, value: LCEResourceType.NEWS_ARTICLE },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.SMARTCITY_ARTICLE, value: LCEResourceType.SMARTCITY_ARTICLE },
        { trLabel: this.TR_RESOURCE_TYPE_BASE + LCEResourceType.SERVICE_POINT, value: LCEResourceType.SERVICE_POINT },
    ];

    eventNames: XSLabelValueItem[];
    eventNameItems: XSLabelValueItem[] = [];

    resourceTypeField: XSInputMultiSelectOptions;
    eventNameField: XSInputMultiSelectOptions;
    createdByAutocompleteField: XSInputFieldBaseOptions;
    districtsAutoCompleteField: XSInputFieldBaseOptions;
    municipalitiesAutoCompleteField: XSInputFieldBaseOptions;
    facilitiesAutoCompleteField: XSInputFieldBaseOptions;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService) {
        this.initializeTranslation();
    }

    ngOnInit(): void {
        this.buildFields();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private buildFields(): void {
        this.resourceTypeField = {
            labelField: 'trLabel',
            valueField: 'value',
            filter: true,
            filterBy: 'trLabel',
            showToggleAll: true,
            translateItemLabels: true,
        };

        this.eventNameField = {
            labelField: 'trLabel',
            valueField: 'value',
            filter: true,
            filterBy: 'trLabel',
            showToggleAll: true,
            translateItemLabels: true,
        };

        this.createdByAutocompleteField = {
            control: new FormControl(),
            fieldName: 'createdBy',
            belowText: this.TR_BASE + 'label.createdBy',
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small xs-mt-0-imp',
        };

        this.districtsAutoCompleteField = {
            control: new FormControl(),
            fieldName: 'districts',
            belowText: 'lce.shared.district.main.title',
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small xs-mt-0-imp',
        };

        this.municipalitiesAutoCompleteField = {
            control: new FormControl(),
            fieldName: 'municipalities',
            belowText: 'lce.shared.municipality.main.title',
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small xs-mt-0-imp',
        };

        this.facilitiesAutoCompleteField = {
            control: new FormControl(),
            fieldName: 'facilities',
            belowText: 'lce.shared.facility.main.title',
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small xs-mt-0-imp',
        };
    }

    public update(): void {
        this.eventNameItems = this.filterEventNames();
    }

    private filterEventNames(): XSLabelValueItem[] {
        // TODO
        return this.ALL_EVENT_NAME_ITEMS;
    }

    private buildEventNameItems(): XSLabelValueItem[] {
        const items: XSLabelValueItem[] = [];
        if (XSUtils.isEmpty(this.eventTypes) || XSUtils.isEmpty(this.resourceTypes)) this.getEventNames(items, LCECertificateOrderEventName);
        this.getEventNames(items, LCECertificateOrderEventName);
        this.getEventNames(items, LCECertificateOrderEventName);
        this.getEventNames(items, LCECertificateOrderEventName);

        return items;
    }

    private getEventNames(items: XSLabelValueItem[], eventName: any): void {
        const values = XSUtils.getEnumValues(eventName);
        values.forEach((value) => {
            items.push({ trLabel: this.TR_EVENT_NAME_BASE + value, value: value });
        });
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.eventNameItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.eventNameItems);
                this.eventNameItems = [...this.eventNameItems];
            })
        );

        this.translationService.translateItems(this.eventTypeItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.eventTypeItems);
                this.eventTypeItems = [...this.eventTypeItems];
            })
        );
    }
}
