import {Injectable} from '@angular/core';
import {
    LCEBirthDeclarationService,
    LCECertificateOrderService,
    LCEDistrictService,
    LCEFacilityService,
    LCEMunicipalityService,
    LCENewsArticleService,
    LCEResourceType,
    LCEServicePointService,
    LCESmartCityArticleService
} from '@lce/core';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {XSPKSearchFilter, XSUtils} from '@xs/base';
import {LCEGlobalSearchNResults} from './lce-global-search';
import {map, tap} from 'rxjs/operators';

export interface LCEGlobalSearchEvent {
    eventType: 'searchText' | 'tabChange';
    searchFilter: XSPKSearchFilter;
    currentResourceTypeTabPanel: LCEResourceType
}

@Injectable()
export class LCEGlobalSearchFeatureService {

    public numberOfResults: LCEGlobalSearchNResults = {
        nOrders: 0,
        nBirthDeclarations: 0,
        nNewsArticles: 0,
        nSmartCityArticles: 0,
        nDistricts: 0,
        nMunicipalities: 0,
        nFacilities: 0,
        nServicePoints: 0
    }

    public searchFilter: XSPKSearchFilter = {query: undefined};

    private currentResourceTypeTabPanel: LCEResourceType;

    private searchSubject = new BehaviorSubject<LCEGlobalSearchEvent>(undefined!);
    public onSearch = this.searchSubject.asObservable();

    private tabChangeSubject = new BehaviorSubject<LCEGlobalSearchEvent>(undefined!);
    public onTabChange = this.tabChangeSubject.asObservable();

    private updateSubject = new Subject<void>();
    public onUpdate = this.updateSubject.asObservable();

    constructor(
        private certificateOrderService: LCECertificateOrderService,
        private birthDeclarationService: LCEBirthDeclarationService,
        private newsArticleService: LCENewsArticleService,
        private smartCityArticleService: LCESmartCityArticleService,
        private districtService: LCEDistrictService,
        private municipalityService: LCEMunicipalityService,
        private facilityService: LCEFacilityService,
        private servicePointService: LCEServicePointService) {
    }

    public update(): Observable<void> {
        this.updateSubject.next();
        return this.countAll();
    }

    public countAll(): Observable<void> {
        if (XSUtils.isEmpty(this.searchFilter)) of(undefined);
        return forkJoin([
            this.certificateOrderService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nOrders = response.total)),
            this.birthDeclarationService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nBirthDeclarations = response.total)),
            this.newsArticleService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nNewsArticles = response.total)),
            this.smartCityArticleService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nSmartCityArticles = response.total)),
            this.districtService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nDistricts = response.total)),
            this.municipalityService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nMunicipalities = response.total)),
            this.facilityService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nFacilities = response.total)),
            this.servicePointService.count(this.searchFilter).pipe(tap(response => this.numberOfResults.nServicePoints = response.total))
        ])
            .pipe(map(() => undefined));
    }

    public emitTabChangeEvent(currentResourceTypeTabPanel: LCEResourceType) {
        this.currentResourceTypeTabPanel = currentResourceTypeTabPanel;
        this.tabChangeSubject.next({
            eventType: 'tabChange',
            searchFilter: this.searchFilter,
            currentResourceTypeTabPanel: this.currentResourceTypeTabPanel
        });
    }

    public emitSearchEvent(query: string) {
        this.searchFilter.query = query;
        this.searchSubject.next({
            eventType: 'searchText',
            searchFilter: this.searchFilter,
            currentResourceTypeTabPanel: this.currentResourceTypeTabPanel
        });
    }
}
