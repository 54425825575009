import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCEFacilityTownHallStampLoadPartial} from '@lce/core';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSTranslationService} from '@xs/common';
import {Subscription} from 'rxjs';
import {LCEFacilityTownHallStampLoadDialogService} from '../lce-facility-town-hall-stamp-load-dialog.service';

@Component({
    selector: 'lce-facility-town-hall-stamp-load-partial',
    host: {class: 'xs-width-full'},
    template: `
        <div [ngClass]="{ 'xs-disabled': disabled === true, 'xs-border-discrete xs-border-radius-default-imp xs-p-10': showBorder }" class="{{ styleClass }} xs-flex-column">
            <label *ngIf="label | xsIsNotEmpty" [ngClass]="{ 'lce-resource-partial-label-ml': showIcon }" class="xs-data-field-label lce-resource-partial-label {{ labelStyleClass }}">{{ label! | translate }}</label>
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full">
                <div class="xs-flex-row xs-align-items-center xs-width-full">
                    <xs-icon *ngIf="showIcon" [ngClass]="{'xs-clickable': viewRecord}" styleClass="lce-resource-partial-avatar-icon xs-color-secondary-imp xs-font-size-20-imp" [value]="ICON.stamp"></xs-icon>
                    <div class="xs-flex-column">
                    <span [ngClass]="{'xs-clickable': viewRecord}" (click)="onClick()"
                          class="xs-display-block xs-font-weight-500 xs-mb-1 {{ intermediate ? 'xs-font-size-intermediate-imp' : '' }} {{ sizeStyleClass }}"> 
                        {{ 'lce.shared.facility.townHallStampLoad.label.loadDate' | translate: {date: (data.createdOn | xsDate : format)} }}</span>
                        <xs-time-ago styleClass="xs-display-block xs-font-size-small-imp xs-color-secondary" [givenDate]="data.createdOn"></xs-time-ago>
                    </div>
                </div>

                <span class="xs-display-block xs-font-size-medium xs-color-secondary"> {{ data.size }}</span>
            </div>
        </div>
    `
})
export class LCEFacilityTownHallStampLoadPartialComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;
    @Input() styleClass?: string;

    @Input() sizeStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() intermediate?: boolean;

    @Input() labelStyleClass?: string;
    @Input() label?: string;
    @Input() showDefaultLabel?: boolean;

    @Input() showCode?: boolean;

    @Input() showIcon?: boolean;

    @Input() showBorder?: boolean;

    @Input() viewRecord?: boolean;

    @Input() data: LCEFacilityTownHallStampLoadPartial;

    format: string;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService, private facilityTownHallStampLoadDialogService: LCEFacilityTownHallStampLoadDialogService) {
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.handleDateFormat()));
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.showCode)) this.showCode = true;
        if (XSUtils.isNull(this.intermediate)) this.intermediate = true;
        if (this.showDefaultLabel === true) {
            this.label = 'lce.core.label.facility.';
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onClick(): void {
        if (!this.viewRecord) return;
        this.facilityTownHallStampLoadDialogService.openRecordDialog({facilityTownHallStampLoadID: this.data.id});
    }

    private handleDateFormat(): void {
        const currentLanguage = this.translationService.getCurrentLanguage();
        this.format = currentLanguage === XSLanguage.FRENCH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR : XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN;
    }

}