import {Injectable} from '@angular/core';
import {XSAssert, XSHttpParamsUtils, XSSearchResult, XSUtils} from '@xs/base';
import {XSPaymentTransfer, XSPaymentTransferPartial, XSPaymentTransferSearch} from '@xs/payment/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEHttpClientService} from '../lce-http-client.service';

const BASE_ENDPOINT = LCE_CORE_ENDPOINTS.facilities.townHalls.transfers;

@Injectable({providedIn: 'root'})
export class LCEFacilityTownHallService {

    constructor(protected httpClientService: LCEHttpClientService) {
    }

    public retrieveLastTransfer(facilityID: string): Observable<XSPaymentTransferPartial> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        return this.httpClientService.get<XSPaymentTransferPartial>(BASE_ENDPOINT.last.replace('{facilityID}', facilityID.trim()));
    }

    public searchTransfers(facilityID: string, search: XSPaymentTransferSearch): Observable<XSSearchResult<XSPaymentTransferPartial>> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(search, 'search');
        XSUtils.removeNullAndUndefinedEntries(search);
        XSUtils.removeEmptyObjectEntries(search);
        const httpParams = XSHttpParamsUtils.toHttpParameters(search);
        return this.httpClientService.get<XSSearchResult<XSPaymentTransferPartial>>(BASE_ENDPOINT.index.replace('{facilityID}', facilityID.trim()), httpParams);
    }

    public retrieveTransfer(facilityID: string, transferID: string): Observable<XSPaymentTransfer> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(transferID, 'transferID');
        return this.httpClientService.get<XSPaymentTransfer>(
            BASE_ENDPOINT.retrieve
                .replace('{facilityID}', facilityID.trim())
                .replace('{transferID}', transferID.trim())
        );
    }
}
